import React, { Component } from "react";
import Input from "antd/es/input";
import Button from "antd/es/button";
import message from "antd/es/message";
import "./styles.scss";
import { intl } from "../../utils/intl_i18n";
import axiosConfig from "../../axiosConfig";
import ROUTES from "../../routes/api.json";
import PageContent from "../common/PageContent";

class ResetPasswordRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordResetToken: "",
      password: "",
      confirmPassword: "",
    };
  }
  getLogin = () => {
    sessionStorage.clear();
    this.props.history.push("/login");
  };

  handleOTTChange = (value) => {
    this.setState({ passwordResetToken: value });
  };

  handlePasswordChange = (value) => {
    this.setState({ password: value });
  };

  handleConfirmPasswordChange = (value) => {
    this.setState({ confirmPassword: value });
  };

  fieldValidation = () => {
    const { password, confirmPassword } = this.state;
    if (!password) {
      message.warning("Please enter a password.");
      return;
    }
    if (!confirmPassword) {
      message.warning("Please re-enter your password.");
      return;
    }
    this.confirmResetPassword();
  };

  confirmResetPassword = () => {
    let userEntity = {};
    userEntity.email = sessionStorage.getItem("userMail");
    userEntity.passwordResetToken =
      this.state.passwordResetToken || sessionStorage.getItem("oneTimeToken");
    userEntity.password = this.state.password;
    if (this.state.password === this.state.confirmPassword) {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axiosConfig
        .post(ROUTES.CONFIRM_RESET_PASSWORD, userEntity, config)
        .then((res) => {
          message.success("Your password has been changed successfully.");
          sessionStorage.clear();
          this.props.history.push("/login");
        })
        .catch((error) => {
          if (error.response && error.response.status == 400) {
            message.error("Password should be minimum 8 characters!");
            return;
          }
          message.error(
            "We could not authenticate your request due to an invalid one-time token.  Please contact Sales."
          );
        });
    } else {
      message.warning("Your two password entries don't match.");
    }
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  componentDidMount() {
    let oneTimeToken = new URLSearchParams(this.props.location.search).get(
      "ott"
    )
      ? new URLSearchParams(this.props.location.search).get("ott")
      : sessionStorage.getItem("oneTimeTOken");
    let userMail = new URLSearchParams(this.props.location.search).get("email")
      ? new URLSearchParams(this.props.location.search).get("email")
      : sessionStorage.getItem("userMail");
    if (
      new URLSearchParams(this.props.location.search).get("ott") != null &&
      new URLSearchParams(this.props.location.search).get("email") != null
    ) {
      sessionStorage.setItem("userMail", userMail);
      sessionStorage.setItem("oneTimeToken", oneTimeToken);
      this.props.history.push("/confirm-reset");
    }
  }

  render() {
    return (
      <PageContent
        className="reset-container"
        childrenClassName={"reset-password-main h-full"}
        renderMenu={false}
      >
        <div className="w-full flex flex-col">
          <div className="max-w-sm font-din2014 rounded-2xl mx-auto py-8 px-10 w-10/12 md:w-1/2 lg:w-2/3 xl:w-3/4 bg-blur-linear-gradient backdrop-blur login-box-shadow">
            <h2 className="text-center text-[32px] font-extralight text-[#FFFFFF] mb-1">
              {intl.formatMessage({ id: "login.reset.password" })}
            </h2>
            <h3 className="text-center text-base font-extralight text-[#D9D9D9] mb-7">
              {intl.formatMessage({ id: "confirm.reset.content" })}
            </h3>
            <div className="flex flex-col items-center w-full">
              <div className="w-full flex flex-col prism-login-form">
                <Input
                  name="password"
                  type="password"
                  size="large"
                  placeholder={intl.formatMessage({
                    id: "confirm.reset.new.pass.phrase",
                  })}
                  onChange={(e) => this.handlePasswordChange(e.target.value)}
                  className="!mb-4 text-[#D9D9D9] bg-black bg-black-gradient"
                  style={{
                    borderRadius: "20px",
                  }}
                  autoComplete="off"
                />

                <Input
                  name="confirmPassword"
                  type="password"
                  size="large"
                  placeholder={intl.formatMessage({
                    id: "confirm.reset.new.pass.phrase.reenter",
                  })}
                  onChange={(e) =>
                    this.handleConfirmPasswordChange(e.target.value)
                  }
                  className="!mb-4 text-[#D9D9D9] bg-black bg-black-gradient"
                  style={{
                    borderRadius: "20px",
                  }}
                  autoComplete="off"
                />

                <button
                  className="text-[#D9D9D9] text-sm py-1 px-3 self-end bg-black bg-black-gradient rounded-[20px]"
                  onClick={this.fieldValidation}
                >
                  {intl.formatMessage({ id: "login.reset.password" })}
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="text-white mt-8 mx-auto"
            onClick={this.getLogin}
          >
            {intl.formatMessage({ id: "login.login" })}
          </button>
        </div>
      </PageContent>
    );
  }
}

export default ResetPasswordRequest;
