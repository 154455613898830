import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const tabMap = {
  GROWTH: 0,
  INCOME: 1,
  SHAREHOLDER_APPLICATION: 2,
};

export const usePersistingGrowthIncomeTab = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const activeTab = searchParams?.get("activeTab");
  const [value, setValue] = useState(tabMap[activeTab] ?? 0);

  const history = useHistory();

  useEffect(() => {
    if (value === 1) {
      let newSearch = new URLSearchParams(search);
      newSearch.set("activeTab", "INCOME");
      history.push({ search: newSearch.toString() });
    } else if (value === 2) {
      let newSearch = new URLSearchParams(search);
      newSearch.set("activeTab", "SHAREHOLDER_APPLICATION");
      history.push({ search: newSearch.toString() });
    } else {
      let newSearch = new URLSearchParams(search);
      newSearch.delete("activeTab");
      history.push({ search: newSearch.toString() });
    }
  }, [value, history, search]);

  return [value, setValue];
};
