import { orderType } from "../common/constants";

const searchFilteredOrders = (orders, searchValue) => {
  if (!searchValue) return orders;
  const lowerSearchValue = searchValue.toLowerCase();
  return orders.filter(
    ({ underlying, productNameDisplay, considerationType, term }) => {
      const lowerUnderlying = underlying.toLowerCase();
      const lowerProductNameDisplay = productNameDisplay.toLowerCase();
      const lowerConsiderationType = considerationType.toLowerCase();
      return (
        lowerUnderlying.includes(lowerSearchValue) ||
        lowerProductNameDisplay.includes(lowerSearchValue) ||
        lowerConsiderationType.includes(lowerSearchValue) ||
        term.toString().includes(lowerSearchValue)
      );
    }
  );
};

const getFilterByProduct = (orders, activeProducts) => {
  return orders?.filter((product) =>
    activeProducts ? product.seriesPair === activeProducts?.colour : true
  );
};

const getOrdersByCurrency = (orders, activeCurrency) => {
  return orders?.filter((product) =>
    activeCurrency ? product.currency === activeCurrency : true
  );
};

const getOrdersBySeriesType = (orders, seriesType) => {
  return orders?.filter(
    (product) =>
      product?.seriesType === seriesType &&
      product?.orderType !== orderType.SHARE
  );
};

const getOrdersByOrderType = (orders, orderType) => {
  return orders?.filter((product) => product?.orderType === orderType);
};

export const getFilteredOrders = (
  orderData,
  seriesType,
  activeCurrency,
  activeProducts,
  searchValue
) => {
  if (!Array.isArray(orderData)) return [];
  let filteredOrders = getFilterByProduct(orderData, activeProducts);
  filteredOrders = getOrdersByCurrency(filteredOrders, activeCurrency);
  filteredOrders = getOrdersBySeriesType(filteredOrders, seriesType);
  return searchFilteredOrders(filteredOrders, searchValue);
};

export const getShareFilteredOrders = (
  orderData,
  seriesType,
  activeCurrency,
  activeProducts,
  searchValue,
  orderType
) => {
  if (!Array.isArray(orderData)) return [];
  let filteredOrders = getFilterByProduct(orderData, activeProducts);
  filteredOrders = getOrdersByCurrency(filteredOrders, activeCurrency);
  filteredOrders = getOrdersByOrderType(filteredOrders, orderType);
  filteredOrders = filteredOrders.filter(
    (order) => order.seriesType === "INCOME"
  );
  return searchFilteredOrders(filteredOrders, searchValue);
};
