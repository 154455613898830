import React from "react";
import { isArray } from "lodash";
import validator from "validator";
import { Mapping } from "./keyValueMapping";
import { Modal } from "antd";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";

/**
 * Recursive component helps to display the user registration details
 */

class LiComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyProp: props.keyProp,
      jsonObj: props.jsonObj,
    };
  }
  handleViewClick = (url) => {
    console.log("url", url);
    let params = {
      s3URL: url,
    };
    axiosConfig
      .get(ROUTES.PRESIGNED_S3_URL, { params })
      .then((res) => {
        this.setState({ viewDoc: res.data, viewPreviewModal: true });
      })
      .catch((error) => {});
  };

  handleOk = () => {
    this.setState({ viewDoc: undefined, viewPreviewModal: false });
  };

  handleCancel = () => {
    this.setState({ viewDoc: undefined, viewPreviewModal: false });
  };

  getValue = (value) => {
    if (typeof value === "number") {
      return value;
    } else if (typeof value === "boolean") {
      return value === true ? "Yes" : "No";
    } else if (validator.isURL(value)) {
      return <a onClick={(e) => this.handleViewClick(value)}> view</a>;
    } else if (Mapping[value] !== " " && Mapping[value] !== undefined) {
      return Mapping[value];
    } else {
      return value;
    }
  };

  render() {
    const { keyProp, jsonObj, viewPreviewModal } = this.state;
    const isValidKeyProp =
      keyProp !== "Errors" &&
      keyProp !== "Document List" &&
      keyProp !== "Config" &&
      keyProp !== "Current Index" &&
      keyProp !== "documentList" &&
      keyProp !== "adminUploads";
    const isObj = jsonObj !== null && typeof jsonObj === "object";
    const isArrayObj = isArray(jsonObj);
    return (
      <div>
        {isValidKeyProp ? (
          !isArrayObj && !isObj ? (
            <li>
              {" "}
              {Mapping[keyProp] ? Mapping[keyProp] : keyProp} :{" "}
              {this.getValue(jsonObj)}{" "}
            </li>
          ) : isObj ? (
            <tr>
              <li>
                <LiComponent keyProp={keyProp} jsonObj="" />
              </li>
              {Object.keys(jsonObj).map(function (key) {
                return (
                  <ul>
                    <LiComponent keyProp={key} jsonObj={jsonObj[key]} />
                  </ul>
                );
              })}
            </tr>
          ) : null
        ) : null}
        {viewPreviewModal === true && (
          <Modal
            visible={viewPreviewModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            <div className="pdf-file-viewer">
              <object
                data={this.state.viewDoc}
                width="100%"
                height="100%"
                aria-label="view-doc"
              />
              <div className="file-viewer-download">
                {" "}
                <a target="blank" href={this.state.viewDoc}>
                  Click here to download
                </a>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default LiComponent;
