import Container from "@mui/material/Container";
import PageContent from "../common/PageContent";
import ETAPill from "../common/ETAPills/ETAPill";
import { useHistory } from "react-router-dom";

const products = [
  "GROWTH_GUARD",
  "DIV_GUARD",
  "PURE_DIV",
  "MAX_DIV",
  "MAX_GROWTH",
  "PURE_GROWTH",
];

export default function Welcome() {
  const history = useHistory();

  return (
    <PageContent>
      <Container maxWidth="xl" sx={{ marginTop: "30px" }}>
        <div className="text-white text-[40px] font-extralight text-center">
          Welcome
        </div>
        <div className="bg-light-gradient backdrop-blur login-box-shadow rounded-xl p-8 grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="bg-black bg-opacity-50 p-8 text-white">
            <div className="text-[40px] font-extralight">Start here</div>
            <div className="font-bold">User Guide</div>
            <p>
              The user guide is a valuable resource that can help you get the
              most out of our OTC Platform. It covers everything from basic
              setup to advanced features.
            </p>
            <p>
              The user guide is organised by topic, so you can easily find the
              information you need. It also includes screenshots and
              step-by-step instructions, so you can follow along easily.
            </p>
            <p className="mb-0">
              If you have any questions about the user guide, please don't
              hesitate to contact us. We're always happy to help.
            </p>
            <button
              className="mt-2 text-white py-1 px-5 bg-[#5B7FFC] rounded-[20px]"
              onClick={() =>
                window.open(
                  "https://www.prism.markets/otc/investor_resources/OPS_OTC_UserGuide_v04.pdf",
                  "UserGuideWindow",
                  "noopener"
                )
              }
            >
              View User Guide
            </button>
            <div className="mt-4 font-bold">FAQs</div>
            <p className="mb-0">
              Our FAQs (Frequently Asked Questions) section is a great place to
              find answers to your questions about our product.{" "}
            </p>
            <button
              className="mt-2 text-white py-1 px-5 self-end bg-[#5B7FFC] rounded-[20px]"
              onClick={() =>
                window.open(
                  "https://www.prism.markets/otc/investor_resources/PGUKMKT_OTCFAQ.pdf",
                  "FAQWindow",
                  "noopener"
                )
              }
            >
              FAQs
            </button>
          </div>
          <div className="bg-black bg-opacity-50 p-8 text-white">
            <div className="text-[40px] font-extralight">
              Complete or update KYC
            </div>
            <div className="font-bold">Registration Process</div>
            <button
              className="mt-2 text-white py-1 px-5 bg-[#5B7FFC] rounded-[20px]"
              onClick={() =>
                window.open(
                  "https://www.prism.markets/otc/Prism_POAs.pdf",
                  "POAOverviewWindow",
                  "noopener"
                )
              }
            >
              POA Overview
            </button>

            <p className="mt-4">
              Before you can trade on the Prism OTC Platform you will need to
              complete the registration process.
            </p>
            <p>The registration process enables Prism to:</p>

            <ul>
              <li>
                <div className="font-bold">
                  • Meet our regulatory obligations
                </div>
                <button
                  className="mt-2 text-white py-1 px-5 self-end bg-[#5B7FFC] rounded-[20px]"
                  onClick={() => history.push("/online-pdf-registration")}
                >
                  KYC Forms
                </button>
              </li>
              <li className="mt-4">
                <div className="font-bold">
                  • Be assured of your “wholesale” status
                </div>
                <button
                  className="mt-2 text-white py-1 px-5 self-end bg-[#5B7FFC] rounded-[20px]"
                  onClick={() => history.push("/sophisticated-documents")}
                >
                  POA Certificates
                </button>
              </li>
              <li className="mt-4">
                <div className="font-bold">
                  • Record your Standard Settlement Instructions.
                </div>
              </li>
            </ul>
          </div>
          <div className="bg-black bg-opacity-50 p-8 text-white">
            <div className="text-[40px] font-extralight">
              Available Allocations
            </div>
            <div className="font-bold">Profile overview</div>
            <p>
              Below is a list of the type of Allocations available. Hover over
              each type to reveal the risk profiles of each Allocation.
            </p>
            <div className="grid grid-cols-3 gap-2">
              {products.map((product) => (
                <ETAPill
                  key={product}
                  id={crypto.randomUUID()}
                  productId={product}
                  className=""
                />
              ))}
            </div>
            <div className="mt-4 font-bold">To Buy</div>
            <p className="mb-0">
              To view all available allocations, add an expression of interest
              and more click here.
            </p>
            <button
              className="mt-2 text-white py-1 px-5 self-end bg-[#5B7FFC] rounded-[20px]"
              onClick={() => history.push("/available")}
            >
              Click Here
            </button>
          </div>
        </div>
      </Container>
    </PageContent>
  );
}
