import "./style.scss";
import React, { Component } from "react";
import { intl } from "../../../../utils/intl_i18n";
import Button from "antd/es/button";
import {
  CalendarOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import PlaceIndex from "../../../awslocation/placeIndex";

class UnRegulatedTrustBeneficialTaxInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
    if (!this.state.payload.beneficiaryMemberList) {
      this.state.payload.beneficiaryMemberList = [];
      this.addMember();
    }
  }

  validate = () => {
    const { payload } = this.state;
    var flag = true;

    if (
      payload.unRegulatedForeignTaxInformationStatus === null ||
      !payload.unRegulatedForeignTaxInformationStatus
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.unRegulatedForeignTaxInformationStatus === "yes") {
      if (
        !payload.beneficiaryMemberList ||
        payload.beneficiaryMemberList.length === 0
      ) {
        this.onValidationFail();
        return;
      }
      for (var i = 0; i < payload.beneficiaryMemberList.length; i++) {
        if (
          !payload.beneficiaryMemberList[i].familyName ||
          !payload.beneficiaryMemberList[i].givenName ||
          !payload.beneficiaryMemberList[i].role ||
          !payload.beneficiaryMemberList[i].dateofbirth ||
          !payload.beneficiaryMemberList[i].residentialAddress
        ) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.onValidationFail();
        return;
      }
    }
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onFamilyNameChange = (member, value) => {
    const { payload } = this.state;
    member.familyName = value;
    this.updateState(payload);
  };

  onGivenNameChange = (member, value) => {
    const { payload } = this.state;
    member.givenName = value;
    this.updateState(payload);
  };

  onRoleChange = (member, value) => {
    const { payload } = this.state;
    member.role = value;
    this.updateState(payload);
  };

  onDOBChange = (member, value) => {
    const { payload } = this.state;
    member.dateofbirth = value;
    this.updateState(payload);
  };

  onResidentialAddressChange = (member, value) => {
    const { payload } = this.state;
    member.residentialAddress = value;
    this.updateState(payload);
  };

  addMember = () => {
    const { payload } = this.state;
    payload.beneficiaryMemberList.push({
      familyName: "",
      givenName: "",
      role: "",
      dateofbirth: "",
      residentialAddress: "",
    });
    this.updateState(payload);
  };
  removeMember = (member) => {
    const { payload } = this.state;
    let index = payload.beneficiaryMemberList.indexOf(member);
    payload.beneficiaryMemberList.splice(index, 1);
    this.updateState(payload);
  };

  foreignTaxInformationStatusChange = (e) => {
    const { payload } = this.state;
    payload.unRegulatedForeignTaxInformationStatus = e.target.value;
    this.updateState(payload);
  };

  onKeyDown = (e) => {
    if (e.key === "Tab") {
      this.addMember();
    }
  };

  render() {
    const { beneficiaryMemberList, unRegulatedForeignTaxInformationStatus } =
      this.state.payload;
    return (
      <div className="fbTax-main">
        <div className="fbTax-title">
          {this.formatMessage_i18n(
            "UnRegulatedTrustTaxInfomation.foreignBeneficialLabel"
          )}
        </div>
        <div className="fbinformation-header">
          {this.formatMessage_i18n("UnRegulatedTrustTaxInfomation.header")}
        </div>
        <div className="fb-confirmation-text">
          {this.formatMessage_i18n(
            "UnRegulatedTrustTaxInfomation.foreignBeneficialConfirmation"
          )}
        </div>
        <div className="fb-confirmation-dropdown">
          <select
            className={
              (_isUndefined(unRegulatedForeignTaxInformationStatus) ||
                unRegulatedForeignTaxInformationStatus === "") &&
              "error-field"
            }
            onChange={this.foreignTaxInformationStatusChange}
            value={unRegulatedForeignTaxInformationStatus}
          >
            <option value="">
              {this.formatMessage_i18n("FBTaxInformatoin.dropdownPlaceholder")}
            </option>
            <option value="yes">
              {this.formatMessage_i18n("FBTaxInformatoin.dropdownValue1")}
            </option>
            <option value="no">
              {this.formatMessage_i18n("FBTaxInformatoin.dropdownValue2")}
            </option>
          </select>
        </div>
        <div
          className="fb-tax-information-details"
          style={{
            display:
              unRegulatedForeignTaxInformationStatus === "yes"
                ? "block"
                : "none",
          }}
        >
          <div className="tax-residency-rule">
            {this.formatMessage_i18n(
              "UnRegulatedTrustTaxInfomation.taxResidencyRule"
            )}
          </div>
          <div className="name-of-individuals">
            {this.formatMessage_i18n(
              "UnRegulatedTrustTaxInfomation.nameOfIndividuals"
            )}
          </div>
          <div className="field-values-list">
            {beneficiaryMemberList.map((member) => (
              <div className="field-values">
                <div className="individuals-textbox">
                  <div className="family-name">
                    <input
                      className={cn(
                        "family-name-textbox",
                        (_isUndefined(member.familyName) ||
                          member.familyName === "") &&
                          "error-field"
                      )}
                      name="familyName"
                      value={member.familyName}
                      placeholder={this.formatMessage_i18n("family.name")}
                      onChange={(e) =>
                        this.onFamilyNameChange(member, e.target.value)
                      }
                    />
                    <input
                      className={cn(
                        "given-name-textbox",
                        (_isUndefined(member.givenName) ||
                          member.givenName === "") &&
                          "error-field"
                      )}
                      name="givenName"
                      placeholder={this.formatMessage_i18n("given.name")}
                      value={member.givenName}
                      onChange={(e) =>
                        this.onGivenNameChange(member, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="dynamic-form-details">
                  <input
                    className={cn(
                      "individual-role",
                      (_isUndefined(member.role) || member.role === "") &&
                        "error-field"
                    )}
                    name="role"
                    placeholder={this.formatMessage_i18n("role.name")}
                    value={member.role}
                    onChange={(e) => this.onRoleChange(member, e.target.value)}
                  />
                  <DatePicker
                    size="middle"
                    format="DD-MM-YYYY"
                    className={cn(
                      "dob-input",
                      (_isUndefined(member.dateofbirth) ||
                        member.dateofbirth === "") &&
                        "error-field"
                    )}
                    placeholder={this.formatMessage_i18n("user.dateofbirth")}
                    value={
                      !_isUndefined(member.dateofbirth) &&
                      member.dateofbirth !== ""
                        ? moment(member.dateofbirth, "DD-MM-YYYY")
                        : ""
                    }
                    onChange={(date, dateString) =>
                      this.onDOBChange(member, dateString)
                    }
                    allowClear={false}
                    suffixIcon={<CalendarOutlined className="calender-icon" />}
                  />
                </div>
                <div className="member-list-address">
                  <div
                    className={cn(
                      "member-address-container",
                      (_isUndefined(member.residentialAddress) ||
                        member.residentialAddress === "") &&
                        "error-field-autosuggest"
                    )}
                  >
                    <PlaceIndex
                      placeholder={this.formatMessage_i18n(
                        "user.residentAddress"
                      )}
                      onChange={(value) =>
                        this.onResidentialAddressChange(member, value)
                      }
                      value={member.residentialAddress}
                      onKeyDown={(e) => this.onKeyDown(e)}
                    />
                  </div>
                  <div className="btn-box remove-btn">
                    <MinusCircleOutlined
                      className="minus-icon"
                      onClick={() => this.removeMember(member)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="btn-box">
            <Button onClick={() => this.addMember()} icon={<PlusOutlined />} />
          </div>
        </div>
      </div>
    );
  }
}

export default UnRegulatedTrustBeneficialTaxInformation;
