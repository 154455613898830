import { useQuery } from "@tanstack/react-query";
import axiosConfig from "../axiosConfig";
import ROUTES from "../routes/api.json";

export const useSecurities = () => {
  return useQuery({
    queryKey: ["securitiesList"],
    queryFn: async () => {
      const { data } = await axiosConfig.get(ROUTES.SECURITIES);
      return data;
    },
    staleTime: 60 * 60 * 1000,
  });
};

export const useSecurityByTicker = (ticker) => {
  return useQuery({
    queryKey: ["security", ticker],
    queryFn: async () => {
      const { data } = await axiosConfig.get(ROUTES.SECURITIES);
      return data;
    },
    staleTime: 60 * 60 * 1000,
    select: (data) => {
      return data.find((security) => security.ticker === ticker);
    },
  });
};

export const usePOADivYield = (underlying, productName) => {
  return useQuery({
    queryKey: ["poaDivYield", underlying, productName],
    queryFn: async () => {
      const { data } = await axiosConfig.get(
        `${ROUTES.POA_DIVIDEND_YIELD}/${underlying}/${productName}`
      );
      return data;
    },
    enabled: !!underlying && !!productName,
    staleTime: 60 * 60 * 1000,
    select: (data) => data * 100,
  });
};
