import { useEffect } from "react";
import {
  considerationOptions,
  optionsByConsideration,
  riskQuestionByActionOption,
  riskOptionsRGB,
  riskOptionsPurple,
  shareholderApplicationOptions,
  orderProductByActionRisk,
  orderProductByShareholderApplicationOption,
  formActionOption,
  formRiskOption,
  shareholderApplicationOptionPurple,
  SHAREHOLDER_APP,
  getOptionsByConsideration,
} from "./config";
import { renderRiskLabel, renderShareholderApplicationLabel } from "./utils";
import OptionSet from "./OptionSet";
import OrderPanel from "./OrderPanel";
import { useUserDetail } from "../../hooks/investors";
import { etaSwitchPurpleActiveAtom } from "../../store/atoms";
import { useAtomValue } from "jotai";

export default function OrderForm({
  formik,
  orderId,
  securitiesList,
  renderType,
}) {
  const { data: userDetail } = useUserDetail();

  const etaSwitchPurpleActive = useAtomValue(etaSwitchPurpleActiveAtom);

  const handleGoBack = () => {
    if (formik.values.shareholderApplicationOption) {
      formik.setFieldValue("shareholderApplicationOption", "");
      formik.setFieldValue("priceDollars", "");
      return;
    }
    if (formik.values.riskOption) {
      formik.setFieldValue("riskOption", "");
      return;
    }
    if (formik.values.actionOption) {
      formik.setFieldValue("actionOption", "");
      return;
    }
    if (formik.values.considerationType) {
      formik.setFieldValue("considerationType", "");
    }
  };

  useEffect(() => {
    if (etaSwitchPurpleActive && formik.values.riskOption !== "ULTRA_RISK")
      formik.setFieldValue("riskOption", "");
  }, [etaSwitchPurpleActive]);

  const riskOptions = etaSwitchPurpleActive
    ? riskOptionsPurple
    : riskOptionsRGB;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col max-w-sm mx-auto">
        {renderType === SHAREHOLDER_APP && (
          <div className="text-white text-[20px] mb-4 mt-4">
            I have a share holding to convert to POAs
          </div>
        )}
        {renderType !== SHAREHOLDER_APP && (
          <>
            <OptionSet
              handleChange={formik.handleChange}
              name="considerationType"
              prompt="I'm starting with..."
              promptClassName="mt-0"
              options={considerationOptions}
              value={formik.values.considerationType}
            />
            {formik.values.considerationType && (
              <OptionSet
                handleChange={formik.handleChange}
                name="actionOption"
                prompt="I want to..."
                options={getOptionsByConsideration(
                  formik.values.considerationType,
                  userDetail,
                  orderId
                )}
                value={formik.values.actionOption}
              />
            )}
          </>
        )}
        {/* TODO multiple conditional validation and remove code duplicates */}
        {renderType !== SHAREHOLDER_APP ? (
          <>
            {formik.values.considerationType && formik.values.actionOption && (
              <>
                {formik.values.actionOption !== SHAREHOLDER_APP ? (
                  <OptionSet
                    handleChange={formik.handleChange}
                    name="riskOption"
                    prompt={
                      riskQuestionByActionOption[formik.values.actionOption] ??
                      "My risk appetite is..."
                    }
                    options={riskOptions}
                    optionClassName="!pr-0"
                    value={formik.values.riskOption}
                    renderLabel={(option) =>
                      renderRiskLabel(option, formik.values.actionOption)
                    }
                  />
                ) : (
                  <OptionSet
                    handleChange={formik.handleChange}
                    name="shareholderApplicationOption"
                    prompt="The POA pair I would like is..."
                    options={
                      etaSwitchPurpleActive
                        ? shareholderApplicationOptionPurple
                        : shareholderApplicationOptions
                    }
                    optionClassName="!pr-0"
                    value={formik.values.shareholderApplicationOption}
                    renderLabel={renderShareholderApplicationLabel}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <OptionSet
            handleChange={formik.handleChange}
            name="shareholderApplicationOption"
            prompt="The POA pair I would like is..."
            options={
              etaSwitchPurpleActive
                ? shareholderApplicationOptionPurple
                : shareholderApplicationOptions
            }
            optionClassName="!pr-0"
            value={formik.values.shareholderApplicationOption}
            renderLabel={renderShareholderApplicationLabel}
          />
        )}
      </div>
      {(formik.values.considerationType || renderType === SHAREHOLDER_APP) &&
        (formik.values.riskOption ||
          formik.values.shareholderApplicationOption) && (
          <div className="mt-8 max-w-[724px] mx-auto z-[99] relative">
            <OrderPanel
              formik={formik}
              orderId={orderId}
              securitiesList={securitiesList}
              renderType={renderType}
            />
          </div>
        )}
      {(formik.values.considerationType ||
        formik.values.shareholderApplicationOption) && (
        <div className="mt-6 max-w-sm mx-auto">
          <button
            className="bg-black rounded-full text-white px-4 py-1"
            onClick={handleGoBack}
            type="button"
          >
            Back
          </button>
        </div>
      )}
    </form>
  );
}
