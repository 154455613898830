import { useState } from "react";
import PageContent from "../common/PageContent";
import SearchIcon from "../logo/searchIcon";
import FilterPanel from "../common/Filters/FilterPanel";
import { Tab } from "@headlessui/react";
import { Container } from "@mui/material";
import Orders from "./Orders";
import { getFilteredOrders, getShareFilteredOrders } from "./utils";
import { activeCurrencyAtom, activeProductsAtom } from "../../store/atoms";
import { useAtomValue } from "jotai";
import { usePersistingGrowthIncomeTab } from "../../hooks/tabs";
import { useOrders } from "../../hooks/orders";
import { useQueryClient } from "@tanstack/react-query";
import { useInvestorId } from "../common/utils";

import ROUTES from "../../routes/api.json";
import SockJsClient from "react-stomp";
import { getAccess } from "../../utils/utile";
import { orderType } from "../common/constants";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabIndexMap = {
  0: "GROWTH",
  1: "INCOME",
};

function OpenOrders({ currencyType, etaType }) {
  const [searchValue, setSearchValue] = useState("");
  const [activeIndex, setActiveIndex] = usePersistingGrowthIncomeTab();

  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const activeProducts = useAtomValue(activeProductsAtom);

  const { data: orderData, isLoading, error } = useOrders();

  const queryClient = useQueryClient();
  const investorId = useInvestorId();

  const growthOrders = getFilteredOrders(
    orderData,
    "GROWTH",
    activeCurrency,
    activeProducts,
    searchValue
  );
  const incomeOrders = getFilteredOrders(
    orderData,
    "INCOME",
    activeCurrency,
    activeProducts,
    searchValue
  );
  const shareholderAppOrders = getShareFilteredOrders(
    orderData,
    "SHARE_APP",
    activeCurrency,
    activeProducts,
    searchValue,
    orderType.SHARE
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMessage = (message) => {
    console.log("MESSAGE", message);
    console.log(message?.underlying);
    queryClient.invalidateQueries(["openOrders", investorId]);
    queryClient.invalidateQueries([
      "allOrders",
      message?.underlying,
      message?.productName,
      investorId,
    ]);
  };

  return (
    <PageContent>
      <SockJsClient
        headers={{ authorization: getAccess() }}
        url={ROUTES.WEB_SOCKET_URL}
        topics={[ROUTES.TOPIC_EXEC_REPORTS]}
        onMessage={handleMessage}
        onConnect={() => {
          console.log("CONNECTED");
        }}
        onDisconnect={() => {
          console.log("DISCONNECTED");
        }}
        debug={true}
      />
      <Container maxWidth="xl" sx={{ marginTop: "30px" }}>
        <div className="text-white text-[40px] font-extralight text-center">
          My Open Expressions of Interest
        </div>
        <Tab.Group
          selectedIndex={activeIndex}
          onChange={(index) => setActiveIndex(index)}
        >
          <Tab.Panels className="bg-[#2f333b] rounded-xl p-8 max-h-[calc(100vh_-_265px)] flex flex-col space-y-4 divide-y divide-black">
            <div className="flex flex-col md:flex-row justify-between gap-2">
              <div className="flex space-x-4 items-center">
                <Tab.List className="flex space-x-4 p-1">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "rounded-full py-2 w-[100px] text-sm font-light leading-5 text-white border border-[#797D82]",
                        "ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-1",
                        selected
                          ? "bg-black font-semibold shadow"
                          : "text-blue-100 hover:bg-[#343741]/[0.12] hover:text-white"
                      )
                    }
                  >
                    Growth ({growthOrders?.length})
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "rounded-full py-2 w-[100px] text-sm font-light leading-5 text-white border border-[#797D82]",
                        "ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-1",
                        selected
                          ? "bg-black font-semibold shadow"
                          : "text-blue-100 hover:bg-[#343741]/[0.12] hover:text-white"
                      )
                    }
                  >
                    Dividend ({incomeOrders?.length})
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        shareholderAppOrders?.length > 0 ? "" : "hidden",
                        "rounded-full py-2 w-[180px] text-sm font-light leading-5 text-white border border-[#797D82]",
                        "ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-1",
                        selected
                          ? "bg-black font-semibold shadow"
                          : "text-blue-100 hover:bg-[#343741]/[0.12] hover:text-white"
                      )
                    }
                  >
                    Shareholder Application ({shareholderAppOrders?.length})
                  </Tab>
                </Tab.List>
                <div className="relative rounded-full shadow-sm">
                  <input
                    type="text"
                    name="search-input"
                    id="search-input"
                    className="bg-transparent block w-full rounded-full border border-[#797D82] py-1.5 pl-4 pr-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    style={{
                      fill: "linear-gradient(135deg, rgba(232, 232, 232, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%)",
                      boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.30) inset",
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <SearchIcon />
                  </div>
                </div>
              </div>
              <FilterPanel currency side={tabIndexMap[activeIndex]} />
            </div>
            <Tab.Panel className="flex flex-col pt-4 overflow-y-scroll">
              <div className="min-w-[1400px]">
                <Orders
                  orders={growthOrders}
                  type="GROWTH"
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  error={error}
                  isLoading={isLoading}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel className="flex flex-col pt-4 overflow-y-scroll">
              <div className="min-w-[1400px]">
                <Orders
                  orders={incomeOrders}
                  type="INCOME"
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  error={error}
                  isLoading={isLoading}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel className="flex flex-col pt-4 overflow-y-scroll">
              {shareholderAppOrders?.length > 0 && (
                <div className="min-w-[1400px]">
                  <Orders
                    orders={shareholderAppOrders}
                    type="SHARE_APP"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    error={error}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </PageContent>
  );
}

export default OpenOrders;
