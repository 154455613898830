import { Component } from "react";
import Button from "antd/es/button";
import "antd/dist/antd.css";
import message from "antd/es/message";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import {
  CalendarOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";
import { PrismAddress, validatePOInput } from "../../../PrismAddress";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import { validateDate } from "../../../common/validation";
import { dateErrorMessage } from "../../../common/message";

class ProprietaryCompanyDirectors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };

    if (!this.state.payload.directorsList) {
      this.state.payload.directorsList = [];
      this.addDirector();
    }
  }

  validate = () => {
    const { payload } = this.state;
    var flag = true;

    if (!payload.directorsList) {
      this.onValidationFail();
      return;
    }

    if (payload.directorsList.length === 0) {
      this.onValidationFail();
      return;
    }

    for (var i = 0; i < payload.directorsList.length; i++) {
      if (
        !payload.directorsList[i].familyName ||
        !payload.directorsList[i].givenName ||
        !payload.directorsList[i].dateofbirth ||
        (payload.directorsList[i].dateofbirth &&
          !validateDate(payload.directorsList[i].dateofbirth)) ||
        !payload.directorsList[i].streetAddress ||
        (payload.directorsList[i].streetAddress &&
          (payload.directorsList[i].streetAddress.length < 8 ||
            payload.directorsList[i].streetAddress.length > 225 ||
            !validatePOInput(payload.directorsList[i].streetAddress))) ||
        !payload.directorsList[i].subUrban ||
        (payload.directorsList[i].subUrban &&
          payload.directorsList[i].subUrban.length < 4) ||
        !payload.directorsList[i].state ||
        !payload.directorsList[i].postCode ||
        (payload.directorsList[i].postCode &&
          payload.directorsList[i].postCode.length < 4) ||
        !payload.directorsList[i].country
      ) {
        flag = false;
        break;
      }
    }
    if (flag) {
      this.onValidationPass();
    } else this.onValidationFail();
  };
  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onFamilyNameChange = (director, value) => {
    const { payload } = this.state;
    director.familyName = value;
    this.updateState(payload);
  };
  onGivenNameChange = (director, value) => {
    const { payload } = this.state;
    director.givenName = value;
    this.updateState(payload);
  };

  onDOBChange = (director, value) => {
    const { payload } = this.state;
    director.dateofbirth = value;
    this.updateState(payload);
  };

  onResidentialAddressChange = (director, value) => {
    const { payload } = this.state;
    director.residentialAddress = value;
    this.updateState(payload);
  };

  addDirector = () => {
    const { payload } = this.state;
    payload.directorsList.push({
      familyName: "",
      givenName: "",
      dateofbirth: "",
      streetAddress: "",
      subUrban: "",
      state: "",
      postCode: "",
      country: "",
    });
    this.updateState(payload, this.scrolltoActiveDirector);
  };

  /**
   * @method - Method to scroll to the newly added director
   */
  scrolltoActiveDirector = () => {
    const directorLength = document.getElementsByClassName(
      "director-first-name"
    ).length;
    if (directorLength > 0) {
      document
        .getElementsByClassName("director-first-name")
        [directorLength - 1].focus();
    }
  };
  removeDirector = (director) => {
    const { payload } = this.state;
    let index = payload.directorsList.indexOf(director);
    payload.directorsList.splice(index, 1);
    this.updateState(payload);
  };

  updateState = (payload, afterUpdate) => {
    this.setState(payload, () => {
      if (afterUpdate) {
        afterUpdate();
      }
      this.validate();
    });
  };
  componentDidMount() {
    this.validate();
  }

  onKeyDown = (e) => {
    if (e.key === "Tab") {
      this.addDirector();
    }
  };

  handleAddressChange = (fieldValue, fieldName, director) => {
    const { payload } = this.state;
    director[fieldName] = fieldValue;
    this.updateState(payload);
  };

  render() {
    const { directorsList } = this.state.payload;
    return (
      <div className="proprietary-directors-reg-main-content">
        <div className="reg-header">
          {this.formatMessage_i18n("proprietary.directors.title")}
        </div>
        <div className="reg-sub-header">
          {this.formatMessage_i18n("proprietary.directors.subtitle")}
        </div>
        <div className="director-inputs">
          {directorsList.map((director) => (
            <>
              <div className="director-list-iterate">
                <input
                  className={cn(
                    "director-input input-medium director-first-name",
                    (_isUndefined(director.familyName) ||
                      director.familyName === "") &&
                      "error-field"
                  )}
                  name="firstName"
                  placeholder="Family Name"
                  value={director.familyName}
                  onChange={(e) =>
                    this.onFamilyNameChange(director, e.target.value)
                  }
                />
                <input
                  className={cn(
                    "director-input input-medium",
                    (_isUndefined(director.givenName) ||
                      director.givenName === "") &&
                      "error-field"
                  )}
                  name="lastName"
                  placeholder="Given Name"
                  value={director.givenName}
                  onChange={(e) =>
                    this.onGivenNameChange(director, e.target.value)
                  }
                />
                <DatePicker
                  size="middle"
                  format="DD-MM-YYYY"
                  className={cn("director-input input-small", {
                    "error-field":
                      _isUndefined(director.dateofbirth) ||
                      director.dateofbirth === "" ||
                      (director.dateofbirth &&
                        !validateDate(director.dateofbirth)),
                  })}
                  placeholder={this.formatMessage_i18n("user.dateofbirth")}
                  value={
                    !_isUndefined(director.dateofbirth) &&
                    director.dateofbirth !== ""
                      ? moment(director.dateofbirth, "DD-MM-YYYY")
                      : ""
                  }
                  onChange={(date, dateString) => {
                    if (!validateDate(dateString)) {
                      message.error({ content: dateErrorMessage, duration: 3 });
                    }
                    this.onDOBChange(director, dateString);
                  }}
                  allowClear={false}
                  suffixIcon={<CalendarOutlined className="calender-icon" />}
                />
              </div>
              <div className="director-address">
                <div>
                  <PrismAddress
                    streetPlaceholder={this.formatMessage_i18n(
                      "Street.Address"
                    )}
                    streetValue={director.streetAddress}
                    streetOnChange={(value) =>
                      this.handleAddressChange(value, "streetAddress", director)
                    }
                    subUrbanPlaceholder={this.formatMessage_i18n(
                      "Suborb.or.town"
                    )}
                    subUrbanValue={director.subUrban}
                    subUrbanOnChange={(value) =>
                      this.handleAddressChange(value, "subUrban", director)
                    }
                    statePlaceholder={this.formatMessage_i18n("reg.state")}
                    stateValue={director.state}
                    stateOnChange={(val) =>
                      this.handleAddressChange(val, "state", director)
                    }
                    codePlaceholder={this.formatMessage_i18n("reg.postCode")}
                    codeValue={director.postCode}
                    codeOnChange={(val) =>
                      this.handleAddressChange(val, "postCode", director)
                    }
                    countryPlaceholder={this.formatMessage_i18n("reg.country")}
                    countryValue={director.country}
                    countryOnChange={(val) =>
                      this.handleAddressChange(val, "country", director)
                    }
                    countryKeyDown={this.onKeyDown}
                  />
                </div>
                <div className="list-clear-btn">
                  <MinusCircleOutlined
                    className="minus-icon"
                    onClick={() => this.removeDirector(director)}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="btn-box">
          <Button onClick={() => this.addDirector()} icon={<PlusOutlined />} />
        </div>
      </div>
    );
  }
}

export default ProprietaryCompanyDirectors;
