import { Component } from "react";
import Modal from "antd/es/modal";
import Upload from "antd/es/upload";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import DocumentSelection from "./document-select";
import { message } from "antd";
import _isEmpty from "lodash/isEmpty";

const { Dragger } = Upload;

class IndividualVerificationProcedure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      document: null,
    };
  }
  componentDidMount() {
    const { payload } = this.state;
    if (payload.AusDrivingLicense === undefined) {
      payload.AusDrivingLicense = false;
    }
    if (payload.AusPassport === undefined) {
      payload.AusPassport = false;
    }
    if (payload.StateCardIssuanceForAgeProof === undefined) {
      payload.StateCardIssuanceForAgeProof = false;
    }
    if (payload.CurrentPassport === undefined) {
      payload.CurrentPassport = false;
    }
    if (payload.AusBirthCertificate === undefined) {
      payload.AusBirthCertificate = false;
    }
    if (payload.AusCitizenCertificate === undefined) {
      payload.AusCitizenCertificate = false;
    }
    if (payload.PensionCard === undefined) {
      payload.PensionCard = false;
    }
    if (payload.ForeignDrivingLicense === undefined) {
      payload.ForeignDrivingLicense = false;
    }
    if (payload.NationalID === undefined) {
      payload.NationalID = false;
    }
    if (payload.CommonWealthOrTerritoryDoc === undefined) {
      payload.CommonWealthOrTerritoryDoc = false;
    }
    if (payload.AusTaxationDoc === undefined) {
      payload.AusTaxationDoc = false;
    }
    if (payload.LocGovtOrUtilityProviderDoc === undefined) {
      payload.LocGovtOrUtilityProviderDoc = false;
    }
    if (payload.selectPart1 === undefined) {
      payload.selectPart1 = "";
    }
    if (payload.selectPart2 === undefined) {
      payload.selectPart2 = "";
    }
    if (payload.selectPart3 === undefined) {
      payload.selectPart3 = "";
    }
    if (payload.selectPart4 === undefined) {
      payload.selectPart4 = "";
    }
    if (payload.documentList === undefined) {
      payload.documentList = [];
    }

    this.updateState(payload);
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  validate = () => {
    const { payload } = this.state;
    if (
      (payload.AusDrivingLicense === undefined ||
        payload.AusDrivingLicense === false) &&
      (payload.AusPassport === undefined || payload.AusPassport === false) &&
      (payload.StateCardIssuanceForAgeProof === undefined ||
        payload.StateCardIssuanceForAgeProof === false) &&
      (payload.CurrentPassport === undefined ||
        payload.CurrentPassport === false) &&
      (payload.AusBirthCertificate === undefined ||
        payload.AusBirthCertificate === false) &&
      (payload.AusCitizenCertificate === undefined ||
        payload.AusCitizenCertificate === false) &&
      (payload.PensionCard === undefined || payload.PensionCard === false) &&
      (payload.ForeignDrivingLicense === undefined ||
        payload.ForeignDrivingLicense === false) &&
      (payload.NationalID === undefined || payload.NationalID === false) &&
      (payload.CommonWealthOrTerritoryDoc === undefined ||
        payload.CommonWealthOrTerritoryDoc === false) &&
      (payload.AusTaxationDoc === undefined ||
        payload.AusTaxationDoc === false) &&
      (payload.LocGovtOrUtilityProviderDoc === undefined ||
        payload.LocGovtOrUtilityProviderDoc === false)
    ) {
      this.onValidationFail();
      return;
    }

    if (
      ((!_isEmpty(payload.selectPart1.toString()) ||
        !_isEmpty(payload.selectPart4.toString())) &&
        (!_isEmpty(payload.selectPart2.toString()) ||
          !_isEmpty(payload.selectPart3.toString()))) ||
      (!_isEmpty(payload.selectPart1.toString()) &&
        !_isEmpty(payload.selectPart4.toString()))
    ) {
      this.onValidationFail();
      return;
    }

    if (
      _isEmpty(payload.selectPart1.toString()) &&
      _isEmpty(payload.selectPart4.toString())
    ) {
      if (
        _isEmpty(payload.selectPart2.toString()) ||
        _isEmpty(payload.selectPart3.toString())
      ) {
        this.onValidationFail();
        return;
      }
    }

    if (payload.documentList === undefined) {
      this.onValidationFail();
      return;
    }
    if (!payload.documentList) {
      this.onValidationFail();
      return;
    }
    if (
      !_isEmpty(payload.selectPart1.toString()) &&
      payload.documentList.length < 1
    ) {
      this.onValidationFail();
      return;
    }
    if (
      !_isEmpty(payload.selectPart2.toString()) &&
      !_isEmpty(payload.selectPart3.toString()) &&
      payload.documentList.length < 2
    ) {
      this.onValidationFail();
      return;
    }
    if (
      !_isEmpty(payload.selectPart4.toString()) &&
      payload.documentList.length < 1
    ) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };

  handleOnChange = (info) => {
    const { payload } = this.state;
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      file.status = "done";
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    payload.documentList = fileList;
    this.updateState(payload);
  };

  onChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value.target.checked;
    this.updateState(payload);
  };

  docSelect = (value) => {
    this.setState({ document: value });
  };

  fieldOnChange = (part, value) => {
    const { payload } = this.state;
    if (
      part === "part1" &&
      (payload.selectPart2 !== "" ||
        payload.selectPart3 !== "" ||
        payload.selectPart4 !== "") &&
      payload.documentList.length >= 1
    ) {
      message.info(
        "Please delete the selected document before changing the document selection"
      );
      return;
    } else if (part === "part2" && payload.documentList.length >= 1) {
      let documentList = payload.documentList;
      let fileID = "";
      for (var i = 0; i < documentList.length; i++) {
        if (
          documentList[i].fileId === "AusBirthCertificate" ||
          documentList[i].fileId === "AusCitizenCertificate" ||
          documentList[i].fileId === "PensionCard" ||
          documentList[i].fileId === "AusDrivingLicense" ||
          documentList[i].fileId === "AusPassport" ||
          documentList[i].fileId === "StateCardIssuanceForAgeProof" ||
          documentList[i].fileId === "CurrentPassport" ||
          documentList[i].fileId === "ForeignDrivingLicense" ||
          documentList[i].fileId === "NationalID"
        ) {
          fileID = documentList[i].fileId;
        }
      }
      if (!_isEmpty(fileID)) {
        message.info(
          "Please delete the selected document before changing the document selection"
        );
        return;
      }
    } else if (part === "part3" && payload.documentList.length >= 1) {
      let documentList = payload.documentList;
      let fileID = "";
      for (var j = 0; j < documentList.length; j++) {
        if (
          documentList[j].fileId === "ForeignDrivingLicense" ||
          documentList[j].fileId === "NationalID" ||
          documentList[j].fileId === "AusDrivingLicense" ||
          documentList[j].fileId === "AusPassport" ||
          documentList[j].fileId === "StateCardIssuanceForAgeProof" ||
          documentList[j].fileId === "CurrentPassport" ||
          documentList[j].fileId === "CommonWealthOrTerritoryDoc" ||
          documentList[j].fileId === "AusTaxationDoc" ||
          documentList[j].fileId === "LocGovtOrUtilityProviderDoc"
        ) {
          fileID = documentList[j].fileId;
        }
      }
      if (!_isEmpty(fileID)) {
        message.info(
          "Please delete the selected document before changing the document selection"
        );
        return;
      }
    }
    if (
      part === "part4" &&
      (payload.selectPart2 !== "" ||
        payload.selectPart3 !== "" ||
        payload.selectPart1 !== "") &&
      payload.documentList.length >= 1
    ) {
      message.info(
        "Please delete the selected document before changing the document selection"
      );
      return;
    }

    this.resetSelection(part, value);
    // this.updateState(payload)
  };
  resetPart1Selection = () => {
    const { payload } = this.state;
    payload.AusDrivingLicense = false;
    payload.AusPassport = false;
    payload.StateCardIssuanceForAgeProof = false;
    payload.CurrentPassport = false;
    this.updateState(payload);
  };
  resetPart2Selection = () => {
    const { payload } = this.state;
    payload.AusBirthCertificate = false;
    payload.AusCitizenCertificate = false;
    payload.PensionCard = false;
    this.updateState(payload);
  };
  resetPart3Selection = () => {
    const { payload } = this.state;
    payload.CommonWealthOrTerritoryDoc = false;
    payload.AusTaxationDoc = false;
    payload.LocGovtOrUtilityProviderDoc = false;
    this.updateState(payload);
  };
  resetPart4Selection = () => {
    const { payload } = this.state;
    payload.ForeignDrivingLicense = false;
    payload.NationalID = false;
    this.updateState(payload);
  };

  resetSelection = (selectPart, value) => {
    const { payload } = this.state;
    this.resetPart1Selection();
    this.resetPart4Selection();
    if (selectPart === "part1" || selectPart === "part4") {
      this.resetPart2Selection();
      this.resetPart3Selection();
      payload.selectPart2 = "";
      payload.selectPart3 = "";
      if (selectPart === "part1") {
        payload.selectPart1 = value;
        payload.selectPart4 = "";
      } else {
        payload.selectPart4 = value;
        payload.selectPart1 = "";
      }
    } else if (selectPart === "part2" || selectPart === "part3") {
      payload.selectPart1 = "";
      payload.selectPart4 = "";
      if (selectPart === "part2") {
        this.resetPart2Selection();
        payload.selectPart2 = value;
      } else {
        this.resetPart3Selection();
        payload.selectPart3 = value;
      }
    }
    payload[value] = true;
    this.updateState(payload);
  };

  beforeUpload = (file) => {
    const { payload } = this.state;
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: (
          <DocumentSelection
            parentCallback={this.docSelect}
            payload={payload}
          />
        ),
        onOk: () => {
          if (this.state.document === null) {
            resolve(Upload.LIST_IGNORE);
          } else {
            Object.assign(file, { fileId: this.state.document });
            payload[this.state.document] = true;
            this.updateState(payload);
            this.setState({ document: null });
            resolve(true);
          }
        },
        onCancel: () => {
          resolve(Upload.LIST_IGNORE);
        },
        className: "individual-procedure-modal",
        okButtonProps: {
          className: "submit-button",
        },
        cancelButtonProps: {
          className: "cancel-button",
        },
      });
    });
  };
  onRemove = (file) => {
    const { payload } = this.state;
    payload[file.fileId] = true;
    this.updateState(payload);
  };
  defaultFileList = () => {
    const { documentList } = this.state.payload;

    if (documentList !== undefined && documentList.length > 0) {
      return documentList;
    }

    return null;
  };
  render() {
    const { payload } = this.state;
    const props = {
      onChange: this.handleOnChange,
      beforeUpload: this.beforeUpload,
      onRemove: this.onRemove,
      defaultFileList: this.defaultFileList,
      maxCount: 2,
    };

    return (
      <div className="individual-verification-reg-main-content">
        <div className="reg-header">
          {this.formatMessage_i18n("individual.verification.procedure.heading")}
        </div>
        <div className="individual-verification-text">
          {this.formatMessage_i18n("individual.verification.introduction.text")}
        </div>
        <div className="individual-verification-text">
          {this.formatMessage_i18n("individual.verification.statement.text")}
        </div>
        <div className="individual-verfication-dotted-border">
          <div className="reg-sub-header">Part I</div>
          <div className="document-selection-main">
            <select
              onChange={(e) => {
                this.fieldOnChange("part1", e.target.value);
              }}
              value={payload.selectPart1}
            >
              <option value="">
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.text"
                )}
              </option>
              <option
                value="AusDrivingLicense"
                title={this.formatMessage_i18n(
                  "individual-verification.document.Australian.Driving.License"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.Australian.Driving.License"
                )}
              </option>
              <option
                value="AusPassport"
                title={this.formatMessage_i18n(
                  "individual-verification.document.australian.passport"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.australian.passport"
                )}
              </option>
              <option
                value="StateCardIssuanceForAgeProof"
                title={this.formatMessage_i18n(
                  "individual-verification.document.state.or.territory.card"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.state.or.territory.card"
                )}
              </option>
              <option
                value="CurrentPassport"
                title={this.formatMessage_i18n(
                  "individual-verification.document.current.passport"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.current.passport"
                )}
              </option>
            </select>
          </div>
        </div>
        <div className="individual-verification-text">or</div>
        <div className="individual-verfication-dotted-border">
          <div className="reg-sub-header">Part II</div>
          <div className="document-selection-main">
            <select
              onChange={(e) => {
                this.fieldOnChange("part2", e.target.value);
              }}
              value={payload.selectPart2}
            >
              <option value="">
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.text"
                )}
              </option>
              <option
                value="AusBirthCertificate"
                title={this.formatMessage_i18n(
                  "individual-verification.document.australian.birth.certificate"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.australian.birth.certificate"
                )}
              </option>
              <option
                value="AusCitizenCertificate"
                title={this.formatMessage_i18n(
                  "individual-verification.document.australian.citizen.certificate"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.australian.citizen.certificate"
                )}
              </option>
              <option
                value="PensionCard"
                title={this.formatMessage_i18n(
                  "individual-verification.document.pension.card.by.HSD"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.pension.card.by.HSD"
                )}
              </option>
            </select>
          </div>
          <div className="individual-verification-text">and</div>
          <div className="document-selection-main">
            <select
              onChange={(e) => {
                this.fieldOnChange("part3", e.target.value);
              }}
              value={payload.selectPart3}
            >
              <option value="">
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.text"
                )}
              </option>
              <option
                value="CommonWealthOrTerritoryDoc"
                title={this.formatMessage_i18n(
                  "individual-verification.document.commonwealth.state.territory"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.commonwealth.state.territory"
                )}
              </option>
              <option
                value="AusTaxationDoc"
                title={this.formatMessage_i18n(
                  "individual-verification.document.Australian.Taxation"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.Australian.Taxation"
                )}
              </option>
              <option
                value="LocGovtOrUtilityProviderDoc"
                title={this.formatMessage_i18n(
                  "individual-verification.document.LocalGovernment.utility.provider"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.LocalGovernment.utility.provider"
                )}
              </option>
            </select>
          </div>
        </div>
        <div className="individual-verification-text">or</div>
        <div className="individual-verfication-dotted-border">
          <div className="reg-sub-header">Part III</div>
          <div className="document-selection-main">
            <select
              onChange={(e) => {
                this.fieldOnChange("part4", e.target.value);
              }}
              value={payload.selectPart4}
            >
              <option value="">
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.text"
                )}
              </option>
              <option
                value="ForeignDrivingLicense"
                title={this.formatMessage_i18n(
                  "individual-verification.document.foreign.driving.license"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.foreign.driving.license"
                )}
              </option>
              <option
                value="NationalID"
                title={this.formatMessage_i18n(
                  "individual-verification.document.national.id"
                )}
              >
                {" "}
                {this.formatMessage_i18n(
                  "individual-verification.document.national.id"
                )}
              </option>
            </select>
          </div>
        </div>

        <div className="verification-file-upload-main">
          <Dragger
            {...props}
            name="file"
            customRequest={() => {
              return "";
            }}
          >
            <p className="ant-upload-text">
              {this.formatMessage_i18n("verification.procedure.drag.drop.text")}
            </p>
          </Dragger>
        </div>
      </div>
    );
  }
}

export default IndividualVerificationProcedure;
