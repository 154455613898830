import { useMutation, useQuery } from "@tanstack/react-query";
import axiosConfig from "../axiosConfig";
import ROUTES from "../routes/api.json";
import { getUserId, useInvestorId } from "../components/common/utils";
import _filter from "lodash/filter";

export const useParticipantList = () => {
  return useQuery({
    queryKey: ["participantList"],
    queryFn: async () => {
      const { data } = await axiosConfig.get(ROUTES.GET_PARTICIPANT_LIST);
      return data;
    },
    staleTime: 60 * 60 * 1000,
    select: (data) => {
      return filterData(data, getUserId());
    },
  });
};

export const useBrokerInvestors = () => {
  return useQuery({
    queryKey: ["brokerInvestors"],
    queryFn: async () => {
      const { data } = await axiosConfig.get(ROUTES.GET_BROKER_INVESTOR);
      return data;
    },
    staleTime: 60 * 60 * 1000,
    select: (data) => {
      return filterData(data, getUserId());
    },
  });
};

export const usePortfolioPositions = () => {
  const investorId = useInvestorId();
  return useQuery({
    queryKey: ["portfolioPositions", investorId],
    queryFn: async () => {
      const { data } = await axiosConfig.get(
        `${ROUTES.PORTFOLIO_POSITIONS}/${investorId}`
      );
      return data;
    },
    staleTime: 60 * 60 * 1000,
  });
};

export const useInvestorDetails = () => {
  const investorId = useInvestorId();
  return useQuery({
    queryKey: ["investorDetails", investorId],
    queryFn: async () => {
      const { data: isRetailInvestor } = await axiosConfig.get(
        ROUTES.INVESTOR_DETAILS_VALIDATE
      );
      return {
        isRetailInvestor,
      };
    },
    staleTime: 60 * 60 * 1000,
  });
};

export const useUserState = () => {
  const investorId = useInvestorId();
  return useQuery({
    queryKey: ["userState", investorId],
    queryFn: async () => {
      const { data } = await axiosConfig.get(ROUTES.USER_STATE);
      return data;
    },
    staleTime: 60 * 60 * 1000,
  });
};

export const useUserDetail = () => {
  const investorId = useInvestorId();
  return useQuery({
    queryKey: ["userDetail", investorId],
    queryFn: async () => {
      const { data } = await axiosConfig.get(ROUTES.USER_DETAILS);
      return data;
    },
    staleTime: 60 * 60 * 1000,
  });
};

const filterData = (list, loggedInUser) => {
  let filteredList = list;
  if (loggedInUser) {
    filteredList = _filter(list, function (item) {
      return (
        item.id !== loggedInUser &&
        item.kycStatus !== "NOT_REGISTERED" &&
        item.kycStatus !== "REGISTERED_NOT_APPROVED" &&
        item.kycStatus !== "REGISTERED_APPROVED_UPDATED"
      );
    });
  }
  return filteredList;
};

export const useBrokerConnect = (successCallback, errorCallback) => {
  return useMutation({
    mutationFn: async (brokerInvestorId) => {
      const { data } = await axiosConfig.post(
        `${ROUTES.CONNECT_BROKER}/${brokerInvestorId}`
      );
      return data;
    },
    onSuccess: () => {
      if (successCallback) {
        successCallback();
      }
    },
    onError: (err) => {
      if (errorCallback) {
        errorCallback();
        console.error("Error occurred while connecting the Broker", err);
      }
    },
  });
};

export const useBrokerDisConnect = (successCallback, errorCallback) => {
  return useMutation({
    mutationFn: async (investorId) => {
      const { data } = await axiosConfig.post(
        `${ROUTES.DISCONNECT_BROKER}/${investorId}`
      );
      return data;
    },
    onSuccess: () => {
      if (successCallback) {
        successCallback();
      }
    },
    onError: (err) => {
      if (errorCallback) {
        errorCallback();
        console.error("Error occurred while disconnecting the Broker", err);
      }
    },
  });
};
