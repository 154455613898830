import { useHistory } from "react-router-dom";

function PrismLogo() {
  const history = useHistory();

  const navigateHome = () => {
    history.push("/");
  };

  return (
    <svg
      onClick={navigateHome}
      className="cursor-pointer w-[190px] md:w-[272px] md:h-[20px]"
      viewBox="0 0 272 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1396_4327)">
        <path d="M271.507 7.19287H0V8.46641H271.507V7.19287Z" fill="white" />
        <path
          d="M132.762 0.197349H134.843V6.39466H132.762V0.197349ZM132.762 19.8565H134.843V9.25564H132.762V19.8565ZM220.305 19.7938H222.296V9.19286H220.296L220.305 19.7938ZM238.726 19.7938H240.726V9.18389H238.726V19.7938ZM96.9595 7.00452C96.9595 2.78927 93.9281 0.269099 88.8877 0.269099H82.3855V2.06282H88.8339C92.7622 2.06282 94.8429 3.85654 94.8429 6.99556C94.8429 10.1346 92.7712 11.9193 88.8429 11.9193H82.3945V13.713H88.8967C89.4239 13.7144 89.9508 13.6874 90.4751 13.6323L94.9594 19.9103H97.2375L92.4304 13.166C93.7764 12.8123 94.9604 12.0077 95.7847 10.8863C96.6089 9.76495 97.0236 8.39476 96.9595 7.00452ZM38.0895 2.10765C41.7936 2.10765 43.8474 3.6951 44.0805 6.4933H46.1613C45.9012 2.60989 42.9236 0.304968 38.1433 0.304968H30.7891V5.3543V10.3319V19.9642H32.8698V13.7579H38.1433C42.1433 13.7579 44.8698 12.1435 45.8115 9.35429H43.5873C42.7532 11.0045 40.8967 11.9193 38.0895 11.9283H32.8698V2.06282L38.0895 2.10765ZM172.789 5.3812C172.789 3.41708 174.385 1.79376 177.982 1.79376C179.893 1.81766 181.758 2.38033 183.363 3.41708L184.063 1.75788C182.243 0.597097 180.122 -0.00469071 177.964 0.0269429C172.968 0.0269429 170.717 2.52918 170.726 5.40811C170.726 12.6817 182.834 9.36326 182.834 14.7534C182.834 16.6906 181.238 18.2422 177.56 18.2422C175.175 18.2784 172.861 17.4283 171.067 15.8565L170.251 17.4619C171.713 18.9776 174.61 20.009 177.56 20.009C182.583 20.009 184.888 17.5337 184.888 14.6278C184.897 7.46192 172.798 10.7265 172.789 5.33636V5.3812ZM239.013 0.170444L235.596 6.07179L231.794 12.6278L230.574 14.7534L229.273 12.5382L225.489 6.08075L222.018 0.170444H220.305V6.36774H222.296V3.91035L223.901 6.69959L226.942 11.9821L230.018 17.3633H231.004L238.717 4.12559V6.39466H240.717V0.188371L239.013 0.170444Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1396_4327">
          <rect width="271.507" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PrismLogo;
