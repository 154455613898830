import React, { Component } from "react";
import "./style.scss";
import { intl } from "../../../../utils/intl_i18n";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import { Input } from "antd";
import { PrismAddress, validatePOInput } from "../../../PrismAddress";

class ForeignCorporateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      isLocalAgentVisible: false,
      isRegisteredByASIC: props?.dependentProps?.registeredByASIC,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  validate = () => {
    const { payload, isRegisteredByASIC, isLocalAgentVisible } = this.state;
    if (isRegisteredByASIC === "yes") {
      if (
        payload.foreignCorporateARBN === undefined ||
        payload.foreignCorporateARBN === "" ||
        (payload.foreignCorporateARBN !== "" &&
          payload.foreignCorporateARBN.length < 3)
      ) {
        this.onValidationFail();
        return;
      }

      if (payload.companyType === undefined || payload.companyType === "") {
        this.onValidationFail();
        return;
      }

      if (isLocalAgentVisible) {
        if (
          payload.agentName === undefined ||
          payload.agentName === "" ||
          payload.agentName.length < 5
        ) {
          this.onValidationFail();
          return;
        }
      }

      if (
        payload.streetAddress === undefined ||
        !payload.streetAddress ||
        (payload.streetAddress &&
          (payload.streetAddress.length < 8 ||
            payload.streetAddress.length > 225 ||
            !validatePOInput(payload.streetAddress))) ||
        payload.subUrban === undefined ||
        !payload.subUrban ||
        (payload.subUrban && payload.subUrban.length < 4) ||
        payload.state === undefined ||
        !payload.state ||
        payload.postCode === undefined ||
        !payload.postCode ||
        (payload.postCode && payload.postCode.length < 4) ||
        payload.country === undefined ||
        !payload.country
      ) {
        this.onValidationFail();
        return;
      }
    }

    if (isRegisteredByASIC === "no") {
      if (
        payload.foreignRegistrationNumber === undefined ||
        payload.foreignRegistrationNumber === "" ||
        (payload.foreignRegistrationNumber !== "" &&
          payload.foreignRegistrationNumber.length < 5)
      ) {
        this.onValidationFail();
        return;
      }

      if (
        payload.foreignCompanyPlaceOfBusiness === undefined ||
        payload.foreignCompanyPlaceOfBusiness === ""
      ) {
        this.onValidationFail();
        return;
      }
    }
    this.onValidationPass();
  };

  componentDidMount = () => {
    const { payload } = this.state;
    payload["companyType"] = "placeOfBusiness";
    this.updateState(payload);
    this.validate();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  /**
   *
   * @param {*} payload
   * Will get the updated payload when the field changes added
   */
  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    if (field === "companyType") {
      if (value === "localAgent") {
        this.setState({ isLocalAgentVisible: true });
      } else {
        this.setState({ isLocalAgentVisible: false });
      }
      payload.streetAddress = undefined;
      payload.subUrban = undefined;
      payload.state = undefined;
      payload.postCode = undefined;
      payload.country = undefined;
      payload.agentName = undefined;
    }
    payload[field] = value;
    this.updateState(payload);
  };

  handleAddressChange = (fieldValue, fieldName) => {
    this.onInputChange(fieldName, fieldValue);
  };

  render() {
    const { payload, isLocalAgentVisible, isRegisteredByASIC } = this.state;
    return (
      <div className="foreign-corporate-details-main">
        <div className="corporate-details-title">
          {this.formatMessage_i18n("foreignCorporateDetails.title")}
        </div>
        <div className="corporate-details-content">
          {isRegisteredByASIC === "yes" && (
            <>
              <div className="corporate-details-description">
                {this.formatMessage_i18n("foreignCorporateDetails.arbn.title")}
              </div>
              <Input
                className={cn("corporate-details-input", {
                  "error-field":
                    _isUndefined(payload.foreignCorporateARBN) ||
                    payload.foreignCorporateARBN === "" ||
                    payload.foreignCorporateARBN.length < 3,
                })}
                placeholder={this.formatMessage_i18n(
                  "foreignCorporateDetails.arbn.placeHolder"
                )}
                value={payload.foreignCorporateARBN}
                onChange={(e) =>
                  this.onInputChange("foreignCorporateARBN", e.target.value)
                }
              />
              <div>
                <div className="corporate-details-description">
                  {this.formatMessage_i18n(
                    "foreignCorporateDetails.companyName.title"
                  )}
                </div>
                <div className="row">
                  <div className="left">
                    <select
                      value={payload.companyType}
                      className={cn("company-type-select", {
                        "error-field":
                          _isUndefined(payload.companyType) ||
                          payload.companyType === "",
                      })}
                      onChange={(e) =>
                        this.onInputChange("companyType", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="localAgent">Local Agent</option>
                      <option value="placeOfBusiness">Place of Business</option>
                    </select>
                  </div>
                  {isLocalAgentVisible && (
                    <div className="right">
                      <Input
                        className={cn(
                          "corporate-details-input agentName-input-large",
                          {
                            "error-field":
                              _isUndefined(payload.agentName) ||
                              payload.agentName === "" ||
                              payload.agentName.length < 5,
                          }
                        )}
                        placeholder={this.formatMessage_i18n(
                          "foreignCorporateDetails.field.agentName"
                        )}
                        onChange={(e) =>
                          this.onInputChange("agentName", e.target.value)
                        }
                        value={payload.agentName}
                      />
                    </div>
                  )}
                </div>
                <PrismAddress
                  streetPlaceholder={this.formatMessage_i18n("Street.Address")}
                  streetValue={payload.streetAddress}
                  streetOnChange={(value) =>
                    this.handleAddressChange(value, "streetAddress")
                  }
                  subUrbanPlaceholder={this.formatMessage_i18n(
                    "Suborb.or.town"
                  )}
                  subUrbanValue={payload.subUrban}
                  subUrbanOnChange={(value) =>
                    this.handleAddressChange(value, "subUrban")
                  }
                  statePlaceholder={this.formatMessage_i18n("reg.state")}
                  stateValue={payload.state}
                  stateOnChange={(val) =>
                    this.handleAddressChange(val, "state")
                  }
                  codePlaceholder={this.formatMessage_i18n("reg.postCode")}
                  codeValue={payload.postCode}
                  codeOnChange={(val) =>
                    this.handleAddressChange(val, "postCode")
                  }
                  countryPlaceholder={this.formatMessage_i18n("reg.country")}
                  countryValue={payload.country}
                  countryOnChange={(val) =>
                    this.handleAddressChange(val, "country")
                  }
                />
              </div>
            </>
          )}
          {isRegisteredByASIC === "no" && (
            <>
              <div className="corporate-details-description">
                {this.formatMessage_i18n(
                  "foreignCorporateDetails.foreignRegistration.title"
                )}
              </div>
              <Input
                className={cn("corporate-details-input", {
                  "error-field":
                    _isUndefined(payload.foreignRegistrationNumber) ||
                    payload.foreignRegistrationNumber === "" ||
                    payload.foreignRegistrationNumber.length < 5,
                })}
                placeholder={this.formatMessage_i18n(
                  "foreignCorporateDetails.foreign.placeHolder"
                )}
                value={payload.foreignRegistrationNumber}
                onChange={(e) =>
                  this.onInputChange(
                    "foreignRegistrationNumber",
                    e.target.value
                  )
                }
              />
              <div>
                <div className="corporate-details-description">
                  {this.formatMessage_i18n(
                    "foreignCorporateDetails.foreignRegistrationAddress.title"
                  )}
                </div>
                <Input
                  className={cn("corporate-details-input", {
                    "error-field":
                      _isUndefined(payload.foreignCompanyPlaceOfBusiness) ||
                      payload.foreignCompanyPlaceOfBusiness === "",
                  })}
                  placeholder={this.formatMessage_i18n(
                    "foreignCorporateDetails.field.placeOfBusiness"
                  )}
                  onChange={(e) =>
                    this.onInputChange(
                      "foreignCompanyPlaceOfBusiness",
                      e.target.value
                    )
                  }
                  value={payload.foreignCompanyPlaceOfBusiness}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ForeignCorporateDetails;
