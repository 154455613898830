import { useQuery } from "@tanstack/react-query";
import axiosConfig from "../axiosConfig";
import ROUTES from "../routes/api.json";
import { useInvestorId } from "../components/common/utils";
import { getDateXYearsAway } from "../utils/utile";

export const useBuyOpportunities = () => {
  const investorId = useInvestorId();
  return useQuery({
    queryKey: ["buyOpportunities", investorId],
    queryFn: async () => {
      const { data } = await axiosConfig.get(
        `${ROUTES.BUYING_OPPORTUNTIES_BOOK}/${investorId}`
      );
      return data;
    },
    enabled: !!investorId,
    staleTime: 60 * 60 * 1000,
    select: (data) => {
      return appendMaturitiesBuyOps(data);
    },
  });
};

const appendMaturitiesBuyOps = (buyOps) => {
  return buyOps.map((op) =>
    !op.offerMaturity
      ? { ...op, offerMaturity: op.term ? getDateXYearsAway(op.term) : null }
      : op
  );
};

const temp = () => {
  return [
    {
      underlying: "A2M.AU",
      currency: null,
      lastPx: 0.0,
      productName: "MAX_DIV",
      offerId: 10481779586983,
      offerEstabPrice: 2.22,
      offerMaturity: null,
      offerQty: 2000000,
      offerPrice: 2.22,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-06-09",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "AAL.LN",
      currency: null,
      lastPx: 0.0,
      productName: "MAX_DIV",
      offerId: 10482461161076,
      offerEstabPrice: 18.15,
      offerMaturity: null,
      offerQty: 1500000,
      offerPrice: 18.15,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-06-13",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "ADM.LN",
      currency: null,
      lastPx: 0.0,
      productName: "PURE_DIV",
      offerId: 10481779586963,
      offerEstabPrice: 12.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 12.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-06-02",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "AFI.AU",
      currency: null,
      lastPx: 0.0,
      productName: "GROWTH_GUARD",
      offerId: 10474859104330,
      offerEstabPrice: 4.86,
      offerMaturity: "2031-02-28",
      offerQty: 500000,
      offerPrice: 4.9,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 5,
      lastUpdateDate: "2023-05-05",
      seriesType: "GROWTH",
      productNameDisplay: "GrowthGuard",
      productNameColorCode: "RED",
    },
    {
      underlying: "AFI.AU",
      currency: null,
      lastPx: 0.0,
      productName: "MAX_DIV",
      offerId: 10482887975219,
      offerEstabPrice: 2.5,
      offerMaturity: "2031-04-30",
      offerQty: 1000000,
      offerPrice: 2.7,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 0,
      lastUpdateDate: "2023-06-15",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "AKE.AU",
      currency: null,
      lastPx: 0.0,
      productName: "DIV_GUARD",
      offerId: 10481779586981,
      offerEstabPrice: 10.5,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 10.5,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-06-08",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ALD.AU",
      currency: null,
      lastPx: 0.0,
      productName: "PURE_DIV",
      offerId: 10479361364233,
      offerEstabPrice: 14.5,
      offerMaturity: "2032-10-29",
      offerQty: 1000,
      offerPrice: 15.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 5,
      lastUpdateDate: "2023-05-10",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "PURE_DIV",
      offerId: 10485326416022,
      offerEstabPrice: 14.75,
      offerMaturity: null,
      offerQty: 7000000,
      offerPrice: 14.75,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 9.525593220338983,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "PURE_GROWTH",
      offerId: 10485326416023,
      offerEstabPrice: 11.63,
      offerMaturity: null,
      offerQty: 7000000,
      offerPrice: 11.63,
      offerAllowsPartial: true,
      offerLeverage: 2.2141014617368873,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "GROWTH",
      productNameDisplay: "PureGrowth",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_DIV",
      offerId: 10484169303475,
      offerEstabPrice: 12.75,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 12.75,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 11.019803921568627,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-11",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_DIV",
      offerId: 10484169303478,
      offerEstabPrice: 8.75,
      offerMaturity: null,
      offerQty: 150000,
      offerPrice: 8.75,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 16.057428571428574,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-11",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_DIV",
      offerId: 10484169303474,
      offerEstabPrice: 12.0,
      offerMaturity: null,
      offerQty: 150000,
      offerPrice: 12.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 11.708541666666667,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-11",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_DIV",
      offerId: 10484169303477,
      offerEstabPrice: 17.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 17.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 8.26485294117647,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-11",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_DIV",
      offerId: 10485326416008,
      offerEstabPrice: 12.5,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 12.5,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 11.2402,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_GROWTH",
      offerId: 10484169303473,
      offerEstabPrice: 15.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 15.0,
      offerAllowsPartial: true,
      offerLeverage: 1.7166666666666666,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-11",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_GROWTH",
      offerId: 10484169303476,
      offerEstabPrice: 16.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 16.0,
      offerAllowsPartial: true,
      offerLeverage: 1.609375,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-11",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "DIV_GUARD",
      offerId: 10485326416002,
      offerEstabPrice: 10.75,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 10.75,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 13.069999999999999,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-24",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "DIV_GUARD",
      offerId: 10485326416004,
      offerEstabPrice: 10.75,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 10.75,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 13.069999999999999,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-24",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "DIV_GUARD",
      offerId: 10482887975220,
      offerEstabPrice: 18.42,
      offerMaturity: "2031-07-31",
      offerQty: 300000,
      offerPrice: 19.5,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 7.2052564102564105,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 0,
      lastUpdateDate: "2023-06-15",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_GROWTH",
      offerId: 10484169303472,
      offerEstabPrice: 15.75,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 15.75,
      offerAllowsPartial: true,
      offerLeverage: 1.6349206349206349,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-11",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_GROWTH",
      offerId: 10485326415998,
      offerEstabPrice: 6.25,
      offerMaturity: null,
      offerQty: 300000,
      offerPrice: 6.25,
      offerAllowsPartial: true,
      offerLeverage: 4.12,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 10,
      lastUpdateDate: "2023-07-17",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_GROWTH",
      offerId: 10485326416006,
      offerEstabPrice: 6.25,
      offerMaturity: null,
      offerQty: 300000,
      offerPrice: 6.25,
      offerAllowsPartial: true,
      offerLeverage: 4.12,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-24",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_GROWTH",
      offerId: 10485326416010,
      offerEstabPrice: 0.6400000000000006,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 0.6400000000000006,
      offerAllowsPartial: true,
      offerLeverage: 40.234374999999964,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "ANZ.AU",
      currency: null,
      lastPx: 25.75,
      productName: "MAX_GROWTH",
      offerId: 10485326416011,
      offerEstabPrice: 10.0,
      offerMaturity: null,
      offerQty: 300000,
      offerPrice: 10.0,
      offerAllowsPartial: true,
      offerLeverage: 2.575,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "BHP.AU",
      currency: null,
      lastPx: 46.01,
      productName: "MAX_DIV",
      offerId: 10481779586974,
      offerEstabPrice: 35.01,
      offerMaturity: null,
      offerQty: 1000000,
      offerPrice: 35.01,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 6.975713902584345,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 3,
      lastUpdateDate: "2023-06-05",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "BHP.AU",
      currency: null,
      lastPx: 46.01,
      productName: "MAX_DIV",
      offerId: 10486548231485,
      offerEstabPrice: 15.829999999999998,
      offerMaturity: null,
      offerQty: 1000000,
      offerPrice: 15.829999999999998,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 15.427652794028928,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 3,
      lastUpdateDate: "2023-07-28",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "BHP.AU",
      currency: null,
      lastPx: 46.01,
      productName: "GROWTH_GUARD",
      offerId: 10480928796988,
      offerEstabPrice: 34.01,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 34.01,
      offerAllowsPartial: true,
      offerLeverage: 1.352837400764481,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-05-23",
      seriesType: "GROWTH",
      productNameDisplay: "GrowthGuard",
      productNameColorCode: "RED",
    },
    {
      underlying: "BHP.AU",
      currency: null,
      lastPx: 46.01,
      productName: "DIV_GUARD",
      offerId: 10485326416000,
      offerEstabPrice: 34.01,
      offerMaturity: null,
      offerQty: 1000000,
      offerPrice: 34.01,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 7.180821632739721,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-20",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "BHP.AU",
      currency: null,
      lastPx: 46.01,
      productName: "DIV_GUARD",
      offerId: 10481161340671,
      offerEstabPrice: 27.44,
      offerMaturity: "2023-05-31",
      offerQty: 65000,
      offerPrice: 27.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 9.045175693684367,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 0,
      lastUpdateDate: "2023-05-26",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "BHP.AU",
      currency: null,
      lastPx: 46.01,
      productName: "DIV_GUARD",
      offerId: 10481779586965,
      offerEstabPrice: 32.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 32.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 7.631866991546184,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 3,
      lastUpdateDate: "2023-06-02",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "BHP.AU",
      currency: null,
      lastPx: 46.01,
      productName: "MAX_GROWTH",
      offerId: 10485326416001,
      offerEstabPrice: 13.339999999999996,
      offerMaturity: null,
      offerQty: 1000000,
      offerPrice: 13.339999999999996,
      offerAllowsPartial: true,
      offerLeverage: 3.449025487256373,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-20",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "BNP.FP",
      currency: null,
      lastPx: 0.0,
      productName: "PURE_DIV",
      offerId: 10454720678075,
      offerEstabPrice: 19.21,
      offerMaturity: "2027-07-30",
      offerQty: 50000,
      offerPrice: 45.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 5,
      lastUpdateDate: "2022-07-25",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "BNR.GR",
      currency: null,
      lastPx: 0.0,
      productName: "PURE_DIV",
      offerId: 10454720678074,
      offerEstabPrice: 60.12,
      offerMaturity: "2027-07-30",
      offerQty: 100000,
      offerPrice: 35.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 5,
      lastUpdateDate: "2022-07-25",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "PURE_DIV",
      offerId: 10481779586966,
      offerEstabPrice: 60.91,
      offerMaturity: null,
      offerQty: 10000,
      offerPrice: 60.91,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 6.667471679527172,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 3,
      lastUpdateDate: "2023-06-02",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "PURE_GROWTH",
      offerId: 10464861555727,
      offerEstabPrice: 53.709999999999994,
      offerMaturity: null,
      offerQty: 10000,
      offerPrice: 53.709999999999994,
      offerAllowsPartial: false,
      offerLeverage: 1.9681623533792592,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 1,
      lastUpdateDate: "2022-11-22",
      seriesType: "GROWTH",
      productNameDisplay: "PureGrowth",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "PURE_DIV",
      offerId: 10484106588433,
      offerEstabPrice: 65.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 65.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 6.247933846153846,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 3,
      lastUpdateDate: "2023-06-29",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "PURE_DIV",
      offerId: 10486440183313,
      offerEstabPrice: 12.0,
      offerMaturity: null,
      offerQty: 33333333,
      offerPrice: 12.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 33.842975,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-26",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "MAX_DIV",
      offerId: 10475650617343,
      offerEstabPrice: 44.709999999999994,
      offerMaturity: null,
      offerQty: 1000000,
      offerPrice: 44.709999999999994,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 9.083330351151869,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-03-27",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "MAX_DIV",
      offerId: 10478644777211,
      offerEstabPrice: 38.81999999999999,
      offerMaturity: null,
      offerQty: 500000,
      offerPrice: 38.81999999999999,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 10.461506955177747,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-04-27",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "GROWTH_GUARD",
      offerId: 10478644777210,
      offerEstabPrice: 72.71,
      offerMaturity: null,
      offerQty: 500000,
      offerPrice: 72.71,
      offerAllowsPartial: true,
      offerLeverage: 1.453857791225416,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-04-27",
      seriesType: "GROWTH",
      productNameDisplay: "GrowthGuard",
      productNameColorCode: "RED",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "DIV_GUARD",
      offerId: 10485326416018,
      offerEstabPrice: 77.71,
      offerMaturity: null,
      offerQty: 5000000,
      offerPrice: 77.71,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 5.226041693475744,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "CBA.AU",
      currency: null,
      lastPx: 105.71,
      productName: "MAX_GROWTH",
      offerId: 10485326416019,
      offerEstabPrice: 29.279999999999987,
      offerMaturity: null,
      offerQty: 5000000,
      offerPrice: 29.279999999999987,
      offerAllowsPartial: true,
      offerLeverage: 3.6103142076502746,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "FMG.AU",
      currency: null,
      lastPx: 21.68,
      productName: "PURE_GROWTH",
      offerId: 10485326416024,
      offerEstabPrice: 16.0,
      offerMaturity: null,
      offerQty: 3000000,
      offerPrice: 16.0,
      offerAllowsPartial: true,
      offerLeverage: 1.355,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "GROWTH",
      productNameDisplay: "PureGrowth",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "FMG.AU",
      currency: null,
      lastPx: 21.68,
      productName: "PURE_DIV",
      offerId: 10485326416012,
      offerEstabPrice: 13.57,
      offerMaturity: null,
      offerQty: 150000,
      offerPrice: 13.57,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 10.305497420781133,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "FMG.AU",
      currency: null,
      lastPx: 21.68,
      productName: "MAX_DIV",
      offerId: 10486548231484,
      offerEstabPrice: 15.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 15.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 9.323039999999999,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-28",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "GLEN.LN",
      currency: null,
      lastPx: 4.7355,
      productName: "MAX_GROWTH",
      offerId: 10485326416026,
      offerEstabPrice: -0.044500000000000206,
      offerMaturity: null,
      offerQty: 2342342434,
      offerPrice: -0.044500000000000206,
      offerAllowsPartial: true,
      offerLeverage: -106.41573033707816,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-26",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "HSBA.LN",
      currency: null,
      lastPx: 6.462999999999999,
      productName: "DIV_GUARD",
      offerId: 10476600249207,
      offerEstabPrice: 4.462999999999999,
      offerMaturity: null,
      offerQty: 150000,
      offerPrice: 4.462999999999999,
      offerAllowsPartial: false,
      offerLeverage: 0.0,
      offerYield: 12.18685411069429,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-04-03",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "HSBA.LN",
      currency: null,
      lastPx: 6.462999999999999,
      productName: "DIV_GUARD",
      offerId: 10476671081810,
      offerEstabPrice: 3.4529999999999994,
      offerMaturity: null,
      offerQty: 250000,
      offerPrice: 3.4529999999999994,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 15.75150011469117,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-04-05",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "HSBA.LN",
      currency: null,
      lastPx: 6.462999999999999,
      productName: "DIV_GUARD",
      offerId: 10478400883823,
      offerEstabPrice: 3.9529999999999994,
      offerMaturity: null,
      offerQty: 250000,
      offerPrice: 3.9529999999999994,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 13.759152516070987,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-04-24",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "HSBA.LN",
      currency: null,
      lastPx: 6.462999999999999,
      productName: "DIV_GUARD",
      offerId: 10478677412023,
      offerEstabPrice: 3.212999999999999,
      offerMaturity: null,
      offerQty: 250000,
      offerPrice: 3.212999999999999,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 16.928082756311426,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-04-28",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "HSBA.LN",
      currency: null,
      lastPx: 6.462999999999999,
      productName: "MAX_GROWTH",
      offerId: 10477738579528,
      offerEstabPrice: 3.232999999999999,
      offerMaturity: null,
      offerQty: 250000,
      offerPrice: 3.232999999999999,
      offerAllowsPartial: true,
      offerLeverage: 1.9990720692854935,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-04-20",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "MBG.GR",
      currency: null,
      lastPx: 72.72,
      productName: "MAX_DIV",
      offerId: 10485326416025,
      offerEstabPrice: 71.27,
      offerMaturity: null,
      offerQty: 543345,
      offerPrice: 71.27,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-26",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "MQG.AU",
      currency: null,
      lastPx: 175.01,
      productName: "PURE_DIV",
      offerId: 10462879996439,
      offerEstabPrice: 98.00999999999999,
      offerMaturity: null,
      offerQty: 9000,
      offerPrice: 98.00999999999999,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 8.287385981022345,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2022-11-09",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "MQG.AU",
      currency: null,
      lastPx: 175.01,
      productName: "PURE_GROWTH",
      offerId: 10481779586967,
      offerEstabPrice: 90.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 90.0,
      offerAllowsPartial: true,
      offerLeverage: 1.9445555555555554,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-06-05",
      seriesType: "GROWTH",
      productNameDisplay: "PureGrowth",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "MQG.AU",
      currency: null,
      lastPx: 175.01,
      productName: "PURE_GROWTH",
      offerId: 10462879996438,
      offerEstabPrice: 94.00999999999999,
      offerMaturity: null,
      offerQty: 99000,
      offerPrice: 94.00999999999999,
      offerAllowsPartial: true,
      offerLeverage: 1.861610466971599,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2022-11-09",
      seriesType: "GROWTH",
      productNameDisplay: "PureGrowth",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "MQG.AU",
      currency: null,
      lastPx: 175.01,
      productName: "PURE_GROWTH",
      offerId: 10462879996440,
      offerEstabPrice: 93.50999999999999,
      offerMaturity: null,
      offerQty: 2000,
      offerPrice: 93.50999999999999,
      offerAllowsPartial: true,
      offerLeverage: 1.8715645385520265,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-04-11",
      seriesType: "GROWTH",
      productNameDisplay: "PureGrowth",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "MQG.AU",
      currency: null,
      lastPx: 175.01,
      productName: "PURE_DIV",
      offerId: 10464861555728,
      offerEstabPrice: 91.88,
      offerMaturity: "2029-11-30",
      offerQty: 1000,
      offerPrice: 82.0,
      offerAllowsPartial: false,
      offerLeverage: 0.0,
      offerYield: 9.90544756097561,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 5,
      lastUpdateDate: "2022-11-28",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
    {
      underlying: "MQG.AU",
      currency: null,
      lastPx: 175.01,
      productName: "GROWTH_GUARD",
      offerId: 10483230145502,
      offerEstabPrice: 122.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 122.0,
      offerAllowsPartial: true,
      offerLeverage: 1.4345081967213114,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-06-19",
      seriesType: "GROWTH",
      productNameDisplay: "GrowthGuard",
      productNameColorCode: "RED",
    },
    {
      underlying: "STW.AU",
      currency: null,
      lastPx: 66.79,
      productName: "DIV_GUARD",
      offerId: 10485326416013,
      offerEstabPrice: 42.0,
      offerMaturity: null,
      offerQty: 555000,
      offerPrice: 42.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 5.994542857142858,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "INCOME",
      productNameDisplay: "DivGuard",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "STW.AU",
      currency: null,
      lastPx: 66.79,
      productName: "MAX_GROWTH",
      offerId: 10485326416017,
      offerEstabPrice: 24.790000000000006,
      offerMaturity: null,
      offerQty: 555000,
      offerPrice: 24.790000000000006,
      offerAllowsPartial: true,
      offerLeverage: 2.6942315449778134,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-07-25",
      seriesType: "GROWTH",
      productNameDisplay: "MaxGrowth",
      productNameColorCode: "BLUE",
    },
    {
      underlying: "WBC.AU",
      currency: null,
      lastPx: 22.34,
      productName: "GROWTH_GUARD",
      offerId: 10482887975218,
      offerEstabPrice: 16.78,
      offerMaturity: null,
      offerQty: 1000000,
      offerPrice: 16.78,
      offerAllowsPartial: true,
      offerLeverage: 1.33134684147795,
      offerYield: 0.0,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK"],
      term: 5,
      lastUpdateDate: "2023-06-15",
      seriesType: "GROWTH",
      productNameDisplay: "GrowthGuard",
      productNameColorCode: "RED",
    },
    {
      underlying: "WES.AU",
      currency: null,
      lastPx: 49.65,
      productName: "MAX_DIV",
      offerId: 10481779586975,
      offerEstabPrice: 28.0,
      offerMaturity: null,
      offerQty: 100000,
      offerPrice: 28.0,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 5.8434107142857155,
      opportunityType: "MATCH_BID",
      considerationTypeList: ["STOCK", "CASH"],
      term: 5,
      lastUpdateDate: "2023-06-07",
      seriesType: "INCOME",
      productNameDisplay: "MaxDiv",
      productNameColorCode: "RED",
    },
    {
      underlying: "WOW.AU",
      currency: null,
      lastPx: 0.0,
      productName: "PURE_DIV",
      offerId: 10479361364231,
      offerEstabPrice: 13.14,
      offerMaturity: "2026-05-29",
      offerQty: 500000,
      offerPrice: 10.08,
      offerAllowsPartial: true,
      offerLeverage: 0.0,
      offerYield: 0.0,
      opportunityType: "OFFER",
      considerationTypeList: ["CASH"],
      term: 5,
      lastUpdateDate: "2023-05-16",
      seriesType: "INCOME",
      productNameDisplay: "PureDiv",
      productNameColorCode: "GREEN",
    },
  ];
};
