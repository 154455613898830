import {
  considerationOptions,
  optionsByConsideration,
  riskQuestionByActionOption,
  riskOptionsRGB,
  riskOptionsPurple,
  shareholderApplicationOptions,
  orderProductByActionRisk,
  orderProductByShareholderApplicationOption,
  formActionOption,
  formRiskOption,
} from "./config";
import OptionSet from "./OptionSet";
import OrderPanel from "./OrderPanel";

export default function BuyOpsOrderForm({
  formik,
  orderId,
  searchParams,
  securitiesList,
}) {
  const handleGoBack = () => {
    if (formik.values.considerationType) {
      formik.setFieldValue("considerationType", "");
    }
  };

  const handleConsiderationTypeChange = (e) => {
    const value = e.target.value;
    formik.setFieldValue(
      "actionOption",
      `BUY_${searchParams.get("seriesType")}_${value}`
    );
    const riskOption = formRiskOption({
      productName: searchParams?.get("productName"),
      considerationType: value,
      seriesType: searchParams?.get("seriesType"),
      orderSide: "BUY",
    });
    formik.setFieldValue("riskOption", riskOption);
    formik.setFieldValue("considerationType", value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col max-w-sm mx-auto">
        <OptionSet
          handleChange={handleConsiderationTypeChange}
          name="considerationType"
          prompt="I wish to pay with..."
          promptClassName="mt-0"
          options={considerationOptions}
          value={formik.values.considerationType}
        />
      </div>
      {formik.values.considerationType && (
        <div className="mt-8 max-w-[724px] mx-auto z-[99] relative">
          <OrderPanel
            formik={formik}
            orderId={orderId}
            securitiesList={securitiesList}
            buyOp
            searchParams={searchParams}
          />
        </div>
      )}
      {formik.values.considerationType && (
        <div className="mt-6 max-w-sm mx-auto">
          <button
            className="bg-black rounded-full text-white px-4 py-1"
            onClick={handleGoBack}
            type="button"
          >
            Back
          </button>
        </div>
      )}
    </form>
  );
}
