import React, { Component } from "react";
import "./style.scss";
import _isUndefined from "lodash/isUndefined";
import { intl } from "../../../../utils/intl_i18n";

class TrusteeIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  validate = () => {
    const { payload } = this.state;
    if (payload.typeOfTrustee === undefined || !payload.typeOfTrustee) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onChangeTypeOfTrustee = (e) => {
    const { payload } = this.state;
    payload.typeOfTrustee = e.target.value;
    this.updateState(payload);
    // if the sequenceCallback is present are not
    const sequenceCallback = this?.props?.sequenceCallback;
    if (sequenceCallback) {
      sequenceCallback();
    }
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="trusteeIdentification-main">
        <div className="trusteeIdentification-title">
          {this.formatMessage_i18n("trusteeIdentification.typeOfTrustee.title")}
        </div>
        <div className="trusteeIdentification-dropdown">
          <div className="trusteeIdentification-label">
            {this.formatMessage_i18n(
              "trusteeIdentification.typeOfTrustee.label"
            )}
          </div>
          <select
            className={
              (_isUndefined(payload.typeOfTrustee) ||
                payload.typeOfTrustee === "") &&
              "error-field"
            }
            onChange={this.onChangeTypeOfTrustee}
            value={payload.typeOfTrustee}
          >
            <option value="">
              {this.formatMessage_i18n(
                "trusteeIdentification.typeOfTrustee.selectLabel"
              )}
            </option>
            <option value="individualTrustee">
              {this.formatMessage_i18n(
                "trusteeIdentification.typeOfTrustee.option1"
              )}
            </option>
            <option value="australianCompanyTrustee">
              {this.formatMessage_i18n(
                "trusteeIdentification.typeOfTrustee.option2"
              )}
            </option>
            <option value="foreignCompanyTrustee">
              {this.formatMessage_i18n(
                "trusteeIdentification.typeOfTrustee.option3"
              )}
            </option>
          </select>
        </div>
      </div>
    );
  }
}

export default TrusteeIdentification;
