import React from "react";
import "./styles.scss";

const ActionIcons = (props) => {
  return (
    <span className="actionIcons">
      {/* <Tooltip title="Drag">
                <DragOutlined className='actionIcons__drag drag-handler' />
             </Tooltip>
             <Tooltip title="Close">
                <CloseOutlined onClick={props.onClose} className='actionIcons__close' />
             </Tooltip> */}
    </span>
  );
};

export default ActionIcons;
