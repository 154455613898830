/**
 * @author Satheesh Kumar
 * @fileoverview This files contains all the rules used in the application
 *
 */

import moment from "moment";
import {
  maxTermMaximumErrorMessage,
  maxTermMinimumErrorMessage,
  minTermMaximumErrorMessage,
  minTermMinimumErrorMessage,
  taxFileNumberErrorMessage,
} from "./message";

/**
 * @method Method - This method validates the give Date with constant rules
 * @param {Dateobject} date
 * @param {string} validationType
 * @param {string} validationRule
 * @returns {boolean}
 */

// TODO - To extend the validation for the months and days
export const validateDate = (
  date,
  validationType = "years",
  validationRule = 18
) => {
  let isValidDate = false;
  const yearDifference = moment().diff(
    moment(date, "DD-MM-YYYY"),
    validationType
  );
  if (yearDifference >= validationRule) {
    isValidDate = true;
  }
  return isValidDate;
};

export const validateMinTerm = (minTermValue, maxTermValue) => {
  let isMinTermValid = true;
  let errorMessage = "";
  if (minTermValue === "" && minTermValue === undefined) {
    return;
  }

  if (parseInt(minTermValue) <= 0) {
    isMinTermValid = false;
    errorMessage = minTermMinimumErrorMessage;
  }
  if (
    maxTermValue !== "" &&
    maxTermValue !== undefined &&
    parseInt(maxTermValue) < parseInt(minTermValue)
  ) {
    isMinTermValid = false;
    errorMessage = minTermMaximumErrorMessage;
  }

  return {
    isMinTermValid,
    errorMessage,
  };
};

export const validateMaxTerm = (maxTermValue, minTermValue) => {
  let isMaxTermValid = true;
  let errorMessage = "";

  if (maxTermValue === "" && maxTermValue === undefined) {
    return;
  }

  if (parseInt(maxTermValue) > 10) {
    isMaxTermValid = false;
    errorMessage = maxTermMaximumErrorMessage;
  }

  if (
    minTermValue !== "" &&
    minTermValue !== undefined &&
    parseInt(maxTermValue) <= parseInt(minTermValue)
  ) {
    isMaxTermValid = false;
    errorMessage = maxTermMinimumErrorMessage;
  }

  return {
    isMaxTermValid,
    errorMessage,
  };
};

export const validateGiin = (value) => {
  return value.match(
    /\b[A-NP-Z0-9]{6}\.[A-NP-Z0-9]{5}\.(?:LE|SL|ME|BR|SP)\.\d{3}\b/
  ) === null
    ? false
    : true;
};

export const validateTfn = (taxFileNumber) => {
  let isTfnValid = true;
  let errorMessage = "";

  if (taxFileNumber === "") {
    return;
  }

  //remove spaces and update
  taxFileNumber = taxFileNumber.toString().replace(/\s+/g, "");

  //validate only digits
  if (taxFileNumber) {
    var isNumber = /^[0-9]+$/.test(taxFileNumber);
    if (!isNumber) {
      isTfnValid = false;
      errorMessage = taxFileNumberErrorMessage;
    }
  }

  if (taxFileNumber.toString().length == 9) {
    var digits = taxFileNumber.toString().split("");
    var weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];
    var sum = 0;
    for (let i = 0; i < digits.length; i++) {
      //do the calculation
      sum += digits[i] * weights[i];
    }
    if (sum % 11 == 0) {
      isTfnValid = true;
      errorMessage = "";
    } else {
      isTfnValid = false;
      errorMessage = taxFileNumberErrorMessage;
    }
  } else {
    isTfnValid = false;
    errorMessage = taxFileNumberErrorMessage;
  }
  return {
    isTfnValid,
    errorMessage,
  };
};
