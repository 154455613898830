import { useState } from "react";
import Modal from "antd/es/modal";
import "../../styles/react-grid-layout-styles.css";
import "../../styles/react-resizable-styles.css";
import "./style.scss";
import "../../styles/table-override.scss";
import PageContent from "../common/PageContent";
import DealHistory from "./dealHistory";
import InterestHistory from "./interestHistory";
import PersonalDetails from "./personalDetails";
import ParticipantDetail from "../common/ParticipantDetail";
import {
  useInvestorId,
  getStorageData,
  getOBOInvestorID,
  getUserId,
} from "../common/utils";

function Investor({ history }) {
  const [userName, setUserName] = useState(
    JSON.parse(sessionStorage.getItem("UserDetails"))
  );
  const [selectedPage, setSelectedPage] = useState(
    sessionStorage.getItem("investorPage")
      ? sessionStorage.getItem("investorPage")
      : "dealHistory"
  );
  const investorId = useInvestorId();

  const updatePageView = () => {
    setSelectedPage(
      sessionStorage.getItem("investorPage")
        ? sessionStorage.getItem("investorPage")
        : "dealHistory"
    );
  };

  return (
    <PageContent
      className="investor-content"
      activeMenuHeader={"investor"}
      headerMenuCallback={updatePageView}
      childrenClassName={"investor-container"}
      history={history}
    >
      <div className="investor-table-container">
        {selectedPage === "dealHistory" && (
          <DealHistory investorId={investorId} />
        )}
        {selectedPage === "interestHistory" && (
          <InterestHistory investorId={investorId} />
        )}
        {selectedPage === "personalDetails" && (
          <PersonalDetails investorId={investorId} />
        )}
      </div>
    </PageContent>
  );
}

export default Investor;
