import { Component } from "react";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import "antd/dist/antd.css";
import "./styles.scss";
import message from "antd/es/message";
import { intl } from "../../../../utils/intl_i18n";
import {
  CalendarOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import OwnershipControl from "./ownership-control";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";
import { PrismAddress, validatePOInput } from "../../../PrismAddress";
import { validateDate } from "../../../common/validation";
import { dateErrorMessage } from "../../../common/message";

class BeneficialOwnership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };

    if (!this.state.payload.beneficialOwnersList) {
      this.state.payload.beneficialOwnersList = [];
      this.addBeneficialOwner();
    }

    if (!this.state.payload.individualControllerList) {
      this.state.payload.individualControllerList = [];
      this.addIndividualController();
    }
  }

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  updateState = (payload, afterUpdate) => {
    this.setState(payload, () => {
      if (afterUpdate) {
        afterUpdate();
      }
      this.validate();
    });
  };
  componentDidMount() {
    this.validate();
  }

  validate = () => {
    const { payload } = this.state;
    if (!payload.hasBeneficialOwners || payload.hasBeneficialOwners === null) {
      this.onValidationFail();
      return;
    }
    //  console.log(this.validateBeneficialOwnerList())
    if (
      payload.hasBeneficialOwners === "yes" &&
      !this.validateBeneficialOwnerList()
    ) {
      this.onValidationFail();
      return;
    }

    if (
      payload.hasBeneficialOwners === "no" &&
      !this.validateIndividualControllerList()
    ) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };

  validateBeneficialOwnerList = () => {
    const { payload } = this.state;
    var flag = true;
    if (!payload.beneficialOwnersList) {
      return false;
    }
    if (payload.beneficialOwnersList.length === 0) {
      return false;
    }
    for (var i = 0; i < payload.beneficialOwnersList.length; i++) {
      if (
        !payload.beneficialOwnersList[i].familyName ||
        !payload.beneficialOwnersList[i].givenName ||
        !payload.beneficialOwnersList[i].dateofbirth ||
        (payload.beneficialOwnersList[i].dateofbirth &&
          !validateDate(payload.beneficialOwnersList[i].dateofbirth)) ||
        !payload.beneficialOwnersList[i].isTaxResidentOfAustralia ||
        !payload.beneficialOwnersList[i].streetAddress ||
        (payload.beneficialOwnersList[i].streetAddress &&
          (payload.beneficialOwnersList[i].streetAddress.length < 8 ||
            payload.beneficialOwnersList[i].streetAddress.length > 225 ||
            !validatePOInput(payload.beneficialOwnersList[i].streetAddress))) ||
        !payload.beneficialOwnersList[i].subUrban ||
        (payload.beneficialOwnersList[i].subUrban &&
          payload.beneficialOwnersList[i].subUrban.length < 4) ||
        !payload.beneficialOwnersList[i].state ||
        !payload.beneficialOwnersList[i].postCode ||
        (payload.beneficialOwnersList[i].postCode &&
          payload.beneficialOwnersList[i].postCode.length < 4) ||
        !payload.beneficialOwnersList[i].countryName
      ) {
        this.onValidationFail();
        flag = false;
        break;
      }
    }

    return flag;
  };

  validateIndividualControllerList = () => {
    const { payload } = this.state;
    var flag = true;
    if (!payload.individualControllerList) {
      return false;
    }
    if (payload.individualControllerList.length === 0) {
      return false;
    }
    for (var i = 0; i < payload.individualControllerList.length; i++) {
      if (
        !payload.individualControllerList[i].familyName ||
        !payload.individualControllerList[i].givenName ||
        !payload.individualControllerList[i].dateofbirth ||
        (payload.individualControllerList[i].dateofbirth &&
          !validateDate(payload.individualControllerList[i].dateofbirth)) ||
        !payload.individualControllerList[i].isTaxResidentOfAustralia ||
        !payload.individualControllerList[i].streetAddress ||
        (payload.individualControllerList[i].streetAddress &&
          (payload.individualControllerList[i].streetAddress.length < 8 ||
            payload.individualControllerList[i].streetAddress.length > 225 ||
            !validatePOInput(
              payload.individualControllerList[i].streetAddress
            ))) ||
        !payload.individualControllerList[i].subUrban ||
        (payload.individualControllerList[i].subUrban &&
          payload.individualControllerList[i].subUrban.length < 4) ||
        !payload.individualControllerList[i].state ||
        !payload.individualControllerList[i].postCode ||
        (payload.individualControllerList[i].postCode &&
          payload.individualControllerList[i].postCode.length < 4) ||
        !payload.individualControllerList[i].countryName
      ) {
        this.onValidationFail();
        flag = false;
        break;
      }
    }
    return flag;
  };

  formatMessage_i18n = (messageId) => {
    if (messageId === "beneficial.ownership.no.subtitle") {
      return (
        <FormattedMessage
          id={messageId}
          defaultMessage={intl.formatMessage({ id: messageId })}
          values={{
            control: (
              <a onClick={() => this.handleOpenModal("ownership")}>
                <FormattedMessage id="link" defaultMessage="control*" />
              </a>
            ),
          }}
        />
      );
    } else {
      return intl.formatMessage({ id: messageId });
    }
  };

  /**
   * @method Method to handle the Change event in the Beneficial Ownership
   * @param {event} e
   */
  onChangeBeneficialOwner = (e) => {
    const { payload } = this.state;
    payload.hasBeneficialOwners = e.target.value || null;
    // Resets the Beneficial Owners and individual owners list whenever the flag changes
    payload.beneficialOwnersList = [];
    payload.individualControllerList = [];

    // Adding a Beneficial Owner if value is yes
    if (e.target.value === "yes") {
      this.addBeneficialOwner();
    }

    // Adding a Individual Owner if value is yes
    if (e.target.value === "no") {
      this.addIndividualController();
    }
    this.updateState(payload);
  };

  onFamilyNameChange = (beneficiary, value) => {
    const { payload } = this.state;
    beneficiary.familyName = value;
    this.updateState(payload);
  };
  onGivenNameChange = (beneficiary, value) => {
    const { payload } = this.state;
    beneficiary.givenName = value;
    this.updateState(payload);
  };

  onRoleChange = (beneficiary, value) => {
    const { payload } = this.state;
    beneficiary.role = value;
    this.updateState(payload);
  };

  onDOBChange = (beneficiary, value) => {
    const { payload } = this.state;
    beneficiary.dateofbirth = value;
    this.updateState(payload);
  };

  /**
   *
   * @param {object} beneficialOwner  - Beneficiary Controller or the Individual Controller data
   * @param {*} value - Value of the active content to be populated in the Select field
   * @returns  - Updates the Set value based on the selected beneficiary
   */

  onIsTaxResidentOfAustraliaChange = (beneficiary, value) => {
    beneficiary.isTaxResidentOfAustralia = value;
    this.updateFactaDetails(beneficiary, value);
  };

  updateFactaDetails = (activeBeneficiary, value) => {
    const { payload } = this.state;
    // Resetting the activebeneficiary details when switching the resident from no to yes or vice versa
    activeBeneficiary.isTaxNonAustralian = "";
    activeBeneficiary.taxResidentList = [{ country: "", tin: "", reason: "" }];
    this.updateState(payload);
  };

  onResidentialAddressChange = (beneficiary, value) => {
    const { payload } = this.state;
    beneficiary.residentialAddress = value;
    this.updateState(payload);
  };

  addBeneficialOwner = () => {
    const { payload } = this.state;
    payload.beneficialOwnersList.push({
      familyName: "",
      givenName: "",
      dateofbirth: "",
      streetAddress: "",
      subUrban: "",
      state: "",
      postCode: "",
      countryName: "",
      isTaxResidentOfAustralia: "",
    });
    this.updateState(payload, this.scrolltoActiveBeneficialOwner);
  };
  /**
   * @method - Method to scroll to the newly added Beneficial Owner
   */
  scrolltoActiveBeneficialOwner = () => {
    const beneficialOwnersLength = document.getElementsByClassName(
      "beneficiary-first-name"
    ).length;
    if (beneficialOwnersLength > 0) {
      document
        .getElementsByClassName("beneficiary-first-name")
        [beneficialOwnersLength - 1].focus();
    }
  };

  removeBeneficialOwner = (beneficiary) => {
    const { payload } = this.state;
    let index = payload.beneficialOwnersList.indexOf(beneficiary);
    payload.beneficialOwnersList.splice(index, 1);
    this.updateState(payload);
  };

  addIndividualController = () => {
    const { payload } = this.state;
    payload.individualControllerList.push({
      familyName: "",
      givenName: "",
      role: "",
      dateofbirth: "",
      streetAddress: "",
      subUrban: "",
      state: "",
      postCode: "",
      countryName: "",
      isTaxResidentOfAustralia: "",
    });
    this.updateState(payload, this.scrolltoActiveIndividualController);
  };

  /**
   * @method - Method to scroll to the newly added Individual Controller
   */
  scrolltoActiveIndividualController = () => {
    const individualControllerLength = document.getElementsByClassName(
      "individual-first-name"
    ).length;
    if (individualControllerLength > 0) {
      document
        .getElementsByClassName("individual-first-name")
        [individualControllerLength - 1].focus();
    }
  };

  removeIndividualController = (individualMember) => {
    const { payload } = this.state;
    let index = payload.individualControllerList.indexOf(individualMember);
    payload.individualControllerList.splice(index, 1);
    this.updateState(payload);
  };

  handleOpenModal = (modalType) => {
    this.setState({ visible: true });
    this.setState({ modalType: modalType });
  };
  handleModalCancel = () => {
    this.setState({ visible: false });
    this.setState({ modalType: "" });
  };

  onKeyDownBeneficialOwner = (e) => {
    if (e.key === "Tab") {
      this.addBeneficialOwner();
    }
  };
  onKeyDownIndividual = (e) => {
    if (e.key === "Tab") {
      this.addIndividualController();
    }
  };
  /**
   *
   * @param {object} beneficialOwner  - Beneficiary Controller or the Individual Controller data
   * @param {*} value - Value of the active content to be populated in the Select field
   * @returns  - renders the Select content
   */
  renderAustralianResidentDropdown = (beneficialOwner, value) => (
    <select
      className={cn(
        "tax-resident reg-select-large",
        (_isUndefined(value) || value === "") && "error-field"
      )}
      onChange={(e) =>
        this.onIsTaxResidentOfAustraliaChange(beneficialOwner, e.target.value)
      }
      value={value}
    >
      <option value="">Tax resident of Australia? </option>
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </select>
  );

  handleAddressChange = (fieldValue, fieldName, beneficiary) => {
    const { payload } = this.state;
    beneficiary[fieldName] = fieldValue;
    this.updateState(payload);
  };

  render() {
    const {
      hasBeneficialOwners,
      beneficialOwnersList,
      individualControllerList,
    } = this.state.payload;
    return (
      <div className="beneficiary-ownership-reg-main-content">
        <div className="reg-header">
          {this.formatMessage_i18n("beneficial.ownership.title")}
        </div>
        <div className="reg-sub-header">
          {" "}
          {this.formatMessage_i18n("beneficial.ownership.subtitle")}
        </div>
        <select
          className={cn(
            "reg-select-large",
            (_isUndefined(hasBeneficialOwners) ||
              hasBeneficialOwners === null) &&
              "error-field"
          )}
          value={hasBeneficialOwners}
          onChange={(e) => this.onChangeBeneficialOwner(e)}
        >
          <option value="">Yes/No</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>

        {hasBeneficialOwners === "yes" && (
          <div className="beneficiary-individuals">
            <div className="reg-sub-header">
              {" "}
              {this.formatMessage_i18n("beneficial.ownership.yes.subtitle")}
            </div>
            <div className="individual-inputs">
              {beneficialOwnersList.map((beneficialOwner) => (
                <>
                  <div className="individual-list">
                    <input
                      className={cn(
                        "individual-input input-medium beneficiary-first-name",
                        (_isUndefined(beneficialOwner.familyName) ||
                          beneficialOwner.familyName === "") &&
                          "error-field"
                      )}
                      name="familyName"
                      value={beneficialOwner.familyName}
                      placeholder={this.formatMessage_i18n("family.name")}
                      onChange={(e) =>
                        this.onFamilyNameChange(beneficialOwner, e.target.value)
                      }
                    />
                    <input
                      className={cn(
                        "individual-input input-medium",
                        (_isUndefined(beneficialOwner.givenName) ||
                          beneficialOwner.givenName === "") &&
                          "error-field"
                      )}
                      name="givenName"
                      placeholder={this.formatMessage_i18n("given.name")}
                      value={beneficialOwner.givenName}
                      onChange={(e) =>
                        this.onGivenNameChange(beneficialOwner, e.target.value)
                      }
                    />
                    <DatePicker
                      size="middle"
                      className={cn("individual-input input-small", {
                        "error-field":
                          _isUndefined(beneficialOwner.dateofbirth) ||
                          beneficialOwner.dateofbirth === "" ||
                          (beneficialOwner.dateofbirth &&
                            !validateDate(beneficialOwner.dateofbirth)),
                      })}
                      format="DD-MM-YYYY"
                      placeholder={this.formatMessage_i18n("user.dateofbirth")}
                      value={
                        !_isUndefined(beneficialOwner.dateofbirth) &&
                        beneficialOwner.dateofbirth !== ""
                          ? moment(beneficialOwner.dateofbirth, "DD-MM-YYYY")
                          : ""
                      }
                      onChange={(date, dateString) => {
                        if (!validateDate(dateString)) {
                          message.error({
                            content: dateErrorMessage,
                            duration: 3,
                          });
                        }
                        this.onDOBChange(beneficialOwner, dateString);
                      }}
                      allowClear={false}
                      suffixIcon={
                        <CalendarOutlined className="calender-icon" />
                      }
                    />
                  </div>
                  <div className="individual-address">
                    <div>
                      <PrismAddress
                        streetPlaceholder={this.formatMessage_i18n(
                          "Street.Address"
                        )}
                        streetValue={beneficialOwner.streetAddress}
                        streetOnChange={(value) =>
                          this.handleAddressChange(
                            value,
                            "streetAddress",
                            beneficialOwner
                          )
                        }
                        subUrbanPlaceholder={this.formatMessage_i18n(
                          "Suborb.or.town"
                        )}
                        subUrbanValue={beneficialOwner.subUrban}
                        subUrbanOnChange={(value) =>
                          this.handleAddressChange(
                            value,
                            "subUrban",
                            beneficialOwner
                          )
                        }
                        statePlaceholder={this.formatMessage_i18n("reg.state")}
                        stateValue={beneficialOwner.state}
                        stateOnChange={(val) =>
                          this.handleAddressChange(
                            val,
                            "state",
                            beneficialOwner
                          )
                        }
                        codePlaceholder={this.formatMessage_i18n(
                          "reg.postCode"
                        )}
                        codeValue={beneficialOwner.postCode}
                        codeOnChange={(val) =>
                          this.handleAddressChange(
                            val,
                            "postCode",
                            beneficialOwner
                          )
                        }
                        countryPlaceholder={this.formatMessage_i18n(
                          "reg.country"
                        )}
                        countryValue={beneficialOwner.countryName}
                        countryOnChange={(val) =>
                          this.handleAddressChange(
                            val,
                            "countryName",
                            beneficialOwner
                          )
                        }
                      />
                    </div>
                    <div className="list-clear-btn">
                      <MinusCircleOutlined
                        className="minus-icon"
                        onClick={() =>
                          this.removeBeneficialOwner(beneficialOwner)
                        }
                      />
                    </div>
                  </div>
                  <div className="tax-resident-selection">
                    {this.renderAustralianResidentDropdown(
                      beneficialOwner,
                      beneficialOwner.isTaxResidentOfAustralia
                    )}
                    <div className="resident-info">
                      <button
                        class="resident-link"
                        onClick={(e) => this.handleOpenModal("taxresident")}
                        onKeyDown={(e) => this.onKeyDownBeneficialOwner(e)}
                      >
                        {this.formatMessage_i18n(
                          "beneficial.taxResident.popup.title"
                        )}
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="btn-box">
              <Button
                onClick={() => this.addBeneficialOwner()}
                icon={<PlusOutlined />}
              />
            </div>
          </div>
        )}
        {hasBeneficialOwners === "no" && (
          <div className="beneficiary-individuals">
            <div className="reg-sub-header">
              {" "}
              {this.formatMessage_i18n("beneficial.ownership.no.subtitle")}
            </div>
            <div className="individual-inputs">
              {individualControllerList.map((individualController) => (
                <div>
                  <div className="individual-list">
                    <input
                      className={cn(
                        "individual-input individual-first-name",
                        (_isUndefined(individualController.familyName) ||
                          individualController.familyName === "") &&
                          "error-field"
                      )}
                      name="familyName"
                      placeholder={this.formatMessage_i18n("family.name")}
                      value={individualController.familyName}
                      onChange={(e) =>
                        this.onFamilyNameChange(
                          individualController,
                          e.target.value
                        )
                      }
                    />
                    <input
                      className={cn(
                        "individual-input",
                        (_isUndefined(individualController.givenName) ||
                          individualController.givenName === "") &&
                          "error-field"
                      )}
                      name="givenName"
                      placeholder={this.formatMessage_i18n("given.name")}
                      value={individualController.givenName}
                      onChange={(e) =>
                        this.onGivenNameChange(
                          individualController,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="individual-role-list">
                    <input
                      className={cn("individual-input-large")}
                      name="roleName"
                      placeholder={this.formatMessage_i18n("role.name")}
                      value={individualController.role}
                      onChange={(e) =>
                        this.onRoleChange(individualController, e.target.value)
                      }
                    />
                    <DatePicker
                      size="middle"
                      format="DD-MM-YYYY"
                      className={cn("individual-input input-small", {
                        "error-field":
                          _isUndefined(individualController.dateofbirth) ||
                          individualController.dateofbirth === "" ||
                          (individualController.dateofbirth &&
                            !validateDate(individualController.dateofbirth)),
                      })}
                      placeholder={this.formatMessage_i18n("user.dateofbirth")}
                      value={
                        !_isUndefined(individualController.dateofbirth) &&
                        individualController.dateofbirth !== ""
                          ? moment(
                              individualController.dateofbirth,
                              "DD-MM-YYYY"
                            )
                          : ""
                      }
                      onChange={(date, dateString) => {
                        if (!validateDate(dateString)) {
                          message.error({
                            content: dateErrorMessage,
                            duration: 3,
                          });
                        }
                        this.onDOBChange(individualController, dateString);
                      }}
                      allowClear={false}
                      suffixIcon={
                        <CalendarOutlined className="calender-icon" />
                      }
                    />
                  </div>
                  <div className="individual-address">
                    <div>
                      <PrismAddress
                        streetPlaceholder={this.formatMessage_i18n(
                          "Street.Address"
                        )}
                        streetValue={individualController.streetAddress}
                        streetOnChange={(value) =>
                          this.handleAddressChange(
                            value,
                            "streetAddress",
                            individualController
                          )
                        }
                        subUrbanPlaceholder={this.formatMessage_i18n(
                          "Suborb.or.town"
                        )}
                        subUrbanValue={individualController.subUrban}
                        subUrbanOnChange={(value) =>
                          this.handleAddressChange(
                            value,
                            "subUrban",
                            individualController
                          )
                        }
                        statePlaceholder={this.formatMessage_i18n("reg.state")}
                        stateValue={individualController.state}
                        stateOnChange={(val) =>
                          this.handleAddressChange(
                            val,
                            "state",
                            individualController
                          )
                        }
                        codePlaceholder={this.formatMessage_i18n(
                          "reg.postCode"
                        )}
                        codeValue={individualController.postCode}
                        codeOnChange={(val) =>
                          this.handleAddressChange(
                            val,
                            "postCode",
                            individualController
                          )
                        }
                        countryPlaceholder={this.formatMessage_i18n(
                          "reg.country"
                        )}
                        countryValue={individualController.countryName}
                        countryOnChange={(val) =>
                          this.handleAddressChange(
                            val,
                            "countryName",
                            individualController
                          )
                        }
                      />
                    </div>
                    <div className="list-clear-btn">
                      <MinusCircleOutlined
                        className="minus-icon"
                        onClick={() =>
                          this.removeIndividualController(individualController)
                        }
                      />
                    </div>
                  </div>
                  <div className="tax-resident-selection">
                    {this.renderAustralianResidentDropdown(
                      individualController,
                      individualController.isTaxResidentOfAustralia
                    )}
                    <div className="resident-info">
                      <button
                        class="resident-link"
                        onClick={(e) => this.handleOpenModal("taxresident")}
                        onKeyDown={(e) => this.onKeyDownIndividual(e)}
                      >
                        {this.formatMessage_i18n(
                          "beneficial.taxResident.popup.title"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="btn-box">
              <Button
                onClick={() => this.addIndividualController()}
                icon={<PlusOutlined />}
              />
            </div>
          </div>
        )}

        {this.state.visible && this.state.modalType === "ownership" && (
          <Modal
            className="ownership-modal"
            title="Ownership Control"
            visible={this.state.visible}
            onCancel={this.handleModalCancel}
            footer={null}
          >
            <OwnershipControl />
          </Modal>
        )}
        {this.state.visible && this.state.modalType === "taxresident" && (
          <Modal
            className="tax-resident-modal"
            visible={this.state.visible}
            onCancel={this.handleModalCancel}
            footer={null}
          >
            <div>
              {this.formatMessage_i18n("beneficial.taxResident.popup.content")}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default BeneficialOwnership;
