import { useEffect } from "react";
import { Checkbox } from "antd";
import {
  orderProductByActionRisk,
  orderProductByShareholderApplicationOption,
  SHAREHOLDER_APP,
  summaryTextOptionsByAction,
} from "./config";
import ETAPill from "../common/ETAPills/ETAPill";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSecurityByTicker } from "../../hooks/securities";
import {
  useSubmitOrder,
  useAmendOrder,
  useShareOrder,
} from "../../hooks/orders";
import { mapOrderRequestData, mapShareOrderRequestData } from "./utils";
import { useInvestorId } from "../common/utils";
import { useHistory } from "react-router-dom";
import message from "antd/es/message";
import { fetchCurrencySymbol } from "../common/currencies";
import EOISummaryTable from "./EOISummaryTable";
import { numberWithCommas } from "../../utils/numberFormatting";
import { useAtom } from "jotai";
import {
  activeOBOInvestorAtom,
  activeBrokerInvestorAtom,
} from "../../store/atoms";

export default function ConfirmOrder({
  formik,
  handleGoBack,
  shareholderApplication,
}) {
  const investorId = useInvestorId();
  const history = useHistory();

  const [activeOBOInvestor, setActiveOBOInvestor] = useAtom(
    activeOBOInvestorAtom
  );

  const [activeBrokerInvestor, setActiveBrokerInvestor] = useAtom(
    activeBrokerInvestorAtom
  );

  const { data: securityData } = useSecurityByTicker(formik.values.underlying);
  const { forwardDivYield, lastPrice } = securityData ?? {};

  const {
    mutate: submitOrder,
    status: submitOrderStatus,
    error: submitOrderError,
    isLoading: submitOrderIsLoading,
    data: submittedOrder,
  } = useSubmitOrder();

  const { mutate: shareSubmitOrder, status: submitShareOrderStatus } =
    useShareOrder();

  const {
    mutate: amendOrder,
    status: amendOrderStatus,
    error: amendOrderError,
    isLoading: amendOrderIsLoading,
    data: amendedOrder,
  } = useAmendOrder();

  useEffect(() => {
    console.log(submitOrderStatus);
    if (submitOrderStatus === "success") {
      message.success("Order successfully submitted!");
      history.push("/open-interests");
    }
  }, [submitOrderStatus]);

  useEffect(() => {
    console.log(submitShareOrderStatus);
    if (submitShareOrderStatus === "success") {
      message.success("Share Order successfully submitted!");
      history.push("/open-interests");
    }
  }, [submitShareOrderStatus]);

  useEffect(() => {
    console.log(amendOrderStatus);
    if (amendOrderStatus === "success") {
      message.success("Order amended successfully!");
      history.push("/open-interests");
    }
  }, [amendOrderStatus]);

  const orderFormik = useFormik({
    initialValues: {
      termsAgreed: false,
    },
    validationSchema: Yup.object().shape({
      termsAgreed: Yup.boolean()
        .oneOf([true], "Terms must be approved before submitting order")
        .required("Terms must be approved before submitting order"),
    }),
    onSubmit: (values) => {
      if (formik.values?.shareholderApplicationOption) {
        const shareSubmit = mapShareOrderRequestData(
          formik.values,
          investorId,
          lastPrice
        );
        shareSubmitOrder({ requestData: shareSubmit, lastPrice });
      } else {
        const requestData = mapOrderRequestData(formik.values, investorId);
        if (requestData.id) {
          amendOrder({ requestData, lastPrice });
        } else {
          submitOrder({ requestData, lastPrice });
        }
      }
    },
  });

  const order =
    formik.values.actionOption !== SHAREHOLDER_APP
      ? orderProductByActionRisk[formik.values.actionOption]?.[
          formik.values.riskOption
        ]
      : orderProductByShareholderApplicationOption[
          formik.values.shareholderApplicationOption
        ];

  const productId =
    formik.values.actionOption !== SHAREHOLDER_APP
      ? order?.orderProductId
      : order?.receiveIncomeProductId;

  const { considerationType, onBehalfOf, underlying, quantity, priceDollars } =
    formik.values;

  const currency = fetchCurrencySymbol(underlying);

  const orderPrice = parseFloat(priceDollars).toFixed(2);
  const payAmount = numberWithCommas(
    (parseInt(quantity.replace(/,/g, "")) * orderPrice).toFixed(2)
  );

  const activeOBO = onBehalfOf
    ? onBehalfOf
    : activeOBOInvestor?.name
    ? activeOBOInvestor?.name
    : activeBrokerInvestor?.name
    ? activeBrokerInvestor?.name
    : "";

  return (
    <form onSubmit={orderFormik.handleSubmit}>
      <div
        className="flex flex-col items-center rounded-xl overflow-hidden max-w-[1093px] mx-auto bg-white"
        style={{
          backgroundImage:
            "linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)",
        }}
      >
        <div className="bg-black text-white w-full p-4 text-center flex justify-center items-center gap-x-4">
          <div className="text-[30px] font-bold">PROVIDING</div>
          {considerationType === "STOCK" || shareholderApplication ? (
            <div className="text-[20px]">
              <span className="font-bold">{quantity}</span>{" "}
              <span className="uppercase">{underlying}</span> at approx{" "}
              <span className="font-bold">
                {currency}
                {lastPrice?.toFixed(2)}
              </span>
            </div>
          ) : (
            <div className="text-[20px]">
              <span className="font-bold">
                {currency}
                {payAmount} (CASH)
              </span>
            </div>
          )}
        </div>
        <EOISummaryTable
          formik={formik}
          lastPrice={lastPrice}
          forwardDivYield={forwardDivYield}
          shareholderApplication={shareholderApplication}
        />
        <div className="px-16 py-10">
          <div className="text-[16px]">
            <span className="text-[#474C55] font-bold">
              Financial Product Risk
            </span>{" "}
            There is no guarantee that an investment will perform well or that
            an investor will not lose money. All investments are subject to risk
            which means that the value of your investment may rise or fall. Such
            risks include market volatility, liquidity risk, credit risk, and
            other risks that may be specific to the product. Before making an
            investment decision, it is important to understand the risks that
            can affect the value of your investment.
          </div>
          <div className="mt-6">
            <Checkbox
              name="termsAgreed"
              onChange={orderFormik.handleChange}
              className={`!text-gray-900 text-[16px] font-light italic dark-tick ${
                orderFormik.touched.termsAgreed &&
                orderFormik.errors.termsAgreed &&
                "ant-error"
              }`}
            >
              I agree to the Transaction Documents
            </Checkbox>
            {orderFormik.touched.termsAgreed &&
              orderFormik.errors.termsAgreed && (
                <div style={{ color: "#ff4d4f" }}>
                  {orderFormik.errors.termsAgreed}
                </div>
              )}
          </div>
        </div>
        <div className="bg-black text-white w-full px-12 py-8 flex justify-between items-center">
          <div
            className={`flex items-center gap-x-4 ${
              activeOBO ? "visible" : "invisible"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={27}
              height={28}
              fill="none"
            >
              <path
                fill="#fff"
                d="M13.152.608c4.232.05 7.607 3.06 7.655 6.903.048 3.894-3.311 6.948-7.431 7.049-4.247.103-7.823-2.943-7.845-6.942-.021-3.81 3.257-6.92 7.62-7.011v.001ZM26.205 20.835c.173-.247.19-.487.045-.761-1.402-2.736-4.098-4.523-7.365-4.954-.285-.049-.552.034-.796.17-.34.19-.668.398-1.015.576-1.405.717-2.925.971-4.518.877-1.483-.09-2.846-.514-4.05-1.311-.525-.348-1.065-.333-1.653-.223-2.972.545-5.512 2.372-6.776 4.885-.108.226-.108.411.037.63 2.747 4.2 7.573 6.763 13.055 6.763 5.481 0 10.538-2.934 13.036-6.652Z"
              />
              <path
                fill="#fff"
                d="M13.152.608c-4.363.089-7.642 3.201-7.62 7.01.023 4 3.599 7.046 7.845 6.943 4.12-.101 7.48-3.155 7.431-7.05-.05-3.844-3.424-6.853-7.656-6.903Z"
              />
            </svg>
            <div>
              <div className="font-bold">ON BEHALF OF</div>
              <div>{activeOBO}</div>
            </div>
          </div>
          {formik.values.id ? (
            <button
              className="bg-[#5B7FFC] rounded-full text-white px-4 py-1"
              type="submit"
              disabled={amendOrderIsLoading}
            >
              {amendOrderIsLoading ? "Submitting..." : "Amend"}
            </button>
          ) : (
            <button
              className="bg-[#5B7FFC] rounded-full text-white px-4 py-1"
              type="submit"
              disabled={submitOrderIsLoading}
            >
              {submitOrderIsLoading ? "Submitting..." : "Confirm"}
            </button>
          )}
        </div>
      </div>
      <div className="mt-5 flex justify-end max-w-xxl pr-14 max-w-[1093px] mx-auto">
        <button
          className="bg-black rounded-full text-white px-4 py-1"
          onClick={handleGoBack}
          type="button"
        >
          Back
        </button>
      </div>
    </form>
  );
}
