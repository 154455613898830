import React, { Component } from "react";
import "./style.scss";
import { intl } from "../../../../utils/intl_i18n";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import { Checkbox, Input } from "antd";

class CorporateListingAndRegulatory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  componentDidMount() {
    const { payload } = this.state;

    if (payload.isPublicListedCompany === undefined) {
      payload.isPublicListedCompany = false;
    }
    if (payload.isAustralianListedCompany === undefined) {
      payload.isAustralianListedCompany = false;
    }
    if (payload.isRegulatedInAustralia === undefined) {
      payload.isRegulatedInAustralia = false;
    }
    this.updateState(payload);
  }

  validate = () => {
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  /**
   *
   * @param {*} payload
   * Will get the updated payload when the field changes added
   */
  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    if (
      field === "isPublicListedCompany" ||
      field === "isAustralianListedCompany" ||
      field === "isRegulatedInAustralia"
    ) {
      payload[field] = value.target.checked;
    } else {
      payload[field] = value;
    }

    if (payload.isPublicListedCompany === false) {
      payload.nameOfMarketRegime = "";
    }
    if (payload.isAustralianListedCompany === false) {
      payload.nameOfAustralianCompany = "";
    }
    if (payload.isRegulatedInAustralia === undefined) {
      payload.regulatorName = "";
      payload.licenseeDetails = "";
    }
    this.updateState(payload);
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="corporate-listing-main">
        <div className="corporate-listing-title">
          {this.formatMessage_i18n("corporateListing.title")}
        </div>
        <div className="corporate-listing-subTitle">
          {this.formatMessage_i18n("corporateListing.subTitle")}
        </div>
        <div className="public-listed-company-area">
          <Checkbox
            className="listed-company-checkbox"
            checked={payload.isPublicListedCompany}
            onChange={(e) => this.onInputChange("isPublicListedCompany", e)}
          >
            Public Listed Company
          </Checkbox>
          <Input
            className={cn("nameOfMarketRegime-input-large", {
              "error-field":
                payload.isPublicListedCompany &&
                (payload.nameOfMarketRegime === "" ||
                  _isUndefined(payload.nameOfMarketRegime)),
            })}
            placeholder={this.formatMessage_i18n(
              "corporateListing.field.nameOfMarketRegime"
            )}
            onChange={(e) =>
              this.onInputChange("nameOfMarketRegime", e.target.value)
            }
            value={payload.nameOfMarketRegime}
            disabled={!payload.isPublicListedCompany}
          />
        </div>
        <div className="aus-listed-company-area">
          <Checkbox
            className="aus-company-checkbox"
            checked={payload.isAustralianListedCompany}
            onChange={(e) => this.onInputChange("isAustralianListedCompany", e)}
          >
            Majority Owned Subsidiary of an Australian Public Listed Company
          </Checkbox>
          <Input
            className={cn("aus-company-input-large", {
              "error-field":
                payload.isAustralianListedCompany &&
                (payload.nameOfAustralianCompany === "" ||
                  _isUndefined(payload.nameOfAustralianCompany)),
            })}
            placeholder={this.formatMessage_i18n(
              "corporateListing.field.nameOfAustralianCompany"
            )}
            onChange={(e) =>
              this.onInputChange("nameOfAustralianCompany", e.target.value)
            }
            value={payload.nameOfAustralianCompany}
            disabled={!payload.isAustralianListedCompany}
          />
          <Input
            className={cn("market-name-input-large", {
              "error-field":
                payload.isAustralianListedCompany &&
                (payload.nameOfMarket === "" ||
                  _isUndefined(payload.nameOfMarket)),
            })}
            placeholder={this.formatMessage_i18n(
              "corporateListing.field.nameOfMarket"
            )}
            onChange={(e) => this.onInputChange("nameOfMarket", e.target.value)}
            value={payload.nameOfMarket}
            disabled={!payload.isAustralianListedCompany}
          />
        </div>
        <div className="regulated-aus-area">
          <Checkbox
            className="regulated-aus-checkbox"
            checked={payload.isRegulatedInAustralia}
            onChange={(e) => this.onInputChange("isRegulatedInAustralia", e)}
          >
            Regulated in Australia
          </Checkbox>
          <Input
            className={cn("regulator-name-input-large", {
              "error-field":
                payload.isRegulatedInAustralia &&
                (payload.regulatorName === "" ||
                  _isUndefined(payload.regulatorName)),
            })}
            placeholder={this.formatMessage_i18n(
              "corporateListing.field.regulatorName"
            )}
            onChange={(e) =>
              this.onInputChange("regulatorName", e.target.value)
            }
            value={payload.regulatorName}
            disabled={!payload.isRegulatedInAustralia}
          />
          <Input
            className={cn("licensee-details-input-large", {
              "error-field":
                payload.isRegulatedInAustralia &&
                (payload.licenseeDetails === "" ||
                  _isUndefined(payload.licenseeDetails)),
            })}
            placeholder={this.formatMessage_i18n(
              "corporateListing.field.licenseeDetails"
            )}
            onChange={(e) =>
              this.onInputChange("licenseeDetails", e.target.value)
            }
            value={payload.licenseeDetails}
            disabled={!payload.isRegulatedInAustralia}
          />
        </div>
      </div>
    );
  }
}

export default CorporateListingAndRegulatory;
