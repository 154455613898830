import { useState } from "react";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Space from "antd/es/space";
import message from "antd/es/message";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import "./style.scss";
import { intl } from "../../utils/intl_i18n";
import ROUTES from "../../routes/api.json";
import axiosConfig from "../../axiosConfig";
import jwt_decode from "jwt-decode";
import trim from "lodash/trim";
import PageContent from "../common/PageContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  activeOBOInvestorAtom,
  activeBrokerInvestorAtom,
} from "../../store/atoms";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";

const Login = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please enter a valid username"),
      password: Yup.string().required("Please enter a valid password"),
    }),
    onSubmit: (values) => {
      handleLogin(values.email, values.password);
    },
  });

  const setActiveOBOInvestor = useSetAtom(activeOBOInvestorAtom);
  const setActiveBrokerInvestor = useSetAtom(activeBrokerInvestorAtom);

  const handleLogin = (username, password) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .post(ROUTES.USER_LOGIN, { username, password }, config)
      .then((res) => {
        if (res.status == 200) {
          setActiveOBOInvestor(RESET);
          setActiveBrokerInvestor(RESET);
          sessionStorage.setItem("pstack", res.data.jwttoken);
          let userMail = jwt_decode(res.data.jwttoken)["cognito:username"];
          axiosConfig
            .get(`${ROUTES.USER_DETAILS}`, config)
            .then((res) => {
              sessionStorage.setItem("UserDetails", JSON.stringify(res.data));
              message.success(
                `Welcome ${res.data.firstName + " " + res.data.lastName}!`
              );
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              props.history.push("/welcome");
            });
        } else if (res.status == 205) {
          message.warn(
            "Password had been reset, and an email has been sent to set up a new password!"
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 400) {
          props.history.push("/expired-password");
        } else {
          message.error("Bad credentials!");
        }
      });
  };

  const routeToResetPassword = () => {
    props.history.push("/reset-password");
  };

  return (
    <PageContent
      className="login-container"
      childrenClassName={"login-main h-full"}
      renderMenu={false}
    >
      <div className="max-w-md font-din2014 rounded-2xl mx-auto py-8 px-10 w-10/12 md:w-1/2 lg:w-2/3 xl:w-3/4 bg-blur-linear-gradient backdrop-blur login-box-shadow">
        <h2 className="text-center text-[32px] font-extralight text-[#FFFFFF] mb-1">
          {intl.formatMessage({ id: "login.login" })}
        </h2>
        <h3 className="text-center text-base font-extralight text-[#D9D9D9] mb-7">
          See the value within whole shares
        </h3>
        <div className="flex flex-col items-center w-full">
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col prism-login-form"
          >
            <Input
              value={formik.values.email}
              name="email"
              size="large"
              placeholder={intl.formatMessage({ id: "login.email" })}
              onChange={formik.handleChange}
              className="mb-4 text-[#D9D9D9] bg-black bg-black-gradient dark-ant-input"
              prefix={
                <LockOutlined className="site-form-item-icon text-[#D9D9D9]" />
              }
              status={formik.errors.email ? "error" : ""}
              style={{
                borderRadius: "20px",
              }}
            />

            <Input.Password
              value={formik.values.password}
              size="large"
              type="password"
              name="password"
              placeholder={intl.formatMessage({ id: "login.password" })}
              onChange={formik.handleChange}
              className="login-input password mb-4 text-[#D9D9D9] bg-black bg-black-gradient dark-ant-input"
              prefix={
                <LockOutlined className="site-form-item-icon text-[#D9D9D9]" />
              }
              status={formik.errors.password ? "error" : ""}
              style={{
                borderRadius: "20px",
              }}
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />

            <div className="flex items-center justify-between">
              <a className="text-white" onClick={routeToResetPassword}>
                {intl.formatMessage({ id: "login.reset.password" })}
              </a>
              <button
                className="text-[#D9D9D9] text-sm py-1 px-3 self-end bg-black bg-black-gradient rounded-[20px]"
                type="submit"
              >
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>

      {/*<div className="login-activity">
                <div className="login">
                    <p>{intl.formatMessage({id:"login.login"})}</p>
                </div>
                <div className="login-layout">
                    <div className="login-entry">
                        <Input
                            value={formik.values.email}
                            name="email"
                            size="large"
                            className="login-input"
                            placeholder={intl.formatMessage({id:"login.email"})}
                            onChange={formik.handleChange}
                        />
                        <Input
                            value={formik.values.password}
                            size="large"
                            className="login-input"
                            type="password"
                            name="password"
                            placeholder={intl.formatMessage({id:"login.password"})}
                            onChange={formik.handleChange}
                        />
                        <div className="login-button-layout">
                            <Button className="login-button" onClick={handleLogin}>{intl.formatMessage({id:"login.login"})}</Button>
                        </div>
                    </div>
                    <div className="login-content">
                        <p>{intl.formatMessage({id:"login.content.title"})}</p>
                        <p>{intl.formatMessage({id:"login.content"})}</p>
                    </div>
                </div>
            </div>
            <div className="login-resetPassword">
                <p onClick={routeToResetPassword}>{intl.formatMessage({id:"login.reset.password"})}</p>
            </div>*/}
    </PageContent>
  );
};

export default Login;
