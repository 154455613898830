import { Component } from "react";
import Modal from "antd/es/modal";
import Checkbox from "antd/es/checkbox";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import Upload from "antd/es/upload";
import DocumentSelection from "./document-select";

const { Dragger } = Upload;

class VerificationProcedureMarketExchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      document: null,
    };
  }

  componentDidMount() {
    const { payload } = this.state;
    if (payload.membershipDoc === undefined) {
      payload.membershipDoc = false;
    }
    if (payload.ASICRegDoc === undefined) {
      payload.ASICRegDoc = false;
    }
    this.updateState(payload);
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  validate = () => {
    const { payload } = this.state;
    if (
      payload.membershipDoc === undefined ||
      payload.membershipDoc === false
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.ASICRegDoc === undefined || payload.ASICRegDoc === false) {
      this.onValidationFail();
      return;
    }
    if (payload.documentList === undefined) {
      this.onValidationFail();
      return;
    }
    if (!payload.documentList) {
      this.onValidationFail();
      return;
    }
    if (payload.documentList.length < 0) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };

  handleOnChange = (info) => {
    const { payload } = this.state;
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      file.status = "done";
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    payload.documentList = fileList;
    this.updateState(payload);
  };

  onChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value.target.checked;
    this.updateState(payload);
  };

  docSelect = (value) => {
    this.setState({ document: value });
  };

  beforeUpload = (file) => {
    const { payload } = this.state;

    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: (
          <DocumentSelection
            parentCallback={this.docSelect}
            payload={payload}
          />
        ),
        onOk: () => {
          if (this.state.document === null) {
            resolve(Upload.LIST_IGNORE);
          } else {
            Object.assign(file, { fileId: this.state.document });
            payload[this.state.document] = true;
            this.updateState(payload);
            this.setState({ document: null });
            resolve(true);
          }
        },
        onCancel: () => {
          resolve(Upload.LIST_IGNORE);
        },
        okButtonProps: {
          className: "submit-button",
        },
        cancelButtonProps: {
          className: "cancel-button",
        },
        className: "verification-procedure-market-modal",
      });
    });
  };
  onRemove = (file) => {
    const { payload } = this.state;
    payload[file.fileId] = false;
    this.updateState(payload);
  };
  defaultFileList = () => {
    const { documentList } = this.state.payload;

    if (documentList !== undefined && documentList.length > 0) {
      return documentList;
    }

    return null;
  };
  render() {
    const { payload } = this.state;
    const props = {
      onChange: this.handleOnChange,
      beforeUpload: this.beforeUpload,
      onRemove: this.onRemove,
      defaultFileList: this.defaultFileList,
    };
    return (
      <div className="verification-reg-main-content">
        <div className="reg-header">
          {this.formatMessage_i18n("verification.procedure.heading")}
        </div>
        <div className="verification-checklist-details">
          <Checkbox
            className="reg-checkbox"
            checked={payload.membershipDoc}
            onChange={(e) => this.onChange("membershipDoc", e)}
          >
            {this.formatMessage_i18n(
              "verification.procedure.proof.membership.text"
            )}
          </Checkbox>
          <div className="psedo-verification-checklist-details">
            <div
              className="psedo-box"
              style={{ background: payload.membershipDoc ? "#fff" : "#343741" }}
            >
              <svg
                fill="#343741"
                width="16"
                class="_2m7ny _1GLei"
                viewBox="0 0 24 24"
              >
                <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M9.5,18.25 L20.75,7.43269231 L19,5.75 L9.5,14.8846154 L5,10.5576923 L3.25,12.2403846 L9.5,18.25 Z"></path>
              </svg>
            </div>
            <div className="checkbox-label">
              {this.formatMessage_i18n(
                "verification.procedure.proof.membership.text"
              )}
            </div>
          </div>
          <Checkbox
            className="reg-checkbox"
            checked={payload.ASICRegDoc}
            onChange={(e) => this.onChange("ASICRegDoc", e)}
          >
            {this.formatMessage_i18n(
              "verification.procedure.ASIC.registration.copy.text"
            )}
          </Checkbox>
          <div className="psedo-verification-checklist-details">
            <div
              className="psedo-box"
              style={{ background: payload.ASICRegDoc ? "#fff" : "#343741" }}
            >
              <svg
                fill="#343741"
                width="16"
                class="_2m7ny _1GLei"
                viewBox="0 0 24 24"
              >
                <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M9.5,18.25 L20.75,7.43269231 L19,5.75 L9.5,14.8846154 L5,10.5576923 L3.25,12.2403846 L9.5,18.25 Z"></path>
              </svg>
            </div>
            <div className="checkbox-label">
              {this.formatMessage_i18n(
                "verification.procedure.ASIC.registration.copy.text"
              )}
            </div>
          </div>
        </div>
        <div className="verification-file-upload-main">
          <Dragger
            {...props}
            name="file"
            customRequest={() => {
              return "";
            }}
          >
            <p className="ant-upload-text">
              {this.formatMessage_i18n("verification.procedure.drag.drop.text")}
            </p>
          </Dragger>
        </div>
      </div>
    );
  }
}

export default VerificationProcedureMarketExchange;
