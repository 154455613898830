import React, { Component } from "react";
import "./styles.scss";
import Modal from "antd/es/modal";
import _each from "lodash/each";

class FormWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validData: true,
      payload: props.payload,
      formComponents: props.formComponents,
      currentComponent: props.defaultComponent,
      callback: "",
    };
  }

  onValidationFail = () => {
    this.setState({ validData: false });
  };

  onValidationPass = () => {
    this.setState({ validData: true });
  };

  onPrev = () => {
    const { formComponents } = this.props;
    const { currentComponent, payload } = this.state;
    let prevComponent = this.props.onPrev(
      currentComponent,
      payload,
      formComponents[currentComponent.key].payloadField
    );
    this.setState({ currentComponent: prevComponent });
    this.setState({ callback: prevComponent?.sequenceCallback });
  };

  onNext = () => {
    const { formComponents } = this.props;
    const { currentComponent, payload } = this.state;
    let nextComponent = this.props.onNext(
      currentComponent,
      payload,
      formComponents[currentComponent.key].payloadField
    );
    if (nextComponent.renderConfirmationPopup) {
      this.renderConfirmationPopup(nextComponent.confirmCallback);
      return;
    }
    this.setState({ currentComponent: nextComponent });
    this.setState({ callback: nextComponent?.sequenceCallback });
  };

  // Resetting the Payload on changing the
  resetPayload = () => {
    const { payload } = this.state;
    _each(payload, (value, key) => {
      // Not deleting the adminUploads for the payload if the adminUploads already available. Need to move the admin uploads to admin state
      if (key !== "investorEntity" && key !== "adminUploads") {
        delete payload[key];
      }
    });
  };

  /**
   * @method - Renders the Confirmation Popup when completing the registration
   * @param {function} confirmCallback  - Callback function to be called after clicking "Yes" option in the Confirmation Popup
   */

  renderConfirmationPopup = (confirmCallback) => {
    Modal.confirm({
      title: "This will finalise and lock your registration details. Continue?",
      okText: "Yes",
      cancelText: "No",
      className: "register-modal-popup",
      okButtonProps: {
        className: "submit-popup-btn",
      },
      cancelButtonProps: {
        className: "cancel-popup-btn",
      },
      onOk: () => {
        if (confirmCallback) {
          confirmCallback();
        }
      },
    });
  };

  /**
   * @method Method to call and update the payload data if the sequence callback is present on the step
   * For this we are setting the callback as a state param at the component loading and reusing it
   */
  sequenceCallback = () => {
    const { callback, payload } = this.state;
    if (callback) {
      callback(payload);
    }
  };

  loadComponent = (currentComponent) => {
    const { formComponents } = this.props;
    const { payload } = this.state;
    let FormComponent = formComponents[currentComponent.key].FormComponent;
    let activeContent = "";
    if (
      currentComponent.payloadType === "array" &&
      currentComponent.payloadData !== undefined
    ) {
      const index = payload[currentComponent.payloadData].currentIndex;
      activeContent =
        payload[currentComponent.payloadData][currentComponent.payloadParam][
          index
        ];
      if (!activeContent[formComponents[currentComponent.key].payloadField]) {
        activeContent[formComponents[currentComponent.key].payloadField] = {};
      }
    } else if (!payload[formComponents[currentComponent.key].payloadField]) {
      payload[formComponents[currentComponent.key].payloadField] = {};
    }

    let payloadProps = {};
    if (currentComponent.includePayloadCallback) {
      payloadProps.callback = this.resetPayload;
    }

    // Sending the sequenceCallback to the form components
    if (currentComponent?.sequenceCallback) {
      payloadProps.sequenceCallback = this.sequenceCallback;
    }

    return (
      <FormComponent
        onValidationFail={this.onValidationFail}
        onValidationPass={this.onValidationPass}
        payload={
          currentComponent.payloadType === "array"
            ? activeContent[formComponents[currentComponent.key].payloadField]
            : payload[formComponents[currentComponent.key].payloadField]
        }
        type={currentComponent.type}
        dependentProps={currentComponent?.dependentProps}
        {...payloadProps}
      />
    );
  };

  render() {
    const { currentComponent, validData } = this.state;
    return (
      <div className="form-wizard-container">
        <div className="form-wizard-container-inner">
          {this.loadComponent(currentComponent)}
          <div
            className="form-wizard-footer"
            style={{
              justifyContent:
                currentComponent.prev === null ? "center" : "space-between",
            }}
          >
            {currentComponent.prev && (
              <button className="form-wizard-btn-prev" onClick={this.onPrev}>
                {currentComponent.prev}
              </button>
            )}
            {currentComponent.next && (
              <button
                className={
                  validData
                    ? "form-wizard-btn-next"
                    : "form-wizard-btn-next-error"
                }
                disabled={!validData}
                onClick={this.onNext}
              >
                {currentComponent.next}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default FormWizard;
