import { useEffect, useRef, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import PageContent from "../common/PageContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSubmitEnquiry } from '../../hooks/contact';
import ReCAPTCHA from "react-google-recaptcha";

const enquiryTypes = [
  { id: 0, name: 'Sales' },
  { id: 1, name: 'Customer Support' },
  { id: 2, name: 'Newsletter Sign Up' },
  { id: 3, name: 'Technical Support' },
  { id: 4, name: 'Partnerships' },
  { id: 5, name: 'Other' },
]

export default function ContactUs() {
  const { mutate: submitEnquiry, isLoading, status } = useSubmitEnquiry();

  const recaptchaRef = useRef()

  useEffect(() => {
    if (status === "success") console.log("SUCCESS");
  }, [status]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      companyName: "",
      enquiryType: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required field"),
      email: Yup.string()
        .required("Required field")
        .email("Invalid email address"),
      companyName: Yup.string().required("Required field"),
      message: Yup.string().required("Required field"),
    }),
    onSubmit: (values) => {
      const token = recaptchaRef.current.getValue();
      console.log('token', token)
      submitEnquiry({...values, ...(values.enquiryType && {enquiryType: values.enquiryType?.name}),  token})
    }
  })

  const onChangeCaptcha = (value) => {
    console.log("Captcha value:", value);
  }

  return (
    <PageContent>
      <div className="text-white text-[40px] font-extralight text-center">
        Contact Us
      </div>
      <div className="bg-light-gradient backdrop-blur login-box-shadow rounded-xl p-8 max-w-[1488px] mx-auto">
        <div className="bg-[#474C55] rounded-xl max-w-[1015px] mx-auto px-12 py-16 flex flex-col lg:flex-row gap-4 text-white">
          <div className="w-full">
            <div className="font-bold text-[16px]">We're Here to Help</div>
            <div className="max-w-[272px]">
              We're committed to providing our customers with the best possible
              service. If you have any questions or concerns, please don't
              hesitate to contact us. We'll be happy to help you in any way we
              can.
            </div>
          </div>
          <div className="w-full">
            {status === "success" ? (
              <div className="font-bold text-[16px]">
                Your enquiry has been submitted successfully. We'll be in touch
                soon!
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative rounded-full shadow-sm">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-transparent block w-full rounded-full border border-[#797D82] py-1.5 pl-4 pr-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Full name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      style={{
                        fill: "linear-gradient(135deg, rgba(232, 232, 232, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%)",
                        boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.30) inset",
                      }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="pl-4 text-red-500">{formik.errors.name}</div>
                    ) : null}
                  </div>
                  <div className="relative rounded-full shadow-sm">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="bg-transparent block w-full rounded-full border border-[#797D82] py-1.5 pl-4 pr-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Email address"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      style={{
                        fill: "linear-gradient(135deg, rgba(232, 232, 232, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%)",
                        boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.30) inset",
                      }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="pl-4 text-red-500">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="relative rounded-full shadow-sm">
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      className="bg-transparent block w-full rounded-full border border-[#797D82] py-1.5 pl-4 pr-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Company name"
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      style={{
                        fill: "linear-gradient(135deg, rgba(232, 232, 232, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%)",
                        boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.30) inset",
                      }}
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <div className="pl-4 text-red-500">{formik.errors.companyName}</div>
                    ) : null}
                  </div>
                  <div>
                    <Listbox value={formik.values.enquiryType} onChange={(value) => formik.setFieldValue('enquiryType', value)}>
                      <div className="relative">
                        <Listbox.Button className="text-[#474C55] relative w-full cursor-default rounded-full bg-[#C0C1C6] py-2 pl-4 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className="block truncate">{formik.values.enquiryType?.name ?? 'Select enquiry type'}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 cursor-pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={37}
                              height={29}
                              fill="none"
                            >
                              <path fill="#797D82" d="M24 10H10l7 11 7-11Z" />
                            </svg>
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {enquiryTypes.map((type) => (
                              <Listbox.Option
                                key={type.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                  }`
                                }
                                value={type}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                      {type.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                <div className="mt-8">
                  <textarea
                    rows={4}
                    name="message"
                    id="message"
                    placeholder="Your message here..."
                    className="bg-transparent block w-full rounded-md border border-[#797D82] py-1.5 pl-4 pr-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    style={{
                      fill: "linear-gradient(135deg, rgba(232, 232, 232, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%)",
                      boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.30) inset",
                    }}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="pl-4 text-red-500">{formik.errors.message}</div>
                  ) : null}
                </div>
                <div className="px-4">
                  <div className="mt-4 text-[12px] max-w-[400px]">
                    By clicking ‘Submit’ I agree to be contacted at the point of
                    contact provided with more information about Prism.
                  </div>
                  <button
                    type="submit"
                    className="mt-4 text-[12px] uppercase border border-white rounded-full px-8 py-2"
                  >
                    {isLoading ? "Submitting..." : "Submit enquiry"}
                  </button>
                </div>
                <div className="mt-8">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    sitekey="6Le4cCMoAAAAABQErMYiYUKyTXjFAmOvsgiyxbFG"
                    onChange={onChangeCaptcha}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
}
