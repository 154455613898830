import { flexRender } from "@tanstack/react-table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from "./Tooltip";

function TableTooltip({ message }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={14}
            fill="none"
          >
            <path
              fill="#fff"
              d="M13.367 7.058c0 1.743-.618 3.228-1.85 4.455-1.23 1.228-2.72 1.845-4.459 1.845-1.738 0-3.227-.613-4.459-1.845C1.368 10.282.75 8.801.75 7.058c0-1.742.618-3.227 1.85-4.459C3.83 1.368 5.32.75 7.057.75c1.739 0 3.228.618 4.46 1.85 1.23 1.23 1.849 2.72 1.849 4.458Zm-1.031 0c0-1.456-.516-2.704-1.547-3.735-1.03-1.03-2.274-1.546-3.73-1.546-1.457 0-2.705.515-3.736 1.546-1.03 1.031-1.55 2.279-1.55 3.735 0 1.457.515 2.704 1.55 3.731 1.031 1.031 2.279 1.547 3.735 1.547 1.457 0 2.704-.516 3.731-1.547 1.031-1.03 1.547-2.274 1.547-3.73ZM8.35 3.806c0 .335-.127.618-.38.855-.254.237-.56.356-.913.356-.352 0-.667-.119-.916-.356a1.122 1.122 0 0 1-.376-.855c0-.336.126-.618.376-.855s.556-.356.916-.356.659.119.913.356c.253.237.38.52.38.855Zm-.237 7.965h-2.12v-6.01h2.12v6.01Z"
              opacity={0.25}
            />
          </svg>
        </TooltipTrigger>
        <TooltipContent className="bg-black border-0">
          <p className="mb-0">{message}</p>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default function Table({ table, tableId }) {
  return (
    <div className="shadow overflow-hidden rounded-lg">
      <div className="w-full overflow-auto">
        <table className="min-w-full bg-[#434750]" id={tableId}>
          <thead className="bg-[#343741]">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`px-3 py-3.5 text-left font-semibold text-white ${header.column.columnDef.meta?.headerClassName}`}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={`flex items-center gap-x-1 ${header.column.getIsSorted()} cursor-pointer`}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.column.columnDef.meta?.tooltipContent && (
                          <TableTooltip
                            message={
                              header.column.columnDef.meta?.tooltipContent
                            }
                          />
                        )}
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {!header.column.columnDef.meta?.hideSort && (
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={7}
                              height={7}
                              fill="none"
                            >
                              <g clipPath="url(#a)">
                                <path
                                  fill={`${
                                    header.column.getIsSorted() == "asc"
                                      ? "#FFFFFF"
                                      : "none"
                                  }`}
                                  d="M3.347 1.64 5.8 5.952H.893L3.347 1.64Z"
                                />
                                <path
                                  stroke="#fff"
                                  strokeOpacity={0.25}
                                  strokeWidth={0.5}
                                  d="m3.564 1.516-.217-.382-.218.382L.676 5.828l-.213.374H6.23l-.213-.374-2.453-4.312Z"
                                />
                              </g>
                              <defs>
                                <linearGradient
                                  id="b"
                                  x1={0.514}
                                  x2={6.263}
                                  y1={1.64}
                                  y2={7.305}
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#E8E8E8" />
                                  <stop offset={1} stopColor="#fff" />
                                </linearGradient>
                                <clipPath id="a">
                                  <path fill="#fff" d="M0 0h7v7H0z" />
                                </clipPath>
                              </defs>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={8}
                              height={7}
                              fill="none"
                            >
                              <g clipPath="url(#a)">
                                <path
                                  stroke="#fff"
                                  strokeOpacity={0.25}
                                  strokeWidth={0.5}
                                  d="m4.011 5.513.218.382.217-.382 2.453-4.312.213-.374H1.345l.213.374 2.453 4.312Z"
                                  fill={`${
                                    header.column.getIsSorted() == "desc"
                                      ? "#FFFFFF"
                                      : "none"
                                  }`}
                                />
                              </g>
                              <defs>
                                <clipPath id="a">
                                  <path fill="#fff" d="M0 0h8v7H0z" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => (
              <tr
                key={row.id}
                className={`${
                  i % 2 !== 0 && "bg-[#32353E]"
                } hover:bg-[#a1a3a7]`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`px-3 py-2 text-white ${
                      cell.column.columnDef.meta?.darkCell &&
                      "bg-black bg-opacity-50"
                    } ${
                      cell.column.columnDef.meta?.tooltipContent && "pl-[30px]"
                    } ${cell.column.columnDef.meta?.className}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
