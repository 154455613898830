import { Component } from "react";
import Input from "antd/es/input";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";

class UnregulatedTrustTrustee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  componentDidMount() {
    this.validate();
  }

  onInputChange = (field, value) => {
    const { payload } = this.state;
    if (field === "noOfTrustees") {
      let contentValue = parseInt(value);
      if (isNaN(contentValue) || contentValue < 1) {
        payload.noOfTrustees = "";
        this.updateState(payload);
        return;
      }
      payload.noOfTrustees = contentValue;
      payload.details = [];
      payload.currentIndex = 0;
      for (let i = 0; i < contentValue; i++) {
        payload.details.push({});
      }
    }
    this.updateState(payload);
  };

  validate = () => {
    const { payload } = this.state;
    if (
      _isUndefined(payload.noOfTrustees) ||
      payload.noOfTrustees === "" ||
      payload.noOfTrustees.length < 1
    ) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="unregulated-trust-form">
        <div className="reg-header">
          {this.formatMessage_i18n("UnregulatedTrustTrustees.title")}
        </div>
        <div className="sub-input">
          <div className="reg-sub-header">
            {this.formatMessage_i18n("UnregulatedTrustTrustees.label")}
          </div>
          <Input
            className={cn("register-input-large", {
              "error-field":
                _isUndefined(payload.noOfTrustees) ||
                payload.noOfTrustees === "",
            })}
            placeholder={this.formatMessage_i18n(
              "UnregulatedTrustTrustees.label"
            )}
            onChange={(e) => this.onInputChange("noOfTrustees", e.target.value)}
            value={payload.noOfTrustees}
            type={"number"}
          />
        </div>
      </div>
    );
  }
}

export default UnregulatedTrustTrustee;
