import React, { Component } from "react";
import { intl } from "../../../../utils/intl_i18n";
import _map from "lodash/map";
import Upload from "antd/es/upload";
import _isUndefined from "lodash/isUndefined";
import "./style.scss";
import { chessData } from "./utils";
import cn from "classnames";

const { Dragger } = Upload;

class ChessDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }
  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }

  handleSponsoringBroker = (e) => {
    const { payload } = this.state;
    payload.sponsoringBroker = e.target.value;
    if (e.target.value !== "other") {
      payload.brokerPID =
        e.target[e.target.selectedIndex].getAttribute("data-broker-pid");
      payload.other = "";
    } else {
      payload.other = "";
      payload.brokerPID = "";
    }
    this.updateState(payload);
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    this.updateState(payload);
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  validate = () => {
    const { payload } = this.state;
    if (
      payload.sponsoringBroker === undefined ||
      payload.sponsoringBroker === "" ||
      payload.holderIdentificationNumber === undefined ||
      payload.holderIdentificationNumber === ""
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.sponsoringBroker === "other") {
      if (
        payload.other === undefined ||
        !payload.other ||
        payload.brokerPID === undefined ||
        !payload.brokerPID
      ) {
        this.onValidationFail();
        return;
      }
    }
    if (!payload.documentList || payload.documentList.length === 0) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  /**
   *
   * @param {object} chessData  - Chess details array object
   * @returns {string} - Chess data dropdown display text
   */

  getDisplayText = (chessData) => {
    return chessData?.brokerId !== "" && chessData?.brokerId !== undefined
      ? `${chessData?.name}(${chessData?.brokerId})`
      : chessData?.name;
  };

  /**
   * @method - Handles the Handle Change event in the Upload event
   * @param {object} info
   */
  handleChange = (info) => {
    const { payload } = this.state;
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      file.status = "done";
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    payload.documentList = fileList;
    this.updateState(payload);
  };

  /**
   * @method - Renders the default list if the Document list is rendered from the Storage list if present
   */
  viewUploadedFile = () => {
    const { documentList } = this.state.payload;
    if (documentList !== undefined && documentList.length > 0) {
      return documentList;
    }
    return null;
  };

  /**
   * @method - Handles the before upload event
   * @param {file object} file
   */
  handleBeforeUpload = (file) => {
    file.fileId = "chessDetailsDoc";
    file["fileId"] = "chessDetailsDoc";
    const { payload } = this.state;
    payload.chessDetailsDoc = true;
    this.updateState(payload);
  };

  /**
   * @method - Method to handle the removal of uploaded files
   * @param {file object} file
   */
  handleRemove = (file) => {
    const { payload } = this.state;
    payload[file.fileId] = false;
    this.updateState(payload);
  };

  render() {
    const { payload } = this.state;
    const draggerProps = {
      action: "",
      onChange: this.handleChange,
      defaultFileList: this.viewUploadedFile,
      maxCount: 1,
      beforeUpload: this.handleBeforeUpload,
      onRemove: this.handleRemove,
    };
    return (
      <div className="chess-details">
        <div className="title">CHESS Details</div>
        <div className="container-inner">
          <div className="question">
            {this.formatMessage_i18n("chessDetails.desc")}
          </div>
          <div className="select">
            <select
              className={cn({
                "error-field":
                  _isUndefined(payload.sponsoringBroker) ||
                  payload.sponsoringBroker === "",
              })}
              onChange={this.handleSponsoringBroker}
              value={payload.sponsoringBroker}
            >
              <option data-broker-pid="" value="">
                Sponsoring CHESS Participant
              </option>
              {_map(chessData, (data, index) => (
                <option
                  key={index}
                  data-broker-pid={data?.brokerId}
                  value={data?.value || data?.name}
                >
                  {this.getDisplayText(data)}
                </option>
              ))}
            </select>
          </div>
          <input
            className={cn("chess-details-input", {
              "error-field":
                _isUndefined(payload.holderIdentificationNumber) ||
                payload.holderIdentificationNumber === "",
            })}
            type="text"
            placeholder="Holder Identification Number (HIN)"
            value={payload.holderIdentificationNumber}
            onChange={(e) =>
              this.onInputChange("holderIdentificationNumber", e.target.value)
            }
          />
          <input
            className={cn("chess-details-input", {
              "error-field":
                this.state.payload.sponsoringBroker === "other" &&
                (_isUndefined(payload.other) || payload.other === ""),
            })}
            disabled={
              this.state.payload.sponsoringBroker === "other" ? false : true
            }
            type="text"
            placeholder="Other"
            value={payload.other}
            onChange={(e) => this.onInputChange("other", e.target.value)}
          />
          <input
            className={cn("chess-details-input", {
              "error-field":
                this.state.payload.sponsoringBroker === "other" &&
                (_isUndefined(payload.brokerPID) || payload.brokerPID === ""),
            })}
            disabled={
              this.state.payload.sponsoringBroker === "other" ? false : true
            }
            type="text"
            placeholder="Broker PID"
            value={payload.brokerPID}
            onChange={(e) => this.onInputChange("brokerPID", e.target.value)}
          />
          <input
            className={cn("chess-details-input")}
            type="text"
            placeholder="Broker Contact Name"
            value={payload.brokerContactName}
            onChange={(e) =>
              this.onInputChange("brokerContactName", e.target.value)
            }
          />
          <input
            className="chess-details-input"
            type="text"
            placeholder="Number of Account with Broker"
            value={payload.numberOfAccountWithBroker}
            onChange={(e) =>
              this.onInputChange("numberOfAccountWithBroker", e.target.value)
            }
          />

          <div className="chess-details-file-upload">
            <div className="reg-sub-header">
              {" "}
              {this.formatMessage_i18n("chessDetails.statement.header")}
            </div>
            <Dragger
              {...draggerProps}
              name="file"
              customRequest={() => {
                return "";
              }}
            >
              <p className="ant-upload-text">
                {this.formatMessage_i18n(
                  "verification.procedure.drag.drop.text"
                )}
              </p>
              <p className="ant-upload-hint">
                {this.formatMessage_i18n("chessDetails.uploadHint")}
              </p>
            </Dragger>
          </div>
        </div>
      </div>
    );
  }
}

export default ChessDetails;
