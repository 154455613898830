import { useAtom } from "jotai";
import { activeBrokerInvestorAtom } from "../../store/atoms";
import { getGroupName, isBrokerInvestor } from "./utils";

function BrokerMenu({ brokerCallback }) {
  const [activeBrokerMenu, setActiveBrokerMenu] = useAtom(
    activeBrokerInvestorAtom
  );
  const groupName = getGroupName();
  const isBrokerInvestorValue = groupName ? isBrokerInvestor(groupName) : false;
  if (!isBrokerInvestorValue) return null;
  if (!activeBrokerMenu) {
    return (
      <button
        className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 whitespace-nowrap"
        onClick={brokerCallback}
      >
        Select Client Account
      </button>
    );
  }
  return (
    <div
      className="cursor-pointer text-gray-900 pl-2 flex items-baseline gap-x-1"
      onClick={brokerCallback}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          brokerCallback();
        }
      }}
    >
      <div className="font-bold text-[10px]">Broker</div>
      <div className="whitespace-nowrap">{activeBrokerMenu?.name}</div>
    </div>
  );
}

export default BrokerMenu;
