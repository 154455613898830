import { useState, useEffect, Fragment } from "react";
import { Input, InputLabel, InputNumber } from "antd";
import AutoComplete from "antd/es/auto-complete";
import { CustomSelect, StyledOption } from "../common/Dropdown";
import {
  tenorOptions,
  orderProductByActionRisk,
  SHAREHOLDER_APP,
} from "./config";
import { numberWithCommas } from "../../utils/numberFormatting";
import ETAPill from "../common/ETAPills/ETAPill";
import { currencyInputValidation, isReadOnlyStatus } from "../common/utils";
import { fetchCurrencySymbol } from "../common/currencies";
import { Combobox, Transition, Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function OrderPanel({
  formik,
  orderId,
  securitiesList,
  renderType,
  buyOp,
  searchParams,
}) {
  const isReadOnly = isReadOnlyStatus();
  const [underlyingList, setUnderlyingList] = useState([]);
  const [underlyingQuery, setUnderlyingQuery] = useState("");
  const [selectedSecurity, setSelectedSecurity] = useState({});

  useEffect(() => {
    if (securitiesList?.length) {
      setUnderlyingList(
        securitiesList.map((security) => {
          return { ...security, value: security.ticker, text: security.ticker };
        })
      );
      if (formik.values.underlying) {
        setSelectedSecurity(
          securitiesList.find(
            (security) => security.ticker === formik.values.underlying
          )
        );
      }
    }
  }, [securitiesList]);

  useEffect(() => {
    if (securitiesList?.length && formik.values.underlying)
      setSelectedSecurity(
        securitiesList.find(
          (security) => security.ticker === formik.values.underlying
        )
      );
  }, [formik.values.underlying]);

  useEffect(() => {
    const originalOffer = searchParams?.get("priceDollars");
    const originalType = searchParams?.get("seriesType");
    if (
      !buyOp ||
      !selectedSecurity?.lastPrice ||
      !originalOffer ||
      !originalType
    )
      return;
    if (formik.values.considerationType === "STOCK") {
      const oppositeSidePrice =
        Number(selectedSecurity?.lastPrice) - Number(originalOffer).toFixed(2);
      formik.setFieldValue("priceDollars", oppositeSidePrice.toFixed(2));
      let [side, type, consideration] = formik.values.actionOption.split("_");
      let updatedType = originalType === "GROWTH" ? "INCOME" : "GROWTH";
      formik.setFieldValue(
        "actionOption",
        `SELL_${updatedType}_${consideration}`
      );
    } else {
      formik.setFieldValue("priceDollars", Number(originalOffer).toFixed(2));
      let [side, type, consideration] = formik.values.actionOption.split("_");
      formik.setFieldValue(
        "actionOption",
        `BUY_${originalType}_${consideration}`
      );
    }
  }, [buyOp, selectedSecurity, formik.values.considerationType]);

  const handleUnderlyingSelection = (value, option) => {
    formik.setFieldValue("underlying", value);
    formik.setFieldValue("priceDollars", "");
  };

  const handleTermChange = (value) => {
    formik.setFieldValue("term", value);
  };

  const handleQuantityChange = (event) => {
    const { value } = event.target;
    const onlyNumbers = value.replace(/[^0-9.]/g, "");
    formik.setFieldValue("quantity", numberWithCommas(onlyNumbers));
  };

  const handlePriceUpdate = (value) => {
    formik.setFieldValue("priceDollars", value);
  };

  const handlePriceFormatAndUpdate = (e) => {
    const currency = fetchCurrencySymbol(formik?.values?.underlying);
    let value = currencyInputValidation(currency, e.target.value);
    if (Number(value) < 0) {
      value = 0;
    }
    if (Number(value) >= selectedSecurity?.lastPrice) {
      value = selectedSecurity.lastPrice - 0.01;
    }
    value = Number(value).toFixed(2);
    formik.setFieldValue("priceDollars", value);
  };

  const filteredSecurities =
    underlyingQuery === ""
      ? underlyingList
      : underlyingList.filter((underlying) => {
          return underlying.value
            .toLowerCase()
            .includes(underlyingQuery.toLowerCase());
        });

  const productId =
    orderProductByActionRisk[formik.values.actionOption]?.[
      formik.values.riskOption
    ]?.orderSide === "BUY"
      ? "receiveProductId"
      : "orderProductId";

  return (
    <div className="flex flex-col items-center bg-light-gradient backdrop-blur login-box-shadow rounded-xl p-4">
      <div className="flex justify-between items-center w-full">
        <div className="text-white text-[20px] font-bold italic">
          {renderType === SHAREHOLDER_APP
            ? `My Shareholder details are...`
            : `My EOI details are… `}
        </div>
        {formik.values.actionOption !== SHAREHOLDER_APP && (
          <div className="flex gap-x-4 items-center">
            <div className="text-white uppercase">
              {
                orderProductByActionRisk[formik.values.actionOption]?.[
                  formik.values.riskOption
                ]?.orderSide
              }
            </div>
            <ETAPill
              id="order-panel"
              productId={
                orderProductByActionRisk[formik.values.actionOption]?.[
                  formik.values.riskOption
                ]?.[productId]
              }
              className="w-[110px] h-[30px] flex items-center justify-center"
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 my-8 w-full">
        <div className="w-full relative">
          <Combobox
            value={formik.values.underlying}
            onChange={handleUnderlyingSelection}
          >
            <div className="relative">
              <Combobox.Input
                onChange={(event) => setUnderlyingQuery(event.target.value)}
                className="w-full py-[4px] px-[11px] !rounded-full border border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white uppercase"
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setUnderlyingQuery("")}
            >
              <Combobox.Options className="absolute w-full mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredSecurities.length === 0 && underlyingQuery !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredSecurities.map((option) => (
                    <Combobox.Option
                      key={option.value}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option.text}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </Combobox>
          {formik.touched.underlying && formik.errors.underlying ? (
            <label
              htmlFor="underlying"
              className="text-[#ff4d4f] pl-3 text-[14px] block"
            >
              {formik.errors.underlying}
            </label>
          ) : (
            <>
              <label
                htmlFor="underlying"
                className="text-white pl-3 text-[14px]"
              >
                Underlying
              </label>
              {selectedSecurity?.lastPrice && (
                <div className="text-white pl-3 text-[14px]">
                  Last Price: {selectedSecurity.lastPrice.toFixed(2)}
                </div>
              )}
            </>
          )}
        </div>
        {orderId && formik.values?.maturityDate ? (
          <div className="w-full">
            <Input
              id="maturityDate"
              name="maturityDate"
              value={formik.values.maturityDate}
              className="!rounded-full border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white"
              readOnly
            />
            <label
              htmlFor="maturityDate"
              className="text-white pl-3 text-[14px]"
            >
              Maturity Date
            </label>
          </div>
        ) : (
          <div className="w-full">
            <Listbox value={formik.values.term} onChange={handleTermChange}>
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default w-full py-[4px] px-[11px] !rounded-full border border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white">
                  <span className="block truncate text-left">
                    {formik.values.term} years
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {tenorOptions.map((option, optionIdx) => (
                      <Listbox.Option
                        key={optionIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        value={option.value}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {option.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <label htmlFor="term" className="text-white pl-3 text-[14px]">
              Tenor
            </label>
          </div>
        )}

        <div className="w-full">
          <Input
            id="quantity"
            name="quantity"
            value={formik.values.quantity}
            className="!rounded-full border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white"
            placeholder=""
            onChange={handleQuantityChange}
            status={
              formik.touched.quantity && formik.errors.quantity && "error"
            }
          />
          {formik.touched.quantity && formik.errors.quantity ? (
            <label
              htmlFor="quantity"
              className="text-[#ff4d4f] pl-3 text-[14px] block"
            >
              {formik.errors.quantity}
            </label>
          ) : (
            <label htmlFor="quantity" className="text-white pl-3 text-[14px]">
              Quantity
            </label>
          )}
        </div>
        {formik.values.actionOption !== SHAREHOLDER_APP &&
          renderType !== SHAREHOLDER_APP && (
            <div className="w-full">
              <InputNumber
                id="priceDollars"
                name="priceDollars"
                value={formik.values.priceDollars}
                className="!rounded-full !border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white !w-full"
                placeholder=""
                onChange={handlePriceUpdate}
                onBlur={handlePriceFormatAndUpdate}
                status={
                  formik.touched.priceDollars &&
                  formik.errors.priceDollars &&
                  "error"
                }
                controls={false}
                formatter={(value) => {
                  const currency = fetchCurrencySymbol(
                    formik?.values?.underlying
                  );
                  if (
                    String(formik.values.priceDollars)?.split(".")[1]
                      ?.length === 2
                  ) {
                    return `${currency} ${Number(value).toFixed(2)}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }
                  return `${currency} ${value}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  );
                }}
                parser={(value) => {
                  const currency = fetchCurrencySymbol(
                    formik?.values?.underlying
                  );
                  return currencyInputValidation(currency, value);
                }}
              />
              {formik.touched.priceDollars && formik.errors.priceDollars ? (
                <label
                  htmlFor="priceDollars"
                  className="text-[#ff4d4f] pl-3 text-[14px] block"
                >
                  {formik.errors.priceDollars}
                </label>
              ) : (
                <label
                  htmlFor="priceDollars"
                  className="text-white pl-3 text-[14px]"
                >
                  Limit Price
                </label>
              )}
            </div>
          )}
      </div>
      <button
        className={`${
          isReadOnly ? "bg-[#E6E6E6] cursor-not-allowed" : "bg-[#5B7FFC]"
        } rounded-full text-white px-4 py-1`}
        type="submit"
        disabled={isReadOnly}
      >
        {orderId ? "Amend EOI" : "Preview EOI"}
      </button>
    </div>
  );
}
