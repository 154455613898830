import { Component } from "react";
import Button from "antd/es/button";
import "antd/dist/antd.css";
import message from "antd/es/message";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import {
  CalendarOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import { validateDate } from "../../../common/validation";
import { dateErrorMessage } from "../../../common/message";

class UnregulatedTrustBeneficiary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };

    if (!this.state.payload.beneficiaryList) {
      this.state.payload.beneficiaryList = [];
      this.addBenificiary();
    }
  }

  validate = () => {
    const { payload } = this.state;
    var flag = true;

    if (!payload.beneficiaryList) {
      this.onValidationFail();
      return;
    }

    if (payload.beneficiaryList.length === 0) {
      this.onValidationFail();
      return;
    }

    for (var i = 0; i < payload.beneficiaryList.length; i++) {
      if (
        !payload.beneficiaryList[i].familyName ||
        (payload.beneficiaryList[i].familyName &&
          payload.beneficiaryList[i].familyName.length < 2) ||
        !payload.beneficiaryList[i].givenName ||
        (payload.beneficiaryList[i].givenName &&
          payload.beneficiaryList[i].givenName.length < 2) ||
        !payload.beneficiaryList[i].beneficiaryClass ||
        !payload.beneficiaryList[i].dateofbirth ||
        (payload.beneficiaryList[i].dateofbirth &&
          !validateDate(payload.beneficiaryList[i].dateofbirth)) ||
        !payload.beneficiaryList[i].residentialAddress ||
        (payload.beneficiaryList[i].residentialAddress &&
          payload.beneficiaryList[i].residentialAddress.length < 10)
      ) {
        flag = false;
        break;
      }
    }
    if (flag) {
      this.onValidationPass();
    } else this.onValidationFail();
  };
  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onFamilyNameChange = (benificiary, value) => {
    const { payload } = this.state;
    benificiary.familyName = value;
    this.updateState(payload);
  };

  onGivenNameChange = (benificiary, value) => {
    const { payload } = this.state;
    benificiary.givenName = value;
    this.updateState(payload);
  };

  onBeneficiaryClassChange = (benificiary, value) => {
    const { payload } = this.state;
    benificiary.beneficiaryClass = value;
    this.updateState(payload);
  };

  onDOBChange = (benificiary, value) => {
    const { payload } = this.state;
    benificiary.dateofbirth = value;
    this.updateState(payload);
  };

  onResidentialAddressChange = (benificiary, value) => {
    const { payload } = this.state;
    benificiary.residentialAddress = value;
    this.updateState(payload);
  };

  addBenificiary = () => {
    const { payload } = this.state;
    payload.beneficiaryList.push({
      familyName: "",
      givenName: "",
      beneficiaryClass: "",
      dateofbirth: "",
      residentialAddress: "",
    });
    this.updateState(payload, this.scrolltoActiveDirector);
  };

  /**
   * @method - Method to scroll to the newly added director
   */
  scrolltoActiveDirector = () => {
    const beneficiaryLength =
      document.getElementsByClassName("first-name").length;
    if (beneficiaryLength > 0) {
      document
        .getElementsByClassName("first-name")
        [beneficiaryLength - 1].focus();
    }
  };
  removeBenificiary = (benificiary) => {
    const { payload } = this.state;
    let index = payload.beneficiaryList.indexOf(benificiary);
    payload.beneficiaryList.splice(index, 1);
    this.updateState(payload);
  };

  updateState = (payload, afterUpdate) => {
    this.setState(payload, () => {
      if (afterUpdate) {
        afterUpdate();
      }
      this.validate();
    });
  };
  componentDidMount() {
    this.validate();
  }

  onKeyDown = (e) => {
    if (e.key === "Tab") {
      this.addBenificiary();
    }
  };

  render() {
    const { beneficiaryList } = this.state.payload;
    return (
      <div className="proprietary-directors-reg-main-content">
        <div className="reg-header">
          {this.formatMessage_i18n("UnregulatedTrustBeneficiary.title")}
        </div>
        <div className="reg-sub-header">
          {this.formatMessage_i18n("UnregulatedTrustBeneficiary.label")}
        </div>
        <div className="content">
          {beneficiaryList.map((benificiary) => (
            <>
              <div className="list-iterate">
                <div className="list-iterate-row">
                  <input
                    className={cn(
                      "first-name input-medium",
                      (_isUndefined(benificiary.familyName) ||
                        benificiary.familyName === "") &&
                        "error-field"
                    )}
                    name="firstName"
                    placeholder="Family Name"
                    value={benificiary.familyName}
                    onChange={(e) =>
                      this.onFamilyNameChange(benificiary, e.target.value)
                    }
                  />
                  <input
                    className={cn(
                      "input-medium",
                      (_isUndefined(benificiary.givenName) ||
                        benificiary.givenName === "") &&
                        "error-field"
                    )}
                    name="lastName"
                    placeholder="Given Name"
                    value={benificiary.givenName}
                    onChange={(e) =>
                      this.onGivenNameChange(benificiary, e.target.value)
                    }
                  />
                </div>
                <div className="list-iterate-row">
                  <input
                    className={cn(
                      "input-medium-large",
                      (_isUndefined(benificiary.beneficiaryClass) ||
                        benificiary.beneficiaryClass === "") &&
                        "error-field"
                    )}
                    name="firstName"
                    placeholder="Class of beneficiary"
                    value={benificiary.beneficiaryClass}
                    onChange={(e) =>
                      this.onBeneficiaryClassChange(benificiary, e.target.value)
                    }
                  />
                  <DatePicker
                    size="middle"
                    format="DD-MM-YYYY"
                    className={cn("input-small", {
                      "error-field":
                        _isUndefined(benificiary.dateofbirth) ||
                        benificiary.dateofbirth === "" ||
                        (benificiary.dateofbirth &&
                          !validateDate(benificiary.dateofbirth)),
                    })}
                    placeholder={this.formatMessage_i18n("user.dateofbirth")}
                    value={
                      !_isUndefined(benificiary.dateofbirth) &&
                      benificiary.dateofbirth !== ""
                        ? moment(benificiary.dateofbirth, "DD-MM-YYYY")
                        : ""
                    }
                    onChange={(date, dateString) => {
                      if (!validateDate(dateString)) {
                        message.error({
                          content: dateErrorMessage,
                          duration: 3,
                        });
                      }
                      this.onDOBChange(benificiary, dateString);
                    }}
                    allowClear={false}
                    suffixIcon={<CalendarOutlined className="calender-icon" />}
                  />
                </div>
                <div className="list-iterate-row">
                  <input
                    className={cn(
                      "input-large",
                      (_isUndefined(benificiary.residentialAddress) ||
                        benificiary.residentialAddress === "") &&
                        "error-field"
                    )}
                    name="firstName"
                    placeholder="Residential address"
                    value={benificiary.residentialAddress}
                    onChange={(e) =>
                      this.onResidentialAddressChange(
                        benificiary,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div className="director-address">
                <div className="list-clear-btn">
                  <MinusCircleOutlined
                    className="minus-icon"
                    onClick={() => this.removeBenificiary(benificiary)}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="btn-box">
          <Button
            onClick={() => this.addBenificiary()}
            icon={<PlusOutlined />}
          />
        </div>
      </div>
    );
  }
}

export default UnregulatedTrustBeneficiary;
