import React, { Component } from "react";
import "./style.scss";
import { intl } from "../../../../utils/intl_i18n";
import axiosConfig from "../../../../axiosConfig";
import NumberFormat from "react-number-format";
import ROUTES from "../../../../routes/api.json";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";
import NumberFormatter from "../../../common/NumberFormatter";

const primaryBank = "Primary";
const secondaryBank = "Secondary";

class SettlementInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      errors: "",
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  componentDidMount() {
    this.validate();
  }

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    this.updateState(payload);
  };

  validateBSB = (bsb, bank) => {
    const { payload } = this.state;
    var errors = { ...payload.errors };
    var errorStr = "";
    let params = {
      bsb: bsb,
    };
    axiosConfig
      .get(ROUTES.BSB_LOOKUP, { params })
      .then((res) => {
        res = res.data;
        var bankName = "";
        if (res.code === "200") {
          errorStr = "";
          bankName = res.data.instcode;
        } else {
          errorStr =
            res.message === "OK" ? "Not a valid BSB number" : res.message;
        }
        errors = { ...errors, bsb: errorStr };
        if (bank === primaryBank) {
          payload.bankName = bankName;
        }
        if (bank === secondaryBank) {
          payload.secondBankName = bankName;
        }
        payload.errors = errors;
        this.updateState(payload);
      })
      .catch((error) => {
        console.log(error);
        if (bank === primaryBank) {
          payload.bankName = "";
        }
        if (bank === secondaryBank) {
          payload.secondBankName = "";
        }
        payload.errors = error;
        this.updateState(payload);
        return;
      });
  };

  validate = () => {
    const { payload } = this.state;
    if (
      payload.bsb === undefined ||
      payload.bsb === "" ||
      payload.accountNumber === undefined ||
      payload.accountNumber === "" ||
      payload.receiveProceeds === undefined ||
      payload.receiveProceeds === ""
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.receiveProceeds === "no") {
      if (
        payload.secondAccountNumber === undefined ||
        payload.secondAccountNumber === ""
      ) {
        this.onValidationFail();
        return;
      }
    }
    //this.validateBSB ();
    this.onValidationPass();
  };

  handleYesNo = (e, field) => {
    const { payload } = this.state;
    payload[field] = e.target.value;
    this.updateState(payload);
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="settlement-main">
        <div className="settlement-title">
          {this.formatMessage_i18n("settlementInstructions.header")}
        </div>
        <div className="settlement-details">
          {this.formatMessage_i18n("settlementInstructions.details")}
        </div>
        <div className="settlement-notes">
          {this.formatMessage_i18n("settlementInstructions.notes")}
        </div>
        <div className="settlement-textbox">
          <NumberFormat
            className={cn(
              "settlement-input-bsb",
              (_isUndefined(payload.bsb) || payload.bsb === "") && "error-field"
            )}
            format="###-###"
            placeholder={this.formatMessage_i18n("settlementInstructions.bsb")}
            value={payload.bsb}
            onChange={(e) => this.onInputChange("bsb", e.target.value)}
            onBlur={() => this.validateBSB(payload.bsb, primaryBank)}
            mask="_"
          />
          <input
            className={cn(
              "settlement-input-accountNumber",
              (_isUndefined(payload.accountNumber) ||
                payload.accountNumber === "") &&
                "error-field"
            )}
            placeholder={this.formatMessage_i18n(
              "settlementInstructions.accountNumber"
            )}
            value={payload.accountNumber}
            onChange={(e) =>
              this.onInputChange("accountNumber", e.target.value)
            }
          />
          <input
            className="settlement-input-bankName"
            placeholder={this.formatMessage_i18n(
              "settlementInstructions.bankName"
            )}
            value={payload.bankName}
            onChange={(e) => this.onInputChange("bankName", e.target.value)}
            readOnly
          />
          <div className="settlement-notes">
            Do you want to receive proceeds from Corporate Events such as
            dividends to your settlement account?
          </div>
          <div className="select">
            <select
              onChange={(e) => this.handleYesNo(e, "receiveProceeds")}
              value={payload.receiveProceeds}
              className={cn({
                "error-field":
                  _isUndefined(payload.receiveProceeds) ||
                  payload.receiveProceeds === "",
              })}
            >
              <option value="">Yes/No</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div>
            {payload.receiveProceeds == "no" ? (
              <>
                <NumberFormatter
                  value={payload.secondBsb}
                  placeholder={this.formatMessage_i18n(
                    "settlementInstructions.bsb"
                  )}
                  className={cn(
                    "settlement-input-bsb",
                    (_isUndefined(payload.secondBsb) ||
                      payload.secondBsb === "") &&
                      "error-field"
                  )}
                  displayType={"input"}
                  changeCallback={(e) => {
                    this.onInputChange("secondBsb", e.value);
                  }}
                  format="###-###"
                  mask="_"
                  onBlur={() =>
                    this.validateBSB(payload.secondBsb, secondaryBank)
                  }
                />
                <input
                  className={cn(
                    "settlement-input-accountNumber",
                    (_isUndefined(payload.secondAccountNumber) ||
                      payload.secondAccountNumber === "") &&
                      "error-field"
                  )}
                  placeholder={this.formatMessage_i18n(
                    "settlementInstructions.secondAccountNumber"
                  )}
                  value={payload.secondAccountNumber}
                  onChange={(e) =>
                    this.onInputChange("secondAccountNumber", e.target.value)
                  }
                />
                <input
                  className="settlement-input-bankName"
                  placeholder={this.formatMessage_i18n(
                    "settlementInstructions.bankName"
                  )}
                  value={payload.secondBankName}
                  onChange={(e) =>
                    this.onInputChange("secondBankName", e.target.value)
                  }
                  readOnly
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SettlementInstructions;
