import React, { useEffect, useState } from "react";
import { Button, List, Row, Col, Modal } from "antd";
import PageContent from "../../common/PageContent";
import { DownloadOutlined } from "@ant-design/icons";
import ROUTES from "../../../routes/api.json";
import axiosConfig from "../../../axiosConfig";
import PDFViewer from "../../common/pdfViewer";
import "./styles.scss";

const ViewUploads = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileToView, setFileToView] = useState(undefined);
  const [documentTypes, setDocumentTypes] = useState(undefined);

  function convertListToMap(list) {
    const resultMap = {};

    list.forEach((item) => {
      const { id, documentType, description } = item;
      resultMap[id] = { documentType, description };
    });
    console.log("resultMap", resultMap);

    return resultMap;
  }

  const fetchDocumentTypes = async () => {
    try {
      const res = await axiosConfig.get(ROUTES.REGISTRATION_DOCUMENT_TYPES);
      if (res?.data) {
        setDocumentTypes(convertListToMap(res.data));
      }
    } catch (error) {
      // Handle error if API call fails
      console.error(error);
    }
  };

  const fetchUploadedFiles = async () => {
    try {
      const res = await axiosConfig.get(
        ROUTES.PDF_REGISTRATION_DOCUMENTS_LATEST
      );
      if (res?.data) {
        setUploadedFiles(res.data);
      }
    } catch (error) {
      // Handle error if API call fails
      console.error(error);
    }
  };

  const handleDownload = (item) => {
    setFileToView(item);
  };
  const handleClose = () => {
    setFileToView(undefined);
  };
  useEffect(() => {
    fetchUploadedFiles();
    fetchDocumentTypes();
  }, []);

  return (
    <PageContent
      className="view-uploads-container"
      childrenClassName={"document-container"}
    >
      <div>
        <p className="heading">View/Download uploaded files</p>
        <Row>
          <Col>
            <div>
              {uploadedFiles?.length > 0 && (
                <div className="list-items">
                  <List
                    dataSource={uploadedFiles}
                    renderItem={(item, index) => (
                      <List.Item
                        className="list-item"
                        actions={[
                          <Button
                            key={item.id}
                            type="text"
                            icon={
                              <DownloadOutlined className="download-icon" />
                            }
                            onClick={() => handleDownload(item)}
                          />,
                        ]}
                      >
                        <p className="file-name">
                          {item.fileName} - (
                          {documentTypes?.[item.documentTypeId]?.description})
                        </p>
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </div>
            {fileToView && (
              <Modal
                visible={fileToView}
                onOk={handleClose}
                onCancel={handleClose}
                footer={null}
              >
                <PDFViewer docView={fileToView.s3URL} />
              </Modal>
            )}
          </Col>
        </Row>
      </div>
    </PageContent>
  );
};

export default ViewUploads;
