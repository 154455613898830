import ROUTES from "../.././../routes/api.json";
import axiosConfig from "../../../axiosConfig";
import message from "antd/es/message";
import {
  doIterateNext,
  doIteratePrev,
  getActiveBeneficiaryList,
} from "../utils";
import { TIME_FOR_REGISTER_INTERVAL } from "../../common/constants";

class AustralianCompanySequence {
  constructor(screenRef) {
    this.screenRef = screenRef;
  }

  registerUser = (payload) => {
    this.register(payload);
    setTimeout(() => {
      //replace props.history with window.location to prevent infinite call for validation-token api
      window.location.href = "/order";
    }, TIME_FOR_REGISTER_INTERVAL);
  };

  onNext = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };
    if (currentComponent.key === "investorEntity") {
      componentContent.key = "australianCompany";
      return componentContent;
    }
    if (currentComponent.key === "australianCompany") {
      if (payload.australianCompany.companyType === "proprietary") {
        componentContent.key = "proprietaryDirectors";
      } else if (payload.australianCompany.companyType === "public") {
        componentContent.key = "listingRegulatory";
      } else {
        componentContent.key = "australianCompany";
      }
      return componentContent;
    }
    if (currentComponent.key === "proprietaryDirectors") {
      componentContent.key = "listingRegulatory";
      return componentContent;
    }
    if (currentComponent.key === "listingRegulatory") {
      if (
        payload.listingRegulatory.isAustralianListed === true ||
        payload.listingRegulatory.isOwnedSubsidiary === true ||
        payload.listingRegulatory.isRegulated === true
      ) {
        componentContent.key = "financialTaxInformation";
      } else {
        componentContent.key = "beneficialOwnership";
      }
      return componentContent;
    }

    if (
      currentComponent.key === "beneficialOwnership" ||
      currentComponent.key === "beneficialOwnerFactaDetails"
    ) {
      let list = getActiveBeneficiaryList(payload);
      let doIteration = doIterateNext(
        payload[payloadField],
        list,
        (item) => true
      );
      if (doIteration) {
        componentContent.key = "beneficialOwnerFactaDetails";
        return componentContent;
      }
      componentContent.key = "financialTaxInformation";
      return componentContent;
    }
    if (currentComponent.key === "financialTaxInformation") {
      if (
        payload.fiTaxInformation.fitaxInformationStatus ===
          "activeNonFinancial" ||
        payload.fiTaxInformation.fitaxInformationStatus === "other"
      ) {
        componentContent.key = "countryOfTaxResidency";
      } else {
        componentContent.key = "settlementInstructions";
      }
      return componentContent;
    }
    if (currentComponent.key === "countryOfTaxResidency") {
      componentContent.key = "settlementInstructions";
      return componentContent;
    }
    if (currentComponent.key === "settlementInstructions") {
      componentContent.key = "chessDetails";
      return componentContent;
    }
    if (currentComponent.key === "chessDetails") {
      this.uploadFileAndSaveRegister(
        currentComponent,
        currentComponent.key,
        payload
      );
      if (
        (payload.listingRegulatory.isAustralianListed === true &&
          payload.listingRegulatory.isOwnedSubsidiary === false &&
          payload.listingRegulatory.isRegulated === false) ||
        (payload.listingRegulatory.isAustralianListed === true &&
          payload.listingRegulatory.isOwnedSubsidiary === true &&
          payload.listingRegulatory.isRegulated === false)
      ) {
        componentContent.key = "verificationProcedureMarketExchange";
      } else if (
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === false &&
          payload.listingRegulatory.isRegulated === true) ||
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === true &&
          payload.listingRegulatory.isRegulated === true)
      ) {
        componentContent.key = "verificationProcedureAsicOther";
      } else if (
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === false &&
          payload.listingRegulatory.isRegulated === false) ||
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === true &&
          payload.listingRegulatory.isRegulated === false)
      ) {
        componentContent.key = "verificationProcedureASIC";
      } else {
        componentContent.key = "verificationProcedureOther";
      }
      return componentContent;
    }
    if (
      currentComponent.key === "verificationProcedureASIC" ||
      currentComponent.key === "verificationProcedureOther" ||
      currentComponent.key === "verificationProcedureMarketExchange" ||
      currentComponent.key === "verificationProcedureAsicOther"
    ) {
      this.uploadFileAndSaveRegister(
        currentComponent,
        currentComponent.key,
        payload
      );
      componentContent.key = "investorEvidence";
      return componentContent;
    }
    if (currentComponent.key === "investorEvidence") {
      this.uploadFileAndSaveRegister(
        currentComponent,
        currentComponent.key,
        payload
      );
      componentContent.next = "Complete Registration";
      componentContent.key = "subscriptionOptions";
      return componentContent;
    }

    if (currentComponent.key === "subscriptionOptions") {
      this.saveRegistrationDetails(currentComponent, payload);
      componentContent.renderConfirmationPopup = true;
      componentContent.confirmCallback = () => {
        this.registerUser(payload);
      };
      return componentContent;
    }
  };
  onPrev = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };
    if (currentComponent.key === "australianCompany") {
      componentContent.prev = null;
      componentContent.next = "Begin";
      componentContent.key = "investorEntity";
      componentContent.includePayloadCallback = true;
      return componentContent;
    }
    if (currentComponent.key === "proprietaryDirectors") {
      componentContent.key = "australianCompany";
      return componentContent;
    }
    if (currentComponent.key === "listingRegulatory") {
      if (payload.australianCompany.companyType === "proprietary") {
        componentContent.key = "proprietaryDirectors";
      } else {
        componentContent.key = "australianCompany";
      }
      return componentContent;
    }
    if (currentComponent.key === "beneficialOwnership") {
      componentContent.key = "listingRegulatory";
      return componentContent;
    }
    if (currentComponent.key === "beneficialOwnerFactaDetails") {
      let list = getActiveBeneficiaryList(payload);
      let doIteration = doIteratePrev(
        payload["beneficialOwnership"],
        list,
        (item) => true
      );
      if (doIteration) {
        componentContent.key = "beneficialOwnerFactaDetails";
        return componentContent;
      }
      componentContent.key = "beneficialOwnership";
      return componentContent;
    }

    if (currentComponent.key === "financialTaxInformation") {
      if (
        payload.listingRegulatory.isAustralianListed === true ||
        payload.listingRegulatory.isOwnedSubsidiary === true ||
        payload.listingRegulatory.isRegulated === true
      ) {
        componentContent.key = "listingRegulatory";
        return componentContent;
      } else {
        let list = getActiveBeneficiaryList(payload);
        let doIteration = doIteratePrev(
          payload["beneficialOwnership"],
          list,
          (item) => true
        );
        if (doIteration) {
          componentContent.key = "beneficialOwnerFactaDetails";
          return componentContent;
        }
        componentContent.key = "beneficialOwnership";
        return componentContent;
      }
    }

    if (currentComponent.key === "countryOfTaxResidency") {
      componentContent.key = "financialTaxInformation";
      return componentContent;
    }
    if (currentComponent.key === "settlementInstructions") {
      if (
        payload.fiTaxInformation.fitaxInformationStatus ===
          "activeNonFinancial" ||
        payload.fiTaxInformation.fitaxInformationStatus === "other"
      ) {
        componentContent.key = "countryOfTaxResidency";
      } else {
        componentContent.key = "financialTaxInformation";
      }
      return componentContent;
    }
    if (currentComponent.key === "chessDetails") {
      componentContent.key = "settlementInstructions";
      return componentContent;
    }
    if (
      currentComponent.key === "verificationProcedureOther" ||
      currentComponent.key === "verificationProcedureASIC" ||
      currentComponent.key === "verificationProcedureMarketExchange" ||
      currentComponent.key === "verificationProcedureAsicOther"
    ) {
      componentContent.key = "chessDetails";
      return componentContent;
    }
    if (currentComponent.key === "investorEvidence") {
      if (
        (payload.listingRegulatory.isAustralianListed === true &&
          payload.listingRegulatory.isOwnedSubsidiary === false &&
          payload.listingRegulatory.isRegulated === false) ||
        (payload.listingRegulatory.isAustralianListed === true &&
          payload.listingRegulatory.isOwnedSubsidiary === true &&
          payload.listingRegulatory.isRegulated === false)
      ) {
        componentContent.key = "verificationProcedureMarketExchange";
      } else if (
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === false &&
          payload.listingRegulatory.isRegulated === true) ||
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === true &&
          payload.listingRegulatory.isRegulated === true)
      ) {
        componentContent.key = "verificationProcedureAsicOther";
      } else if (
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === false &&
          payload.listingRegulatory.isRegulated === false) ||
        (payload.listingRegulatory.isAustralianListed === false &&
          payload.listingRegulatory.isOwnedSubsidiary === true &&
          payload.listingRegulatory.isRegulated === false)
      ) {
        componentContent.key = "verificationProcedureASIC";
      } else {
        componentContent.key = "verificationProcedureOther";
      }
      return componentContent;
    }
    if (currentComponent.key === "subscriptionOptions") {
      componentContent.key = "investorEvidence";
      return componentContent;
    }
  };

  register = (payload) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .put(ROUTES.REGISTER, payload, config)
      .then((res) => {
        message.success("Registration Completed");
      })
      .catch((error) => {});
  };

  uploadFileAndSaveRegister = async (currentComponent, key, payload) => {
    let fileList = payload[key]["documentList"];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        let res = await this.uploadFile(fileList[i].originFileObj);
        if (res && res.data) {
          payload[key][fileList[i].fileId] = {};
          payload[key][fileList[i].fileId] = res.data;
        } else {
          message.error("File upload failed");
          return;
        }
      }
    }

    this.saveRegistrationDetails(currentComponent, payload);
  };

  uploadFile = async (fileObj) => {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("file", fileObj);
    let userId = JSON.parse(sessionStorage.getItem("UserDetails")).investorId;
    let res = await axiosConfig.post(
      `${ROUTES.FILE_UPLOAD}/${userId}`,
      formData,
      config
    );
    return res;
  };

  saveRegistrationDetails(currentComponent, data, callback) {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //Added to maintain JSON structure as of Participant registration
    let userRegisterData = {};
    userRegisterData["currentComponent"] = currentComponent;
    userRegisterData["payload"] = data;

    axiosConfig
      .post(`${ROUTES.STORAGE_URL}`, JSON.stringify(userRegisterData), config)
      .then((res) => {
        console.log("Server result:", res);
      })
      .catch((error) => {
        console.log("Server error: " + error);
      });
  }
}

export default AustralianCompanySequence;
