import { useState, Fragment } from "react";
import SearchIcon from "../logo/searchIcon";
import { Table } from "antd";
import message from "antd/es/message";
import "./styles.scss";
import axiosConfig from "../../axiosConfig";
import ROUTES from "../../routes/api.json";
import NumberFormatter from "../common/NumberFormatter";
import ETAPills from "../common/ETAPills";
import ActionMenu from "../common/ActionMenu";
import { tableColumnsByType } from "./config";
import FilterPanel from "../common/Filters/FilterPanel";
import InvestorOpenOrder from "./InvestorOpenOrder";
import { Disclosure, Transition } from "@headlessui/react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getColumns } from "./expressionOfInterestModels";

function Orders({
  error,
  isLoading,
  orders,
  type,
  searchValue,
  setSearchValue,
}) {
  const columns = getColumns();

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [sorting, setSorting] = useState([]);

  const data = orders ?? [];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading) return "Loading...";

  if (error) return "Error";

  return (
    <>
      <div className="relative h-[42px] hidden lg:flex">
        <div className="absolute left-6 font-[250] text-[29px] text-white">
          Underlying
        </div>
        <div className="absolute left-[310px] font-[250] text-[29px] text-white">
          Allocation
        </div>
        <div className="absolute left-[960px] font-[250] text-[29px] text-white">
          Bid
        </div>
      </div>
      <div className="mt-4 pl-[36px] text-white bg-[#343741] rounded-t-lg">
        {table.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className="grid grid-cols-11">
            {headerGroup.headers.map((header) => (
              <div
                key={header.id}
                className={`${header.column.columnDef.meta?.headerClassName}`}
              >
                {header.isPlaceholder ? null : (
                  <div
                    className={`flex justify-center items-center gap-x-1 ${header.column.getIsSorted()} cursor-pointer py-4 px-3`}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {!header.column.columnDef.meta?.hideSort && (
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={7}
                          height={7}
                          fill="none"
                        >
                          <g clipPath="url(#a)">
                            <path
                              fill={`${
                                header.column.getIsSorted() == "asc"
                                  ? "#FFFFFF"
                                  : "none"
                              }`}
                              d="M3.347 1.64 5.8 5.952H.893L3.347 1.64Z"
                            />
                            <path
                              stroke="#fff"
                              strokeOpacity={0.25}
                              strokeWidth={0.5}
                              d="m3.564 1.516-.217-.382-.218.382L.676 5.828l-.213.374H6.23l-.213-.374-2.453-4.312Z"
                            />
                          </g>
                          <defs>
                            <linearGradient
                              id="b"
                              x1={0.514}
                              x2={6.263}
                              y1={1.64}
                              y2={7.305}
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#E8E8E8" />
                              <stop offset={1} stopColor="#fff" />
                            </linearGradient>
                            <clipPath id="a">
                              <path fill="#fff" d="M0 0h7v7H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={8}
                          height={7}
                          fill="none"
                        >
                          <g clipPath="url(#a)">
                            <path
                              stroke="#fff"
                              strokeOpacity={0.25}
                              strokeWidth={0.5}
                              d="m4.011 5.513.218.382.217-.382 2.453-4.312.213-.374H1.345l.213.374 2.453 4.312Z"
                              fill={`${
                                header.column.getIsSorted() == "desc"
                                  ? "#FFFFFF"
                                  : "none"
                              }`}
                            />
                          </g>
                          <defs>
                            <clipPath id="a">
                              <path fill="#fff" d="M0 0h8v7H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      {orders.length === 0 && (
        <div className="ant-empty ant-empty-normal">
          <div className="ant-empty-image">
            <svg
              className="ant-empty-img-simple"
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                <ellipse
                  className="ant-empty-img-simple-ellipse"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g className="ant-empty-img-simple-g" fillRule="nonzero">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    className="ant-empty-img-simple-path"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div className="ant-empty-description text-white">No data</div>
        </div>
      )}
      {table.getRowModel().rows.map((order, i) => (
        <InvestorOpenOrder
          key={order.id}
          index={i}
          order={order?.original}
          type={type}
        />
      ))}
    </>
  );
}

export default Orders;
