import { Component } from "react";
import { intl } from "../../../../utils/intl_i18n";

class DocumentSelection extends Component {
  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  fieldOnChange = (data) => {
    this.props.parentCallback(data || null);
  };

  render() {
    const { payload } = this.props;
    return (
      <div className="document-selction-main">
        <select
          onChange={(e) => {
            this.fieldOnChange(e.target.value);
          }}
        >
          <option value="">
            {" "}
            {this.formatMessage_i18n("verification.procedure.document.text")}
          </option>
          {payload.membershipDoc === false ? (
            <option value="membershipDoc">
              {" "}
              {this.formatMessage_i18n(
                "verification.procedure.proof.membership.text"
              )}
            </option>
          ) : null}
          {payload.ASICRegDoc === false ? (
            <option value="ASICRegDoc">
              {" "}
              {this.formatMessage_i18n(
                "verification.procedure.ASIC.registration.copy.text"
              )}
            </option>
          ) : null}
        </select>
      </div>
    );
  }
}

export default DocumentSelection;
