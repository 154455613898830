import { useState, useEffect } from "react";
import { message, Modal } from "antd";
import "./../style.scss";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";
import {
  convertToDateFormat,
  convertToDateTimeFormat,
} from "../../../utils/utile";
import { Box, Container } from "@mui/material";
import { Triangle } from "react-loader-spinner";
import ETAPills from "../../common/ETAPills";
import NumberFormatter from "../../common/NumberFormatter";
import PDFViewer from "../../common/pdfViewer";
import { useInvestorId } from "../../common/utils";
import { dealStatus } from "../../common/constants";
import ActionMenu from "../../common/ActionMenu";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getColumns } from "./dealModels";
import Table from "../../common/Table";

function DealHistory({ investorId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dealHistoryData, setDealHistoryData] = useState([]);
  const [viewHistoryDoc, setViewHistoryDoc] = useState(undefined);
  const [viewHistoryPreviewModal, setViewHistoryPreviewModal] = useState(false);
  const [_investorId, setInvestorId] = useState(investorId);

  const fetchDealHistoryData = (investorId) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .get(`${ROUTES.DEALS_HISTORY}/${investorId}`, config)
      .then((response) => {
        if (response.status === 200) {
          setDealHistoryData(response.data);
        }
      })
      .catch((e) => {
        setDealHistoryData([]);
        console.log(
          "fetchDealHistory error loading historical deals:",
          e.response
        );
      });
  };

  const getStoredS3Url = async (record, status) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axiosConfig
      .get(
        ROUTES.GET_DEAL_CONFO_PDF_URL + "/" + record.dealId + "/" + status,
        config
      )
      .then((res) => {
        setViewHistoryDoc(res.data);
        setViewHistoryPreviewModal(true);
      })
      .catch((e) => {
        if (e.status === 404) {
          message.error("File not found");
        } else if (e.status === 500) {
          message.error("Internal server error");
        }
      });
  };

  const handleOk = () => {
    setViewHistoryDoc(undefined);
    setViewHistoryPreviewModal(false);
  };

  const handleCancel = () => {
    setViewHistoryDoc(undefined);
    setViewHistoryPreviewModal(false);
  };

  useEffect(() => {
    setInvestorId(investorId);
    fetchDealHistoryData(investorId);
  }, [investorId]);

  const columns = getColumns(getStoredS3Url);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [sorting, setSorting] = useState([]);

  const filteredData = dealHistoryData
    ? dealHistoryData.filter(
        (data) => data.status !== "REJECTED" && data.status !== "MATCHED"
      )
    : [];

  const data = filteredData;

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Container maxWidth="xl" sx={{ marginTop: "15px" }}>
      <div className="text-white text-[40px] font-extralight text-center">
        Deal History
      </div>
      <div
        className="register-loader"
        style={{ display: isLoading ? "flex" : "none" }}
      >
        <div className="register-loader-inner">
          <Triangle
            height="100"
            width="100"
            color="#E8983F"
            ariaLabel="loading-indicator"
          />
        </div>
      </div>
      <div className="bg-[#2f333b] rounded-xl">
        <Box sx={{ p: 3 }}>
          <Table table={table} />
        </Box>
      </div>
      {viewHistoryPreviewModal && (
        <Modal
          visible={viewHistoryPreviewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <PDFViewer docView={viewHistoryDoc} />
        </Modal>
      )}
    </Container>
  );
}

export default DealHistory;
