import React from "react";
import AWS from "aws-sdk";
import { AutoComplete } from "antd";
import { isEmpty, map } from "lodash";
import "./styles.scss";
import cn from "classnames";

const PlaceIndex = (props) => {
  /**
   *
   * @returns the region value
   */
  const getRegion = () => {
    return "ap-southeast-2";
  };

  /**
   *
   * @returns the Identity pool Id value
   */
  const getIdentityPoolId = () => {
    return "ap-southeast-2:68c89e5e-f001-4f23-8145-d0e4d1e6ee6d";
  };

  const awsLocationAPI = new AWS.Location({});
  AWS.config.region = getRegion();
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: getIdentityPoolId(),
  });
  const [suggestions, setSuggestions] = React.useState([]);

  /**
   * On input change event validate the length of the text in the input field,
   * if the length is greater than 2 then searchPlaceIndexForSuggestions is API is called to fetch the results
   */
  const onSuggestionsFetchRequested = async (value) => {
    var inputLength = value?.length;

    if (inputLength && inputLength > 6) {
      const params = {
        IndexName: "Tecassa-Address-Lookup-Index",
        Text: value,
      };

      awsLocationAPI.searchPlaceIndexForSuggestions(params, (err, data) => {
        if (data) {
          setSuggestions(data.Results);
        }
        if (err) {
          console.log(err);
          setSuggestions([]);
        }
      });
    } else {
      setSuggestions([]);
    }
  };

  const handleChange = (newValue) => {
    props.onChange(newValue);
  };

  const handleKeyDownEvent = (event) => {
    if (
      props.onKeyDown !== undefined &&
      typeof props.onKeyDown === "function"
    ) {
      props.onKeyDown(event);
    }
  };

  const parseText = (suggestion) => {
    const filteredSuggestions = [];
    if (!isEmpty(suggestion)) {
      map(suggestion, (underlying) => {
        const list = {};
        list.value = underlying.Text;
        list.label = underlying.Text;
        filteredSuggestions.push(list);
      });
    }
    return filteredSuggestions;
  };

  return (
    <>
      <AutoComplete
        onSearch={onSuggestionsFetchRequested}
        onChange={handleChange}
        options={parseText(suggestions) || undefined}
        className={cn(props.className, "address-autocomplete")}
        placeholder={props?.placeholder || ""}
        value={props.value}
        onKeyDown={handleKeyDownEvent}
        popupClassName={"address-autocomplete-dropdown"}
        onFocus={(e) => onSuggestionsFetchRequested(e.target.value)}
        disabled={props.disabled}
      />
    </>
  );
};
export default PlaceIndex;
