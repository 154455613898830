export const chessData = [
  { name: "ABN AMRO Clearing Sydney Pty Ltd", brokerId: "01193" },
  { name: "ANZ Margin Services Pty Limited", brokerId: "21019" },
  { name: "Australian Executor Trustees Limited", brokerId: "20120" },
  { name: "Australian Investment Exchange Limited", brokerId: "06381" },
  { name: "Barrenjoey Markets Pty Limited", brokerId: "02802" },
  { name: "Bell Potter Securities Limited", brokerId: "01543" },
  { name: "Bell Potter Securities Limited", brokerId: "01547" },
  { name: "BNP Paribas Securities Services", brokerId: "01892" },
  { name: "Bond Street Custodians Limited", brokerId: "21028" },
  { name: "BPC Securities Pty Ltd", brokerId: "20306" },
  { name: "Bridges Financial Services Pty Ltd", brokerId: "01064" },
  { name: "BT Portfolio Services Limited", brokerId: "21044" },
  { name: "Burrell & Co Holdings Pty Ltd", brokerId: "04094" },
  { name: "Canaccord Genuity Financial Limited", brokerId: "06777" },
  { name: "Certane CT Pty Ltd", brokerId: "20066" },
  { name: "Chimaera Capital Ltd", brokerId: "22123" },
  { name: "CIP Licensing Limited", brokerId: "21033" },
  { name: "Citicorp Nominees Pty Ltd", brokerId: "20018" },
  { name: "Citigroup Global Markets Australia Pty Ltd", brokerId: "02033" },
  {
    name: "Citigroup Securities Clearing Australia Limited",
    brokerId: "03033",
  },
  { name: "CMC Markets Stockbroking Limited", brokerId: "02662" },
  { name: "Commonwealth Securities Limited", brokerId: "01402" },
  { name: "Computershare Clearing Pty Limited", brokerId: "20127" },
  { name: "Credit Suisse Equities (Australia) Limited", brokerId: "01104" },
  { name: "E.L. & C. Baillieu Limited", brokerId: "03118" },
  { name: "Equity Trustees Limited", brokerId: "20054" },
  { name: "Euroz Hartleys Limited", brokerId: "06086" },
  { name: "FinClear Pty Ltd", brokerId: "01822" },
  { name: "FinClear Services Pty Ltd", brokerId: "01792" },
  { name: "FNZ Custodians (Australia) Pty Ltd", brokerId: "03772" },
  { name: "Goldman Sachs Australia Pty Ltd", brokerId: "03614" },
  { name: "HSBC Custody Nominees (Australia) Limited", brokerId: "20057" },
  { name: "Instinet Australia Pty Limited", brokerId: "02172" },
  { name: "Investment Administration Services Pty Limited", brokerId: "27055" },
  { name: "J.P. Morgan Nominees Australia Pty Limited", brokerId: "20104" },
  { name: "J.P. Morgan Securities Australia Limited", brokerId: "02973" },
  { name: "Link Market Services Limited", brokerId: "20378" },
  { name: "Macquarie Bank Limited", brokerId: "01572" },
  { name: "Macquarie Equities Limited", brokerId: "02442" },
  { name: "Merrill Lynch Equities (Australia) Limited", brokerId: "03666" },
  { name: "Morgan Stanley Australia Securities Limited", brokerId: "02992" },
  {
    name: "Morgan Stanley Wealth Management Australia Pty Ltd",
    brokerId: "03383",
  },
  { name: "Morgans Financial Limited", brokerId: "04064" },
  { name: "Morrison Securities Pty Limited", brokerId: "01089" },
  { name: "Mutual Trust Pty Ltd", brokerId: "21036" },
  { name: "National Australia Bank Limited", brokerId: "20006" },
  { name: "National Margin Services Pty Ltd", brokerId: "21030" },
  { name: "Netwealth Investments Limited", brokerId: "20367" },
  { name: "One Managed Investment Funds Limited", brokerId: "27052" },
  { name: "OpenMarkets Australia Limited", brokerId: "01137" },
  { name: "Ord Minnett Limited", brokerId: "02337" },
  { name: "Other", brokerId: "", value: "other" },
  { name: "Phillip Capital Limited", brokerId: "02924" },
  { name: "Pirie Street Custodian Ltd", brokerId: "21008" },
  { name: "RBC Securities Australia Pty Ltd", brokerId: "02542" },
  { name: "Sandhurst Trustees Limited", brokerId: "20082" },
  { name: "Shaw and Partners Limited", brokerId: "02981" },
  { name: "State One Stockbroking Limited", brokerId: "06788" },
  { name: "Taylor Collison Limited", brokerId: "05127" },
  { name: "The Trust Company Limited", brokerId: "20025" },
  { name: "Third Party Platform Pty Ltd", brokerId: "02552" },
  { name: "UBS Securities Australia Ltd", brokerId: "01505" },
  { name: "UBS Securities Australia Ltd", brokerId: "01506" },
  { name: "Value Nominees Pty Limited", brokerId: "22888" },
  { name: "Velocity Trade Limited", brokerId: "01343" },
  { name: "Virtu ITG Australia Limited", brokerId: "03456" },
  { name: "Wealthhub Securities Limited", brokerId: "01226" },
  { name: "Wealthhub Securities Limited", brokerId: "01227" },
  { name: "Wilsons Advisory and Stockbroking Limited", brokerId: "04126" },
];
