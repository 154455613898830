import React, { Component } from "react";
import "./style.scss";

class PDFViewer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="pdf-file-viewer">
        <object
          data={this.props.docView}
          width="100%"
          height="100%"
          aria-label="view-doc"
        />
        <div class="file-viewer-download">
          {" "}
          <a target="blank" href={this.props.docView}>
            Click here to download
          </a>
        </div>
      </div>
    );
  }
}

export default PDFViewer;
