import { Component } from "react";
import Input from "antd/es/input";
import Checkbox from "antd/es/checkbox";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import Acn from "../../../acn";
import { PrismAddress, validatePOInput } from "../../../PrismAddress";

class AustraliaCompanyRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      errors: "",
      isCompanyFieldEditable: false,
    };
    this.autocomplete = null;
  }

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    const { payload, errors } = this.state;

    if (
      payload.companyName === undefined ||
      !payload.companyName ||
      payload.acnNumber === undefined ||
      !payload.acnNumber ||
      payload.officeStreetAddr === undefined ||
      !payload.officeStreetAddr ||
      (payload.officeStreetAddr &&
        (payload.officeStreetAddr.length < 8 ||
          payload.officeStreetAddr.length > 225 ||
          !validatePOInput(payload.officeStreetAddr))) ||
      payload.officeSuburb === undefined ||
      !payload.officeSuburb ||
      (payload.officeSuburb && payload.officeSuburb.length < 4) ||
      payload.officeState === undefined ||
      !payload.officeState ||
      payload.officePostCode === undefined ||
      !payload.officePostCode ||
      (payload.officePostCode && payload.officePostCode.length < 4) ||
      payload.officeCountry === undefined ||
      !payload.officeCountry ||
      payload.businessStreetAddr === undefined ||
      !payload.businessStreetAddr ||
      (payload.businessStreetAddr &&
        (payload.businessStreetAddr.length < 8 ||
          payload.businessStreetAddr.length > 225 ||
          !validatePOInput(payload.businessStreetAddr))) ||
      payload.businessSuburb === undefined ||
      !payload.businessSuburb ||
      (payload.businessSuburb && payload.businessSuburb.length < 4) ||
      payload.businessState === undefined ||
      !payload.businessState ||
      payload.businessPostCode === undefined ||
      !payload.businessPostCode ||
      (payload.businessPostCode && payload.businessPostCode.length < 4) ||
      payload.businessCountry === undefined ||
      !payload.businessCountry ||
      payload.companyType === undefined ||
      !payload.companyType
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.isBusinessAddrSameasRegdAddr === undefined) {
      payload.isBusinessAddrSameasRegdAddr = false;
      this.updateState(payload);
    }

    // Added Validation for the ACN number field when it returns error
    if (errors?.abnAcn) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    if (payload.isBusinessAddrSameasRegdAddr) {
      if (field === "officeStreetAddr") {
        payload.businessStreetAddr = value;
      }
      if (field === "officeSuburb") {
        payload.businessSuburb = value;
      }
      if (field === "officeState") {
        payload.businessState = value;
      }
      if (field === "officePostCode") {
        payload.businessPostCode = value;
      }
      if (field === "officeCountry") {
        payload.businessCountry = value;
      }
    }
    this.updateState(payload);
  };

  onChange = (field, value) => {
    const { payload } = this.state;
    const checkedState = value.target.checked;
    payload[field] = checkedState;
    if (field === "isBusinessAddrSameasRegdAddr") {
      if (checkedState) {
        payload.businessStreetAddr = payload.officeStreetAddr;
        payload.businessSuburb = payload.officeSuburb;
        payload.businessState = payload.officeState;
        payload.businessPostCode = payload.officePostCode;
        payload.businessCountry = payload.officeCountry;
      }
    }
    this.updateState(payload);
  };

  companyName = (value) => {
    const { payload } = this.state;
    // PBT-1575 - Updating the value only if the value returns from the API, otherwise if the user edits and ACN lookup API returns empty, the previous value is not cleared
    if (value) {
      payload.companyName = value;
      this.updateState(payload);
    }
  };

  abnNumber = (value) => {
    const { payload } = this.state;
    payload.acnNumber = value;
    this.updateState(payload);
  };

  abnErrors = (value) => {
    const { payload } = this.state;
    payload.errors = value;
    this.updateState(payload);
  };

  handleAddressChange = (fieldValue, fieldName) => {
    this.onInputChange(fieldName, fieldValue);
  };

  /** PBT-1575
   * @method Method to validate the CompanyName to be editable based on the value from the ACN lookup API
   * @param {boolean} value - Value to update if the CompanyField is editable
   */
  validateCompanyNameField = (value) => {
    this.updateState({
      isCompanyFieldEditable: value,
    });
  };

  /**
   * PBT-1575 - This will work only if the company field is editable
   * @param {*} fieldKey - Key to handle the fieldKey in the Payload
   * @param {*} value - value to be updated after the field change
   */
  handleCompanyNameChange = (fieldKey, value) => {
    const { isCompanyFieldEditable } = this.state;
    if (!isCompanyFieldEditable) {
      return;
    }
    this.onInputChange(fieldKey, value);
  };

  render() {
    const { payload, errors } = this.state;
    return (
      <div className="australia-company-reg-main-content">
        <div className="reg-header"> Australia Company Registration</div>
        <div className="reg-company-details">
          <Acn
            className={cn(
              "register-input-large",
              (_isUndefined(payload.acnNumber) ||
                payload.acnNumber === "" ||
                errors.abnAcn) &&
                "error-field"
            )}
            companyName={this.companyName}
            abnErrors={this.abnErrors}
            abnNumber={this.abnNumber}
            value={payload.acnNumber}
            validateCompanyNameField={this.validateCompanyNameField}
          />
          <Input
            className={cn(
              "register-input-large",
              (_isUndefined(payload.companyName) ||
                payload.companyName === "") &&
                "error-field"
            )}
            placeholder={this.formatMessage_i18n("company.name")}
            onChange={(e) =>
              this.handleCompanyNameChange("companyName", e.target.value)
            }
            value={payload.companyName}
            readOnly={!this.state.isCompanyFieldEditable}
          />
        </div>
        <div className="reg-company-office-addr">
          <div className="reg-sub-header"> Registered Office</div>
          <>
            <PrismAddress
              streetPlaceholder={this.formatMessage_i18n("Street.Address")}
              streetValue={payload.officeStreetAddr}
              streetOnChange={(value) =>
                this.handleAddressChange(value, "officeStreetAddr")
              }
              subUrbanPlaceholder={this.formatMessage_i18n("Suborb.or.town")}
              subUrbanValue={payload.officeSuburb}
              subUrbanOnChange={(value) =>
                this.handleAddressChange(value, "officeSuburb")
              }
              statePlaceholder={this.formatMessage_i18n("reg.state")}
              stateValue={payload.officeState}
              stateOnChange={(val) =>
                this.handleAddressChange(val, "officeState")
              }
              codePlaceholder={this.formatMessage_i18n("reg.postCode")}
              codeValue={payload.officePostCode}
              codeOnChange={(val) =>
                this.handleAddressChange(val, "officePostCode")
              }
              countryPlaceholder={this.formatMessage_i18n("reg.country")}
              countryValue={payload.officeCountry}
              countryOnChange={(val) =>
                this.handleAddressChange(val, "officeCountry")
              }
            />
          </>
        </div>
        <div className="reg-company-business-addr">
          <div className="reg-sub-header"> Principal Place of Business</div>
          <Checkbox
            className="reg-checkbox"
            checked={payload.isBusinessAddrSameasRegdAddr}
            onChange={(e) => this.onChange("isBusinessAddrSameasRegdAddr", e)}
          >
            As Above
          </Checkbox>
          <PrismAddress
            streetPlaceholder={this.formatMessage_i18n("Street.Address")}
            streetValue={payload.businessStreetAddr}
            streetOnChange={(value) =>
              this.handleAddressChange(value, "businessStreetAddr")
            }
            streetReadOnly={payload.isBusinessAddrSameasRegdAddr}
            subUrbanPlaceholder={this.formatMessage_i18n("Suborb.or.town")}
            subUrbanValue={payload.businessSuburb}
            subUrbanOnChange={(value) =>
              this.handleAddressChange(value, "businessSuburb")
            }
            subUrbanReadOnly={payload.isBusinessAddrSameasRegdAddr}
            statePlaceholder={this.formatMessage_i18n("reg.state")}
            stateValue={payload.businessState}
            stateOnChange={(val) =>
              this.handleAddressChange(val, "businessState")
            }
            stateReadOnly={payload.isBusinessAddrSameasRegdAddr}
            codePlaceholder={this.formatMessage_i18n("reg.postCode")}
            codeValue={payload.businessPostCode}
            codeOnChange={(val) =>
              this.handleAddressChange(val, "businessPostCode")
            }
            codeReadOnly={payload.isBusinessAddrSameasRegdAddr}
            countryPlaceholder={this.formatMessage_i18n("reg.country")}
            countryValue={payload.businessCountry}
            countryOnChange={(val) =>
              this.handleAddressChange(val, "businessCountry")
            }
            countryReadOnly={payload.isBusinessAddrSameasRegdAddr}
          />
        </div>
        <div className="reg-company-type">
          <div className="reg-sub-header"> Company Type</div>
          <select
            value={payload.companyType}
            className={cn(
              "register-select-large",
              (_isUndefined(payload.companyType) ||
                payload.companyType === "") &&
                "error-field"
            )}
            onChange={(e) => this.onInputChange("companyType", e.target.value)}
          >
            <option value="">Company Type</option>
            <option value="proprietary">Proprietary</option>
            <option value="public">Public</option>
          </select>
        </div>
      </div>
    );
  }
}

export default AustraliaCompanyRegistration;
