export const cloudFrontDomain = "https://www.prism.markets";

// Sophisticated Investor Documents
export const ACCOUNTANT_CERTIFICATE = `${cloudFrontDomain}/otc/sophisticated/PGAUOPS_230614_Qualified_Accountant_Certificate_P2.pdf`;
export const CERTIFICATE_CONTROL = `${cloudFrontDomain}/otc/sophisticated/PGAUOPS_230614_Control_Certificate_P1.pdf`;
export const WHOLESALE_CERTIFICATE = `${cloudFrontDomain}/otc/sophisticated/PGAUOPS_230614_Wholesale_Client_Certificate_P2.pdf`;
export const SophisticatedDocumentContent = [
  { label: "Accountant Certificate", link: ACCOUNTANT_CERTIFICATE },
  {
    label: "Certificate of Control of a Trust or Company",
    link: CERTIFICATE_CONTROL,
  },
  { label: "Wholesale Client Certificate", link: WHOLESALE_CERTIFICATE },
];

// POA Transaction Documents
export const GENERAL_SECURITY_DEED = `${cloudFrontDomain}/otc/Prism_General_Security_Deed_-_Growth_Products.pdf`;
export const GENERAL_TAX_CONSIDERATIONS = `${cloudFrontDomain}/otc/General_Tax_Considerations.pdf`;
export const INCOME_PRODUCT_DEED = `${cloudFrontDomain}/otc/Prism_Income_Product_Deed.pdf`;
export const LOAN_AGREEMENT = `${cloudFrontDomain}/otc/Loan_Agreement.pdf`;
export const TERMS_INCOME_GROWTH_POA = `${cloudFrontDomain}/otc/Terms_for_Income_and_Growth_POAs.pdf`;
export const INVESTMENT_MEMORANDUM = `${cloudFrontDomain}/otc/Prism_Investment_Memorandum.pdf`;
export const SECURITY_TRUST_DEED = `${cloudFrontDomain}/otc/Prism_Growth_Products_Security_Trust_Deed.pdf`;
export const RISK_FACTORS = `${cloudFrontDomain}/otc/Risk_Factors.pdf`;
export const PRODUCT_WARRANTY = `${cloudFrontDomain}/otc/Warranties_and_Representations.pdf`;

// POA regional disclaimer documents
export const DISCLAIMER_UK = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersUK_v05_MASTER.pdf`;
export const DISCLAIMER_EEA = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersEEA_v05_MASTER.pdf`;
export const DISCLAIMER_HK = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersHongKong_v05_MASTER (2).pdf`;
export const DISCLAIMER_JP = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersJapan_v03_MASTER (1).pdf`;
export const DISCLAIMER_MALAYSIA = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersMalaysia_v03_MASTER (1).pdf`;
export const DISCLAIMER_NZ = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersNewZealand_v05_MASTER.pdf`;
export const DISCLAIMER_QATAR = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersQatar_v03_MASTER.pdf`;
export const DISCLAIMER_SAUDI = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersSaudiArabia_v03_MASTER.pdf`;
export const DISCLAIMER_SING = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersSingapore_v04_MASTER.pdf`;
export const DISCLAIMER_TURKEY = `${cloudFrontDomain}/otc/PGAUDST_220930_EXT_geographicalDisclaimersTurkey_v03_MASTER.pdf`;
export const DISCLAIMER_CANADA = `${cloudFrontDomain}/otc/PGAUDST_230328_EXT_geographicalDisclaimerCanada_v01_MASTER.pdf`;
export const DISCLAIMER_USA = `${cloudFrontDomain}/otc/PGAUDST_230501_EXT_geographicalDisclaimerUS_v01_MASTER.pdf`;

// POA Online PDF Registration Documents
export const ONLINE_PDF_EXT_KYC_POA_AUS_CO = `${cloudFrontDomain}/otc/registration/PGAUOPS_230504_EXT_KYC_POA_AusCo_MA.pdf`;
export const ONLINE_PDF_EXT_KYC_POA_FOREGIN_CO = `${cloudFrontDomain}/otc/registration/PGAUOPS_230504_EXT_KYC_POA_ForeignCo_MA.pdf`;
export const ONLINE_PDF_EXT_KYC_POA_PARTNERSHIP_MA = `${cloudFrontDomain}/otc/registration/PGAUOPS_230504_EXT_KYC_POA_Partnership_MA.pdf`;
export const ONLINE_PDF_EXT_KYC_POA_REGTRUST_MA = `${cloudFrontDomain}/otc/registration/PGAUOPS_230504_EXT_KYC_POA_RegTrust_MA.pdf`;
export const ONLINE_PDF_EXT_KYC_POA_SOLETRADER_MA = `${cloudFrontDomain}/otc/registration/PGAUOPS_230504_EXT_KYC_POA_SoleTrader_MA.pdf`;
export const ONLINE_PDF_EXT_KYC_POA_UNREGTRUST_MA = `${cloudFrontDomain}/otc/registration/PGAUOPS_230504_EXT_KYC_POA_UnregTrust_MA.pdf`;
export const ONLINE_PDF_EXT_KYC_POA_ASSOCIATIONS_MA = `${cloudFrontDomain}/otc/registration/PGAUOPS_230504_EXT_KYC_POA_Associations_MA.pdf`;

export const TransactionDocumentContent = [
  { label: "OTC Investment Memorandum", link: INVESTMENT_MEMORANDUM },
  { label: "OTC Terms Dividend Growth POA", link: TERMS_INCOME_GROWTH_POA },
  {
    label: "Prism Growth Products - Security Trust Deed",
    link: SECURITY_TRUST_DEED,
  },
  { label: "Dividend Product Deed", link: INCOME_PRODUCT_DEED },
  { label: "General Tax Considerations", link: GENERAL_TAX_CONSIDERATIONS },
  { label: "Loan Agreement", link: LOAN_AGREEMENT },
  { label: "Risk Factors", link: RISK_FACTORS },
  { label: "General Security Deed", link: GENERAL_SECURITY_DEED },
];

export const DisclaimerDocument = [
  { label: "Disclaimer - UK", link: DISCLAIMER_UK },
  { label: "Disclaimer - Canada", link: DISCLAIMER_CANADA },
  { label: "Disclaimer - EEA", link: DISCLAIMER_EEA },
  { label: "Disclaimer - Hong Kong", link: DISCLAIMER_HK },
  { label: "Disclaimer - Japan", link: DISCLAIMER_JP },
  { label: "Disclaimer - Malaysia", link: DISCLAIMER_MALAYSIA },
  { label: "Disclaimer - New Zealand", link: DISCLAIMER_NZ },
  { label: "Disclaimer - Qatar", link: DISCLAIMER_QATAR },
  { label: "Disclaimer - Saudi Arabia", link: DISCLAIMER_SAUDI },
  { label: "Disclaimer - Singapore", link: DISCLAIMER_SING },
  { label: "Disclaimer - Turkey", link: DISCLAIMER_TURKEY },
  { label: "Disclaimer - USA", link: DISCLAIMER_USA },
];

export const OnlinePDFRegistrationDocument = [
  {
    label: "Individual and Sole Trader",
    link: ONLINE_PDF_EXT_KYC_POA_SOLETRADER_MA,
  },
  { label: "Australian Companies", link: ONLINE_PDF_EXT_KYC_POA_AUS_CO },
  { label: "Foreign Companies", link: ONLINE_PDF_EXT_KYC_POA_FOREGIN_CO },
  {
    label: "Australian Regulated Trusts",
    link: ONLINE_PDF_EXT_KYC_POA_REGTRUST_MA,
  },
  {
    label: "Unregulated Australian Trusts and Foreign Trusts",
    link: ONLINE_PDF_EXT_KYC_POA_UNREGTRUST_MA,
  },
  {
    label: "Partnerships and Partners",
    link: ONLINE_PDF_EXT_KYC_POA_PARTNERSHIP_MA,
  },
  {
    label: "Associations",
    link: ONLINE_PDF_EXT_KYC_POA_ASSOCIATIONS_MA,
  },
];

export const OBO_USER_GROUP = "prism-otc-trading-obo-user";
export const BROKER_INVESTOR = "prism-otc-trading-broker-investor";
export const INVESTOR_SHARIA_COMPLIANT_GROUPNAME =
  "prism-otc-trading-investor-sharia";

export const dealStatus = {
  FILLED: "FILLED",
  SETTLED: "SETTLED",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
  MATCHED: "MATCHED",
};

export const orderType = {
  LIMIT: "LIMIT",
  REDEEM: "REDEEM",
  SHARE: "SHARE_APP",
};

export const seriesType = {
  GROWTH: "GROWTH",
  INCOME: "INCOME",
};

export const otherSide = {
  PURE_DIV: "PURE_GROWTH",
  MAX_DIV: "GROWTH_GUARD",
  DIV_GUARD: "MAX_GROWTH",
};

export const TIME_FOR_REGISTER_INTERVAL = 1500;
