import { ReactComponent as PureGrowthIcon } from "./icons/eta-pill-puregrowth.svg";
import { ReactComponent as PureDivIcon } from "./icons/eta-pill-purediv.svg";
import { ReactComponent as GrowthGuardIcon } from "./icons/eta-pill-growthguard.svg";
import { ReactComponent as MaxDivIcon } from "./icons/eta-pill-maxdiv.svg";
import { ReactComponent as MaxGrowthIcon } from "./icons/eta-pill-maxgrowth.svg";
import { ReactComponent as DivGuardIcon } from "./icons/eta-pill-divguard.svg";
import { ReactComponent as UltraGrowthIcon } from "./icons/eta-pill-ultragrowth.svg";
import { ReactComponent as UltraGuardIcon } from "./icons/eta-pill-ultraguard.svg";

export const etaPairMapping = {
  PURE_GROWTH: {
    displayLabel: "PureGrowth",
    tClassName: "bg-[#CDE9E3] text-[#5C8370]",
    className: "green-growth-pill",
    opposingPair: "PURE_DIV",
    productInformation: (
      <>
        <p>
          The POA that receives all the equity capital gains of the Underlying
          Security above the Total Capital Allocation Price at creation.
        </p>
        <p className="!mb-0">
          At maturity if the Underlying Security price is below the Total
          Capital Allocation price at creation receives the PureGrowth Capital
          Allocation scaled down proportionately by the percentage decline in
          the Underlying security.
        </p>
      </>
    ),
    icon: <PureGrowthIcon />,
  },
  PURE_DIV: {
    displayLabel: "PureDiv",
    tClassName: "bg-[#3D8D74] text-white",
    colorCode: "#3D8D74",
    className: "green-income-pill",
    opposingPair: "PURE_GROWTH",
    productInformation: (
      <>
        <p>
          The POA that receives all the dividends paid by the Underlying
          Security over the tenor of PureDiv POA but receives no capital gains.
        </p>
        <p className="!mb-0">
          At maturity if the Underlying Security price is below the Total
          Capital Allocation price at creation receives the PureDiv Capital
          Allocation scaled down proportionately by the percentage decline in
          the Underlying security.
        </p>
      </>
    ),
    icon: <PureDivIcon />,
  },
  GROWTH_GUARD: {
    displayLabel: "GrowthGuard",
    tClassName: "bg-[#A94548] text-white",
    colorCode: "#A94548",
    className: "red-growth-pill",
    opposingPair: "MAX_DIV",
    productInformation: (
      <>
        <p>
          The POA that receives all the equity capital gains of the Underlying
          Security above the Total Capital Allocation price at creation.
        </p>
        <p className="!mb-0">
          At maturity bears the losses only if the Underlying Security price has
          fallen below the GrowthGuard Capital Allocation price (or by more than
          the MaxDiv capital allocation) at creation.
        </p>
      </>
    ),
    icon: <GrowthGuardIcon />,
  },
  MAX_DIV: {
    displayLabel: "MaxDiv",
    tClassName: "bg-[#FCF4EC] text-[#7B4A35]",
    colorCode: "#FCF4EC",
    className: "red-income-pill",
    opposingPair: "GROWTH_GUARD",
    productInformation: (
      <>
        <p>
          The POA that receives all the dividends paid by the Underlying
          Security over the tenor of the MaxDiv POA but receives no capital
          gains.
        </p>
        <p className="!mb-0">
          At maturity bears losses on the Underlying Security should the
          Underlying Security price be below the Total Capital Allocation at
          creation.
        </p>
      </>
    ),
    icon: <MaxDivIcon />,
  },
  MAX_GROWTH: {
    displayLabel: "MaxGrowth",
    tClassName: "bg-[#CAEAF6] text-[#1A2741]",
    colorCode: "#CAEAF6",
    className: "blue-growth-pill",
    opposingPair: "DIV_GUARD",
    productInformation: (
      <>
        <p>
          The POA that receives all the equity capital gains of the Underlying
          Security above Total Capital Allocation price at creation.
        </p>
        <p className="!mb-0">
          At maturity bares losses on the Underlying Security should the
          Underlying Security price be below the Total Capital Allocation at
          creation.
        </p>
      </>
    ),
    icon: <MaxGrowthIcon />,
  },
  DIV_GUARD: {
    displayLabel: "DivGuard",
    tClassName: "bg-[#416399] text-white",
    colorCode: "#416399",
    className: "blue-income-pill",
    opposingPair: "MAX_GROWTH",
    productInformation: (
      <>
        <p>
          The POA that receives all the dividends paid by the Underlying
          Security over the tenor of the DivGuard POA but receives no capital
          gains.
        </p>
        <p className="!mb-0">
          At maturity bears the losses only if the Underlying Security price has
          fallen below the DivGuard Capital Allocation price (or by more than
          the MaxGrowth Capital Allocation) at creation.
        </p>
      </>
    ),
    icon: <DivGuardIcon />,
  },
  ULTRA_GROWTH: {
    displayLabel: "UltraGrowth",
    tClassName: "bg-[#DEDFF0]",
    colorCode: "#DEDFF0",
    className: "purple-growth-pill",
    opposingPair: "ULTRA_GUARD",
    icon: <UltraGrowthIcon />,
  },
  ULTRA_GUARD: {
    displayLabel: "UltraGuard",
    tClassName: "bg-[#7368AE]",
    colorCode: "#7368AE",
    className: "purple-income-pill",
    opposingPair: "ULTRA_GROWTH",
    icon: <UltraGuardIcon />,
  },
  ERROR: {
    displayLabel: "-",
    tClassName: "bg-[#4d515a]",
    colorCode: "#4d515a",
    className: "error-pill",
  },
};
