import { Component } from "react";
import Input from "antd/es/input";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";

class UnregulatedForeignTrust extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  componentDidMount() {
    this.validate();
  }

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    this.updateState(payload);
  };

  validate = () => {
    const { payload } = this.state;
    if (
      !_isUndefined(payload.trustFullName) &&
      payload.trustFullName.length < 3
    ) {
      this.onValidationFail();
      return;
    }
    if (!_isUndefined(payload.country) && payload.country === "") {
      this.onValidationFail();
      return;
    }
    if (
      !_isUndefined(payload.settlorsName) &&
      payload.settlorsName.length < 3
    ) {
      this.onValidationFail();
      return;
    }
    if (!_isUndefined(payload.trustType) && payload.trustType === "") {
      this.onValidationFail();
      return;
    }
    if (payload.trustType) {
      if (payload.trustType === "otherUnregulatedTrust") {
        if (
          _isUndefined(payload.otherUnregulatedTrustName) ||
          payload.otherUnregulatedTrustName.length < 8
        ) {
          this.onValidationFail();
          return;
        }
      }
    }
    this.onValidationPass();
  };

  render() {
    const { payload, errors } = this.state;
    return (
      <div className="unregulated-trust-form">
        <div className="reg-header">
          {this.formatMessage_i18n("UnregulatedForeignTrust.title")}
        </div>
        <Input
          className={cn("register-input-large", {
            "error-field":
              _isUndefined(payload.trustFullName) ||
              payload.trustFullName === "" ||
              payload.trustFullName < 3,
          })}
          placeholder={this.formatMessage_i18n("RegulatedTrust.FullName.trust")}
          onChange={(e) => this.onInputChange("trustFullName", e.target.value)}
          value={payload.trustFullName}
        />
        <Input
          className={cn("register-input-large")}
          placeholder={this.formatMessage_i18n(
            "RegulatedTrust.FullName.trustee"
          )}
          onChange={(e) =>
            this.onInputChange("trusteeFullName", e.target.value)
          }
          value={payload.trusteeFullName}
        />
        <div className="sub-input">
          <div className="reg-sub-header">
            {this.formatMessage_i18n("UnregulatedForeignTrust.label.country")}
          </div>
          <select
            value={payload.country}
            className={cn(
              "register-select-large",
              (_isUndefined(payload.country) || payload.country === "") &&
                "error-field"
            )}
            onChange={(e) => this.onInputChange("country", e.target.value)}
          >
            <option value="">
              {this.formatMessage_i18n(
                "UnregulatedForeignTrust.select.country"
              )}
            </option>
            <option value="australia">Australia</option>
            <option value="newZealand">New Zealand</option>
            <option value="canada">Canada</option>
            <option value="unitedKingdom">United Kingdom</option>
            <option value="unitedStates">United States</option>
          </select>
        </div>

        <div className="sub-input">
          <div className="reg-sub-header">
            {this.formatMessage_i18n(
              "UnregulatedForeignTrust.label.settlorsName"
            )}
          </div>
          <Input
            className={cn("register-input-large", {
              "error-field":
                _isUndefined(payload.settlorsName) ||
                payload.settlorsName === "" ||
                payload.settlorsName < 3,
            })}
            placeholder={this.formatMessage_i18n(
              "UnregulatedForeignTrust.placeholder.settlorsName"
            )}
            onChange={(e) => this.onInputChange("settlorsName", e.target.value)}
            value={payload.settlorsName}
          />
        </div>

        <div className="sub-input">
          <div className="reg-sub-header">
            {this.formatMessage_i18n(
              "UnregulatedForeignTrust.label.unregulatedType"
            )}
          </div>
          <select
            value={payload.trustType}
            className={cn(
              "register-select-large",
              (_isUndefined(payload.trustType) || payload.trustType === "") &&
                "error-field"
            )}
            onChange={(e) => this.onInputChange("trustType", e.target.value)}
          >
            <option value="">
              {this.formatMessage_i18n(
                "UnregulatedForeignTrust.select.unregulatedTrustType"
              )}
            </option>
            <option value="familyTrust">Family trust</option>
            <option value="chairatableTrust">Chairatable trust</option>
            <option value="testamentaryTrust">Testamentary trust</option>
            <option value="otherUnregulatedTrust">
              Other unregulated trust
            </option>
          </select>
        </div>

        <Input
          className={cn("register-input-large", {
            "error-field":
              _isUndefined(payload.otherUnregulatedTrustName) ||
              payload.otherUnregulatedTrustName === "" ||
              payload.otherUnregulatedTrustName < 3,
          })}
          placeholder={this.formatMessage_i18n(
            "UnregulatedForeignTrust.placeholder.typeIfOther"
          )}
          onChange={(e) =>
            this.onInputChange("otherUnregulatedTrustName", e.target.value)
          }
          value={payload.otherUnregulatedTrustName}
        />
      </div>
    );
  }
}

export default UnregulatedForeignTrust;
