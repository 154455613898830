import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosConfig from "../axiosConfig";
import ROUTES from "../routes/api.json";

export const useSubmitEnquiry = () => {
  return useMutation({
    mutationFn: async (requestData) => {
      const { data } = await axiosConfig.post(
        `${ROUTES.CONTACT_US}?token=${requestData?.token}`,
        requestData
      );
      return data;
    },
  });
};
