import React, { Component } from "react";

class DownloadLogo extends Component {
  render() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4376 1.06129e-05C14.427 0.00485334 14.4166 0.0100649 14.4063 0.0156356L0.406328 2.67189C0.290543 2.69398 0.186285 2.75626 0.111954 2.84775C0.037624 2.93923 -0.00199938 3.05403 7.77025e-05 3.17189V21.8281C-0.00199938 21.946 0.037624 22.0608 0.111954 22.1523C0.186285 22.2438 0.290543 22.306 0.406328 22.3281L14.4063 24.9844C14.4781 24.9981 14.5519 24.9959 14.6227 24.978C14.6936 24.9601 14.7596 24.9268 14.8162 24.8807C14.8728 24.8345 14.9186 24.7765 14.9503 24.7107C14.9821 24.645 14.9991 24.573 15.0001 24.5V22H23.5001C24.0466 22 24.5001 21.5465 24.5001 21V4.00001C24.5001 3.45348 24.0466 3.00001 23.5001 3.00001H15.0001V0.500011C15.0006 0.428745 14.9859 0.358186 14.957 0.293061C14.9281 0.227935 14.8855 0.169749 14.8323 0.122402C14.779 0.0750544 14.7162 0.0396397 14.6482 0.0185312C14.5801 -0.00257732 14.5083 -0.00889191 14.4376 1.06129e-05ZM14.0001 1.09376V3.26564C13.9671 3.33384 13.9499 3.40862 13.9499 3.48439C13.9499 3.56015 13.9671 3.63494 14.0001 3.70314V21.4063C13.9862 21.4733 13.9862 21.5424 14.0001 21.6094V23.9063L1.00008 21.4219V3.57814L14.0001 1.09376ZM15.0001 4.00001H23.5001V21H15.0001V18.5H17.0001V17.5H15.0001V14.5H17.0001V13.5H15.0001V11H17.0001V10H15.0001V7.50001H17.0001V6.50001H15.0001V4.00001ZM18.0001 6.50001V7.50001H22.0001V6.50001H18.0001ZM3.34383 7.84376L6.0782 12.5156L3.09383 17.1875H5.59383L7.21883 14.1719C7.33183 13.8809 7.40708 13.6591 7.43758 13.5156H7.4532C7.5182 13.8196 7.5797 14.0276 7.6407 14.1406L9.2657 17.1875H11.7501L8.87508 12.4688L11.672 7.84376H9.3282L7.84383 10.6094C7.70033 10.9699 7.59895 11.255 7.54695 11.4375H7.53133C7.44883 11.133 7.35458 10.8621 7.25008 10.6406L5.90633 7.84376H3.34383ZM18.0001 10V11H22.0001V10H18.0001ZM18.0001 13.5V14.5H22.0001V13.5H18.0001ZM18.0001 17.5V18.5H22.0001V17.5H18.0001Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default DownloadLogo;
