import jwt from "jwt-decode";
import axiosConfig from "../axiosConfig";
import ROUTES from "../routes/api.json";

class SessionValidation {
  constructor({ onFailed }) {
    this.onFailed = onFailed;
    this.startInterval();
    this.validatedToken = null;
  }
  validate = async () => {
    try {
      /**
       * Check for token on session storage */
      const token = sessionStorage.getItem("pstack");
      if (!token) {
        throw "Validation failed";
      }

      /**
       * Validate token with server and keep it in memory for future validation */
      if (!this.validatedToken || this.validatedToken !== token) {
        let res = await axiosConfig(ROUTES.AUTH_VALIDATION);
        if (res && res.status === 200) {
          this.validatedToken = token;
        } else {
          throw "Validation failed";
        }
      }

      /**
             * Once token is successfully validated againt server, we can cache the token in memory and check for expiry time
            this avoids network round trip **/

      const json = jwt(token);
      if (Date.now() / 1000 > json.exp) {
        throw "Validation failed";
      }
    } catch {
      this.failed();
    }
  };
  failed = () => {
    this.onFailed();
    this.cleanUp();
  };
  startInterval = () => {
    this.interval = setInterval(async () => {
      this.validate();
    }, 2000);
  };
  cleanUp() {
    sessionStorage.removeItem("pstack");
    clearInterval(this.interval);
  }
}

export default SessionValidation;
