import { etaPairMapping } from "./config";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Tooltip";

export default function ETAPill({ className, productId, id }) {
  const etaDetails = etaPairMapping[productId];
  const [x, y] = etaDetails?.displayLabel?.split(/(?=[A-Z])/) ?? ["", ""];

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={`px-4 rounded-full text-center ${etaDetails?.tClassName} ${className}`}
            data-tooltip-id={`${id}-${productId}-tooltip`}
          >
            <span className="font-bold">{x}</span>
            {y}
          </div>
        </TooltipTrigger>
        <TooltipContent className="bg-black border-0 text-center text-white">
          {etaDetails?.productInformation}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
