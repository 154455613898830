import { useState, useEffect } from "react";
import { tableColumnsByType, allOrderTableColumns } from "./config";
import {
  useAllOrdersByUnderlyingProduct,
  useAmendOrder,
  useDeleteOrder,
} from "../../hooks/orders";
import { isReadOnlyStatus } from "../common/utils";
import { useHistory } from "react-router-dom";
import { useInvestorId } from "../common/utils";
import message from "antd/es/message";
import AdjustPrompt from "./AdjustPrompt";
import NumberFormatter from "../common/NumberFormatter";
import ConfirmCancel from "./ConfirmCancel";
import { Disclosure, Transition } from "@headlessui/react";

export default function InvestorOpenOrder({ index, order, type }) {
  const [amendPromptActiveOrder, setAmendPromptActiveOrder] = useState("");
  const [confirmCancelActive, setConfirmCancelActive] = useState(false);
  const investorId = useInvestorId();

  const { data: allOrders } = useAllOrdersByUnderlyingProduct(
    order.underlying,
    order.productName,
    investorId
  );
  const { gap, offers, bids } = allOrders ?? {};
  const {
    mutate: amendOrder,
    status: amendOrderStatus,
    error: amendOrderError,
    isLoading: amendOrderIsLoading,
    data: amendedOrder,
  } = useAmendOrder();

  const {
    mutate: deleteOrder,
    status: deleteOrderStatus,
    error: deleteOrderError,
    isLoading: deleteOrderIsLoading,
  } = useDeleteOrder();

  useEffect(() => {
    if (amendOrderStatus === "success")
      message.success(`Successfully updated order!`);
  }, [amendOrderStatus]);

  useEffect(() => {
    if (amendOrderError?.message) message.error(`Failed to update order`);
  }, [amendOrderError]);

  useEffect(() => {
    if (deleteOrderStatus === "success") message.success("Success!");
  }, [deleteOrderStatus]);

  useEffect(() => {
    if (deleteOrderError?.message) message.error("Failed to cancel order.");
  }, [deleteOrderError]);

  const isReadOnly = isReadOnlyStatus();
  const history = useHistory();

  const navigateToOrderAmend = () => {
    history.push(`/order?orderId=${order.id}`, {
      search: `?orderId=${order.id}`,
    });
  };

  const handleAmendOrderPrice = (newPrice) => {
    setAmendPromptActiveOrder("");
    amendOrder({
      requestData: { ...order, priceDollars: newPrice },
      lastPrice: order.lastPrice,
    });
  };

  const handleDeleteOrder = () => {
    setConfirmCancelActive(false);
    deleteOrder(order);
  };

  const menuItems = [
    ...(order?.orderType === "LIMIT"
      ? [
          {
            label: "Amend",
            onClick: () => {
              navigateToOrderAmend();
            },
          },
        ]
      : []),
    {
      label: "Cancel",
      onClick: () => {
        if (isReadOnly) return;
        setConfirmCancelActive(true);
      },
    },
  ];

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`flex items-center text-white ${
                index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
              } pl-6 border-b`}
            >
              <div
                className={`${
                  index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
                } py-4`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={15}
                  fill="none"
                  className={`transition duration-300 ${
                    open ? "rotate-90 transform" : ""
                  }`}
                >
                  <path fill="#557FFF" d="M.084.758v14l11-7-11-7Z" />
                </svg>
              </div>
              <div className="grid grid-cols-11 w-full">
                {tableColumnsByType[type].map((column) => (
                  <>
                    {column.action
                      ? column.render(
                          index,
                          order[column.dataIndex],
                          order,
                          menuItems
                        )
                      : column.render(index, order[column.dataIndex], order)}
                  </>
                ))}
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div>
                {offers?.map((order) => (
                  <div
                    key={order.id}
                    className={`
                      border-b
                      border-dotted
                      last:border-none
                      pl-[36px]
                      text-white
                      ${index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"}
                      ${
                        order?.myOrder && "bg-[#5a5f69]"
                      } grid grid-cols-11 w-full hover:bg-[#5a5f69] transition-colors duration-500 cursor-pointer`}
                    onClick={() => setAmendPromptActiveOrder(order)}
                  >
                    {allOrderTableColumns.map((column) => (
                      <>
                        {column.render(index, order[column.dataIndex], order)}
                      </>
                    ))}
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-11 w-full bg-black border-y border-[#F00] hover:bg-[#5a5f69] transition-colors duration-500 cursor-pointer pl-[36px]">
                <div className="text-[#F00] text-center py-4">Price gap</div>
                <div className="col-start-3 border-l border-white py-4" />
                <div className="text-[#F00] text-center col-start-7 border-r border-white py-4">
                  <NumberFormatter
                    value={gap ?? 0}
                    isCurrency={true}
                    tickerCode={order.underlying}
                    decimalScale={2}
                    emptyContent={"-"}
                  />
                </div>
              </div>
              {bids?.map((order) => (
                <div
                  key={order.id}
                  className={`
                    border-b
                    border-dotted
                    last:border-none
                    pl-[36px]
                    text-white
                    ${index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"}
                    ${
                      order?.myOrder && "bg-[#5a5f69]"
                    } grid grid-cols-11 w-full col-start-3 hover:bg-[#5a5f69] transition-colors duration-500 cursor-pointer`}
                  onClick={() => setAmendPromptActiveOrder(order)}
                >
                  {allOrderTableColumns.map((column) => (
                    <>{column.render(index, order[column.dataIndex], order)}</>
                  ))}
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {amendPromptActiveOrder && (
        <AdjustPrompt
          handleCancel={() => setAmendPromptActiveOrder("")}
          handleConfirm={handleAmendOrderPrice}
          order={order}
          otherOrder={amendPromptActiveOrder}
        />
      )}
      {confirmCancelActive && (
        <ConfirmCancel
          handleCancel={() => setConfirmCancelActive(false)}
          handleConfirm={handleDeleteOrder}
        />
      )}
    </>
  );
}
