import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const activeOBOInvestorAtom = atomWithStorage("activeOBOInvestor", "");
export const activeBrokerInvestorAtom = atomWithStorage(
  "activeBrokerInvestor",
  ""
);

export const activeCurrencyAtom = atom("");
export const etaSwitchPurpleActiveAtom = atom(false);

export const activeProductsAtom = atom("");
