import React, { Component } from "react";
import "./style.scss";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";

class InvestorEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      payLoadCallback: props.callback,
    };
  }

  validate = () => {
    const { payload } = this.state;
    if (payload.value === undefined || payload.value === null) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onChange = (e) => {
    let { payload, payLoadCallback } = this.state;
    payload.value = e.target.value || "";
    this.updateState(payload);
    if (payLoadCallback) {
      payLoadCallback();
    }
  };

  render() {
    const { payload } = this.state;
    return (
      <div>
        <div className="title">Register to Trade</div>
        <div className="contentTop">
          Choose the type of legal entity under which you plan to trade Prism
          OTC Allocations. This selection will determine what information we
          need to collect.
        </div>
        <div className="register-trade-select">
          <select
            value={payload.value}
            onChange={this.onChange}
            className={cn({
              "error-field":
                _isUndefined(payload.value) || payload.value === "",
            })}
          >
            <option value="">Investor Entity</option>
            <option value="australianCompany">Australian Company</option>
            <option value="foreignCompany">Foreign Company</option>
            <option value="registeredTrust">Regulated Trust</option>
            <option value="unRegulatedTrust">Unregulated Trust</option>
            <option value="partnership">Partnership</option>
            <option value="individualOrSoleTrader">
              Individual / Sole Trader
            </option>
          </select>
        </div>
        <div className="information">
          <div className="information-title">
            Why do we need this information?
          </div>
          <div className="information-content">
            To comply with Anti-Money Laundering rules, Prism OTC must conduct
            an automated Know-Your-Customer check.
          </div>
          <div className="information-content">
            You’ll be notified as soon as that’s completed.
          </div>
        </div>
      </div>
    );
  }
}

export default InvestorEntity;
