import React, { Component } from "react";
import "./../style.scss";
import "./style.scss";
import { Collapse, Descriptions, message, Modal } from "antd";
import { Box, Container } from "@mui/material";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";
import { withRouter } from "react-router-dom";
import { intl } from "../../../utils/intl_i18n";
import { isArray } from "lodash";
import { Mapping } from "./keyValueMapping";
import validator from "validator";
import LiComponent from "./LiComponent";
import { getOBOInvestorID } from "../../common/utils";

const { Panel } = Collapse;
/**
 * Component helps to view user registration details
 * and uploaded documents
 */

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalInfo: [],
      payload: [],
      addressInfo: [],
      userEntityResult: new Map(),
    };
  }

  fetchUserDetails = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .get(ROUTES.USER_INFO, config)
      .then((res) => {
        this.setState({ personalInfo: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchUserRegistrationData = async () => {
    let _this = this;
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axiosConfig
      .get(`${ROUTES.STORAGE_URL}`, config)
      .then((res) => {
        if (res.data) {
          if (res.data !== "") {
            if (!res.data.currentComponent && res.data.payload) {
              res.data.currentComponent =
                this.state.defaultPayload.currentComponent;
            }
            _this.setState({ payload: res.data.payload });
            this.populateUserDetails();
            this.generateUserRegistration();
          } else {
            message.warn("No stored registration details found");
            console.log("No stored progress retrieved from server.");
          }
        }
      })
      .catch((error) => {
        console.log("Server response: " + error);
      })
      .finally(() => {
        _this.setState({ isUpdated: true });
      });
  };

  populateUserDetails = () => {
    const { payload } = this.state;
    for (var userDetailKey in payload) {
      if (userDetailKey === "investorEntity") {
        if (payload[userDetailKey].value === "australianCompany") {
          this.setState({ addressInfo: payload.australianCompany });
          return;
        }
      }
    }
  };
  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  componentDidMount = () => {
    this.fetchUserDetails();
    this.fetchUserRegistrationData();
  };

  redirectToRegister = (event) => {
    this.props.history.push("/register");
  };

  handleViewClick = (url) => {
    console.log("url", url);
    let params = {
      s3URL: url,
    };
    axiosConfig
      .get(ROUTES.PRESIGNED_S3_URL, { params })
      .then((res) => {
        this.setState({ viewDoc: res.data, viewPreviewModal: true });
      })
      .catch((error) => {});
  };

  handleOk = () => {
    this.setState({ viewDoc: undefined, viewPreviewModal: false });
  };

  handleCancel = () => {
    this.setState({ viewDoc: undefined, viewPreviewModal: false });
  };

  generateUserRegistration = () => {
    const { payload } = this.state;
    let result = new Map();
    const userDetails = payload;
    for (var userDetailKey in userDetails) {
      if (userDetailKey !== "adminUploads") {
        var userDetail = userDetails[userDetailKey];
        let userDetailList = [];
        Object.keys(userDetail).forEach(function (labelKey) {
          let details = {};
          var result = labelKey.replace(/([A-Z])/g, " $1");
          details["label"] = result.charAt(0).toUpperCase() + result.slice(1);
          if (typeof userDetail[labelKey] === "boolean") {
            details["value"] = userDetail[labelKey] ? "Yes" : "No";
          } else {
            details["value"] = userDetail[labelKey];
          }
          userDetailList.push(details);
        });
        result.set(userDetailKey, userDetailList);
      }
    }
    this.setState({ userEntityResult: result });
  };

  getValue = (data) => {
    if (typeof data.value === "number") {
      return data.value;
    } else if (typeof data.value === "boolean") {
      return data.value === true ? "Yes" : "No";
    } else if (validator.isURL(data.value)) {
      return <a onClick={(e) => this.handleViewClick(data.value)}> view</a>;
    } else if (
      Mapping[data.value] !== " " &&
      Mapping[data.value] !== undefined
    ) {
      return Mapping[data.value];
    } else {
      return data.value;
    }
  };

  render() {
    const { personalInfo, addressInfo, userEntityResult, viewPreviewModal } =
      this.state;
    return (
      <Container maxWidth="xl" sx={{ marginTop: "15px" }}>
        <div className="text-white text-[40px] font-extralight text-center">
          Personal details
        </div>
        <div className="bg-light-gradient backdrop-blur login-box-shadow rounded-xl">
          <Box sx={{ p: 3 }}>
            <div>
              <Descriptions
                className="user-description"
                title=""
                layout="vertical"
                column={2}
              >
                <Descriptions.Item label="UserName">
                  {personalInfo?.firstName} {personalInfo?.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {personalInfo?.email}
                </Descriptions.Item>
                <Descriptions.Item label="Telephone">
                  {personalInfo?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Address">
                  {addressInfo?.businessStreetAddr} <br />
                  {addressInfo?.businessSuburb} <br />
                  {addressInfo?.businessState} <br />
                  {addressInfo?.businessCountry} <br />
                  {addressInfo?.businessPostCode}
                </Descriptions.Item>
              </Descriptions>
            </div>
            {getOBOInvestorID() === "" && (
              <div>
                <a onClick={(e) => this.redirectToRegister(e)}>
                  {" "}
                  Click here to update Registration details{" "}
                </a>
              </div>
            )}
            <h2 className="register-title">View Registration Details</h2>

            <Collapse accordion>
              {[...userEntityResult.keys()].map((entityKey) => (
                <Panel
                  header={Mapping[entityKey] ? Mapping[entityKey] : entityKey}
                  key={entityKey}
                >
                  {userEntityResult.get(entityKey).map((userData) => {
                    if (
                      userData.label !== "Errors" &&
                      userData.label !== "Document List" &&
                      userData.label !== "Current Index" &&
                      userData.label !== "documentList"
                    ) {
                      if (
                        !isArray(userData?.value) &&
                        typeof userData?.value !== "object"
                      ) {
                        let label = Mapping[userData.label]
                          ? Mapping[userData.label]
                          : userData.label;
                        let value = this.getValue(userData);
                        return (
                          <ul>
                            {" "}
                            <li>
                              {" "}
                              {label} : {value}{" "}
                            </li>
                          </ul>
                        );
                      } else if (isArray(userData?.value)) {
                        return (
                          <tr>
                            <LiComponent
                              keyProp={userData.label}
                              jsonObj={userData.value}
                            />
                          </tr>
                        );
                      } else {
                        return;
                      }
                    } else {
                      return;
                    }
                  })}
                </Panel>
              ))}
            </Collapse>
          </Box>
        </div>
        {viewPreviewModal === true && (
          <Modal
            visible={viewPreviewModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            <div className="pdf-file-viewer">
              <object
                data={this.state.viewDoc}
                width="100%"
                height="100%"
                aria-label="view-doc"
              />
              <div className="file-viewer-download">
                {" "}
                <a target="blank" href={this.state.viewDoc}>
                  Click here to download
                </a>
              </div>
            </div>
          </Modal>
        )}
      </Container>
    );
  }
}

export default withRouter(PersonalDetails);
