import { useEffect } from "react";
import SettingsLogo from "../../logo/settingsLogo";
import { useIdleTimer } from "react-idle-timer";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";
import "./styles.scss";
import cn from "classnames";
import { getStorageData } from "../utils";
import { useHistory } from "react-router-dom";

function UserInfo({ firstName, lastName, updateSelectionInvestor }) {
  const OBOSelectedInvestor = getStorageData("OBOInvestor");
  const history = useHistory();

  const getLogout = (e) => {
    sessionStorage.clear();
    history.push("/login");
  };

  return (
    <div className="user-details">
      <div
        className="user-logout bg-white-card p-2 border border-[#e8e8ea] rounded-lg"
        onClick={getLogout}
        title="Log Out"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={17}
          fill="none"
          className="fill-[#8B8E94] hover:fill-[#474C55]"
        >
          <path
            fillRule="evenodd"
            d="M12.951.75H.701V17h12.25v-5.875h-1.25v4.625h-9.75V2h9.75v4.25h1.25V.75Zm5.746 8.37-3.98 4.005 1.394-.01 4.465-4.495-4.465-4.495h-1.394l3.98 3.995H4.577v1h14.12Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

export default UserInfo;
