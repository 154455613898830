import { convertToDateFormat } from "../../utils/utile";
import NumberFormatter from "../common/NumberFormatter";
import { etaPairMapping } from "../common/ETAPills/config";
import ETAPill from "../common/ETAPills/ETAPill";
import ActionMenu from "../common/ActionMenu";
import moment from "moment";

const otherSide = {
  PURE_DIV: "PURE_GROWTH",
  MAX_DIV: "GROWTH_GUARD",
  DIV_GUARD: "MAX_GROWTH",
};

export const allOrderTableColumns = [
  {
    className: "",
    title: "",
    titleClass: "",
    dataIndex: "",
    key: "",
    render: (index, term, record) => {
      return (
        <div
          className={`border-l border-white text-center col-start-3 py-4 px-3 ${
            index % 2 !== 0
              ? "bg-black bg-opacity-50"
              : "bg-black bg-opacity-50"
          }`}
        />
      );
    },
  },
  {
    className: "headings align-center py-4 px-3",
    title: "Maturity",
    titleClass: "text-center",
    dataIndex: "term",
    key: "term",
    render: (index, term, record) => {
      return (
        <div
          className={`text-center col-start-4 py-4 px-3 ${
            index % 2 !== 0
              ? "bg-black bg-opacity-50"
              : "bg-black bg-opacity-50"
          }`}
        >
          {record.term
            ? record.term + " years"
            : record.maturityDate
            ? convertToDateFormat(record.maturityDate)
            : "-"}
        </div>
      );
    },
  },
  {
    className: "headings align-right",
    title: "Qty",
    dataIndex: "quantity",
    key: "quantity",
    render: (index, quantity) => {
      return (
        <div
          className={`py-4 px-3 text-center ${
            index % 2 !== 0
              ? "bg-black bg-opacity-50"
              : "bg-black bg-opacity-50"
          }`}
        >
          <NumberFormatter value={quantity} emptyContent={"-"} />
        </div>
      );
    },
  },
  {
    className: "headings align-right",
    title: "Remaining",
    dataIndex: "remaining",
    key: "remaining",
    render: (index, remaining) => {
      return (
        <div
          className={`py-4 px-3 text-center ${
            index % 2 !== 0
              ? "bg-black bg-opacity-50"
              : "bg-black bg-opacity-50"
          }`}
        >
          <NumberFormatter value={remaining} emptyContent={"-"} />
        </div>
      );
    },
  },
  {
    className: "headings align-right",
    title: "Price",
    titleClass: "py-4 px-3 border-r",
    dataIndex: "price",
    key: "price",
    render: (index, price, record) => {
      return (
        <div
          className={`border-r border-white py-4 px-3 text-center ${
            index % 2 !== 0
              ? "bg-black bg-opacity-50"
              : "bg-black bg-opacity-50"
          }`}
        >
          <NumberFormatter
            value={price}
            isCurrency={true}
            tickerCode={record.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        </div>
      );
    },
  },
  {
    className: "headings align-center",
    title: "Consideration",
    dataIndex: "considerationType",
    key: "considerationType",
    render: (index, considerationType) => (
      <div className="py-4 px-3 text-center">{considerationType}</div>
    ),
  },
  {
    className: "headings align-center",
    title: "Leg",
    dataIndex: "side",
    key: "side",
    render: (index, side) => (
      <div className="py-4 px-3 text-center">{side}</div>
    ),
  },
  {
    className: "headings align-center",
    title: "Last Update",
    titleClass: "py-4 px-3",
    dataIndex: "lastUpdateDate",
    key: "lastUpdateDate",
    render: (index, lastUpdateDate) => (
      <div className="py-4 px-3 text-center">
        {lastUpdateDate ? moment(lastUpdateDate).format("DD MMM") : "-"}
      </div>
    ),
  },
];

const commonProductColumns = [
  {
    className: "headings text-center",
    title: "Underlying",
    titleClass: "py-4 px-3 text-center",
    dataIndex: "underlying",
    key: "underlying",
    render: (index, underlying) => (
      <div
        className={`${
          index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
        } py-4 px-3 flex justify-center items-center`}
      >
        {underlying}
      </div>
    ),
  },
  {
    className: "headings text-center",
    title: "Last Price",
    titleClass: "py-4 px-3 text-center",
    dataIndex: "lastPrice",
    key: "lastPrice",
    render: (index, lastPrice, record) => {
      return (
        <div
          className={`${
            index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
          } py-4 px-3 flex justify-center items-center`}
        >
          <NumberFormatter
            value={lastPrice}
            isCurrency={true}
            tickerCode={record.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        </div>
      );
    },
  },
  {
    className: "headings text-center",
    title: "Product",
    titleClass: "text-center border-l py-4 px-3",
    dataIndex: "productName",
    key: "productName",
    render: (index, productName, record) => {
      return (
        <div className="border-l bg-black bg-opacity-50 py-4 px-3 flex flex-col gap-y-2 flex justify-center items-center">
          {record.orderType === "SHARE_APP" && (
            <ETAPill
              id={crypto.randomUUID()}
              productId={otherSide[productName]}
              className="w-[110px]"
            />
          )}
          <ETAPill
            id={crypto.randomUUID()}
            productId={productName}
            className="w-[110px]"
          />
        </div>
      );
    },
  },
  {
    className: "headings text-center",
    title: "Maturity",
    titleClass: "text-center py-4 px-3",
    dataIndex: "term",
    key: "term",
    render: (index, term, record) => {
      return (
        <div className="text-center bg-black bg-opacity-50 py-4 px-3 flex justify-center items-center">
          {record.term
            ? record.term + " years"
            : record.maturityDate
            ? convertToDateFormat(record.maturityDate)
            : "-"}
        </div>
      );
    },
  },
  {
    className: "headings align-right",
    title: "Qty",
    titleClass: "text-center py-4 px-3",
    dataIndex: "quantity",
    key: "quantity",
    render: (index, quantity) => {
      return (
        <div className="bg-black bg-opacity-50 py-4 px-3 flex justify-center items-center">
          <NumberFormatter value={quantity} emptyContent={"-"} />
        </div>
      );
    },
  },
  {
    className: "headings align-right",
    title: "Remaining",
    titleClass: "text-center py-4 px-3",
    dataIndex: "remainingQty",
    key: "remainingQty",
    render: (index, remainingQty) => {
      return (
        <div className="bg-black bg-opacity-50 py-4 px-3 flex justify-center items-center">
          <NumberFormatter value={remainingQty} emptyContent={"-"} />
        </div>
      );
    },
  },
  {
    className: "headings align-right",
    title: "Price",
    titleClass: "text-center py-4 px-3 border-r",
    dataIndex: "priceDollars",
    key: "priceDollars",
    render: (index, priceDollars, record) => {
      return (
        <div className="border-r bg-black bg-opacity-50 py-4 px-3 flex justify-center items-center">
          <NumberFormatter
            value={priceDollars}
            isCurrency={true}
            tickerCode={record.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        </div>
      );
    },
  },
  {
    className: "headings text-center",
    title: "Consideration",
    titleClass: "text-center py-4 px-3",
    dataIndex: "considerationType",
    key: "considerationType",
    render: (index, considerationType) => {
      return (
        <div
          className={`${
            index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
          } py-4 px-3 flex justify-center items-center`}
        >
          {considerationType}
        </div>
      );
    },
  },
  {
    className: "headings text-center",
    title: "Leg",
    titleClass: "text-center py-4 px-3",
    dataIndex: "orderSide",
    key: "side",
    render: (index, orderSide) => (
      <div
        className={`${
          index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
        } py-4 px-3 flex justify-center items-center`}
      >
        {orderSide}
      </div>
    ),
  },
  {
    className: "headings text-center",
    title: "Last Update",
    titleClass: "text-center py-4 px-3",
    dataIndex: "lastUpdateDate",
    key: "lastUpdateDate",
    render: (index, lastUpdateDate) => (
      <div
        className={`${
          index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
        } py-4 px-3 flex justify-center items-center`}
      >
        {lastUpdateDate ? moment(lastUpdateDate).format("DD MMM") : "-"}
      </div>
    ),
  },
  {
    action: true,
    className: "headings text-center",
    title: "Actions",
    titleClass: "text-center py-4 px-3",
    render: (index, item, record, menuItems) => {
      return (
        <div
          className={`${
            index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
          } text-center py-4 px-3 flex justify-center items-center`}
        >
          <ActionMenu menuItems={menuItems} disabled={!record.id} />
        </div>
      );
    },
  },
];

const leverageColumn = {
  title: "Leverage",
  dataIndex: "offerLeverage",
  key: "offerLeverage",
  render: (index, offerLeverage, record) => {
    return (
      <div
        className={`${
          index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
        } py-4 px-3`}
      >
        {offerLeverage ? parseFloat(offerLeverage).toFixed(1) + "x" : "-"}
      </div>
    );
  },
  className: "table-row-content align-center",
};

const yieldColumn = {
  title: "Yield",
  dataIndex: "offerYield",
  key: "offerYield",
  render: (index, offerYield, record) => {
    return (
      <div
        className={`${
          index % 2 !== 0 ? "bg-[#32353E]" : "bg-[#434750]"
        } py-4 px-3`}
      >
        {offerYield ? parseFloat(offerYield).toFixed(1) + "%" : "-"}
      </div>
    );
  },
  className: "table-row-content align-center",
};

const growthProductColumns = commonProductColumns.toSpliced(
  commonProductColumns.length - 2,
  0,
  leverageColumn
);

const incomeProductColumns = commonProductColumns.toSpliced(
  commonProductColumns.length - 2,
  0,
  yieldColumn
);

export const tableColumnsByType = {
  GROWTH: commonProductColumns,
  INCOME: commonProductColumns,
  SHARE_APP: commonProductColumns,
};
