import { Component } from "react";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import { Collapse, Upload } from "antd";

const { Dragger } = Upload;
const { Panel } = Collapse;

class InvestorEvidence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    const { payload } = this.state;

    this.updateState(payload);
  }

  validate = () => {
    const { payload } = this.state;

    if (payload.documentList === undefined) {
      this.onValidationFail();
      return;
    }
    if (!payload.documentList) {
      this.onValidationFail();
      return;
    }
    if (payload.documentList.length === 0) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };
  handleChange = (info) => {
    const { payload } = this.state;
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      file.status = "done";
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    payload.documentList = fileList;
    this.updateState(payload);
  };
  docSelect = (field) => {
    const { payload } = this.state;
    payload[field] = true;
    this.updateState(payload);
  };

  viewUploadedFile = () => {
    const { documentList } = this.state.payload;
    if (documentList !== undefined && documentList.length > 0)
      return documentList;

    return null;
  };

  handleBeforeUpload = (file) => {
    file.fileId = file.name;
    file["fileId"] = file.name;
    const { payload } = this.state;
    this.updateState(payload);
  };

  handleRemove = (file) => {
    const { payload } = this.state;
    payload[file.fileId] = false;
    this.updateState(payload);
  };

  render() {
    const props = {
      action: "",
      onChange: this.handleChange,
      defaultFileList: this.viewUploadedFile,
      maxCount: 5,
      beforeUpload: this.handleBeforeUpload,
      onRemove: this.handleRemove,
    };
    return (
      <div className="investor-evidence-reg-main-content">
        <div className="reg-header">
          {this.formatMessage_i18n("investor.evidence.heading")}
        </div>
        <div className="reg-sub-info">
          {this.formatMessage_i18n("investor.evidence.description1")}
        </div>
        <div className="reg-sub-info">
          {this.formatMessage_i18n("investor.evidence.description2")}
        </div>
        <div className="reg-sub-info">
          {this.formatMessage_i18n("investor.evidence.individual.document")}
        </div>

        <Collapse accordion>
          <Panel
            header={this.formatMessage_i18n(
              "investor.evidence.individual.document"
            )}
            key="1"
          >
            <div className="reg-list">
              <ul>
                <li className="reg-list-item">
                  {this.formatMessage_i18n(
                    "investor.evidence.support.document1"
                  )}
                </li>
                <li className="reg-list-item">
                  {this.formatMessage_i18n(
                    "investor.evidence.support.document2"
                  )}
                </li>
              </ul>
            </div>
          </Panel>
          <Panel
            header={this.formatMessage_i18n(
              "investor.evidence.company.document"
            )}
            key="2"
          >
            <div className="reg-list">
              <ul>
                <li className="reg-list-item">
                  {this.formatMessage_i18n(
                    "investor.evidence.support.document3"
                  )}
                </li>
                <li className="reg-list-item">
                  {this.formatMessage_i18n(
                    "investor.evidence.support.document4"
                  )}
                </li>
                <li className="reg-list-item">
                  {this.formatMessage_i18n(
                    "investor.evidence.support.document5"
                  )}
                </li>
                <li className="reg-list-item">
                  {this.formatMessage_i18n(
                    "investor.evidence.support.document6"
                  )}
                </li>
              </ul>
            </div>
          </Panel>
        </Collapse>

        <div className="verification-file-upload-main">
          <Dragger
            {...props}
            name="file"
            customRequest={() => {
              return "";
            }}
          >
            <p className="ant-upload-text">
              {this.formatMessage_i18n("verification.procedure.drag.drop.text")}
            </p>
            <p className="ant-upload-text">Max allowed : 5</p>
          </Dragger>
        </div>
      </div>
    );
  }
}

export default InvestorEvidence;
