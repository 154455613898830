import { createColumnHelper } from "@tanstack/react-table";
import NumberFormatter from "../common/NumberFormatter";
import { convertToDateFormat } from "../../utils/utile";
import moment from "moment";
import ETAPill from "../common/ETAPills/ETAPill";
import ActionMenu from "../common/ActionMenu";
import { fetchLastPrice } from "../trade/utils";
import message from "antd/es/message";

const columnHelper = createColumnHelper();

const readOnlyCheck = (isReadOnly) => {
  if (isReadOnly) {
    message.error(
      "This user is set to read only and cannot perform these actions"
    );
    return true;
  }
  return false;
};

export const getColumns = (
  lastPriceList,
  isReadOnly,
  secondaryMarketDataAccess,
  handleViewPositions,
  handleSellOrder,
  handleRedeem
) => {
  return [
    columnHelper.accessor("securityCode", {
      header: "Security Code",
    }),
    columnHelper.accessor("underlying", {
      header: "Underlying",
    }),
    columnHelper.accessor("underlyingLastClose", {
      header: "Underlying Last Close",
      cell: (info) => {
        const price = fetchLastPrice(
          lastPriceList,
          info.row.original,
          "underlying"
        );
        return (
          <NumberFormatter
            value={price}
            isCurrency={true}
            tickerCode={info.row.original?.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        );
      },
    }),
    columnHelper.accessor("productName", {
      header: "Product",
      cell: (info) => (
        <ETAPill
          id={crypto.randomUUID()}
          productId={info.getValue()}
          className="w-[110px]"
        />
      ),
    }),
    columnHelper.accessor("establishmentPrice", {
      header: "Capital Allocation Price",
      cell: (info) => (
        <NumberFormatter
          value={info.getValue()}
          isCurrency={true}
          tickerCode={info.row.original?.underlying}
          decimalScale={2}
          emptyContent={"-"}
        />
      ),
    }),
    columnHelper.accessor("averagePrice", {
      header: "Average Price",
      cell: (info) => (
        <NumberFormatter
          value={info.getValue()}
          isCurrency={true}
          tickerCode={info.row.original?.underlying}
          decimalScale={2}
          emptyContent={"-"}
        />
      ),
    }),
    columnHelper.accessor("quantity", {
      header: "Total Quantity",
      cell: (info) => (
        <NumberFormatter value={info.getValue()} emptyContent={"-"} />
      ),
    }),
    columnHelper.accessor("availableQuantity", {
      header: "Available Quantity",
      cell: (info) => {
        let available =
          info.row.original?.quantity - info.row.original?.lockedQty;
        return <NumberFormatter value={available} emptyContent={"-"} />;
      },
    }),
    columnHelper.accessor("lockedQty", {
      header: "Locked Quantity",
      cell: (info) => (
        <NumberFormatter value={info.getValue()} emptyContent={"-"} />
      ),
    }),
    columnHelper.accessor("maturityDate", {
      header: "Maturity Date",
      cell: (info) => {
        return info.getValue() ? convertToDateFormat(info.getValue()) : "-";
      },
    }),
    columnHelper.accessor("action", {
      header: "Actions",
      cell: (info) => {
        let menuItems = [
          {
            label: "View",
            onClick: () => {
              if (readOnlyCheck(isReadOnly)) return;
              handleViewPositions(info.row.original);
            },
          },
        ];
        if (secondaryMarketDataAccess) {
          menuItems.push({
            label: "Sell",
            onClick: () => {
              if (readOnlyCheck(isReadOnly)) return;
              handleSellOrder(info.row.original);
            },
          });
        }
        if (info.row.original?.isRedeemAllowed) {
          menuItems.push({
            label: "Redeem",
            onClick: () => {
              if (readOnlyCheck(isReadOnly)) return;
              handleRedeem(info.row.original);
            },
          });
        }
        return <ActionMenu menuItems={menuItems} />;
      },
    }),
  ];
};
