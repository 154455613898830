import React, { Component } from "react";
import PageContent from "../common/PageContent";
import axiosConfig from "../../axiosConfig";
import "./styles.scss";
import ActionIcons from "../actionIcons";
import ROUTES from "../../routes/api.json";
import Input from "antd/es/input";
import { SearchOutlined } from "@ant-design/icons";
import {
  etaTypeFilter,
  filterCurrencyandEtaList,
  getFilteredRow,
  getStorageData,
  isSecondaryMarketAllowed,
} from "../common/utils";
import { getInvestorEmail } from "../common/utils";
import _isUndefined from "lodash/isUndefined";
import _isEmpty from "lodash/isEmpty";
import { fetchLastPrice } from "../trade/utils";
import cn from "classnames";
import IssuanceOrderTable from "./IssuanceOrderTable";
import SecondaryOrderTable from "./SecondaryOrderTable";
import jwt_decode from "jwt-decode";

const ISSUANCE = "issuance";
const SECONDARY = "secondary";

class OrderBook extends Component {
  /*
   * Constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      issuanceOrderbookData: [],
      secondaryOrderbookData: [],
      orderType: ISSUANCE,
      secondaryMarketDataAccess: false,
      investorId: this.props.investorId,
    };
  }

  /*
   * Initial data pull from the order book.  An array of rows will be returned, each row
   * consisting of:
   *
   * "underlying": "CBA.AX",
   * "lastPx": 0.0,
   * "growthMatch": 0,
   * "growthProductName": "GrowthGuard",
   * "growthTerm": "5-6",
   * "growthQty": 3000,
   * "growthBid": 46.0,
   * "incomeBid": 0.0,
   * "incomeQty": 0,
   * "incomeTerm": null,
   * "incomeProductName": null,
   * "incomeMatch": 0
   *
   * If either "growthProductName" or "incomeProductName" is null or empty, then don't show that side
   * of the row.
   */
  fetchOrderBook = () => {
    console.log("loading issuance book");
    axiosConfig
      .get(`${ROUTES.ISSUANCE_ORDER_BOOK}/${this.state.investorId}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ issuanceOrderbookData: response.data });
        }
      })
      .catch((e) => console.log("error=>", e.response));
  };

  /**
   * It will return list of secondary market data
   */
  fetchSecondaryMarketData = () => {
    console.log("loading secondary book");
    axiosConfig
      .get(`${ROUTES.SECONDARY_ORDER_BOOK}/${this.state.investorId}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ secondaryOrderbookData: response.data });
        }
      })
      .catch((e) => console.log("error=>", e.response));
  };

  /**
   * To get the secondary market data access for the investor
   */
  getSecondaryMarketAccessStatus = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .get(`${ROUTES.USER_DETAILS}`, config)
      .then((res) => {
        let secondaryMarketAllowed = isSecondaryMarketAllowed(res.data.groups);
        this.setState({ secondaryMarketDataAccess: secondaryMarketAllowed });
        if (secondaryMarketAllowed) {
          this.fetchSecondaryMarketData();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  /*
   * We pull down order book data as soon as the class is fully loaded.
   */
  componentDidMount() {
    this.getSecondaryMarketAccessStatus();
    this.fetchOrderBook();
  }

  /*
   * May be called to notify this component that a new execution report has arrived,
   * so it's time to update the data model.
   */
  execReport(execReport) {
    this.fetchOrderBook();
  }

  /*
   * This is called when the user flips between Issuance and Secondary market types.
   */
  onOrderTypeChange = (e) => {
    this.setState({ orderType: e.target.value });
  };

  onHandleSearchText = (inputValue, orderType) => {
    if (orderType === ISSUANCE) {
      const updatedIssuanceData = getFilteredRow(
        inputValue,
        this.state.issuanceOrderbookData
      );
      !_isUndefined(updatedIssuanceData) &&
        this.setState({ filteredIssuanceData: updatedIssuanceData });
    } else if (orderType === SECONDARY) {
      const updatedSecondaryData = getFilteredRow(
        inputValue,
        this.state.secondaryOrderbookData,
        true
      );
      !_isUndefined(updatedSecondaryData) &&
        this.setState({ filteredSecondaryData: updatedSecondaryData });
    }
  };

  /*
   * Formats yield/leverage for the given price
   * For GROWTH - Calculation on last price and offer price
   */
  fetchLeverage = (record, bidPrice) => {
    let result = "-";
    let storageContent = getStorageData("user-filter");
    let lastPrice = fetchLastPrice(
      this.props.lastPriceList,
      record,
      "underlying"
    );
    if (!_isEmpty(lastPrice.toString())) {
      if (storageContent) {
        if (etaTypeFilter.purple.key === storageContent.etaType) {
          lastPrice = lastPrice / 2;
        }
      }
      result = (lastPrice / bidPrice).toFixed(1);
      result = result + "x";
    }
    return result;
  };

  render() {
    const { lastPriceList, currencyType, etaType } = this.props;
    const {
      issuanceOrderbookData,
      secondaryOrderbookData,
      orderType,
      filteredIssuanceData,
      filteredSecondaryData,
      secondaryMarketDataAccess,
    } = this.state;

    let issuanceData = !_isUndefined(filteredIssuanceData)
      ? filteredIssuanceData
      : issuanceOrderbookData;
    let secondaryData = !_isUndefined(filteredSecondaryData)
      ? filteredSecondaryData
      : secondaryOrderbookData;

    if (orderType === ISSUANCE) {
      issuanceData = filterCurrencyandEtaList(
        issuanceData,
        currencyType,
        "underlying",
        etaType,
        ["growthProductName", "incomeProductName"]
      );
    } else {
      secondaryData = filterCurrencyandEtaList(
        secondaryData,
        currencyType,
        "underlying",
        etaType,
        "productName"
      );
    }

    return (
      <PageContent>
        <div className="order-book-main-container">
          <div className="order-book-header">
            <div className={cn("drag-handler order-book-title")}>
              <div>Expressions of Interest</div>
              <ActionIcons onClose={this.props.onClose} />
            </div>
            <div className="order-book-input">
              {secondaryMarketDataAccess && (
                <select
                  className="order-type"
                  value={orderType}
                  onChange={this.onOrderTypeChange}
                >
                  <option key="issuance" value="issuance">
                    Bid Matching
                  </option>
                  <option key="secondary" value="secondary">
                    Secondary
                  </option>
                </select>
              )}
              <Input
                className="search-text"
                onChange={(e) =>
                  this.onHandleSearchText(e.target.value, orderType)
                }
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          <div className="order-book-table-container">
            <div className="order-book-table">
              <div className="table-container">
                {/* Re-rendering the Entire table content to perform the API call on every render on the Dividend Yield */}
                {orderType === ISSUANCE &&
                  etaType === etaTypeFilter.rgb.key && (
                    <IssuanceOrderTable
                      issuanceData={issuanceData}
                      lastPriceList={lastPriceList}
                      fetchLeverage={this.fetchLeverage}
                    />
                  )}
                {orderType === ISSUANCE &&
                  etaType === etaTypeFilter.purple.key && (
                    <IssuanceOrderTable
                      issuanceData={issuanceData}
                      lastPriceList={lastPriceList}
                      fetchLeverage={this.fetchLeverage}
                    />
                  )}
                {orderType === SECONDARY &&
                  etaType === etaTypeFilter.rgb.key && (
                    <SecondaryOrderTable
                      secondaryData={secondaryData}
                      lastPriceList={lastPriceList}
                      fetchLeverage={this.fetchLeverage}
                    />
                  )}
                {orderType === SECONDARY &&
                  etaType === etaTypeFilter.purple.key && (
                    <SecondaryOrderTable
                      secondaryData={secondaryData}
                      lastPriceList={lastPriceList}
                      fetchLeverage={this.fetchLeverage}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    );
  }
}

export default OrderBook;
