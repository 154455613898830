import Modal from "antd/es/modal";

export default function AdjustPrompt({
  handleCancel,
  handleConfirm,
  order,
  otherOrder,
}) {
  return (
    <Modal
      className="sell-Positions-modal"
      visible={otherOrder}
      onCancel={handleCancel}
      footer={null}
    >
      <h2 className="text-white text-[20px] py-2">
        Adjust interest to match this level?
      </h2>
      <div className="flex justify-between items-center">
        <button
          className="bg-black rounded-full text-white px-4 py-1 w-[150px]"
          onClick={handleCancel}
        >
          No
        </button>
        <button
          className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 w-[150px]"
          onClick={() => handleConfirm(otherOrder.price)}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
}
