import { useState } from "react";
import "./styles.scss";
import Table from "antd/es/table";
import _filter from "lodash/filter";
import moment from "moment";
import message from "antd/es/message";
import SearchIcon from "../../logo/searchIcon";
import { setStorageData } from "../utils";
import { useAtom } from "jotai";
import { activeBrokerInvestorAtom } from "../../../store/atoms";
import {
  useBrokerConnect,
  useBrokerInvestors,
  useBrokerDisConnect,
} from "../../../hooks/investors";
import {
  brokerAccountConnectErrorMessage,
  brokerAccountConnectSuccessMessage,
  brokerAccountDisconnectSuccessMessage,
} from "../message";

const { Column } = Table;

function BrokerDetail({ cancelCallback }) {
  const [activeBrokerInvestor, setActiveBrokerInvestor] = useAtom(
    activeBrokerInvestorAtom
  );
  const { data: brokerInvestorList } = useBrokerInvestors();

  const [filteredBrokerInvestors, setFilteredBrokerInvestors] = useState([]);

  const [activeSelectedRow, setActiveSelectedRow] = useState(
    activeBrokerInvestor?.investorId ?? ""
  );

  const connectSuccessCallback = () => {
    message.success(brokerAccountConnectSuccessMessage);
    cancelCallback();
  };

  const connectErrorCallback = () => {
    message.error(brokerAccountConnectErrorMessage);
  };

  const disconnectSuccessCallback = () => {
    message.success(brokerAccountDisconnectSuccessMessage);
    setActiveBrokerInvestor("");
    cancelCallback();
  };

  const disconnectErrorCallback = () => {
    message.error(brokerAccountDisconnectErrorMessage);
  };

  const connectBroker = useBrokerConnect(
    connectSuccessCallback,
    connectErrorCallback
  );
  const disconnectBroker = useBrokerDisConnect(
    disconnectSuccessCallback,
    disconnectErrorCallback
  );

  const triggerCancelEvent = () => {
    cancelCallback();
  };

  const triggerDisconnectEvent = () => {
    message.info("Processing the disconnect request");
    disconnectBroker.mutate(activeBrokerInvestor?.investorId);
  };

  const triggerSelectionEvent = () => {
    if (activeSelectedRow) {
      let selectedInvestor = _filter(brokerInvestorList, {
        id: activeSelectedRow,
      });
      if (selectedInvestor) {
        selectedInvestor = selectedInvestor[0];
        setStorageData("brokerInvestor", {
          investorId: activeSelectedRow,
          name: `${selectedInvestor.legalName}`,
          email: selectedInvestor.primaryEmail,
        });
        setActiveBrokerInvestor({
          investorId: activeSelectedRow,
          name: `${selectedInvestor.legalName}`,
          email: selectedInvestor.primaryEmail,
        });
        message.info("Processing the connect request");
        connectBroker.mutate(activeSelectedRow);
      }
    }
  };

  const handleRowClick = (rowData) => {
    let activeId = rowData.id;
    if (activeSelectedRow === rowData.id) {
      activeId = "";
    }
    setActiveSelectedRow(activeId);
  };

  const applyFilters = (brokerInvestorList, searchValue) => {
    let filteredBroker = [];
    if (searchValue) {
      searchValue = searchValue.toLowerCase().trim();
      filteredBroker = _filter(brokerInvestorList, function (item) {
        return (
          (item?.legalName &&
            item?.legalName.toLowerCase().includes(searchValue)) ||
          (item?.primaryEmail &&
            item?.primaryEmail.toLowerCase().includes(searchValue)) ||
          (item?.id && item?.id.toString().includes(searchValue))
        );
      });
      setFilteredBrokerInvestors(filteredBroker);
    } else {
      setFilteredBrokerInvestors(brokerInvestorList);
    }
  };

  return (
    <div className="broker-detail-container">
      <div className="search-box" style={{ flex: "initial" }}>
        <SearchIcon />
        <input
          className="search-text"
          type="text"
          onChange={(e) => {
            applyFilters(brokerInvestorList, e.target.value);
          }}
        />
      </div>
      <div className="table-container">
        <Table
          className="table-data"
          pagination={false}
          dataSource={
            filteredBrokerInvestors.length > 0
              ? filteredBrokerInvestors
              : brokerInvestorList
          }
          scroll={{ y: 300 }}
          onRow={(record) => {
            return {
              onClick: (event) => handleRowClick(record),
            };
          }}
          rowClassName={(record) => {
            if (record.id === activeSelectedRow) {
              return "active-row";
            }
            return null;
          }}
        >
          <Column
            className="headings"
            title="Name"
            dataIndex="legalName"
            key="legalName"
          />
          <Column
            className="headings"
            title="Email"
            dataIndex="primaryEmail"
            key="primaryEmail"
          />
          <Column
            width={80}
            className="headings align-right"
            title="ID"
            dataIndex="id"
            key="id"
          />
          <Column
            className="headings align-right"
            title="Last Login"
            dataIndex="lastLogin"
            key="lastLogin"
            render={(lastLogin) => (
              <div>
                {lastLogin ? moment(lastLogin).format("DD/MM/YY HH:mm") : "-"}
              </div>
            )}
          />
        </Table>
      </div>
      <div className="flex justify-between items-center">
        <button
          className="bg-black rounded-full text-white px-4 py-1 whitespace-nowrap"
          onClick={triggerCancelEvent}
        >
          Cancel
        </button>
        <div className="flex gap-x-2">
          <button
            className={`${
              activeBrokerInvestor === ""
                ? "bg-[#E6E6E6] cursor-not-allowed"
                : "bg-[#5B7FFC]"
            } rounded-full text-white px-4 py-1`}
            onClick={triggerDisconnectEvent}
            disabled={activeBrokerInvestor === ""}
          >
            Disconnect
          </button>
          <button
            className="bg-[#5B7FFC] rounded-full text-white px-4 py-1"
            onClick={triggerSelectionEvent}
            disabled={activeSelectedRow === ""}
          >
            Select Client
          </button>
        </div>
      </div>
    </div>
  );
}

export default BrokerDetail;
