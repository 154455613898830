import React, { Component } from "react";
import { message, Modal, Table } from "antd";
import "./../style.scss";
import "./styles.scss";
import NumberFormatter from "../../common/NumberFormatter";
import {
  convertToDateFormat,
  convertToDateTimeFormat,
} from "../../../utils/utile";
import ETAPills from "../../common/ETAPills";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";
import PDFViewer from "../../common/pdfViewer";

class OrderTrail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewHistoryDoc: undefined,
      viewHistoryPreviewModal: false,
    };
  }

  orderStatus = {
    NEW: "NEW",
    CANCELLED: "CANCELLED",
  };

  fetchTermOrMaturity = (order) => {
    var termOrMaturity = "";
    if (order.orderSide === "BUY") {
      termOrMaturity =
        parseInt(order.minTermDays / 365) +
        "-" +
        parseInt(order.maxTermDays / 365) +
        "yrs";
    } else if (order.orderSide === "SELL") {
      termOrMaturity = convertToDateFormat(order.maturityDate);
    }
    return termOrMaturity;
  };

  getConfoPdfLink = async (investorId,orderId, orderStatus) => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosConfig.get(
        ROUTES.GET_ORDER_CONFO_PDF_URL + "/" + investorId+ "/"+orderId + "/" + orderStatus,
        config
      );
      this.setState({
        viewHistoryDoc: res.data,
        viewHistoryPreviewModal: true,
      });
    } catch (e) {
      if (e.status === 404) {
        message.error("File not found");
      } else if (e.status === 500) {
        message.error("Internal server error");
      }
    }
  };

  getColumns = () => {
    return [
      {
        title: "orderId",
        dataIndex: "orderId",
        key: "orderId",
        render: (orderId) => {
          return orderId ? orderId : "-";
        },
      },
      {
        title: "Created",
        dataIndex: "createdTime",
        key: "createdTime",
        render: (createdTime) => {
          return createdTime ? convertToDateTimeFormat(createdTime) : "-";
        },
      },
      {
        title: "Underlying Code",
        dataIndex: "underlying",
        key: "underlying",
        sorter: (a, b) => a.underlying.localeCompare(b.underlying),
        render: (underlying) => {
          return underlying ? underlying : "-";
        },
      },
      {
        title: "Product",
        dataIndex: "productNameDisplay",
        key: "productNameDisplay",
        sorter: (a, b) =>
          a.productNameDisplay.localeCompare(b.productNameDisplay),
        render: (productNameDisplay, record) => {
          return productNameDisplay ? (
            <ETAPills
              etaDisplayText={productNameDisplay}
              etaId={record.productName}
            />
          ) : (
            "-"
          );
        },
      },
      {
        title: "Side",
        dataIndex: "orderSide",
        key: "orderSide",
        sorter: (a, b) => a.orderSide.localeCompare(b.orderSide),
        render: (orderSide) => {
          return orderSide ? orderSide : "-";
        },
      },
      {
        title: "Price",
        dataIndex: "priceDollars",
        key: "priceDollars",
        sorter: (a, b) => a.priceDollars - b.priceDollars,
        render: (priceDollars, record) => (
          <NumberFormatter
            value={priceDollars}
            isCurrency={true}
            tickerCode={record.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        ),
        align: "right",
      },
      {
        title: "Order Qty",
        dataIndex: "quantity",
        key: "quantity",
        sorter: (a, b) => a.quantity.localeCompare(b.quantity),
        render: (quantity) => {
          return <NumberFormatter value={quantity} emptyContent={"-"} />;
        },
      },
      {
        title: "Filled Qty",
        dataIndex: "filledQty",
        key: "filledQty",
        sorter: (a, b) => a.filledQty.localeCompare(b.filledQty),
        render: (filledQty) => {
          return <NumberFormatter value={filledQty} emptyContent={"-"} />;
        },
      },
      {
        title: "Consideration Type",
        dataIndex: "considerationType",
        key: "considerationType",
        sorter: (a, b) =>
          a.considerationType.localeCompare(b.considerationType),
        render: (considerationType) => {
          return considerationType ? considerationType : "-";
        },
      },
      {
        title: "Your Ref",
        dataIndex: "clientRef",
        key: "clientRef",
      },
      {
        title: "Term / Maturity",
        dataIndex: "term",
        key: "term",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Confo Pdf",
        dataIndex: "orderId",
        key: "orderId",
        render: (orderId, record) => {
          if (
            record.status === this.orderStatus.NEW ||
            record.status === this.orderStatus.CANCELLED
          ) {
            return (
              <a
                href="javascript:void(0);"
                onClick={() => {
                  this.getConfoPdfLink(record.investorId,orderId, record.status);
                }}
              >
                View
              </a>
            );
          } else {
            return "";
          }
        },
      },
    ];
  };

  handleOk = () => {
    this.setState({
      viewHistoryDoc: undefined,
      viewHistoryPreviewModal: false,
    });
  };

  handleCancel = () => {
    this.setState({
      viewHistoryDoc: undefined,
      viewHistoryPreviewModal: false,
    });
  };

  render() {
    const { orderTrailData } = this.props;
    const { viewHistoryPreviewModal, viewHistoryDoc } = this.state;
    return (
      <div className="view-container">
        <div className="view-table-container">
          <div className="view-table">
            <div className="table-container">
              <Table
                className="table-data"
                pagination={false}
                dataSource={orderTrailData}
                columns={this.getColumns()}
                sortDirections={["descend", "ascend"]}
                scroll={{ y: 400 }}
              />
            </div>
          </div>
        </div>
        {viewHistoryPreviewModal && (
          <Modal
            visible={viewHistoryPreviewModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            <PDFViewer docView={viewHistoryDoc} />
          </Modal>
        )}
      </div>
    );
  }
}

export default OrderTrail;
