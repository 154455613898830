import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _toUpper from "lodash/toUpper";
import _isArray from "lodash/isArray";
import jwt_decode from "jwt-decode";
import {
  BROKER_INVESTOR,
  INVESTOR_SHARIA_COMPLIANT_GROUPNAME,
  OBO_USER_GROUP,
} from "./constants";
import { useAtomValue } from "jotai";
import {
  activeOBOInvestorAtom,
  activeBrokerInvestorAtom,
} from "../../store/atoms";

export const getFilteredRow = (
  inputValue,
  dataObj,
  isSecondaryOrder = false
) => {
  return _filter(
    dataObj,
    (data) =>
      _includes(_toUpper(data?.underlying), _toUpper(inputValue)) ||
      (isSecondaryOrder
        ? _includes(_toUpper(data?.productName), _toUpper(inputValue))
        : _includes(_toUpper(data?.growthProductName), _toUpper(inputValue)) ||
          _includes(_toUpper(data?.incomeProductName), _toUpper(inputValue)))
  );
};

/**
 * CurrencyList contains to be used in the Header Menu filters
 */
export const currencyList = [
  {
    value: "AUD",
    text: "AUD",
    disabled: false,
    currencyFilter: "AU",
  },
  {
    value: "CAD",
    text: "CAD",
    disabled: false,
    currencyFilter: "CT",
  },
  {
    value: "CHF",
    text: "CHF",
    disabled: false,
    currencyFilter: "SE",
  },
  {
    value: "EUR",
    text: "EUR",
    disabled: false,
    currencyFilter: ["SM", "AV", "IM", "PL", "SS", "NA", "EU"],
  },
  {
    value: "GBP",
    text: "GBP",
    disabled: false,
    currencyFilter: ["LN", "LI"],
  },
  {
    value: "USD",
    text: "USD",
    disabled: false,
    currencyFilter: ["UN", "UQ", "UR", "UW"],
  },
];

export const productListBySide = {
  INCOME: [
    {
      value: "MaxDiv",
      text: "MaxDiv",
      colour: "RED",
    },
    {
      value: "PureDiv",
      text: "PureDiv",
      colour: "GREEN",
    },
    {
      value: "DivGuard",
      text: "DivGuard",
      colour: "BLUE",
    },
  ],
  GROWTH: [
    {
      value: "MaxGrowth",
      text: "MaxGrowth",
      colour: "BLUE",
    },
    {
      value: "PureGrowth",
      text: "PureGrowth",
      colour: "GREEN",
    },
    {
      value: "GrowthGuard",
      text: "GrowthGuard",
      colour: "RED",
    },
  ],
};

/**
 * ETAType filter which contains details of the Switch as well as the allowed ETAs per the Switch type
 */

export const etaTypeFilter = {
  rgb: {
    key: "rgb",
    displayLabel: "RGB",
    allowedProductName: [
      "PURE_GROWTH",
      "PURE_DIV",
      "GROWTH_GUARD",
      "MAX_DIV",
      "MAX_GROWTH",
      "DIV_GUARD",
    ],
  },
  purple: {
    key: "purple",
    displayLabel: "Purple",
    allowedProductName: ["ULTRA_GROWTH", "ULTRA_GUARD"],
  },
};

/**
 * @method Filters the input list to the return the filterList based on the Currency selected and ETA Type is provided
 * Using a single method to check the Currency as well as ETA Type inorder to reduce looping the same list multiple times
 * @param {Object} list - A list sent by a particular page to filter out the Currency and ETA
 * @param {string} currencyType - Currency value of the Selected Currencies
 * @param {string} currencyFilterKey - The key which determines from which key we need to filter the list for Currency
 * @param {string} etaType - ETA value selected on the header menu
 * @param {string/array} etaFilterKey - The key which determines from which key we need to filter the list based on the ETAType
 * @returns
 */
export const filterCurrencyandEtaList = (
  list,
  currencyType,
  currencyFilterKey,
  etaType,
  etaFilterKey
) => {
  if (!currencyType && !etaType) {
    return list;
  }
  let currencyFilter = "";
  // Check to fetch the supported Currencies for each type
  if (currencyType) {
    const currencyValue = _filter(currencyList, { value: currencyType });
    if (currencyValue) {
      currencyFilter = currencyValue[0].currencyFilter;
    }
  }
  let filteredList = [];
  if (list) {
    filteredList = _filter(list, function (item) {
      if (currencyType && etaType) {
        let isCurrencyValid = "";
        let isETAFilterValid = "";
        // Validating the multiple currency formatting as well
        if (_isArray(currencyFilter)) {
          isCurrencyValid = validateMultipleCurrencyType(
            item,
            currencyFilter,
            currencyFilterKey
          );
        } else {
          isCurrencyValid = validateCurrencyType(
            item,
            currencyFilter,
            currencyFilterKey
          );
        }
        // ETA type needs to two input fields for the filters to be applied on the multiple values
        if (_isArray(etaFilterKey)) {
          isETAFilterValid = validateMultipleETAFilters(
            item,
            etaType,
            etaFilterKey
          );
        } else {
          isETAFilterValid = validateEtaType(item, etaType, etaFilterKey);
        }
        return isCurrencyValid && isETAFilterValid;
      } else if (currencyType && !etaType) {
        if (_isArray(currencyFilter)) {
          return validateMultipleCurrencyType(
            item,
            currencyFilter,
            currencyFilterKey
          );
        } else {
          return validateCurrencyType(item, currencyFilter, currencyFilterKey);
        }
      } else if (!currencyType && etaType) {
        if (_isArray(etaFilterKey)) {
          return validateMultipleETAFilters(item, etaType, etaFilterKey);
        } else {
          return validateEtaType(item, etaType, etaFilterKey);
        }
      }
    });
    return filteredList;
  }
};

/**
 * @method Method to check whether a listitem contains the currency content and returns the listitem if it matches the currency criteria
 * @param {list} listItem A list sent by a particular page to filter out the Currency
 * @param {string} currencyType - Currency value of the Selected Currency
 * @param {string} currencyFilterKey - The key which determines from which key we need to filter the list for Currency
 * @returns the listitem if it matches the currency criteria
 */
export const validateCurrencyType = (
  listItem,
  currencyType,
  currencyFilterKey
) => {
  const listContent = listItem[currencyFilterKey];
  const listCurrency = listContent.split(".").pop();
  if (listCurrency === currencyType) {
    return listItem;
  }
};

/**
 * @method Method to check whether a listitem contains the ETA content and returns the listitem if it matches the ETA criteria
 * @param {list} listItem A list sent by a particular page to filter out the ETA
 * @param {string} etaType - ETA value selected on the header menu
 * @param {string} etaTypeFilterKey -  The key which determines from which key we need to filter the list based on the ETAType
 * @returns the listitem if it matches the ETA criteria
 */

export const validateEtaType = (listItem, etaType, etaTypeFilterKey) => {
  const listContent = listItem[etaTypeFilterKey];
  const allowedETAs = etaTypeFilter[etaType].allowedProductName;
  if (allowedETAs.indexOf(listContent) !== -1) {
    return listItem;
  }
};

/**
 * @method Method to check whether a listitem contains the currency content and returns the listitem if it matches the multiple currency criteria
 * @param {object} listItem A list sent by a particular page to filter out the Currency
 * @param {array} currencyType Currency values of the Selected Currency
 * @param {string} currencyFilterKey The key which determines from which key we need to filter the list for Currency
 * @returns the listitem if it matches the currency criteria
 */
export const validateMultipleCurrencyType = (
  listItem,
  currencyType,
  currencyFilterKey
) => {
  let isCurrencyPresent = false;
  for (let i = 0; i < currencyType.length; i++) {
    if (validateCurrencyType(listItem, currencyType[i], currencyFilterKey)) {
      isCurrencyPresent = true;
      break;
    }
  }
  return isCurrencyPresent;
};

/**
 * @method Method to check whether a listitem contains the ETA content and returns the listitem if it matches the multiple ETA criteria
 * @param {list} listItem A list sent by a particular page to filter out the ETA
 * @param {string} etaType - ETA value selected on the header menu
 * @param {array} etaTypeFilterKey -  The array of keys which determines from which key we need to filter the list based on the ETAType
 * @returns the listitem if it matches the multiple ETA criteria
 */

export const validateMultipleETAFilters = (
  listItem,
  etaType,
  etaTypeFilterKey
) => {
  let isEtaPresent = false;
  for (let i = 0; i < etaTypeFilterKey.length; i++) {
    if (validateEtaType(listItem, etaType, etaTypeFilterKey[i])) {
      isEtaPresent = true;
      break;
    }
  }
  return isEtaPresent;
};

/**
 * @method Method to filter the input Product Name dropdown based on the provided ETA type
 * @param {object} productNames - ProductName object with contains the productName dropdown
 * @param {*} etaType - ETA value selected on the header menu
 * @returns filtered Product dropdown list based on the ETA types
 */
export const filterProductName = (productNames, etaType) => {
  const allowedETAs = etaTypeFilter[etaType].allowedProductName;
  const filteredProductNames = _filter(productNames, function (product) {
    return allowedETAs.indexOf(product.value) !== -1;
  });
  return filteredProductNames;
};

/**
 * @method Method to get the storage data based on the provided key
 * @param {SessionStorage key} key - Key to fetch the storage data
 */
export const getStorageData = (key) => {
  let storageContent = sessionStorage.getItem(key);
  if (storageContent) {
    storageContent = JSON.parse(storageContent);
  }
  return storageContent || "";
};

/**
 * @method Method to set the storage data based on the provided key
 * @param {SessionStorage key} key - Key to fetch the storage data
 * @param {*} storageContent - StorageContent which needs to be saved to the storage
 */
export const setStorageData = (key, storageContent) => {
  console.log(key, storageContent);
  sessionStorage.setItem(key, JSON.stringify(storageContent));
};

/**
 * @method Method to fetch the env variable based on the .env file
 * @returns the env variable based on the .env file
 */
export const getEnv = () => {
  try {
    return process.env?.REACT_APP_ENV ? process.env?.REACT_APP_ENV : "";
  } catch (e) {
    return "";
  }
};

/**
 * @method Method to fetch teh env label based on the env variable
 * @returns returns the env Label based on the env variable
 */
export const getEnvLabel = () => {
  let envLabel = "";
  const env = getEnv();
  if (env === "dev") {
    envLabel = "Dev/Test";
  } else if (env === "staging") {
    envLabel = "Staging";
  }
  return envLabel;
};

export const isReadOnlyStatus = () => {
  const groupName = getGroupName();
  if (groupName.indexOf("prism-otc-trading-readonly") >= 0) {
    return true;
  }
};

export const isOBOUser = (groupName) => {
  let isOBOUser = false;
  if (groupName.indexOf(OBO_USER_GROUP) >= 0) {
    isOBOUser = true;
  }
  return isOBOUser;
};

export const isBrokerInvestor = (groupName) => {
  let isBrokerInvestor = false;
  if (groupName.indexOf(BROKER_INVESTOR) >= 0) {
    isBrokerInvestor = true;
  }
  return isBrokerInvestor;
};

export const getGroupName = () => {
  const UserDetails = getStorageData("UserDetails");
  if (!UserDetails) return "";
  let groupName = "";
  if (UserDetails?.groups) {
    groupName = UserDetails?.groups;
  }
  return groupName;
};

export const getUserId = () => {
  let userDetails = getStorageData("UserDetails");
  let userId = "";
  if (userDetails) {
    userId = userDetails?.investorId;
  }
  return userId;
};

export const getOBOInvestorID = () => {
  let OboStorage = getStorageData("OBOInvestor");
  let investorId = "";
  if (OboStorage) {
    investorId = OboStorage?.investorId;
  }
  return investorId;
};

export const getBrokerInvestorId = () => {
  let broketStorage = getStorageData("brokerInvestor");
  let investorId = "";
  if (broketStorage) {
    investorId = broketStorage?.investorId;
  }
  return investorId;
};

export const useInvestorId = () => {
  const groupName = getGroupName();
  let id = getUserId();
  const activeOBOInvestor = useAtomValue(activeOBOInvestorAtom);
  const activeBrokerInvestor = useAtomValue(activeBrokerInvestorAtom);
  if (activeOBOInvestor && isOBOUser(groupName)) {
    id = activeOBOInvestor?.investorId;
  } else if (activeBrokerInvestor && isBrokerInvestor(groupName)) {
    id = activeBrokerInvestor?.investorId;
  }
  return id;
};

export const getInvestorEmail = () => {
  const UserDetails = getStorageData("UserDetails");
  if (!UserDetails) return "";
  if (UserDetails.groups && UserDetails.groups.includes(OBO_USER_GROUP)) {
    const OBOInvestor = getStorageData("OBOInvestor");
    if (OBOInvestor) {
      return OBOInvestor.email;
    }
  }
  return UserDetails.email;
};

export const isSecondaryMarketAllowed = (groups) => {
  if (groups != undefined) {
    if (
      groups.includes("prism-otc-trading-investor-secondary") ||
      groups.includes("prism-otc-trading-readonly") ||
      groups.includes(OBO_USER_GROUP) ||
      groups.includes(INVESTOR_SHARIA_COMPLIANT_GROUPNAME)
    ) {
      return true;
    }
  }
  return false;
};

export const currencyInputValidation = (currency, value) => {
  const regex = new RegExp(`\\${currency}\\s?|(,*)`, "g");
  return value.replace(regex, "");
};
