import { useState } from "react";
import { fetchLastPrice } from "../../trade/utils";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";
import _isEmpty from "lodash/isEmpty";
import { usePOADivYield } from "../../../hooks/securities";

function DividendYield({
  record,
  lastPriceList,
  underlying,
  productName,
  bidPrice,
}) {
  const { data: poaDivYield, isLoading } = usePOADivYield(
    underlying,
    productName
  );
  const lastPrice = record.lastPrice || record.lastPx;

  if (isLoading) return null;

  const yieldValue = (poaDivYield * (lastPrice / bidPrice)).toFixed(1);

  return <div>{yieldValue ? yieldValue + "%" : "-"} </div>;
}

export default DividendYield;
