import { Radio, Space } from "antd";

export default function OptionSet({
  handleChange,
  name,
  prompt,
  promptClassName,
  options,
  optionClassName,
  renderLabel,
  value,
}) {
  if (!options) return null;

  return (
    <>
      <div
        className={`text-white text-[20px] italic mb-4 mt-4 ${promptClassName}`}
      >
        {prompt}
      </div>
      <Radio.Group
        name={name}
        onChange={handleChange}
        value={value}
        className="w-full"
      >
        <Space direction="vertical" className="w-full">
          {options?.map((option) => {
            if (value && option.value !== value) return null;
            return (
              <Radio.Button
                className={`!bg-[#474C55] !rounded-full !text-white w-full !border-none login-box-shadow ${optionClassName}`}
                value={option.value}
              >
                <div className="flex justify-between">
                  <div>{option.name}</div>
                  {renderLabel?.(option)}
                </div>
              </Radio.Button>
            );
          })}
        </Space>
      </Radio.Group>
    </>
  );
}
