import "./style.scss";
import React, { Component } from "react";
import { intl } from "../../../../utils/intl_i18n";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";
import { validateGiin } from "../../../../components/common/validation";

class FITaxInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  componentDidMount() {
    this.validate();
  }

  onChangeFitaxInformationStatus = (e) => {
    const { payload } = this.state;
    payload.fitaxInformationStatus = e.target.value;
    // Resetting the values in the GIIN field whenever the tax information dropdown value changes
    payload.fitaxInformationGiin = "";
    this.updateState(payload);
  };

  onChangeFatcaStatus = (e) => {
    const { payload } = this.state;
    payload.fatcaStatus = e.target.value;
    // Resetting the values in the Other facta status field whenever the facta dropdown value changes
    payload.otherFatcaStatus = "";
    this.updateState(payload);
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    // If the selects the facta details first and update the GIIN Number,  I have resets the factsstatus and otherFatcaStatus to empty as it is not required
    if (field === "fitaxInformationGiin") {
      payload.fatcaStatus = "";
      payload.otherFatcaStatus = "";
    }
    this.updateState(payload);
  };

  validate = () => {
    const { payload } = this.state;
    console.log(payload);
    if (!payload.fitaxInformationStatus) {
      this.onValidationFail();
      return;
    }
    if (payload.fitaxInformationStatus === "financialInstitution") {
      if (!payload.fitaxInformationGiin) {
        if (payload.fatcaStatus === undefined || !payload.fatcaStatus) {
          this.onValidationFail();
          return;
        } else if (payload.fatcaStatus === "other") {
          if (
            payload.otherFatcaStatus === undefined ||
            !payload.otherFatcaStatus
          ) {
            this.onValidationFail();
            return;
          }
        }
      }
    }

    this.onValidationPass();
  };

  /**
   *
   * @param {string} giinValue - GIIN value
   * @returns {boolean} - Whether we need to disable the Facta Dropdown field or not
   */
  checkGiinState = (giinValue) => {
    if (giinValue === undefined) {
      return false;
    }
    if (giinValue !== "") {
      return true;
    }
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="fiTax-main">
        <div className="fiTax-title">
          {this.formatMessage_i18n("FITaxInformation.title")}
        </div>
        <div className="fiTax-contentTop">
          {this.formatMessage_i18n("FITaxInformation.topContent")}
        </div>
        <div className="tax-status-dropdown-label">
          {this.formatMessage_i18n("FITaxInformation.taxStatusDropdownLabel")}
        </div>
        <div className="tax-status-dropdown">
          <select
            className={
              (_isUndefined(payload.fitaxInformationStatus) ||
                payload.fitaxInformationStatus === "") &&
              "error-field"
            }
            onChange={this.onChangeFitaxInformationStatus}
            value={payload.fitaxInformationStatus}
          >
            <option value="">
              {this.formatMessage_i18n("FITaxInformation.taxStatusDefault")}
            </option>
            <option value="financialInstitution">
              {this.formatMessage_i18n(
                "FITaxInformation.taxStatusDropdownOption1"
              )}
            </option>
            <option value="autralianPublicListedCompany">
              {this.formatMessage_i18n(
                "FITaxInformation.taxStatusDropdownOption2"
              )}
            </option>
            <option value="majorOwnedSubsidiary">
              {this.formatMessage_i18n(
                "FITaxInformation.taxStatusDropdownOption3"
              )}
            </option>
            <option value="activeNonFinancial">
              {this.formatMessage_i18n(
                "FITaxInformation.taxStatusDropdownOption4"
              )}
            </option>
            <option value="other">
              {this.formatMessage_i18n(
                "FITaxInformation.taxStatusDropdownOption5"
              )}
            </option>
          </select>
        </div>
        <div
          className="fiTax-status-values"
          style={{
            display:
              payload.fitaxInformationStatus === null ||
              payload.fitaxInformationStatus !== "financialInstitution"
                ? "none"
                : "block",
          }}
        >
          <div className="giin-label">
            {this.formatMessage_i18n("FITaxInformation.giinLabel")}
          </div>
          <div className="giin-textbox">
            <input
              // Adding the error field check to the giin input field when the fatcaStatus is set empty( or undefined) and giin is empty( or undefined)
              className={cn("giin-input-large", {
                "error-field":
                  (_isUndefined(payload.fatcaStatus) ||
                    payload.fatcaStatus === "") &&
                  (_isUndefined(payload.fitaxInformationGiin) ||
                    payload.fitaxInformationGiin === "" ||
                    !validateGiin(payload.fitaxInformationGiin)),
              })}
              placeholder={this.formatMessage_i18n(
                "FITaxInformation.giinPlaceHolder"
              )}
              value={payload.fitaxInformationGiin}
              onChange={(e) =>
                this.onInputChange("fitaxInformationGiin", e.target.value)
              }
            />
          </div>
          <div className="fatca-status-label">
            {this.formatMessage_i18n("FITaxInformation.fatcaLabel")}
          </div>
          <div className="fatca-status-dropdown">
            <select
              onChange={this.onChangeFatcaStatus}
              value={payload.fatcaStatus}
              disabled={this.checkGiinState(payload.fitaxInformationGiin)}
            >
              <option value="">
                {this.formatMessage_i18n(
                  "FITaxInformation.fatcaDropdownPlaceholder"
                )}
              </option>
              <option value="deemedCompliant">
                {this.formatMessage_i18n(
                  "FITaxInformation.fatcaDropdownOption1"
                )}
              </option>
              <option value="exceptedFinancialInstitution">
                {this.formatMessage_i18n(
                  "FITaxInformation.fatcaDropdownOption2"
                )}
              </option>
              <option value="exemptBeneficialOwner">
                {this.formatMessage_i18n(
                  "FITaxInformation.fatcaDropdownOption3"
                )}
              </option>
              <option value="nonReportingIGA">
                {this.formatMessage_i18n(
                  "FITaxInformation.fatcaDropdownOption4"
                )}
              </option>
              <option value="nonParticipating">
                {this.formatMessage_i18n(
                  "FITaxInformation.fatcaDropdownOption5"
                )}
              </option>
              <option value="other">
                {this.formatMessage_i18n(
                  "FITaxInformation.fatcaDropdownOption6"
                )}
              </option>
            </select>
          </div>
          <div
            className="otherFatcaStatus-textbox"
            style={{
              display: payload.fatcaStatus === "other" ? "block" : "none",
            }}
          >
            <input
              // Adding the error field check to the otherFacta Status field when the fatcaStatus is set to other and value is empty
              className={cn("fatcaStatus-input-large", {
                "error-field":
                  payload.fatcaStatus === "other" &&
                  (_isUndefined(payload.otherFatcaStatus) ||
                    payload.otherFatcaStatus === ""),
              })}
              placeholder={this.formatMessage_i18n(
                "FITaxInformation.otherFatcaStatusText"
              )}
              value={payload.otherFatcaStatus}
              onChange={(e) =>
                this.onInputChange("otherFatcaStatus", e.target.value)
              }
              disabled={this.checkGiinState(payload.fitaxInformationGiin)}
            />
          </div>
          <div className="facta-notification-clause">
            {this.formatMessage_i18n(
              "FITaxInformation.fatcaNotificationClause"
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FITaxInformation;
