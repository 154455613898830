import { useState } from "react";
import { useUserDetail } from "../../hooks/investors";
import { useSecurities } from "../../hooks/securities";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getColumns } from "./productModels";
import Table from "../common/Table";
import { useHistory } from "react-router-dom";
import { getTermFromMaturity } from "./utils";

function Products({ error, isLoading, opportunities, type, searchValue }) {
  const history = useHistory();
  const { data: userDetail } = useUserDetail();

  const handleBid = (asset) => {
    console.log("asset", asset);
    const consideration =
      asset.considerationTypeList.length === 1
        ? `&considerationType=${asset.considerationTypeList[0]}`
        : "";
    const term =
      asset.term && asset.term > 0
        ? asset.term
        : getTermFromMaturity(asset.offerMaturity);
    history.push(
      `/order?ticker=${asset.underlying}&productName=${asset.productName}${consideration}&seriesType=${asset.seriesType}&quantity=${asset.offerQty}&priceDollars=${asset.offerPrice}&term=${term}`,
      {
        search: `?ticker=${asset.underlying}&productName=${asset.productName}${consideration}&seriesType=${asset.seriesType}&quantity=${asset.offerQty}&priceDollars=${asset.offerPrice}&term=${term}`,
      }
    );
  };

  const columns = getColumns(type, handleBid, userDetail);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [sorting, setSorting] = useState([]);

  const data = opportunities;

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading) return "Loading...";

  if (error) return "Error";

  return (
    <div className="pr-4">
      <div className="relative h-[42px] hidden lg:flex">
        <div className="absolute left-4 font-[250] text-[29px] text-white">
          Underlying
        </div>
        <div className="absolute left-[290px] font-[250] text-[29px] text-white">
          Allocation
        </div>
        <div className="absolute left-[960px] font-[250] text-[29px] text-white">
          Bid
        </div>
      </div>
      <Table table={table} />
    </div>
  );
}

export default Products;
