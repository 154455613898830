import { Component } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Input from "antd/es/input";
import message from "antd/es/message";
import Tooltip from "antd/es/tooltip";
import "antd/dist/antd.css";
import "./styles.scss";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import { streetNameMaximumErrorMessage } from "../common/message";

/**
 * @method - Validates whether the streetAddressInput Contains PO text in it and fails it if the value is present
 * @param {string} inputText for which we validates the text
 */
const validatePOInput = (inputText) => {
  if (inputText === "" || inputText === undefined) {
    return;
  }

  const poValidationFields = "pobox";
  let inputArray = inputText.toLowerCase().trim();
  inputArray = inputArray.replace(/[^a-zA-Z]/g, "");
  if (inputArray.includes(poValidationFields)) {
    return false;
  } else {
    return true;
  }
};

class PrismAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
    };
  }

  render() {
    const {
      streetPlaceholder,
      streetValue,
      streetOnChange,
      streetReadOnly,
      streetKeyDown,
      isStreetFieldRequired = true,
      subUrbanPlaceholder,
      subUrbanValue,
      subUrbanOnChange,
      subUrbanReadOnly,
      isSubUrbanFieldRequired = true,
      statePlaceholder,
      stateValue,
      stateOnChange,
      stateReadOnly,
      isStateFieldRequired = true,
      codePlaceholder,
      codeValue,
      codeOnChange,
      codeReadOnly,
      isCodeFieldRequired = true,
      countryPlaceholder,
      countryValue,
      countryOnChange,
      countryReadOnly,
      countryKeyDown,
      isCountryFieldRequired = true,
    } = this.props;

    return (
      <div className="prism-address-container">
        <Tooltip
          placement="rightTop"
          title="A PO Box is not an acceptable address"
          trigger="hover | focus | click"
        >
          <div className={cn("street-input-container")}>
            <Input
              placeholder={streetPlaceholder}
              value={streetValue}
              onChange={(e) => streetOnChange(e.target.value)}
              className={cn(
                "address-input street-input",
                isStreetFieldRequired
                  ? (_isUndefined(streetValue) ||
                      streetValue === "" ||
                      (streetValue &&
                        (streetValue.length < 8 ||
                          streetValue.length > 225 ||
                          !validatePOInput(streetValue)))) &&
                      "error-field"
                  : ""
              )}
              readOnly={streetReadOnly}
              onKeyDown={(e) => {
                if (streetKeyDown) {
                  streetKeyDown(e);
                }
              }}
              onBlur={(e) => {
                const streetContent = e?.target?.value;
                if (
                  isStreetFieldRequired &&
                  streetContent &&
                  streetContent.length > 225
                ) {
                  message.error({
                    content: streetNameMaximumErrorMessage,
                    duration: 3,
                  });
                }
              }}
            />
          </div>
        </Tooltip>
        <div className={cn("suburban-container")}>
          <Input
            placeholder={subUrbanPlaceholder}
            onChange={(e) => subUrbanOnChange(e.target.value)}
            value={subUrbanValue}
            className={cn(
              "address-input suburban-input",
              isSubUrbanFieldRequired
                ? (_isUndefined(subUrbanValue) ||
                    subUrbanValue === "" ||
                    subUrbanValue.length < 4) &&
                    "error-field"
                : ""
            )}
            readOnly={subUrbanReadOnly}
          />
        </div>
        <div className="state-container">
          <CountryDropdown
            value={countryValue}
            defaultOptionLabel={countryPlaceholder}
            onChange={(val) => {
              countryOnChange(val);
              if (val === "Australia") {
                stateOnChange("");
              }
            }}
            className={cn(
              "address-input country-input",
              isCodeFieldRequired
                ? (_isUndefined(countryValue) || countryValue === "") &&
                    "error-field"
                : ""
            )}
            disabled={countryReadOnly}
            onKeyDown={(e) => {
              if (countryKeyDown) {
                countryKeyDown(e);
              }
            }}
          />
          {countryValue === "Australia" ? (
            <RegionDropdown
              className={cn(
                "address-input state-input",
                isStateFieldRequired
                  ? (_isUndefined(stateValue) || stateValue === "") &&
                      "error-field"
                  : ""
              )}
              country="Australia"
              blankOptionLabel={statePlaceholder}
              defaultOptionLabel={statePlaceholder}
              onChange={(val) => stateOnChange(val)}
              value={stateValue}
              disabled={stateReadOnly}
            />
          ) : (
            <Input
              placeholder={statePlaceholder}
              onChange={(e) => stateOnChange(e.target.value)}
              value={stateValue}
              className={cn(
                "address-input state-input",
                isCountryFieldRequired
                  ? (_isUndefined(stateValue) || stateValue === "") &&
                      "error-field"
                  : ""
              )}
              readOnly={stateReadOnly}
            />
          )}
          <Input
            placeholder={codePlaceholder}
            onChange={(e) => codeOnChange(e.target.value)}
            value={codeValue}
            className={cn(
              "address-input code-input",
              isCodeFieldRequired
                ? (_isUndefined(codeValue) ||
                    codeValue === "" ||
                    codeValue.length < 4) &&
                    "error-field"
                : ""
            )}
            readOnly={codeReadOnly}
          />
        </div>
      </div>
    );
  }
}

export { PrismAddress, validatePOInput };
