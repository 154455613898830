import { Button } from "antd";
import { useAtom } from "jotai";
import { activeOBOInvestorAtom } from "../../store/atoms";
import { getGroupName, isOBOUser } from "./utils";

function OBOMenu({ triggerOBOCallback }) {
  const [activeOBOInvestor, setActiveOBOInvestor] = useAtom(
    activeOBOInvestorAtom
  );
  const groupName = getGroupName();
  const isOBOUserValue = groupName ? isOBOUser(groupName) : false;
  if (!isOBOUserValue) return null;
  if (!activeOBOInvestor) {
    return (
      <button
        className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 whitespace-nowrap"
        onClick={triggerOBOCallback}
      >
        Activate OBO
      </button>
    );
  }
  return (
    <div
      className="cursor-pointer text-gray-900 pl-2 flex items-baseline gap-x-1"
      onClick={triggerOBOCallback}
    >
      <div className="font-bold text-[10px]">OBO</div>
      <div className="whitespace-nowrap">{activeOBOInvestor?.name}</div>
    </div>
  );
}

export default OBOMenu;
