import "./App.css";
import Routers from "./routes";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        console.error(error);
        if (error.response && error.response.status === 401) {
          window.location = "/login";
        }
      },
      retry: (failureCount, error) => {
        if (error.response?.status === 401) {
          return false;
        }
        return failureCount < 2;
      },
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider messages={{}} locale="en" defaultLocale="en">
        <Routers />
      </IntlProvider>
    </QueryClientProvider>
  );
}

export default App;
