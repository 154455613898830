import "./style.scss";
import React, { Component } from "react";
import { intl } from "../../../../utils/intl_i18n";
import _isUndefined from "lodash/isUndefined";
import { CountryDropdown } from "react-country-region-selector";
import cn from "classnames";
import Acn from "../../../acn";
import { Input } from "antd";

class AusRegulatedTrustIdentify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      errors: "",
      isCompanyFieldEditable: false,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  componentDidMount() {
    const { payload } = this.state;
    if (payload && !payload.country) {
      this.handleCountryChange("Australia");
    }
    this.validate();
  }

  onChangeTypeOfRegulatedTrust = (e) => {
    const { payload } = this.state;
    payload.typeOfRegulatedTrust = e.target.value;
    payload.selfManagedABN = undefined;
    payload.registeredARSN = undefined;
    payload.unregisteredABN = undefined;
    payload.legislationName = undefined;
    payload.regulatorName = undefined;
    payload.otherABN = undefined;
    payload.licenseDetails = undefined;
    payload.companyName = undefined; // Using single company Name field for all the ABN/ARSN fields when the RegulatedTrustType Changes, this value will be resets on every type change
    this.setState({ errors: "" }); // Using single error field for all the ABN/ARSN fields when the RegulatedTrustType Changes, this value will be resets on every type change
    this.updateState(payload);
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    if (
      field === "selfManagedABN" ||
      field === "registeredARSN" ||
      field === "unregisteredABN"
    ) {
      const res = value.replace(/\D/g, "");
      payload[field] = res;
    } else {
      payload[field] = value;
    }
    this.updateState(payload);
  };

  validate = () => {
    const { payload } = this.state;
    console.log(payload);

    if (
      _isUndefined(payload.trustFullName) ||
      _isUndefined(payload.typeOfRegulatedTrust)
    ) {
      this.onValidationFail();
      return;
    }
    if (
      !_isUndefined(payload.trustFullName) &&
      payload.trustFullName.length < 3
    ) {
      this.onValidationFail();
      return;
    }

    if (
      !_isUndefined(payload.typeOfRegulatedTrust) &&
      payload.typeOfRegulatedTrust === ""
    ) {
      this.onValidationFail();
      return;
    }

    if (payload.typeOfRegulatedTrust) {
      if (payload.typeOfRegulatedTrust === "selfManagedFund") {
        if (
          _isUndefined(payload.selfManagedABN) ||
          !payload.selfManagedABN ||
          _isUndefined(payload.companyName) ||
          !payload.companyName
        ) {
          this.onValidationFail();
          return;
        }
      } else if (
        payload.typeOfRegulatedTrust === "registeredManagedInvestScheme"
      ) {
        if (
          _isUndefined(payload.registeredARSN) ||
          !payload.registeredARSN ||
          _isUndefined(payload.companyName) ||
          !payload.companyName
        ) {
          this.onValidationFail();
          return;
        }
      } else if (
        payload.typeOfRegulatedTrust === "unregisteredManagedInvestScheme"
      ) {
        if (
          _isUndefined(payload.unregisteredABN) ||
          !payload.unregisteredABN ||
          _isUndefined(payload.companyName) ||
          !payload.companyName
        ) {
          this.onValidationFail();
          return;
        }
      } else if (payload.typeOfRegulatedTrust === "govSuperannunationFund") {
        if (
          _isUndefined(payload.legislationName) ||
          payload.legislationName.length < 5
        ) {
          this.onValidationFail();
          return;
        }
      } else if (payload.typeOfRegulatedTrust === "otherTrust") {
        if (
          _isUndefined(payload.regulatorName) ||
          payload.regulatorName.length < 3
        ) {
          this.onValidationFail();
          return;
        }
        // Either licenseDetails should be present or OtherABN and CompanyName details to be present
        if (_isUndefined(payload.licenseDetails) || !payload.licenseDetails) {
          if (
            _isUndefined(payload.otherABN) ||
            !payload.otherABN ||
            _isUndefined(payload.companyName) ||
            !payload.companyName
          ) {
            this.onValidationFail();
            return;
          }
        }
      }
    }

    if (_isUndefined(payload.country) || payload.country === "") {
      this.onValidationFail();
      return;
    }

    // Added Validation for the ACN number field when it returns error
    if (this.errors?.abnAcn) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };

  handleCountryChange = (value) => {
    const { payload } = this.state;
    payload.country = value;
    this.updateState(payload);
  };

  /**
   * @method Method to validate the CompanyName to be editable based on the value from the ACN lookup API
   * @param {boolean} value - Value to update if the CompanyField is editable
   */
  validateCompanyNameField = (value) => {
    this.updateState({
      isCompanyFieldEditable: value,
    });
  };

  /**
   * @method Method to handle the Input event from the CompanyName field isCompanyFieldEditable is set to true
   * @param {string} fieldKey - Key to handle the fieldKey in the Payload
   * @param {string} value - value to be updated after the field change
   */
  handleCompanyNameChange = (fieldKey, value) => {
    const { isCompanyFieldEditable } = this.state;
    if (!isCompanyFieldEditable) {
      return;
    }
    this.onInputChange(fieldKey, value);
  };

  /**
   * @method Method to update the companyName field from the ACN/ABN/ARSN API lookup calls
   * @param {string} value - value to be updated after the field change
   */
  companyNamefromACNField = (value) => {
    const { payload } = this.state;
    if (value) {
      payload.companyName = value;
      this.updateState(payload);
    }
  };

  /**
   * @method Method to update the SelfABN field
   * @param {string} value - value to be updated after the field change
   */
  updateSelfABNfield = (value) => {
    this.updateACNInputField("selfManagedABN", value);
  };

  /**
   * @method Method to update the ABNerror field. Using single error field for all the ABN fields in this page
   * @param {string} value - value to be updated after the field change
   */
  abnErrors = (value) => {
    const { payload } = this.state;
    payload.errors = value;
    this.updateState(payload);
  };

  /**
   * @method Method to handle all the ABN/ACN/ARSN input field
   * @param {string} payloadkey - Key to handle the fieldKey in the Payload
   * @param {string} value - value to be updated after the field change
   */
  updateACNInputField = (payloadkey, value) => {
    const { payload } = this.state;
    payload[payloadkey] = value;
    this.updateState(payload);
  };

  /**
   * @method Method to update the RegisteredARSN field
   * @param {string} value - value to be updated after the field change
   */
  updateRegisteredARSNfield = (value) => {
    this.updateACNInputField("registeredARSN", value);
  };

  /**
   * @method Method to update the UNRegisteredABN field
   * @param {string} value - value to be updated after the field change
   */
  updateUnRegisteredABNfield = (value) => {
    this.updateACNInputField("unregisteredABN", value);
  };

  /**
   * @method Method to update the otherABN field
   * @param {string} value - value to be updated after the field change
   */
  updateOtherABNfield = (value) => {
    this.updateACNInputField("otherABN", value);
  };

  /**
   *
   * @param {objectparam} acnPayloadField - Payload field of a ACN/ABN/ARSN field used in all types of trust
   * @param {objectparam} companyField - Payload field of a CompanyName field used in all types - As of now used single one, can be changed using this field in future if needed
   * @param {string} placeHolderText - PlaceHolder text of a particular ABN/ACN/ARSN input field
   * @param {function} payloadUpdateCallback - Callback method to handle the ABN/ACN/ARSN change event
   * @param {boolean} skipErrorClassValidation - Flag to handle error class handling, Like for otherTrust type either License Details or ABN value should be present
   * @returns ACN/ABN/ARSN html template
   */
  renderACNField = (
    acnPayloadField,
    companyField,
    placeHolderText,
    payloadUpdateCallback,
    skipErrorClassValidation = false
  ) => {
    return (
      <>
        <Acn
          className={cn(
            "register-input-large",
            !skipErrorClassValidation &&
              (_isUndefined(acnPayloadField) ||
                acnPayloadField === "" ||
                this.errors?.abnAcn) &&
              "error-field"
          )}
          companyName={this.companyNamefromACNField}
          abnErrors={this.abnErrors}
          abnNumber={payloadUpdateCallback}
          value={acnPayloadField}
          validateCompanyNameField={this.validateCompanyNameField}
          placeHolderText={placeHolderText}
        />
        <Input
          className={cn(
            "register-input-large company-name",
            !skipErrorClassValidation &&
              (_isUndefined(companyField) || companyField === "") &&
              "error-field"
          )}
          placeholder={this.formatMessage_i18n("company.name")}
          onChange={(e) =>
            this.handleCompanyNameChange("companyName", e.target.value)
          }
          value={companyField}
          readOnly={!this.state.isCompanyFieldEditable}
        />
      </>
    );
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="Regulated-Trust-Main">
        <div className="regulatedTrust-title">
          {this.formatMessage_i18n("RegulatedTrust.identity.title")}
        </div>
        <div className="main">
          <Input
            className={cn("register-input-large", {
              "error-field":
                _isUndefined(payload.trustFullName) ||
                payload.trustFullName === "" ||
                payload.trustFullName < 3,
            })}
            placeholder={this.formatMessage_i18n(
              "RegulatedTrust.FullName.trust"
            )}
            onChange={(e) =>
              this.onInputChange("trustFullName", e.target.value)
            }
            value={payload.trustFullName}
          />
        </div>
        <div className="main">
          <Input
            className={cn("register-input-large")}
            placeholder={this.formatMessage_i18n(
              "RegulatedTrust.FullName.trustee"
            )}
            onChange={(e) =>
              this.onInputChange("trusteeFullName", e.target.value)
            }
            value={payload.trusteeFullName}
          />
        </div>
        <div className="country-container">
          <CountryDropdown
            className={cn(
              "register-input-large",
              (_isUndefined(payload.country) || payload.country === "") &&
                "error-field"
            )}
            value={payload.country}
            defaultOptionLabel="Country"
            onChange={(val) => {
              this.handleCountryChange(val);
            }}
          />
        </div>
        <div className="regulated-trust-dropdown">
          <div className="registered-trust-label">
            {this.formatMessage_i18n("regulated-trust-types")}
          </div>
          <select
            className={cn({
              "error-field":
                _isUndefined(payload.typeOfRegulatedTrust) ||
                payload.typeOfRegulatedTrust === "",
            })}
            onChange={this.onChangeTypeOfRegulatedTrust}
            value={payload.typeOfRegulatedTrust}
          >
            <option value="">
              {this.formatMessage_i18n(
                "RegulatedTrust.typeOfRegulatedTrust.default"
              )}
            </option>
            <option value="selfManagedFund">
              {this.formatMessage_i18n(
                "RegulatedTrust.typeOfRegulatedTrust.option1"
              )}
            </option>
            <option value="registeredManagedInvestScheme">
              {this.formatMessage_i18n(
                "RegulatedTrust.typeOfRegulatedTrust.option2"
              )}
            </option>
            <option value="unregisteredManagedInvestScheme">
              {this.formatMessage_i18n(
                "RegulatedTrust.typeOfRegulatedTrust.option3"
              )}
            </option>
            <option value="govSuperannunationFund">
              {this.formatMessage_i18n(
                "RegulatedTrust.typeOfRegulatedTrust.option4"
              )}
            </option>
            <option value="otherTrust">
              {this.formatMessage_i18n(
                "RegulatedTrust.typeOfRegulatedTrust.option5"
              )}
            </option>
          </select>
        </div>

        <div
          className="textbox"
          style={{ display: !payload.typeOfRegulatedTrust ? "block" : "none" }}
        >
          <div className="registered-trust-label">
            {this.formatMessage_i18n("regulated-trust-description")}
          </div>
        </div>
        <div
          className="textbox"
          style={{ display: payload.typeOfRegulatedTrust ? "block" : "none" }}
        >
          {payload.typeOfRegulatedTrust === "selfManagedFund" && (
            <div>
              <div className="registered-trust-label">
                {this.formatMessage_i18n(
                  "Regulated.trust.self.Managed.superfund.ABN"
                )}
              </div>
              {this.renderACNField(
                payload.selfManagedABN,
                payload.companyName,
                this.formatMessage_i18n("abn.Number"),
                this.updateSelfABNfield
              )}
            </div>
          )}

          {payload.typeOfRegulatedTrust === "otherTrust" && (
            <div>
              <div className="registered-trust-label">
                {this.formatMessage_i18n("RegulatedTrust.otherTrust.label")}
              </div>
              <div className="textbox">
                <input
                  className={cn("register-input-large", {
                    "error-field":
                      (_isUndefined(payload.otherTrustType) ||
                        payload.otherTrustType === "") &&
                      (_isUndefined(payload.otherTrustType) ||
                        payload.otherTrustType === ""),
                  })}
                  placeholder={this.formatMessage_i18n(
                    "RegulatedTrust.otherTrust.label"
                  )}
                  value={payload.otherTrustType}
                  onChange={(e) =>
                    this.onInputChange("otherTrustType", e.target.value)
                  }
                />
              </div>
            </div>
          )}

          {payload.typeOfRegulatedTrust === "registeredManagedInvestScheme" && (
            <div>
              <div className="registered-trust-label">
                {this.formatMessage_i18n(
                  "Regulated.trust.Managed.Investment.ARSN"
                )}
              </div>
              {this.renderACNField(
                payload.registeredARSN,
                payload.companyName,
                this.formatMessage_i18n("RegulatedTrust.registered.ARSN.text"),
                this.updateRegisteredARSNfield
              )}
            </div>
          )}

          {payload.typeOfRegulatedTrust ===
            "unregisteredManagedInvestScheme" && (
            <div>
              <div className="registered-trust-label">
                {this.formatMessage_i18n(
                  "Regulated.trust.Unmanaged.Investment.ABN"
                )}
              </div>
              {this.renderACNField(
                payload.unregisteredABN,
                payload.companyName,
                this.formatMessage_i18n("abn.Number"),
                this.updateUnRegisteredABNfield
              )}
            </div>
          )}

          {payload.typeOfRegulatedTrust === "govSuperannunationFund" && (
            <div>
              <div className="registered-trust-label">
                {this.formatMessage_i18n("Regulated.trust.Legislation.Name")}
              </div>
              <div className="textbox">
                <input
                  className={cn("register-input-large", {
                    "error-field":
                      (_isUndefined(payload.legislationName) ||
                        payload.legislationName === "" ||
                        payload.legislationName < 5) &&
                      (_isUndefined(payload.legislationName) ||
                        payload.legislationName === "" ||
                        payload.legislationName < 5),
                  })}
                  placeholder={this.formatMessage_i18n(
                    "RegulatedTrust.legislation.Name.text"
                  )}
                  value={payload.legislationName}
                  onChange={(e) =>
                    this.onInputChange("legislationName", e.target.value)
                  }
                />
              </div>
            </div>
          )}

          {payload.typeOfRegulatedTrust === "otherTrust" && (
            <div>
              <div className="registered-trust-label">
                {this.formatMessage_i18n(
                  "Regulated.trust.other.regulator.Name"
                )}
              </div>
              <div className="textbox">
                <input
                  className={cn("register-input-large", {
                    "error-field":
                      (_isUndefined(payload.regulatorName) ||
                        payload.regulatorName === "" ||
                        payload.regulatorName.length < 3) &&
                      (_isUndefined(payload.regulatorName) ||
                        payload.regulatorName === "" ||
                        payload.regulatorName.length < 3),
                  })}
                  placeholder={this.formatMessage_i18n(
                    "RegulatedTrust.regulator.Name.text"
                  )}
                  value={payload.regulatorName}
                  onChange={(e) =>
                    this.onInputChange("regulatorName", e.target.value)
                  }
                />
              </div>

              <div className="registered-trust-label">
                {this.formatMessage_i18n("Regulated.trust.ABN.license.Name")}
              </div>
              {this.renderACNField(
                payload.otherABN,
                payload.companyName,
                this.formatMessage_i18n("abn.Number"),
                this.updateOtherABNfield,
                payload.licenseDetails !== "" &&
                  typeof payload.licenseDetails !== "undefined"
              )}
              <div className="textbox">
                <input
                  className={cn("register-input-large", {
                    "error-field":
                      (_isUndefined(payload.licenseDetails) ||
                        payload.licenseDetails === "") &&
                      (payload.companyName === "" ||
                        typeof payload.companyName === "undefined") &&
                      (payload.otherABN === "" ||
                        typeof payload.otherABN === "undefined"),
                  })}
                  placeholder={this.formatMessage_i18n(
                    "RegulatedTrust.License.text"
                  )}
                  value={payload.licenseDetails}
                  onChange={(e) =>
                    this.onInputChange("licenseDetails", e.target.value)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AusRegulatedTrustIdentify;
