import { useState, Fragment } from "react";
import cn from "classnames";
import Select from "antd/es/select";
import { activeCurrencyAtom } from "../../../store/atoms";
import { useAtom } from "jotai";
import { currencyList } from "../utils";
import { Combobox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

const { Option } = Select;

export default function CurrencyFilter() {
  const [query, setQuery] = useState("");
  const [currency, setCurrency] = useAtom(activeCurrencyAtom);

  const updateCurrencyValue = (value) => {
    setCurrency(value);
  };

  const filteredCurrencyList =
    query === ""
      ? currencyList
      : currencyList.filter((currency) => {
          return currency.value.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className="w-[135px] relative">
      <Combobox value={currency} onChange={updateCurrencyValue}>
        <div className="relative">
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            className="flex items-center gap-x-1 text-white font-light text-[16px] leading-6 dark-menu-item pl-4 pr-14 h-[29px] w-[135px]"
            placeholder="Currency"
          />
          {currency && (
            <button
              type="button"
              className="absolute inset-y-0 right-11 -m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={() => updateCurrencyValue("")}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </button>
          )}
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={37}
              height={29}
              fill="none"
            >
              <path
                fill="url(#z)"
                d="M0 0h22.5C30.508 0 37 6.492 37 14.5S30.508 29 22.5 29H0V0Z"
              />
              <path
                fill="#474C55"
                fillOpacity={0.7}
                d="M0 0h22.5C30.508 0 37 6.492 37 14.5S30.508 29 22.5 29H0V0Z"
              />
              <path
                stroke="#C0C1C6"
                strokeOpacity={0.3}
                strokeWidth={0.5}
                d="M.25.25H22.5c7.87 0 14.25 6.38 14.25 14.25S30.37 28.75 22.5 28.75H.25V.25Z"
              />
              <path fill="#343741" d="M24 10H10l7 11 7-11Z" />
              <defs>
                <linearGradient
                  id="z"
                  x1={0}
                  x2={28.16}
                  y1={0}
                  y2={35.928}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B6B7BC" />
                  <stop offset={1} stopColor="#CACBD0" />
                </linearGradient>
              </defs>
            </svg>
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="z-10 absolute w-full mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCurrencyList.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredCurrencyList.map((option) => (
                <Combobox.Option
                  key={option.value}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={option.value}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.text}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
}
