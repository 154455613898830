import React, { Component } from "react";
import FormWizard from "../form-wizard";
import "./style.scss";
import "antd/dist/antd.css";
import { intl } from "../../utils/intl_i18n";
import PageContent from "../common/PageContent";
import InvestorEntity from "./screens/investorEntity";
import AustralianCompany from "./screens/AustralianCompany";
import ProprietaryDirectors from "./screens/ProprietaryDirectors";
import FITaxInformation from "./screens/financialInstitutionTaxInformation";
import ListingAndRegulatory from "./screens/ListingRegulatory";
import BeneficialOwnership from "./screens/BeneficialOwnership";
import ChessDetails from "./screens/chessDetails";
import SettlementInstructions from "./screens/settlementInstructions";
import CountryOfTaxResidency from "./screens/countryOfTaxResidency";
import { Triangle } from "react-loader-spinner";
import axiosConfig from "../../axiosConfig";
import VerificationProcedureOther from "./screens/verificationProcedureOther";
import VerificationProcedureASIC from "./screens/verificationProcedureASIC";
import VerificationProcedureMarketExchange from "./screens/VerificationProcedureMarketExchange";
import VerificationProcedureAsicOther from "./screens/VerificationProcedureAsicOther";
import SubscriptionOptions from "./screens/subscription";
import NumberOfInvestors from "./screens/NumberOfInvestors";
import NationalCreditCode from "./screens/NationalCreditCode";
import PersonalDetails from "./screens/PersonalDetails";
import IndividualVerificationProcedure from "./screens/IndividualVerificationProcedure";
import BeneficialOwnerFactaDetails from "./screens/BeneficialOwnerFACTADetails";
import TrusteeIdentification from "./screens/TrusteeIdentification";
import AustralianCompanySequence from "./sequences/australianCompanySequence";
import IndividualSoloTraderSequence from "./sequences/individualSoloTraderSequence";
import ROUTES from "../../routes/api.json";
import AusRegulatedTrustIdentify from "./screens/AustralianRegulatedTrustIdentification";
import AustralianRegisteredTrustSequence from "./sequences/australianRegisteredTrustSequence";
import RTTaxInformation from "./screens/RegulatedTrustTaxInformation";
import ForeignCorporateDetails from "./screens/ForeignCorporateDetails";
import TrustVerificationProcedure from "./screens/TrustVerificationProcedure";
import CorporateListingAndRegulatory from "./screens/CorporateListingAndRegulatory";
import ForeignCorporateIdentification from "./screens/ForeignCorporateIdentification";
import InvestorEvidence from "./screens/investorEvidence";
import UnregulatedForeignTrust from "./screens/unregulatedForeignTrust";
import UnregulatedForeignTrustSequence from "./sequences/unregulatedForeignTrustSequence";
import UnregulatedTrustBeneficiary from "./screens/unregulatedTrustBeneficiary";
import UnregulatedTrustTrustee from "./screens/unregulatedTrustTrustee";
import UnRegulatedFinancialTaxInformation from "./screens/UnRegulatedFinancialTaxInformation";
import UnRegulatedTrustBeneficialTaxInformation from "./screens/UnRegulatedTrustBeneficialTaxInformation";
import UnRegulatedVerificationProcedure from "./screens/UnRegulatedVerificationProcodure";

const JSEncrypt = require("nodejs-jsencrypt").default;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sequence: null,
      isLoading: false,
      isUpdated: false,
      publicKey: "",
      sequences: this.getSequences(),
      defaultPayload: {
        currentComponent: {
          key: "investorEntity",
          next: "Begin",
          prev: null,
          includePayloadCallback: true,
        },
        payload: {},
      },
    };
  }

  componentDidMount = () => {
    console.log("Register componentDidMount");
    let _this = this;
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // TODO should this be moved into retrieveUserRegistrationDetails() ?
    this.setState({ isUpdated: false });
    axiosConfig
      .get(`${ROUTES.STORAGE_URL}`, config)
      .then((res) => {
        // don't overwrite the structure in defaultPayload if this is the first time
        // we've called and the storage returns an empty string
        if (res.data) {
          if (res.data !== "") {
            console.log("Setting state: " + JSON.stringify(res.data));
            //Added when there is no current component but have payloads (admin uploads)
            if (!res.data.currentComponent && res.data.payload) {
              res.data.currentComponent =
                this.state.defaultPayload.currentComponent;
            }
            _this.setState({ defaultPayload: res.data });
          } else {
            console.log("No stored progress retrieved from server.");
          }
        }
      })
      .catch((error) => {
        console.log("Server response: " + error);
      })
      .finally(() => {
        _this.setState({ isUpdated: true });
      });
    this.getPublicKey();
  };

  /**
   * ComponentMap construction
   */
  getComponentMap = () => {
    return {
      investorEntity: {
        FormComponent: InvestorEntity,
        title: "Investor Entity",
        payloadField: "investorEntity",
      },
      australianCompany: {
        FormComponent: AustralianCompany,
        title: "Australian Company",
        payloadField: "australianCompany",
      },
      foreignCompany: {
        FormComponent: AustralianCompany, // Need to replace with the foreign company component
        title: "Australian Company",
        payloadField: "australianCompany",
      },
      registeredTrust: {
        FormComponent: AustralianCompany, // Need to replace with the registerd trust component
        title: "Australian Company",
        payloadField: "australianCompany",
      },
      unRegisteredTrust: {
        FormComponent: AustralianCompany, // Need to replace with the unregistered trust component
        title: "Australian Company",
        payloadField: "australianCompany",
      },
      partnership: {
        FormComponent: AustralianCompany, // Need to replace with the partneship component
        title: "Australian Company",
        payloadField: "australianCompany",
      },
      individualOrSoleTrader: {
        FormComponent: AustralianCompany, // Need to replace with the individual or soletrader component
        title: "Australian Company",
        payloadField: "australianCompany",
      },
      proprietaryDirectors: {
        FormComponent: ProprietaryDirectors,
        title: "Proprietary Company Directors",
        payloadField: "proprietaryDirectors",
      },
      listingRegulatory: {
        FormComponent: ListingAndRegulatory,
        title: "Proprietary Company Directors",
        payloadField: "listingRegulatory",
      },
      beneficialOwnership: {
        FormComponent: BeneficialOwnership,
        title: "Beneficial Ownership",
        payloadField: "beneficialOwnership",
      },
      financialTaxInformation: {
        FormComponent: FITaxInformation,
        title: "Financial Institution Tax Information",
        payloadField: "fiTaxInformation",
      },
      countryOfTaxResidency: {
        FormComponent: CountryOfTaxResidency,
        title: "Country of Tax Residency",
        payloadField: "CountryOfTaxResidency",
      },
      settlementInstructions: {
        FormComponent: SettlementInstructions,
        title: "Settlement Instructions",
        payloadField: "settlementInstructions",
      },
      chessDetails: {
        FormComponent: ChessDetails,
        title: "Chess Details",
        payloadField: "chessDetails",
      },
      verificationProcedureASIC: {
        FormComponent: VerificationProcedureASIC,
        title: "Company Verification Procedure",
        payloadField: "verificationProcedureASIC",
      },
      verificationProcedureMarketExchange: {
        FormComponent: VerificationProcedureMarketExchange,
        title: "Company Verification Procedure",
        payloadField: "verificationProcedureMarketExchange",
      },
      verificationProcedureOther: {
        FormComponent: VerificationProcedureOther,
        title: "Company Verification Procedure",
        payloadField: "verificationProcedureOther",
      },
      verificationProcedureAsicOther: {
        FormComponent: VerificationProcedureAsicOther,
        title: "Company Verification Procedure",
        payloadField: "verificationProcedureAsicOther",
      },
      subscriptionOptions: {
        FormComponent: SubscriptionOptions,
        title: "Subscription Option",
        payloadField: "subscriptionOptions",
      },
      numberOfInvestors: {
        FormComponent: NumberOfInvestors,
        title: "Individual or Sole Trader Registration",
        payloadField: "numberOfInvestors",
      },
      personalDetails: {
        FormComponent: PersonalDetails,
        title: "Personal Details",
        payloadField: "user",
      },
      nationalCreditCode: {
        FormComponent: NationalCreditCode,
        title: "Individual or Sole Trader Registration",
        payloadField: "nationalCreditCode",
      },
      individualVerificationProcedure: {
        FormComponent: IndividualVerificationProcedure,
        title: "Individual or Sole Trader Registration",
        payloadField: "individualVerificationProcedure",
      },
      beneficialOwnerFactaDetails: {
        FormComponent: BeneficialOwnerFactaDetails,
        title: "Beneficial Owner FATCA Details",
        payloadField: "beneficialOwnership",
      },
      australianRegulatedTrustIdentification: {
        FormComponent: AusRegulatedTrustIdentify,
        title: "Australian Regulated Trust Identification",
        payloadField: "australianRegTrustIdentity",
      },
      trusteeIdentification: {
        FormComponent: TrusteeIdentification,
        title: "Trustee Identification",
        payloadField: "trusteeIdentification",
      },
      regulatedTrustTaxInformation: {
        FormComponent: RTTaxInformation,
        title: "Regulated Trust Tax Information",
        payloadField: "regulatedTrustTaxInformation",
      },
      foreignCorporateDetails: {
        FormComponent: ForeignCorporateDetails,
        title: "Foreign Corporate Details",
        payloadField: "foreignCorporateDetails",
      },
      trustVerificationProcedure: {
        FormComponent: TrustVerificationProcedure,
        title: "Trust Verification Procedure",
        payloadField: "trustVerificationProcedure",
      },
      corporateListingAndRegulatory: {
        FormComponent: CorporateListingAndRegulatory,
        title: "Foreign Corporate Listing and Regulatory Details",
        payloadField: "corporateListingAndRegulatory",
      },
      foreignCorporateIdentification: {
        FormComponent: ForeignCorporateIdentification,
        title: "Foreign Corporate Identification",
        payloadField: "foreignCorporateIdentification",
      },
      investorEvidence: {
        FormComponent: InvestorEvidence,
        title: "Sophisticated or Professional Investor evidence",
        payloadField: "investorEvidence",
      },
      unRegulatedTrust: {
        FormComponent: UnregulatedForeignTrust,
        title: "Unregulated and Foreign Trust",
        payloadField: "unRegulatedTrust",
      },
      unregulatedTrustBeneficiary: {
        FormComponent: UnregulatedTrustBeneficiary,
        title: "Unregulated Trust Beneficiary Details",
        payloadField: "unregulatedTrustBeneficiary",
      },
      unregulatedTrustTrustee: {
        FormComponent: UnregulatedTrustTrustee,
        title: "Unregulated Trust Trustee",
        payloadField: "unregulatedTrustTrustee",
      },
      unRegulatedTaxInformation: {
        FormComponent: UnRegulatedFinancialTaxInformation,
        title: "Unegulated Tax Information",
        payloadField: "unregulatedTrustTaxInformation",
      },
      unRegulatedTrustBeneficialTaxInformation: {
        FormComponent: UnRegulatedTrustBeneficialTaxInformation,
        title: "Unegulated Beneficial Tax Information",
        payloadField: "unregulatedTrustBeneficialTaxInformation",
      },
      unRegulatedVerificationProcedure: {
        FormComponent: UnRegulatedVerificationProcedure,
        title: "Unregulated Trust Verification Procedure",
        payloadField: "unRegulatedVerificationProcedure",
      },
    };
  };

  getSequences = () => {
    let sequences = {};
    sequences["australianCompany"] = new AustralianCompanySequence(this);
    sequences["individualOrSoleTrader"] = new IndividualSoloTraderSequence(
      this
    );
    sequences["registeredTrust"] = new AustralianRegisteredTrustSequence(this);
    sequences["unRegulatedTrust"] = new UnregulatedForeignTrustSequence(this);
    return sequences;
  };

  getPublicKey = () => {
    let _this = this;
    axiosConfig
      .get(`${ROUTES.GET_CRYPTO_KEY}`)
      .then((res) => {
        if (res.data) {
          if (res.data !== "") {
            const { sequences } = this.state;
            this.setState({ publicKey: res.data });
          } else {
            console.log("No stored progress retrieved from server.");
          }
        }
      })
      .catch((error) => {
        console.log("Server response: " + error);
      });
  };

  /*
   * Handle on previous action in the form wizard
   */
  onPrev = (currentComponent, payload, payloadField) => {
    if (!payload.investorEntity || !payload.investorEntity.value)
      return currentComponent;

    let data = {
      currentComponent,
      payload,
    };
    this.saveRegistrationDetails(data);
    const { sequences } = this.state;
    if (sequences[payload.investorEntity.value]) {
      return sequences[payload.investorEntity.value].onPrev(
        currentComponent,
        payload,
        payloadField
      );
    }
    return currentComponent;
  };

  /*
   * Handle on next action in the form wizard
   */
  onNext = (currentComponent, payload, payloadField) => {
    if (!payload.investorEntity || !payload.investorEntity.value)
      return currentComponent;
    let data = {
      currentComponent,
      payload,
    };
    const { sequences } = this.state;
    if (sequences[payload.investorEntity.value]) {
      if (currentComponent.key === "countryOfTaxResidency") {
        if (data && data.payload && data.payload["numberOfInvestors"]) {
          const index = data.payload["numberOfInvestors"].currentIndex;
          let taxFileNumber =
            data.payload["numberOfInvestors"].details[index]
              .CountryOfTaxResidency.taxFileNumber;
          let encryptValue = "";
          if (
            taxFileNumber !== null &&
            taxFileNumber !== "" &&
            !isNaN(taxFileNumber)
          ) {
            encryptValue = this.getEncryptedTfn(
              taxFileNumber,
              this.state.publicKey
            );
            data.payload["numberOfInvestors"].details[
              index
            ].CountryOfTaxResidency.taxFileNumber = encryptValue;
          }
        }
        this.saveRegistrationDetails(data);
      } else {
        this.saveRegistrationDetails(data);
      }
      return sequences[payload.investorEntity.value].onNext(
        currentComponent,
        payload,
        payloadField
      );
    } else {
      this.saveRegistrationDetails(data);
    }
    return currentComponent;
  };

  getEncryptedTfn = (taxFileNumber, publicKey) => {
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    let encrypted = encrypt.encrypt(taxFileNumber);
    return encrypted;
  };

  validateTfn = (tfn) => {
    //remove spaces and update
    tfn = tfn.toString().replace(/\s+/g, "");

    //validate only digits
    var isNumber = /^[0-9]+$/.test(tfn);
    if (!isNumber) {
      return false; // Invalid TFN, only numbers are allowed.
    }

    if (tfn.toString().length == 9) {
      var digits = tfn.toString().split("");
      var weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];
      var sum = 0;
      for (let i = 0; i < digits.length; i++) {
        //do the calculation
        sum += digits[i] * weights[i];
      }
      return sum % 11 == 0;
    }
    return false;
  };

  saveRegistrationDetails(data, callback) {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .post(`${ROUTES.STORAGE_URL}`, JSON.stringify(data), config)
      .then((res) => {
        console.log("Server result:", res);
      })
      .catch((error) => {
        console.log("Server error: " + error);
      });
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  render() {
    const { defaultPayload, isUpdated } = this.state;
    if (!isUpdated) return false;
    return (
      <PageContent
        className="register-container"
        skipFooter={true}
        activeMenuHeader={"register"}
        history={this.props.history}
      >
        <div
          className="register-loader"
          style={{ display: this.state.isLoading ? "flex" : "none" }}
        >
          <div className="register-loader-inner">
            <Triangle
              height="100"
              width="100"
              color="#E8983F"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
        <main className="register-main">
          <FormWizard
            formComponents={this.getComponentMap()}
            defaultComponent={defaultPayload.currentComponent}
            onNext={this.onNext}
            onPrev={this.onPrev}
            payload={defaultPayload.payload}
          />
          <div className="register-main-content">
            {this.formatMessage_i18n("prism.otc.description")}
          </div>
        </main>
      </PageContent>
    );
  }
}

export default Register;
