import "./style.scss";
import React, { Component } from "react";
import { Button, Modal } from "antd";
import { intl } from "../../../../utils/intl_i18n";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";
import { validateGiin } from "../../../../components/common/validation";

class UnRegulatedFinancialTaxInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      viewDetailsPreviewModal: false,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  componentDidMount() {
    this.validate();
  }

  onChangeTaxInformationStatus = (e) => {
    const { payload } = this.state;
    payload.unregulatedTrustTaxStatus = e.target.value;
    // Resetting the values in the GIIN field whenever the tax information dropdown value changes
    payload.unregulatedTrustGiin = "";
    this.updateState(payload);
  };

  onChangeFatcaStatus = (e) => {
    const { payload } = this.state;
    payload.unregulatedFatcaStatus = e.target.value;
    // Resetting the values in the Other facta status field whenever the facta dropdown value changes
    payload.otherFatcaStatus = "";
    this.updateState(payload);
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    // If the selects the facta details first and update the GIIN Number,  I have resets the unregulatedFatcaStatus and otherFatcaStatus to empty as it is not required
    if (field === "unregulatedTrustGiin") {
      payload.unregulatedFatcaStatus = "";
      payload.otherFatcaStatus = "";
    }
    this.updateState(payload);
  };

  validate = () => {
    const { payload } = this.state;
    if (!payload.unregulatedTrustTaxStatus) {
      this.onValidationFail();
      return;
    }
    if (payload.unregulatedTrustTaxStatus === "financialInstitution") {
      if (!payload.unregulatedTrustGiin) {
        if (
          payload.unregulatedFatcaStatus === undefined ||
          !payload.unregulatedFatcaStatus
        ) {
          this.onValidationFail();
          return;
        } else if (payload.unregulatedFatcaStatus === "other") {
          if (
            payload.otherFatcaStatus === undefined ||
            !payload.otherFatcaStatus
          ) {
            this.onValidationFail();
            return;
          }
        }
      }

      if (!payload.isNonCRSJurisdictionAndManagedByAFinancialInsto) {
        this.onValidationFail();
        return;
      }
    }
    this.onValidationPass();
  };

  /**
   *
   * @param {string} giinValue - GIIN value
   * @returns {boolean} - Whether we need to disable the Facta Dropdown field or not
   */
  checkGiinState = (giinValue) => {
    if (giinValue === undefined) {
      return false;
    }
    if (giinValue !== "") {
      return true;
    }
  };

  /**
   * @method Method to handle the CRSJurisdiction Dropdown
   * @param {object} e Event object
   */
  onChangNonCRSJurisdictionDropdown = (e) => {
    const { payload } = this.state;
    payload.isNonCRSJurisdictionAndManagedByAFinancialInsto = e.target.value;
    this.updateState(payload);
  };

  handleOk = () => {
    this.setState({ viewDetailsPreviewModal: false });
  };

  renderCRSJurisdicationPanel = () => {
    this.setState({ viewDetailsPreviewModal: true });
  };

  render() {
    const { payload, viewDetailsPreviewModal } = this.state;
    return (
      <div className="unregulatedTrust-tax-main">
        <div className="unregulatedTrustTax-title">
          {this.formatMessage_i18n("UnRegulatedTrustTaxInfomation.title")}
        </div>
        <div className="unregulatedTrustTax-contentTop">
          {this.formatMessage_i18n("UnRegulatedTrustTaxInfomation.topContent")}
        </div>
        <div className="tax-status-dropdown-label">
          {this.formatMessage_i18n(
            "UnRegulatedTrustTaxInfomation.taxStatusDropdownLabel"
          )}
        </div>
        <div className="tax-status-dropdown">
          <select
            className={
              (_isUndefined(payload.unregulatedTrustTaxStatus) ||
                payload.unregulatedTrustTaxStatus === "") &&
              "error-field"
            }
            onChange={this.onChangeTaxInformationStatus}
            value={payload.unregulatedTrustTaxStatus}
          >
            <option value="">
              {this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.taxStatusDefault"
              )}
            </option>
            <option value="financialInstitution">
              {this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.taxStatusDropdownOption1"
              )}
            </option>
            <option value="australianRegisteredCharity">
              {this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.taxStatusDropdownOption2"
              )}
            </option>
            <option value="foreignCharity">
              {this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.taxStatusDropdownOption3"
              )}
            </option>
            <option value="activeNonFinancial">
              {this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.taxStatusDropdownOption4"
              )}
            </option>
            <option value="other">
              {this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.taxStatusDropdownOption5"
              )}
            </option>
          </select>
        </div>
        <div
          className="unregulatedTrustTax-status-values"
          style={{
            display:
              payload.unregulatedTrustTaxStatus === null ||
              payload.unregulatedTrustTaxStatus !== "financialInstitution"
                ? "none"
                : "block",
          }}
        >
          <div className="giin-label">
            {this.formatMessage_i18n("UnRegulatedTrustTaxInfomation.giinLabel")}
          </div>
          <div className="giin-textbox">
            <input
              // Adding the error field check to the giin input field when the unregulatedFatcaStatus is set empty( or undefined) and giin is empty( or undefined)
              className={cn("giin-input-large", {
                "error-field":
                  (_isUndefined(payload.unregulatedFatcaStatus) ||
                    payload.unregulatedFatcaStatus === "") &&
                  (_isUndefined(payload.unregulatedTrustGiin) ||
                    payload.unregulatedTrustGiin === "" ||
                    !validateGiin(payload.unregulatedTrustGiin)),
              })}
              placeholder={this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.giinPlaceHolder"
              )}
              value={payload.unregulatedTrustGiin}
              onChange={(e) =>
                this.onInputChange("unregulatedTrustGiin", e.target.value)
              }
            />
          </div>
          <div className="fatca-status-label">
            {this.formatMessage_i18n(
              "UnRegulatedTrustTaxInfomation.fatcaLabel"
            )}
          </div>
          <div className="fatca-status-dropdown">
            <select
              onChange={this.onChangeFatcaStatus}
              value={payload.unregulatedFatcaStatus}
              disabled={this.checkGiinState(payload.unregulatedTrustGiin)}
            >
              <option value="">
                {this.formatMessage_i18n(
                  "UnRegulatedTrustTaxInfomation.fatcaDropdownPlaceholder"
                )}
              </option>
              <option value="deemedCompliant">
                {this.formatMessage_i18n(
                  "UnRegulatedTrustTaxInfomation.fatcaDropdownOption1"
                )}
              </option>
              <option value="exceptedFinancialInstitution">
                {this.formatMessage_i18n(
                  "UnRegulatedTrustTaxInfomation.fatcaDropdownOption2"
                )}
              </option>
              <option value="exemptBeneficialOwner">
                {this.formatMessage_i18n(
                  "UnRegulatedTrustTaxInfomation.fatcaDropdownOption3"
                )}
              </option>
              <option value="nonReportingIGA">
                {this.formatMessage_i18n(
                  "UnRegulatedTrustTaxInfomation.fatcaDropdownOption4"
                )}
              </option>
              <option value="nonParticipating">
                {this.formatMessage_i18n(
                  "UnRegulatedTrustTaxInfomation.fatcaDropdownOption5"
                )}
              </option>
              <option value="other">
                {this.formatMessage_i18n(
                  "UnRegulatedTrustTaxInfomation.fatcaDropdownOption6"
                )}
              </option>
            </select>
          </div>
          <div
            className="otherFatcaStatus-textbox"
            style={{
              display:
                payload.unregulatedFatcaStatus === "other" ? "block" : "none",
            }}
          >
            <input
              // Adding the error field check to the otherFacta Status field when the unregulatedFatcaStatus is set to other and value is empty
              className={cn("fatcaStatus-input-large", {
                "error-field":
                  payload.unregulatedFatcaStatus === "other" &&
                  (_isUndefined(payload.otherFatcaStatus) ||
                    payload.otherFatcaStatus === ""),
              })}
              placeholder={this.formatMessage_i18n(
                "UnRegulatedTrustTaxInfomation.otherFatcaStatusText"
              )}
              value={payload.otherFatcaStatus}
              onChange={(e) =>
                this.onInputChange("otherFatcaStatus", e.target.value)
              }
              disabled={this.checkGiinState(payload.unregulatedTrustGiin)}
            />
          </div>
          <div className="unRegulated-jurisdication-panel">
            <div className="label">
              <span>
                Is the Financial Institution an Investment Entity located in a{" "}
                <Button
                  type="link"
                  className="nonCRSJurisdicationLink"
                  onClick={this.renderCRSJurisdicationPanel}
                >
                  {" "}
                  Non-Participating CRS Jurisdiction{" "}
                </Button>{" "}
                and managed by another Financial Institution?
              </span>
            </div>
            <div className="jurisdication-confirmation-dropdown">
              <select
                className={
                  (_isUndefined(
                    payload.isNonCRSJurisdictionAndManagedByAFinancialInsto
                  ) ||
                    payload.isNonCRSJurisdictionAndManagedByAFinancialInsto ===
                      "") &&
                  "error-field"
                }
                onChange={this.onChangNonCRSJurisdictionDropdown}
                value={payload.isNonCRSJurisdictionAndManagedByAFinancialInsto}
              >
                <option value="">
                  {this.formatMessage_i18n(
                    "UnRegulatedTrustTaxInfomation.dropdownPlaceholder"
                  )}
                </option>
                <option value="yes">
                  {this.formatMessage_i18n(
                    "UnRegulatedTrustTaxInfomation.dropdownValue1"
                  )}
                </option>
                <option value="no">
                  {this.formatMessage_i18n(
                    "UnRegulatedTrustTaxInfomation.dropdownValue2"
                  )}
                </option>
              </select>
            </div>
          </div>
          <div className="facta-notification-clause">
            {this.formatMessage_i18n(
              "UnRegulatedTrustTaxInfomation.fatcaNotificationClause"
            )}
          </div>
        </div>
        <Modal
          className="view-details-modal"
          visible={viewDetailsPreviewModal}
          onOk={this.handleOk}
          onCancel={this.handleOk}
          footer={null}
        >
          <div>
            CRS Participating Jurisdictions are on the OECD website at{" "}
            <a
              target="_blank"
              href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/crs-by-jurisdiction"
              rel="noreferrer"
            >
              http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/crs-by-jurisdiction
            </a>
          </div>
        </Modal>
      </div>
    );
  }
}

export default UnRegulatedFinancialTaxInformation;
