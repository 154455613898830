import { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { Radio, Space } from "antd";
import PageContent from "../common/PageContent";
import NotApprovedBanner from "../common/NotApprovedBanner";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  considerationOptions,
  optionsByConsideration,
  riskQuestionByActionOption,
  riskOptionsRGB,
  riskOptionsPurple,
  shareholderApplicationOptions,
  orderProductByActionRisk,
  orderProductByShareholderApplicationOption,
  formActionOption,
  formRiskOption,
  SHAREHOLDER_APP,
} from "./config";
import { numberWithCommas } from "../../utils/numberFormatting";
import ConfirmOrder from "./ConfirmOrder";
import ETAPill from "../common/ETAPills/ETAPill";
import { useOrderById } from "../../hooks/orders";
import { useInvestorDetails } from "../../hooks/investors";
import { useSecurities } from "../../hooks/securities";
import { useLocation } from "react-router-dom";
import message from "antd/es/message";
import FilterPanel from "../common/Filters/FilterPanel";
import OrderForm from "./OrderForm";
import BuyOpOrderForm from "./BuyOpOrderForm";

function PlaceOrder(props) {
  const renderType = props?.type;
  const { data: investorDetails } = useInvestorDetails();
  const [orderStatus, setOrderStatus] = useState("");
  const { search, key } = useLocation();
  const searchParams = new URLSearchParams(search);
  const orderId = searchParams?.get("orderId");
  const { data: order } = useOrderById(orderId);
  const { data: securitiesList } = useSecurities();

  useEffect(() => {
    setOrderStatus("");
  }, [key]);

  const formik = useFormik({
    initialValues: {
      id: order?.id ?? "",
      clientRef: order?.clientRef ?? "",
      considerationType:
        order?.considerationType ??
        searchParams?.get("considerationType") ??
        "",
      actionOption: order
        ? formActionOption(order)
        : searchParams?.get("considerationType")
        ? `BUY_${searchParams?.get("seriesType")}_${searchParams.get(
            "considerationType"
          )}`
        : "",
      riskOption: order
        ? formRiskOption(order)
        : searchParams?.get("productName") &&
          searchParams?.get("considerationType")
        ? formRiskOption({
            productName: searchParams?.get("productName"),
            considerationType: searchParams?.get("considerationType"),
            seriesType: searchParams?.get("seriesType"),
            orderSide: "BUY",
          })
        : "",
      shareholderApplicationOption: "",
      underlying: order?.underlying ?? searchParams?.get("ticker") ?? "",
      term: order
        ? order.term
        : searchParams?.get("term")
        ? Number(searchParams.get("term"))
        : 5,
      quantity: order?.quantity
        ? numberWithCommas(order?.quantity)
        : searchParams.get("quantity")
        ? numberWithCommas(searchParams.get("quantity"))
        : "",
      priceDollars: order?.priceDollars
        ? order.priceDollars.toFixed(2)
        : searchParams?.get("priceDollars")
        ? Number(searchParams.get("priceDollars")).toFixed(2)
        : "",
      onBehalfOf: order?.onBehalfOf ? order.onBehalfOf : "",
      maturityDate: order?.maturityDate ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      underlying: Yup.string()
        .required("Underlying required")
        .test(
          "isValidUnderlying",
          "Underlying must match an item in the list",
          (value) => {
            return securitiesList?.find(
              (security) => security.ticker === value
            );
          }
        ),
      term: Yup.string().required("Term required"),
      quantity: Yup.string()
        .matches(/^\d{1,3}(,\d{3})*$/, "Quantity must be a whole number")
        .required("Quantity required"),
      priceDollars: Yup.string().when("shareholderApplicationOption", {
        is: (shareholderApplicationOption) => {
          return !shareholderApplicationOption;
        },
        then: () => Yup.string().required("Price required"),
        otherwise: () => Yup.string(),
      }),
      onBehalfOf: Yup.string(),
    }),
    onSubmit: (values) => {
      if (!validOrder(values)) return;
      setOrderStatus("PENDING_CONFIRMATION");
    },
  });

  const validOrder = (values) => {
    let hasErrors = false;
    if (values.shareholderApplicationOption) {
      return !hasErrors;
    }

    const orderValue =
      parseInt(values.quantity.replace(/,/g, "")) *
      parseFloat(values.priceDollars);
    if (investorDetails?.isRetailInvestor) {
      if (values.considerationType === "CASH" && orderValue < 500000) {
        hasErrors = true;
        message.error("Transaction value must be more than $500,000.");
      }
      if (values.considerationType === "STOCK" && orderValue < 1000000) {
        hasErrors = true;
        message.error(
          "Transaction value must be more than $1,000,000 for stock trades."
        );
      }
    }
    return !hasErrors;
  };

  const fromBuyOps = searchParams?.get("ticker");

  console.log("riskOption index", formik.values.riskOption);

  const shareholderApplication =
    renderType === SHAREHOLDER_APP ||
    formik.values.actionOption === SHAREHOLDER_APP;

  return (
    <PageContent>
      <Container maxWidth="xl" sx={{ marginTop: "15px" }}>
        <div className="text-white text-[40px] font-extralight text-center">
          {orderStatus === "PENDING_CONFIRMATION"
            ? shareholderApplication
              ? "EOI Confirmation for Shareholder Application"
              : "Preview EOI"
            : order
            ? "Amend Order"
            : shareholderApplication
            ? "Shareholder Application"
            : "Place Expression of Interest"}
        </div>

        <NotApprovedBanner />
        <div>
          <div className="bg-light-gradient backdrop-blur login-box-shadow rounded-xl p-8">
            {orderStatus === "PENDING_CONFIRMATION" && (
              <ConfirmOrder
                formik={formik}
                handleGoBack={() => setOrderStatus("")}
                renderType={renderType}
                shareholderApplication={shareholderApplication}
              />
            )}
            {orderStatus !== "PENDING_CONFIRMATION" && (
              <>
                {fromBuyOps ? (
                  <BuyOpOrderForm
                    formik={formik}
                    orderId={orderId}
                    searchParams={searchParams}
                    securitiesList={securitiesList}
                  />
                ) : (
                  <OrderForm
                    formik={formik}
                    orderId={orderId}
                    securitiesList={securitiesList}
                    renderType={renderType}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </PageContent>
  );
}

export default PlaceOrder;
