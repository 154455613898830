import {
  orderProductByActionRisk,
  orderProductByShareholderApplicationOption,
  prismFee,
} from "./config";
import ETAPill from "../common/ETAPills/ETAPill";
import { numberWithCommas } from "../../utils/numberFormatting";
import { fetchCurrencySymbol } from "../common/currencies";

export default function EOISummaryTable({
  formik,
  lastPrice,
  forwardDivYield,
  shareholderApplication,
}) {
  const {
    considerationType,
    underlying,
    term,
    quantity,
    priceDollars,
    actionOption,
    riskOption,
    shareholderApplicationOption,
  } = formik.values;

  const sell = considerationType === "STOCK" && !shareholderApplication;
  const [side, type] = actionOption.split("_");

  console.log("actionOption", actionOption);
  console.log("riskOption", riskOption);

  const currentUnderlyingPrice = lastPrice?.toFixed(2) ?? 0;
  const price = parseFloat(priceDollars).toFixed(2);
  const sellSidePrice =
    side === "SELL" ? price : (currentUnderlyingPrice - price).toFixed(2);
  const buySidePrice =
    side === "BUY" ? price : (currentUnderlyingPrice - price).toFixed(2);

  const leverageValue = (currentUnderlyingPrice / buySidePrice).toFixed(2);
  const currency = fetchCurrencySymbol(underlying);
  const yieldValue =
    forwardDivYield <= 0
      ? 0
      : (
          100 *
          ((forwardDivYield - prismFee) *
            (currentUnderlyingPrice / buySidePrice))
        ).toFixed(2);
  const receiveAmount = numberWithCommas(
    (parseInt(quantity.replace(/,/g, "")) * sellSidePrice).toFixed(2)
  );

  const receivingIncomeProduct = orderProductByActionRisk[actionOption]?.[
    riskOption
  ]?.receiveProductId
    .split("_")
    .includes("DIV");

  const columns = [
    ...(shareholderApplication
      ? [
          {
            borderRight: true,
            header: {
              title: "Receive",
            },
            productId:
              orderProductByShareholderApplicationOption[
                shareholderApplicationOption
              ]?.receiveIncomeProductId,
            underlying,
            rows: [
              {
                label: "Quantity to Sell",
                content: (
                  <span className="pl-4 mt-2 font-bold">{quantity}</span>
                ),
              },
              {
                label: "Priced at",
                content: (
                  <span className="pl-4 mt-2">
                    Capital Allocations to be agreed
                  </span>
                ),
              },
              {
                label: "Term",
                content: (
                  <>
                    <span className="pl-4 mt-2 font-bold">{term}</span> years
                  </>
                ),
              },
            ],
          },
          {
            header: {
              title: "Receive",
            },
            productId:
              orderProductByShareholderApplicationOption[
                shareholderApplicationOption
              ]?.receiveGrowthProductId,
            underlying,
            rows: [
              {
                label: "Quantity to Sell",
                content: (
                  <span className="pl-4 mt-2 font-bold">{quantity}</span>
                ),
              },
              {
                label: "Priced at",
                content: (
                  <span className="pl-4 mt-2">
                    Capital Allocations to be agreed
                  </span>
                ),
              },
              {
                label: "Term",
                content: (
                  <>
                    <span className="pl-4 mt-2 font-bold">{term}</span> years
                  </>
                ),
              },
            ],
          },
        ]
      : []),
    ...(sell
      ? [
          {
            borderRight: true,
            header: {
              title: "Sell",
            },
            productId:
              orderProductByActionRisk[actionOption]?.[riskOption]
                ?.orderProductId,
            underlying,
            rows: [
              {
                label: "Quantity to Sell",
                content: (
                  <span className="pl-4 mt-2 font-bold">{quantity}</span>
                ),
              },
              {
                label: "Priced at",
                content: (
                  <>
                    <span className="pl-4 mt-2 font-bold">
                      {currency}
                      {sellSidePrice}
                    </span>
                  </>
                ),
              },
              {
                label: "Term",
                content: (
                  <>
                    <span className="pl-4 mt-2 font-bold">{term}</span> years
                  </>
                ),
              },
            ],
            highlightedRow: {
              label: "Equity Returned",
              content: (
                <div className="pl-4 mt-2">
                  ({quantity} x{" "}
                  <span className="font-bold">
                    {currency}
                    {sellSidePrice}
                  </span>
                  ) =
                </div>
              ),
            },
          },
        ]
      : []),
    ...(!shareholderApplication
      ? [
          {
            header: {
              title: "Receive",
            },
            productId:
              orderProductByActionRisk[actionOption]?.[riskOption]
                ?.receiveProductId,
            underlying,
            rows: [
              {
                label: "Quantity to Receive",
                content: (
                  <span className="pl-4 mt-2 font-bold">{quantity}</span>
                ),
              },
              {
                label: "Priced at",
                content: receivingIncomeProduct ? (
                  <>
                    <span className="pl-4 mt-2 font-bold">
                      {currency}
                      {buySidePrice}
                    </span>{" "}
                    with est. yield of{" "}
                    <span className="font-bold">{yieldValue}%</span>
                  </>
                ) : (
                  <>
                    <span className="pl-4 mt-2 font-bold">
                      {currency}
                      {buySidePrice}
                    </span>{" "}
                    with an est. multiple of{" "}
                    <span className="font-bold">{leverageValue}x</span>
                  </>
                ),
              },
              {
                label: "Term",
                content: (
                  <>
                    <span className="pl-4 mt-2 font-bold">{term}</span> years
                  </>
                ),
              },
            ],
            ...(sell && {
              highlightedRow: {
                label: "Equity Returned",
                content: (
                  <div className="pl-4 mt-2">
                    {currency}
                    {receiveAmount} (CASH)
                  </div>
                ),
              },
            }),
          },
        ]
      : []),
  ];

  return (
    <div
      className={`grid grid-cols-1 ${
        (shareholderApplication || sell) && "grid-cols-1 md:grid-cols-2"
      } w-full`}
    >
      {columns.map((column) => (
        <div key={column?.underlying}>
          <div
            className={`${
              column.borderRight && "md:border-r-2 border-[#CACBD0]"
            } px-16 py-2 relative`}
          >
            <div className="flex gap-x-4 items-center">
              <div className="text-[35px] text-[#474C55]">
                {column.header.title}
              </div>
              <ETAPill
                id="receive"
                productId={column.productId}
                className="w-[110px] h-[30px] flex items-center justify-center text-[14px]"
              />
              ({column.underlying})
            </div>
            <div className="h-0 w-0 border-x-8 border-x-transparent border-t-[16px] border-t-white absolute"></div>
          </div>
          {column.rows.map((row, i) => (
            <>
              <div
                className={`${
                  column.borderRight && "md:border-r-2 border-[#CACBD0]"
                } px-16 py-2 bg-[#585c64] text-white text-[20px] ${
                  i === 0 ? "pt-4 h-[84px]" : "h-[79px]"
                }`}
              >
                <div>
                  <div
                    className="inline-block rounded-full border border-[#797D82] text-[14px] italic text-white px-4"
                    style={{
                      background:
                        "linear-gradient(139deg, #474C55 0%, #343741 100%)",
                    }}
                  >
                    {row.label}
                  </div>
                </div>
                {row.content}
              </div>
            </>
          ))}
          {column.highlightedRow && (
            <div
              className={`${
                column.borderRight &&
                "hidden md:block md:border-r-2 border-[#CACBD0]"
              } px-16 py-2 bg-[#7795FD] bg-opacity-0.9 text-white text-[20px] h-[87px]`}
            >
              <div>
                <div
                  className="inline-block rounded-full border border-[#797D82] text-[14px] italic text-white px-4"
                  style={{
                    background:
                      "linear-gradient(139deg, #474C55 0%, #343741 100%)",
                  }}
                >
                  {column.highlightedRow.label}
                </div>
              </div>
              {column.highlightedRow.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
