import { Component } from "react";
import Input from "antd/es/input";
import Checkbox from "antd/es/checkbox";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";
import _isUndefined from "lodash/isUndefined";
import cn from "classnames";

class ListingAndRegulatory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  validate = () => {
    const { payload } = this.state;

    if (payload.isAustralianListed && !payload.australianMarketname) {
      this.onValidationFail();
      return;
    }
    if (
      payload.isOwnedSubsidiary &&
      (!payload.companyName || !payload.subsidiaryMarketname)
    ) {
      this.onValidationFail();
      return;
    }

    if (payload.isRegulated && !payload.regulatorName) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };
  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    const { payload } = this.state;
    if (payload.isAustralianListed === undefined) {
      payload.isAustralianListed = false;
    }
    if (payload.isOwnedSubsidiary === undefined) {
      payload.isOwnedSubsidiary = false;
    }
    if (payload.isRegulated === undefined) {
      payload.isRegulated = false;
    }
    this.updateState(payload);
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    if (field === "australianMarketname" || field === "subsidiaryMarketname") {
      //allows text, hypen, space
      if (value !== "" && value.match(/^[A-Za-z-\s]+$/) === null) return;
    }
    if (field === "companyName") {
      //allows text, numbers, hyphen (-), spaces and stops(.)
      if (value !== "" && value.match(/^[A-Za-z0-9-.\s]+$/) === null) return;
    }
    payload[field] = value;
    this.updateState(payload);
  };

  onChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value.target.checked;
    if (!value.target.checked) {
      if (field === "isAustralianListed") {
        payload.australianMarketname = "";
      }
      if (field === "isOwnedSubsidiary") {
        payload.companyName = "";
        payload.subsidiaryMarketname = "";
      }
      if (field === "isRegulated") {
        payload.regulatorName = "";
        payload.licenseNumber = "";
      }
    }
    this.updateState(payload);
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="listing-regulatory-reg-main-content">
        <div className="reg-header">
          {this.formatMessage_i18n("listing.regulatory.heading")}
        </div>
        <div className="reg-sub-header">
          {" "}
          {this.formatMessage_i18n("listing.regulatory.select.context")}
        </div>
        <div className="reg-company-details">
          <Checkbox
            className="reg-checkbox"
            checked={payload.isAustralianListed}
            onChange={(e) => this.onChange("isAustralianListed", e)}
          >
            {this.formatMessage_i18n(
              "listing.regulatory.australia.listed.company.text"
            )}
          </Checkbox>
          <Input
            className={cn(
              "register-input-large",
              payload.isAustralianListed &&
                (_isUndefined(payload.australianMarketname) ||
                  payload.australianMarketname === "") &&
                "error-field"
            )}
            disabled={!payload.isAustralianListed}
            value={payload.australianMarketname}
            placeholder={this.formatMessage_i18n(
              "listing.regulatory.market.name"
            )}
            onChange={(e) =>
              this.onInputChange("australianMarketname", e.target.value)
            }
          />
        </div>
        <div className="reg-subsidiary">
          <Checkbox
            className="reg-checkbox"
            checked={payload.isOwnedSubsidiary}
            onChange={(e) => this.onChange("isOwnedSubsidiary", e)}
          >
            {this.formatMessage_i18n("listing.regulatory.own.subsidiary")}
          </Checkbox>
          <Input
            className={cn(
              "register-input-large",
              payload.isOwnedSubsidiary &&
                (_isUndefined(payload.companyName) ||
                  payload.companyName === "") &&
                "error-field"
            )}
            value={payload.companyName}
            disabled={!payload.isOwnedSubsidiary}
            placeholder={this.formatMessage_i18n(
              "listing.regulatory.australis.list.company.text"
            )}
            onChange={(e) => this.onInputChange("companyName", e.target.value)}
          />
          <Input
            className={cn(
              "register-input-large",
              payload.isOwnedSubsidiary &&
                (_isUndefined(payload.subsidiaryMarketname) ||
                  payload.subsidiaryMarketname === "") &&
                "error-field"
            )}
            value={payload.subsidiaryMarketname}
            disabled={!payload.isOwnedSubsidiary}
            placeholder={this.formatMessage_i18n(
              "listing.regulatory.market.name"
            )}
            onChange={(e) =>
              this.onInputChange("subsidiaryMarketname", e.target.value)
            }
          />
        </div>
        <div className="reg-regulated">
          <Checkbox
            className="reg-checkbox"
            checked={payload.isRegulated}
            onChange={(e) => this.onChange("isRegulated", e)}
          >
            {this.formatMessage_i18n("listing.regulatory.regulated.company")}
          </Checkbox>
          <Input
            className={cn(
              "register-input-large",
              payload.isRegulated &&
                (_isUndefined(payload.regulatorName) ||
                  payload.regulatorName === "") &&
                "error-field"
            )}
            value={payload.regulatorName}
            disabled={!payload.isRegulated}
            placeholder={this.formatMessage_i18n(
              "listing.regulatory.regulated.name"
            )}
            onChange={(e) =>
              this.onInputChange("regulatorName", e.target.value)
            }
          />
          <Input
            className={cn(
              "register-input-large",
              payload.isRegulated &&
                (_isUndefined(payload.licenseNumber) ||
                  payload.licenseNumber === "") &&
                "error-field"
            )}
            value={payload.licenseNumber}
            disabled={!payload.isRegulated}
            placeholder={this.formatMessage_i18n(
              "listing.regulatory.license.details"
            )}
            onChange={(e) =>
              this.onInputChange("licenseNumber", e.target.value)
            }
          />
        </div>
      </div>
    );
  }
}

export default ListingAndRegulatory;
