import { useState, useEffect } from "react";
import { Modal } from "antd";
import "./../style.scss";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";
import {
  convertToDateFormat,
  convertToDateTimeFormat,
} from "../../../utils/utile";
import { Box, Container } from "@mui/material";
import { Triangle } from "react-loader-spinner";
import NumberFormatter from "../../common/NumberFormatter";
import OrderTrail from "./orderTrail";
import ETAPills from "../../common/ETAPills";
import { orderType, seriesType } from "../../common/constants";
import { etaPairMapping } from "../../common/ETAPills/config";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getColumns } from "./interestHistoryModels";
import Table from "../../common/Table";

function InterestHistory({ investorId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [viewAuditTrailModal, setViewAuditTrailModal] = useState(false);
  const [orderTrailData, setOrderTrailData] = useState([]);
  const [_investorId, setInvestorId] = useState(investorId);

  //PBT-2682 - Removing the Redeem Growth Leg records and rendering the both leg in a single cell
  const computeRedeemRecords = (records) => {
    return records.filter(
      (record) =>
        !(
          record?.seriesType === seriesType.GROWTH &&
          record?.orderType === orderType.REDEEM
        )
    );
  };

  const fetchOrderHistoryData = (investorId) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .get(`${ROUTES.ALL_ORDERS_HISTORY}/${investorId}`, config)
      .then((response) => {
        if (response.status === 200) {
          const eoiHistoryRecords = computeRedeemRecords(response.data);
          setOrderHistoryData(eoiHistoryRecords);
        }
      })
      .catch((e) => {
        setOrderHistoryData([]);
        console.log(
          "fetchOrderHistoryData error loading historical orders:",
          e.response
        );
      });
  };

  useEffect(() => {
    setInvestorId(investorId);
    fetchOrderHistoryData(investorId);
  }, [investorId]);

  const viewOrderAudit = (order) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .get(
        `${ROUTES.ORDERS_BY_GROUPID_RESPONSE}/${investorId}/${order.groupId}`,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          setOrderTrailData(response.data);
          setViewAuditTrailModal(true);
        }
      })
      .catch((e) => {
        setOrderTrailData([]);
        console.log(
          "viewOrderAudit error loading order responses:",
          e.response
        );
      });
  };

  const fetchTermOrMaturity = (order) => {
    var termOrMaturity = "";
    if (order.orderSide === "BUY") {
      termOrMaturity =
        parseInt(order.minTermDays / 365) +
        "-" +
        parseInt(order.maxTermDays / 365) +
        "yrs";
    } else if (order.orderSide === "SELL") {
      termOrMaturity = convertToDateFormat(order.maturityDate);
    }
    return termOrMaturity;
  };

  const handleOk = () => {
    setOrderTrailData(undefined);
  };

  const handleCancel = () => {
    setOrderTrailData(undefined);
    setViewAuditTrailModal(false);
  };

  const columns = getColumns(viewOrderAudit);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [sorting, setSorting] = useState([]);

  const data =
    orderHistoryData?.filter(
      (order) =>
        (order.orderType !== orderType.REDEEM &&
          order.orderType !== orderType.SHARE) ||
        ((order.orderType === orderType.REDEEM ||
          order.orderType === orderType.SHARE) &&
          order.seriesType === seriesType.INCOME)
    ) ?? [];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Container maxWidth="xl" sx={{ marginTop: "15px" }}>
      <div className="text-white text-[40px] font-extralight text-center">
        Expression of Interest History
      </div>
      <div
        className="register-loader"
        style={{ display: isLoading ? "flex" : "none" }}
      >
        <div className="register-loader-inner">
          <Triangle
            height="100"
            width="100"
            color="#E8983F"
            ariaLabel="loading-indicator"
          />
        </div>
      </div>
      <div className="bg-[#2f333b] rounded-xl">
        <Box sx={{ p: 3 }}>
          <Table table={table} />
        </Box>
      </div>
      <Modal
        className="order-trail-modal"
        title="Order Audit Trail"
        visible={viewAuditTrailModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <OrderTrail orderTrailData={orderTrailData} />
      </Modal>
    </Container>
  );
}

export default InterestHistory;
