import {
  orderProductByActionRisk,
  orderProductByShareholderApplicationOption,
} from "./config";
import ETAPill from "../common/ETAPills/ETAPill";

export const mapOrderRequestData = (values, investorId) => {
  const { orderProductId, orderSide, considerationType } =
    orderProductByActionRisk[values.actionOption]?.[values.riskOption];
  return {
    ...(values.id && { id: values.id }),
    ...(values.clientRef && { clientRef: values.clientRef }),
    ...(values.maturityDate && { maturityDate: values.maturityDate }),
    underlying: values.underlying,
    productName: orderProductId,
    orderSide,
    quantity: parseInt(values.quantity.replace(/,/g, "")),
    priceDollars: parseFloat(values.priceDollars),
    term: values.term,
    allowPartialMatch: true,
    considerationType,
    investorId,
  };
};

export const mapShareOrderRequestData = (values, investorId, lastPrice) => {
  const orderProductId =
    orderProductByShareholderApplicationOption[
      values.shareholderApplicationOption
    ];
  return {
    underlying: values.underlying,
    growthProduct: orderProductId.receiveGrowthProductId,
    incomeProduct: orderProductId.receiveIncomeProductId,
    quantity: parseInt(values.quantity.replace(/,/g, "")),
    priceDollars: lastPrice,
    term: values.term,
    investorId,
    orderSide: "BUY",
  };
};

export const renderRiskLabel = (option, actionOption) => {
  return (
    <ETAPill
      id="risk-option"
      productId={
        orderProductByActionRisk[actionOption]?.[option.value]?.receiveProductId
      }
      className="mt-[1px] mr-[1px] w-[110px]"
    />
  );
};

export const renderShareholderApplicationLabel = (option) => {
  return (
    <>
      <ETAPill
        id="shareholder-application"
        productId={
          orderProductByShareholderApplicationOption[option.value]
            ?.receiveIncomeProductId
        }
        className="mt-[1px] mr-[-62px] w-[110px]"
      />
      <ETAPill
        id="shareholder-application"
        productId={
          orderProductByShareholderApplicationOption[option.value]
            ?.receiveGrowthProductId
        }
        className="mt-[1px] mr-[1px] w-[110px]"
      />
    </>
  );
};
