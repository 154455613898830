import React, { Component } from "react";
import "./style.scss";
import { intl } from "../../../../utils/intl_i18n";
import { Checkbox, Modal } from "antd";
import Upload from "antd/es/upload";
import DocumentSelection from "./document-select";

const { Dragger } = Upload;

class TrustVerificationProcedure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      document: null,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  componentDidMount() {
    const { payload } = this.state;
    if (payload.ASICRegDoc === undefined) {
      payload.ASICRegDoc = false;
    }
    if (payload.productDisclosureDocument === undefined) {
      payload.productDisclosureDocument = false;
    }
    if (payload.otherDoc === undefined) {
      payload.otherDoc = false;
    }
    this.updateState(payload);
  }

  validate = () => {
    const { payload } = this.state;
    if (
      (payload.ASICRegDoc === undefined || payload.ASICRegDoc === false) &&
      (payload.productDisclosureDocument === undefined ||
        payload.productDisclosureDocument === false) &&
      (payload.otherDoc === undefined || payload.otherDoc === false)
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.documentList === undefined || payload.documentList === null) {
      this.onValidationFail();
      return;
    }
    if (payload.documentList.length <= 0) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  /**
   *
   * @param {*} payload
   * Will get the updated payload when the field changes added
   */
  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value.target.checked;
    this.updateState(payload);
  };

  docSelect = (value) => {
    this.setState({ document: value });
  };

  handleOnChange = (info) => {
    const { payload } = this.state;
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      file.status = "done";
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    payload.documentList = fileList;
    this.updateState(payload);
  };

  beforeUpload = (file) => {
    const { payload } = this.state;

    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: (
          <DocumentSelection
            parentCallback={this.docSelect}
            payload={payload}
          />
        ),
        onOk: () => {
          if (this.state.document === null) {
            resolve(Upload.LIST_IGNORE);
          } else {
            Object.assign(file, { fileId: this.state.document });
            payload[this.state.document] = true;
            this.updateState(payload);
            this.setState({ document: null });
            resolve(true);
          }
        },
        onCancel: () => {
          resolve(Upload.LIST_IGNORE);
        },
        okButtonProps: {
          className: "submit-button",
        },
        cancelButtonProps: {
          className: "cancel-button",
        },
        className: "trust-verification-procedure-modal",
      });
    });
  };

  onRemove = (file) => {
    const { payload } = this.state;
    payload[file.fileId] = false;
    this.updateState(payload);
  };

  defaultFileList = () => {
    const { documentList } = this.state.payload;

    if (documentList !== undefined && documentList.length > 0) {
      return documentList;
    }

    return null;
  };

  render() {
    const { payload } = this.state;
    const props = {
      action: "",
      onChange: this.handleOnChange,
      beforeUpload: this.beforeUpload,
      onRemove: this.onRemove,
      defaultFileList: this.defaultFileList,
    };
    return (
      <div className="trust-verification-main">
        <div className="trust-verification-title">
          {this.formatMessage_i18n("trustVerification.title")}
        </div>
        <div className="trust-verification-documents">
          <Checkbox
            className="trust-verification-checkbox"
            checked={payload.ASICRegDoc}
            onChange={(e) => this.onInputChange("ASICRegDoc", e)}
          >
            {this.formatMessage_i18n("trustVerification.checkbox.name1")}
          </Checkbox>
          <Checkbox
            className="trust-verification-checkbox"
            checked={payload.productDisclosureDocument}
            onChange={(e) => this.onInputChange("productDisclosureDocument", e)}
          >
            {this.formatMessage_i18n("trustVerification.checkbox.name2")}
          </Checkbox>
          <Checkbox
            className="trust-verification-checkbox"
            checked={payload.otherDoc}
            onChange={(e) => this.onInputChange("otherDoc", e)}
          >
            {this.formatMessage_i18n("trustVerification.checkbox.name3")}
          </Checkbox>
        </div>
        <div className="trust-verification-file-upload">
          <Dragger
            {...props}
            name="file"
            customRequest={() => {
              return "";
            }}
          >
            <p className="ant-upload-text">
              {this.formatMessage_i18n("verification.procedure.drag.drop.text")}
            </p>
          </Dragger>
        </div>
      </div>
    );
  }
}

export default TrustVerificationProcedure;
