import React, { Component } from "react";
import "./styles.scss";
import Table from "antd/es/table";
import _isEmpty from "lodash/isEmpty";
import { fetchLastPrice, fetchSecondaryPriceGap } from "../trade/utils";
import NumberFormatter from "../common/NumberFormatter";
import ETAPills from "../common/ETAPills";
import DividendYield from "../common/DividendYield";
import { convertToDateFormat } from "../../utils/utile";

const { Column, ColumnGroup } = Table;

class SecondaryOrderTable extends Component {
  render() {
    const { lastPriceList, secondaryData, fetchLeverage } = this.props;
    return (
      <Table
        className="table-data"
        pagination={false}
        dataSource={secondaryData}
        sticky={false}
      >
        <ColumnGroup className="headings-with-border" title="">
          <Column
            className="headings"
            title="Underlying"
            dataIndex="underlying"
            key="underlying"
          />
          <Column
            className="headings align-right"
            title="Last Close"
            dataIndex="lastPx"
            key="lastPx"
            render={(lastPx, record) => {
              const price = fetchLastPrice(lastPriceList, record, "underlying");
              if (!_isEmpty(price.toString())) {
                return (
                  <NumberFormatter
                    value={price}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                    emptyContent={"-"}
                  />
                );
              } else {
                return "-";
              }
            }}
          />
          <Column
            className="headings"
            title="Side"
            dataIndex="seriesType"
            key="seriesType"
          />
          <Column
            className="headings show-border"
            title="Product"
            dataIndex="productNameDisplay"
            key="productNameDisplay"
            render={(productNameDisplay, record) =>
              record.productNameDisplay !== "NULL" ? (
                <div>
                  <ETAPills
                    etaDisplayText={record.productNameDisplay}
                    etaId={record.productName}
                  />
                </div>
              ) : null
            }
          />
        </ColumnGroup>
        <ColumnGroup className="headings-with-border" title="Offers">
          <Column
            className="headings align-right"
            title="Estab. Price"
            dataIndex="offerEstabPrice"
            key="offerEstabPrice"
            render={(offerEstabPrice, record) =>
              record.offerId !== null && offerEstabPrice !== null ? (
                <div>
                  <NumberFormatter
                    value={offerEstabPrice}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                    emptyContent={"-"}
                  />
                </div>
              ) : (
                "-"
              )
            }
          />
          <Column
            className="headings"
            title="Maturity"
            dataIndex="offerMaturity"
            key="offerMaturity"
            render={(offerMaturity, record) =>
              record.offerId !== null && offerMaturity !== null ? (
                <div>{convertToDateFormat(offerMaturity)}</div>
              ) : (
                "-"
              )
            }
          />
          <Column
            className="headings align-right"
            title="Qty"
            dataIndex="offerQty"
            key="offerQty"
            render={(offerQty, record) =>
              record.offerId !== null && offerQty !== null ? (
                <div>
                  <NumberFormatter value={offerQty} emptyContent={"-"} />
                </div>
              ) : (
                "-"
              )
            }
          />
          <Column
            className="headings align-center"
            title="%/X"
            dataIndex=""
            key=""
            render={(offerQty, record) =>
              record.offerId !== null && offerQty !== null ? (
                record.seriesType === "GROWTH" ? (
                  <div>{fetchLeverage(record, record.offerPrice)}</div>
                ) : (
                  <DividendYield
                    record={record}
                    productName={record.productName}
                    underlying={record.underlying}
                    bidPrice={record.offerPrice}
                    lastPriceList={lastPriceList}
                  />
                )
              ) : (
                "-"
              )
            }
          />
          <Column
            className="headings show-border align-right"
            title="Offer"
            dataIndex="offerPrice"
            key="offerPrice"
            render={(offerPrice, record) =>
              record.offerId !== null && offerPrice !== null ? (
                <div>
                  <NumberFormatter
                    value={offerPrice}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                    emptyContent={"-"}
                  />
                </div>
              ) : (
                "-"
              )
            }
          />
        </ColumnGroup>
        <ColumnGroup className="headings-with-border headings" title="">
          <Column
            className="headings show-border align-right"
            title="Price Gap"
            render={(text, record) => {
              const priceGap = fetchSecondaryPriceGap(record);
              if (!_isEmpty(priceGap.toString())) {
                return (
                  <NumberFormatter
                    value={priceGap}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                    emptyContent={"-"}
                  />
                );
              } else {
                return "-";
              }
            }}
          />
        </ColumnGroup>
        <ColumnGroup className="headings" title="Bids">
          <Column
            className="headings align-right"
            title="Bid"
            dataIndex="bidPrice"
            key="bidPrice"
            render={(bidPrice, record) =>
              record.bidPrice != null ? (
                <div>
                  <NumberFormatter
                    value={bidPrice}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                  />
                </div>
              ) : null
            }
          />
          <Column
            className="headings align-center"
            title="%/X"
            dataIndex=""
            key=""
            render={(offerQty, record) =>
              record.bidPrice != null && parseFloat(record.bidPrice) > 0 ? (
                record.seriesType === "GROWTH" ? (
                  <div>{fetchLeverage(record, record.bidPrice)}</div>
                ) : (
                  <DividendYield
                    record={record}
                    productName={record.productName}
                    underlying={record.underlying}
                    bidPrice={record.bidPrice}
                    lastPriceList={lastPriceList}
                  />
                )
              ) : (
                "-"
              )
            }
          />
          <Column
            className="headings align-right"
            title="Qty"
            dataIndex="bidQty"
            key="bidQty"
            render={(bidQty, record) =>
              record.bidQty != null ? (
                <div>
                  <NumberFormatter value={bidQty} />
                </div>
              ) : null
            }
          />
          <Column
            className="headings align-center"
            title="Term"
            dataIndex="bidTerm"
            key="bidTerm"
            render={(bidTerm, record) =>
              record.bidTerm != null ? <div>{bidTerm}</div> : null
            }
          />
        </ColumnGroup>
      </Table>
    );
  }
}

export default SecondaryOrderTable;
