export const CurrencyAndSymbol = {
  AU: {
    currency: "dollar",
    symbol: "$",
  },
  UN: {
    currency: "dollar",
    symbol: "$",
  },
  UQ: {
    currency: "dollar",
    symbol: "$",
  },
  UR: {
    currency: "dollar",
    symbol: "$",
  },
  UW: {
    currency: "dollar",
    symbol: "$",
  },
  CT: {
    currency: "dollar",
    symbol: "$",
  },
  LN: {
    currency: "pound",
    symbol: "£",
  },
  LI: {
    currency: "pound",
    symbol: "£",
  },
  SE: {
    currency: "franc",
    symbol: "₣",
  },
  SW: {
    currency: "franc",
    symbol: "₣",
  },
  SM: {
    currency: "euro",
    symbol: "€",
  },
  AV: {
    currency: "euro",
    symbol: "€",
  },
  IM: {
    currency: "euro",
    symbol: "€",
  },
  PL: {
    currency: "euro",
    symbol: "€",
  },
  SS: {
    currency: "euro",
    symbol: "€",
  },
  NA: {
    currency: "euro",
    symbol: "€",
  },
  EU: {
    currency: "euro",
    symbol: "€",
  },
  FP: {
    currency: "euro",
    symbol: "€",
  },
  GR: {
    currency: "euro",
    symbol: "€",
  },
  JP: {
    currency: "yen",
    symbol: "¥",
  },
  DEFAULT: {
    currency: "dollar",
    symbol: "$",
  },
};

export const fetchUnderlyingCountry = (ticker) => {
  let underlyingCountry = "";
  if (ticker) {
    ticker = ticker.split(".").pop();
    underlyingCountry = ticker ? ticker.toUpperCase() : "DEFAULT";
  } else {
    underlyingCountry = "DEFAULT";
  }
  return underlyingCountry;
};

export const fetchCurrencySymbol = (ticker) => {
  let underlyingCountry = fetchUnderlyingCountry(ticker);
  return CurrencyAndSymbol[underlyingCountry]
    ? CurrencyAndSymbol[underlyingCountry].symbol
    : CurrencyAndSymbol["DEFAULT"].symbol;
};
