import { useState } from "react";
import "./styles.scss";

function Footer({ footerRefContent }) {
  const [userName, setUserName] = useState(
    JSON.parse(sessionStorage.getItem("UserDetails"))
  );

  return (
    <div
      className="w-full flex items-center justify-between px-[50px] uppercase mt-2 min-h-[50px] collapse lg:visible"
      ref={footerRefContent}
    >
      <div className="flex items-center text-white gap-x-8">
        <div>
          <a className="text-white no-underline" href="/transaction-document">
            Transaction Documents
          </a>
        </div>
        {userName && (
          <div>
            <a
              className="text-white no-underline"
              href="https://www.prism.markets/otc/Prism_POAs.pdf"
              target="_blank"
              rel="noreferrer"
            >
              POA Overview
            </a>
          </div>
        )}
        <div>
          <a
            className="text-white no-underline"
            href="https://www.prism.markets/privacy-policy/"
          >
            Privacy Policy
          </a>
        </div>
        <div>
          <a className="text-white no-underline" href="/contact-us">
            Contact Us
          </a>
        </div>
      </div>
      <div className="text-[#C0C1C6]">© PRISM MARKETS 2023</div>
    </div>
  );
}

export default Footer;
