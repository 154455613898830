import React, { Component } from "react";
import "./styles.scss";
import Table from "antd/es/table";
import _isEmpty from "lodash/isEmpty";
import { fetchLastPrice, fetchPriceGap } from "../trade/utils";
import NumberFormatter from "../common/NumberFormatter";
import ETAPills from "../common/ETAPills";
import DividendYield from "../common/DividendYield";

const { Column, ColumnGroup } = Table;
let priceGapRecord = [];

class OrderBookTable extends Component {
  render() {
    const { lastPriceList, issuanceData, fetchLeverage } = this.props;
    return (
      <Table
        className="table-data"
        pagination={false}
        dataSource={issuanceData}
        sticky={false}
      >
        <ColumnGroup className="headings-with-border" title="">
          <Column
            className="headings"
            title="Underlying"
            dataIndex="underlying"
            key="underlying"
          />
          <Column
            className="headings lastClose show-border align-right"
            title="Last Close"
            dataIndex="lastPx"
            key="lastPx"
            render={(lastPx, record) => {
              const price = fetchLastPrice(lastPriceList, record, "underlying");
              if (!_isEmpty(price.toString())) {
                return (
                  <NumberFormatter
                    value={price}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                    emptyContent={"-"}
                  />
                );
              } else {
                return "-";
              }
            }}
          />
        </ColumnGroup>
        <ColumnGroup className="headings-with-border headings" title="Growth">
          <Column
            className="headings"
            title="Product"
            dataIndex="growthProductNameDisplay"
            key="growthProductNameDisplay"
            render={(growthProductNameDisplay, record) =>
              record.growthProductNameDisplay !== "NULL" ? (
                <div>
                  <ETAPills
                    etaDisplayText={record.growthProductNameDisplay}
                    etaId={record.growthProductName}
                  />
                </div>
              ) : null
            }
          />
          <Column
            className="headings align-center"
            title="Term"
            dataIndex="growthTerm"
            key="growthTerm"
            render={(growthTerm, record) =>
              record.growthProductNameDisplay !== "NULL" ? (
                <div>{growthTerm}</div>
              ) : null
            }
          />
          <Column
            className="headings align-right"
            title="Qty"
            dataIndex="growthQty"
            key="growthQty"
            render={(growthQty, record) =>
              record.growthProductNameDisplay !== "NULL" ? (
                <div>
                  <NumberFormatter value={growthQty} />{" "}
                </div>
              ) : null
            }
          />
          <Column
            className="headings  align-center"
            title="Leverage"
            dataIndex=""
            key=""
            render={(text, record) =>
              record.growthProductNameDisplay !== "NULL" ? (
                <div>{fetchLeverage(record, record.growthBid)}</div>
              ) : (
                "-"
              )
            }
          />
          <Column
            className="headings show-border align-right"
            title="Bid"
            dataIndex="growthBid"
            key="growthBid"
            render={(growthBid, record) =>
              record.growthProductNameDisplay !== "NULL" ? (
                <div>
                  <NumberFormatter
                    value={growthBid}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                  />{" "}
                </div>
              ) : null
            }
          />
        </ColumnGroup>
        <ColumnGroup className="headings-with-border headings" title="">
          <Column
            className="headings show-border align-center"
            title="Price Gap"
            render={(text, record) => {
              if (
                record.growthProductNameDisplay === "NULL" ||
                record.incomeProductNameDisplay === "NULL"
              ) {
                return "-";
              } else {
                const priceGap = fetchPriceGap(
                  lastPriceList,
                  record,
                  "underlying"
                );
                if (priceGap.toString().length === 0) {
                  return "-";
                } else {
                  if (
                    !priceGapRecord.includes(
                      record.underlying + record.growthProductName
                    ) ||
                    priceGapRecord.includes(record.growthId)
                  ) {
                    priceGapRecord.push(
                      record.underlying + record.growthProductName,
                      record.growthId
                    );
                    return (
                      <NumberFormatter
                        value={priceGap}
                        isCurrency={true}
                        tickerCode={record.underlying}
                        decimalScale={2}
                        emptyContent={"-"}
                      />
                    );
                  } else {
                    return "-";
                  }
                }
              }
            }}
          />
        </ColumnGroup>
        <ColumnGroup className="headings" title="Income">
          <Column
            className="headings align-right"
            title="Bid"
            dataIndex="incomeBid"
            key="incomeBid"
            render={(incomeBid, record) =>
              record.incomeProductNameDisplay !== "NULL" ? (
                <div>
                  <NumberFormatter
                    value={incomeBid}
                    isCurrency={true}
                    tickerCode={record.underlying}
                    decimalScale={2}
                  />
                </div>
              ) : null
            }
          />
          <Column
            className="headings align-center"
            title="Yield"
            dataIndex=""
            key=""
            render={(incomeBid, record) =>
              record.incomeProductNameDisplay !== "NULL" ? (
                <DividendYield
                  record={record}
                  productName={record.incomeProductName}
                  underlying={record.underlying}
                  bidPrice={record.incomeBid}
                  lastPriceList={lastPriceList}
                />
              ) : (
                "-"
              )
            }
          />
          <Column
            className="headings align-right"
            title="Qty"
            dataIndex="incomeQty"
            key="incomeQty"
            render={(incomeQty, record) =>
              record.incomeProductNameDisplay !== "NULL" ? (
                <div>
                  <NumberFormatter value={incomeQty} />{" "}
                </div>
              ) : null
            }
          />
          <Column
            className="headings align-center"
            title="Term"
            dataIndex="incomeTerm"
            key="incomeTerm"
            render={(incomeTerm, record) =>
              record.incomeProductNameDisplay !== "NULL" ? (
                <div>{incomeTerm}</div>
              ) : null
            }
          />
          <Column
            className="headings"
            title="Product"
            dataIndex="incomeProductNameDisplay"
            key="incomeProductNameDisplay"
            render={(incomeProductNameDisplay, record) =>
              record.incomeProductNameDisplay !== "NULL" ? (
                <div>
                  <ETAPills
                    etaDisplayText={record.incomeProductNameDisplay}
                    etaId={record.incomeProductName}
                  />
                </div>
              ) : null
            }
          />
        </ColumnGroup>
      </Table>
    );
  }
}

export default OrderBookTable;
