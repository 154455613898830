import ROUTES from "../.././../routes/api.json";
import axiosConfig from "../../../axiosConfig";
import message from "antd/es/message";
import { TIME_FOR_REGISTER_INTERVAL } from "../../common/constants";

class IndividualSoloTraderSequence {
  constructor(screenRef) {
    this.screenRef = screenRef;
  }

  register = (payload) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .put(ROUTES.REGISTER, payload, config)
      .then((res) => {
        message.success("Registration Completed");
      })
      .catch((error) => {});
  };

  checkNextIteration = (list) => {
    let index = list.currentIndex;
    const listLength = list.details.length;
    if (index < listLength - 1) {
      list.currentIndex !== "" && list.currentIndex !== undefined
        ? list.currentIndex++
        : (list.currentIndex = 1);
    } else {
      return false;
    }
    return true;
  };

  checkPrevIteration = (list) => {
    let index = list.currentIndex;
    const listLength = list.details.length;
    if (index < listLength && index - 1 >= 0) {
      list.currentIndex !== "" && list.currentIndex !== undefined
        ? list.currentIndex--
        : (list.currentIndex = 1);
    } else {
      return false;
    }
    return true;
  };

  registerUser = (payload) => {
    this.register(payload);
    setTimeout(() => {
      //replace props.history with window.location to prevent infinite call for validation-token api
      window.location.href = "/order";
    }, TIME_FOR_REGISTER_INTERVAL);
  };

  onNext = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };
    if (currentComponent.key === "investorEntity") {
      componentContent.key = "numberOfInvestors";
      return componentContent;
    }

    if (currentComponent.key === "numberOfInvestors") {
      componentContent.key = "personalDetails";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }
    if (currentComponent.key === "personalDetails") {
      componentContent.key = "countryOfTaxResidency";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }
    if (currentComponent.key === "countryOfTaxResidency") {
      componentContent.key = "nationalCreditCode";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }
    if (currentComponent.key === "nationalCreditCode") {
      componentContent.key = "individualVerificationProcedure";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }
    if (currentComponent.key === "individualVerificationProcedure") {
      const index = payload["numberOfInvestors"].currentIndex;
      this.uploadFileAndSaveRegister(
        payload["numberOfInvestors"].details[index][currentComponent.key],
        payload,
        currentComponent
      );
      componentContent.key = "subscriptionOptions";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "subscriptionOptions") {
      const doIteration = this.checkNextIteration(payload["numberOfInvestors"]);
      if (doIteration) {
        componentContent.key = "personalDetails";
        componentContent.type = "individual";
        componentContent.payloadType = "array";
        componentContent.payloadData = "numberOfInvestors";
        componentContent.payloadParam = "details";
      } else {
        componentContent.key = "settlementInstructions";
      }
      return componentContent;
    }
    if (currentComponent.key === "settlementInstructions") {
      componentContent.next = "Complete Registration";
      componentContent.key = "chessDetails";
      return componentContent;
    }

    if (currentComponent.key === "chessDetails") {
      this.uploadFileAndSaveRegister(
        payload[currentComponent.key],
        payload,
        currentComponent
      );
      componentContent.renderConfirmationPopup = true;
      componentContent.confirmCallback = () => {
        this.registerUser(payload);
      };
      return componentContent;
    }
    return componentContent;
  };

  onPrev = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };
    if (currentComponent.key === "numberOfInvestors") {
      componentContent.prev = null;
      componentContent.next = "Begin";
      componentContent.key = "investorEntity";
      componentContent.includePayloadCallback = true;
      return componentContent;
    }
    if (currentComponent.key === "personalDetails") {
      let doIteration = this.checkPrevIteration(payload["numberOfInvestors"]);
      if (doIteration) {
        componentContent.key = "subscriptionOptions";
        componentContent.type = "individual";
        componentContent.payloadType = "array";
        componentContent.payloadData = "numberOfInvestors";
        componentContent.payloadParam = "details";
      } else {
        componentContent.key = "numberOfInvestors";
      }
      return componentContent;
    }
    if (currentComponent.key === "countryOfTaxResidency") {
      componentContent.key = "personalDetails";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }
    if (currentComponent.key === "nationalCreditCode") {
      componentContent.key = "countryOfTaxResidency";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "individualVerificationProcedure") {
      componentContent.key = "nationalCreditCode";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "subscriptionOptions") {
      componentContent.key = "individualVerificationProcedure";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }
    if (currentComponent.key === "settlementInstructions") {
      componentContent.key = "subscriptionOptions";
      componentContent.type = "individual";
      componentContent.payloadType = "array";
      componentContent.payloadData = "numberOfInvestors";
      componentContent.payloadParam = "details";
      return componentContent;
    }
    if (currentComponent.key === "chessDetails") {
      componentContent.key = "settlementInstructions";
      return componentContent;
    }
    return componentContent;
  };

  uploadFileAndSaveRegister = async (
    payloadData,
    payload,
    currentComponent
  ) => {
    let fileList = payloadData["documentList"];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        let res = await this.uploadFile(fileList[i].originFileObj);
        if (res && res.data) {
          payloadData[fileList[i].fileId] = {};
          payloadData[fileList[i].fileId] = res.data;
        } else {
          message.error("File upload failed");
          return;
        }
      }
    }

    this.saveRegistrationDetails(payload, currentComponent);
  };

  uploadFile = async (fileObj) => {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("file", fileObj);
    let userId = JSON.parse(sessionStorage.getItem("UserDetails")).investorId;
    let res = await axiosConfig.post(
      `${ROUTES.FILE_UPLOAD}/${userId}`,
      formData,
      config
    );
    return res;
  };

  saveRegistrationDetails(data, currentComponent) {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //Added to maintain JSON structure as of Participant registration
    let userRegisterData = {};
    userRegisterData["currentComponent"] = currentComponent;
    userRegisterData["payload"] = data;

    axiosConfig
      .post(`${ROUTES.STORAGE_URL}`, JSON.stringify(userRegisterData), config)
      .then((res) => {
        console.log("Server result:", res);
      })
      .catch((error) => {
        console.log("Server error: " + error);
      });
  }
}

export default IndividualSoloTraderSequence;
