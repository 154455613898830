export const Mapping = {
  investorEntity: "Investor Entity Type",
  numberOfInvestors: "Number of Investors",
  fiTaxInformation: "Tax Information Details",
  settlementInstructions: "Settlement Instructions",
  chessDetails: "CHESS Details",
  nationalCreditCode: "National Credit Code",
  australianCompany: "Australian Company",
  proprietaryDirectors: "Proprietary Company Directors",
  listingRegulatory: "Listing and Regulatory Details",
  beneficialOwnership: "Beneficial Ownership",
  CountryOfTaxResidency: "Country of Tax Residency",
  verificationProcedureASIC: "Verification Documents",
  subscriptionOptions: "Subscription Details",
  Noofinvestors: "Number of Investors",
  australianCompany: "Australian Company",
  Value: "Name",
  individualVerificationProcedure: "Verification Details",
  adminUploads: "Admin - uploaded documents",
  title: "Title",
  familyName: "Second Name",
  givenName: "First Name",
  dateOfBirth: "Date of Birth",
  streetAddress: "Street Address",
  subUrban: "City",
  state: "State",
  postCode: "Post Code",
  country: "Country",
  "Fitax Information Status": "Tax information Status",
  "Fitax Information Giin": "Global Intermediary Identification Number (GIIN)",
  dateofbirth: "Date of Birth",
  "Has Declared Credit": "Declared credit?",
  hasDeclaredCredit: "Declared Credit?",
  "Is Business Addr Sameas Regd Addr":
    "Business address is same as Office address?",
  "Is Australian Listed": "Australian listed company?",
  "Is Owned Subsidiary": "Owned Subsidiary?",
  "Is Regulated": "Regulated Company?",
  isTaxResidentOfAustralia: "Tax Resident of Australia?",
  isTaxNonAustralian: "Non Australian Tax payer?",
  tin: "TIN Number",
  reason: "Reason",
  "Has Beneficial Owners": "Any Beneficial owners specified?",
  "Is Tax Non Australian": "Non-Australian Tax payer?",
  "Has Subscribed": "Register for subscription?",
  hasSubscribed: "Register for subscription?",
  "Has Agreed Legal Documents": "Agree with Legal Documents?",
  hasAgreedLegalDocuments: "Agree with Legal Documents?",
  "Has Agreed Product Warranty": "Agree with Product Warranty?",
  hasAgreedProductWarranty: "Agree with Product Warranty?",
  Tin: "TIN Number",
  activeNonFinancial: "Non-Financial Active",
  " Aus Driving License": "Australian Driving License",
  " Aus Passport": "Australian Passport",
  StateCardIssuanceForAgeProof: "Card issued by State Government as age proof",
  CurrentPassport: "Current foreign passport or similar travel document",
  AusBirthCertificate: "Australian birth certificate",
  AusCitizenCertificate: "Australian citizenship certificate",
  PensionCard: "Pension card issued by Department of Human Services",
  ForeignDrivingLicense: "Foreign driver’s licence",
  NationalID: "National ID card issued by a foreign government",
  CommonWealthOrTerritoryDoc:
    "A document issued by the Commonwealth or a State or Territory",
  AusTaxationDoc: "A document issued by the Australian Taxation Office",
  LocGovtOrUtilityProviderDoc:
    "A document issued by a local government body or utilities provider",
  investorEvidence: "Sophisticated Investor Certificates",
  deemedCompliant: "Deemed compliant",
  individualOrSoleTrader: "Individual/Sole Trader",
  abnAcn: "ABN/ACN Number",
  Bsb: "Bank State Branch number",
  AusDrivingLicense: "Australian Driving License",
  " Aus Taxation Doc": "A document issued by the Australian Taxation Office",
  " Aus Citizen Certificate": "Australian citizenship certificate",
  " Aus Birth Certificate": "Australian birth certificate",
  " Foreign Driving License": "Foreign driver’s licence",
  " National I D": "National ID card issued by a foreign government",
  " Common Wealth Or Territory Doc":
    "A document issued by the Commonwealth or a State or Territory",
  " Loc Govt Or Utility Provider Doc":
    "A document issued by a local government body or utilities provider",
  " State Card Issuance For Age Proof":
    "Card issued by State Government as age proof",
  " Current Passport": "Current foreign passport or similar travel document",
  "Acn Number": "ACN/ABN Number",
  acnNumber: "ACN/ABN Number",
  " A S I C Reg Doc": "Certified copy of ASIC registration",
  Details: "Details",
  isInvestorAustralianResident: "Investor is Australian Resident?",
  membershipDoc: "Proof of membership of market/exchange",
  productDisclosureDocument:
    "Legislation establishing the government superannuation fund sourced from a government website",
  otherDoc: "Certified copy of an offer  document of the MIS",
  australianRegTrustIdentity: "Australian Regulated Trust Identity",
  trusteeIdentification: "Trustee Identification",
  foreignCorporateIdentification: "Foreign Corporate Identification",
  foreignCorporateDetails: "Foreign Corporate Details",
  user: "User",
  corporateListingAndRegulatory: "Corporate Listing and Regulatory Details",
  regulatedTrustTaxInformation: "Regulated Trust Tax Information",
  trustVerificationProcedure: "Trust Verification Procedure",
  taxFileNumber: "Tax File Number",
  unRegulatedTrust: "Unregulated and Foreign Trust",
  unregulatedTrustBeneficiary: "Unregulated Trust Beneficiary Details",
  unregulatedTrustTrustee: "Unregulated Trust Trustee",
  unregulatedTrustTaxInformation: "Unegulated Tax Information",
  unRegulatedTrustBeneficialTaxInformation:
    "Unegulated Beneficial Tax Information",
  unRegulatedVerificationProcedure: "Unregulated Trust Verification Procedure",
};
