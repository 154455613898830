import { useState } from "react";
import "./styles.scss";
import axiosConfig from "../../../axiosConfig";
import Table from "antd/es/table";
import Button from "antd/es/button";
import ROUTES from "../../../routes/api.json";
import _filter from "lodash/filter";
import moment from "moment";
import SearchIcon from "../../logo/searchIcon";
import Input from "antd/es/input";
import { setStorageData, getStorageData, getUserId } from "../utils";
import { useAtom } from "jotai";
import { activeOBOInvestorAtom } from "../../../store/atoms";
import { useParticipantList } from "../../../hooks/investors";

const { Column } = Table;

function ParticipantDetail({ cancelCallback }) {
  const [activeOBOInvestor, setActiveOBOInvestor] = useAtom(
    activeOBOInvestorAtom
  );
  const { data: participantList } = useParticipantList();

  const [filteredParticipantList, setFilteredParticipantList] = useState([]);

  const [activeSelectedRow, setActiveSelectedRow] = useState(
    activeOBOInvestor?.investorId ?? ""
  );

  const triggerCancelEvent = () => {
    cancelCallback();
  };

  const triggerDisconnectEvent = () => {
    setActiveOBOInvestor("");
    cancelCallback();
  };

  const triggerSelectionEvent = () => {
    if (activeSelectedRow) {
      let selectedInvestor = _filter(participantList, {
        id: activeSelectedRow,
      });
      if (selectedInvestor) {
        selectedInvestor = selectedInvestor[0];
        setStorageData("OBOInvestor", {
          investorId: activeSelectedRow,
          name: `${selectedInvestor.legalName}`,
          email: selectedInvestor.email,
        });
        setActiveOBOInvestor({
          investorId: activeSelectedRow,
          name: `${selectedInvestor.legalName}`,
          email: selectedInvestor.email,
        });
        cancelCallback();
      }
    }
  };

  const handleRowClick = (rowData) => {
    let activeId = rowData.id;
    if (activeSelectedRow === rowData.id) {
      activeId = "";
    }
    setActiveSelectedRow(activeId);
  };

  const applyFilters = (participantList, searchValue) => {
    let filteredParticipant = [];
    if (searchValue) {
      searchValue = searchValue.toLowerCase().trim();
      filteredParticipant = _filter(participantList, function (item) {
        return (
          (item?.firstName &&
            item?.firstName.toLowerCase().includes(searchValue)) ||
          (item?.lastName &&
            item?.firstName.toLowerCase().includes(searchValue)) ||
          (item?.email && item?.email.toLowerCase().includes(searchValue)) ||
          (item?.id && item?.id.toString().includes(searchValue))
        );
      });
      setFilteredParticipantList(filteredParticipant);
    } else {
      setFilteredParticipantList(participantList);
    }
  };

  return (
    <div className="participant-detail-container">
      <div className="search-box" style={{ flex: "initial" }}>
        <SearchIcon />
        <input
          className="search-text"
          type="text"
          onChange={(e) => {
            applyFilters(participantList, e.target.value);
          }}
        />
      </div>
      <div className="table-container">
        <Table
          className="table-data"
          pagination={false}
          dataSource={
            filteredParticipantList.length
              ? filteredParticipantList
              : participantList
          }
          scroll={{ y: 300 }}
          onRow={(record) => {
            return {
              onClick: (event) => handleRowClick(record),
            };
          }}
          rowClassName={(record) => {
            if (record.prismOBOAuthorised == false) {
              return "disabled-row";
            }
            if (record.id === activeSelectedRow) {
              return "active-row";
            }
            return null;
          }}
        >
          <Column
            className="headings"
            title="Name"
            dataIndex="legalName"
            key="legalName"
          />
          <Column
            className="headings"
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Column
            width={80}
            className="headings align-right"
            title="ID"
            dataIndex="id"
            key="id"
          />
          <Column
            className="headings"
            title="Status"
            dataIndex="status"
            key="status"
          />
          <Column
            className="headings align-right"
            title="Last Login"
            dataIndex="lastLogin"
            key="lastLogin"
            render={(lastLogin) => (
              <div>
                {lastLogin ? moment(lastLogin).format("DD/MM/YY HH:mm") : "-"}
              </div>
            )}
          />
        </Table>
      </div>
      <div className="flex justify-between items-center">
        <button
          className="bg-black rounded-full text-white px-4 py-1 whitespace-nowrap"
          onClick={triggerCancelEvent}
        >
          {" "}
          Cancel{" "}
        </button>
        <div className="flex gap-x-2">
          <button
            className="bg-[#5B7FFC] rounded-full text-white px-4 py-1"
            onClick={triggerDisconnectEvent}
            disabled={activeOBOInvestor === ""}
          >
            {" "}
            Disconnect{" "}
          </button>
          <button
            className="bg-[#5B7FFC] rounded-full text-white px-4 py-1"
            onClick={triggerSelectionEvent}
            disabled={activeSelectedRow === ""}
          >
            {" "}
            Select Investor{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ParticipantDetail;
