import Modal from "antd/es/modal";

export default function AdjustPrompt({
  handleCancel,
  handleConfirm,
  quantity,
  product1,
  product2,
  underlying,
}) {
  return (
    <Modal
      className="sell-Positions-modal"
      visible
      onCancel={handleCancel}
      footer={null}
    >
      <div className="text-white pt-8">
        <p>
          Being the Holder of the of the Prism Off-Market Allocations Series
          Pair (Series Pair) specified below, hereby apply to convert the Series
          Pair to the Underlying Security pursuant to the right granted under
          the Terms of Issue.
        </p>
        <p>This Early Redemption Request is irrevocable once approved.</p>
        <p>
          By completing this Early Redemption Request, I/we represent and
          warrant that the Series Pair is neither directly nor indirectly held
          in favour of a US Person and have been acquired in accordance with the
          sales restrictions set forth in the Investment Memorandum.
        </p>
        <p>
          Early redemption of {quantity} each of {product1} and {product2}, for{" "}
          {quantity} {underlying}
        </p>
        <p>Do you wish to proceed?</p>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-black rounded-full text-white px-4 py-1 w-[150px]"
          onClick={handleCancel}
        >
          No
        </button>
        <button
          className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 w-[150px]"
          onClick={() => handleConfirm()}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
}
