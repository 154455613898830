import React, { Component } from "react";
import "./styles.scss";
import { etaPairMapping } from "./config";

class ETAPills extends Component {
  render() {
    let { etaDisplayText, etaId } = this.props;
    return etaPairMapping[etaId]
      ? etaPairMapping[etaId]?.icon
      : etaPairMapping["ERROR"].displayLabel;
  }
}

export default ETAPills;
