import { createColumnHelper } from "@tanstack/react-table";
import NumberFormatter from "../common/NumberFormatter";
import { convertToDateFormat, getInvestorKYCStatus } from "../../utils/utile";
import moment from "moment";
import ETAPill from "../common/ETAPills/ETAPill";

const convertToDate = (dateStr) => {
  if (!dateStr) return "";
  return new Date(dateStr).getTime();
};

const columnHelper = createColumnHelper();

export const getColumns = (type, handleBid, userDetail) => {
  const dynamicCol =
    type === "GROWTH"
      ? [
          columnHelper.accessor("offerLeverage", {
            header: "Multiple",
            meta: {
              tooltipContent:
                "last price divided by offer price, the potential increased return of the POA over the underlying",
            },
            cell: (info) =>
              info.getValue()
                ? parseFloat(info.getValue()).toFixed(1) + "x"
                : "-",
          }),
        ]
      : [
          columnHelper.accessor("offerYield", {
            header: "Yield",
            meta: {
              tooltipContent:
                "potential increase in return of the POA over the underlying",
            },
            cell: (info) =>
              info.getValue()
                ? parseFloat(info.getValue()).toFixed(1) + "%"
                : "-",
          }),
        ];
  console.log("dynamicCol", dynamicCol);
  return [
    columnHelper.accessor("underlying", {
      header: "Underlying",
      meta: {
        headerClassName: "rounded-tl-lg",
        tooltipContent:
          "the listed security to be split into growth and income",
      },
    }),
    columnHelper.accessor("lastPx", {
      header: "Last Close",
      meta: {
        tooltipContent:
          "the most recent closing price of the underlying security",
      },
      cell: (info) => (
        <NumberFormatter
          value={info.getValue()}
          isCurrency={true}
          tickerCode={info.row.original?.underlying}
          decimalScale={2}
          emptyContent={"-"}
        />
      ),
    }),
    columnHelper.accessor("productName", {
      header: "Product",
      meta: {
        darkCell: true,
        headerClassName: "border-l border-white",
        className: "border-l border-l-white ",
        tooltipContent: "the Prism POA that is available to buy",
      },
      cell: (info) => (
        <ETAPill
          id={crypto.randomUUID()}
          productId={info.getValue()}
          className="w-[110px]"
        />
      ),
    }),
    columnHelper.accessor("offerEstabPrice", {
      header: "Capital Allocation",
      meta: {
        darkCell: true,
        tooltipContent: "the price at which the POA for sale is issued",
      },
      cell: (info) => (
        <NumberFormatter
          value={info.getValue()}
          isCurrency={true}
          tickerCode={info.row.original?.underlying}
          decimalScale={2}
          emptyContent={"-"}
        />
      ),
    }),
    columnHelper.accessor("offerMaturity", {
      header: "Maturity",
      meta: {
        darkCell: true,
        tooltipContent:
          "the maturity date or remaining term of the POA on sale ",
      },
      cell: (info) =>
        info.row.original?.offerId ? (
          <div>
            {info.row.original?.opportunityType === "MATCH_BID"
              ? info.row.original?.term + " years"
              : convertToDateFormat(info.getValue())}
          </div>
        ) : (
          "-"
        ),
      sortingFn: (a, b) => {
        const dateA = convertToDate(a.original.offerMaturity);
        const dateB = convertToDate(b.original.offerMaturity);
        return dateA - dateB;
      },
    }),
    columnHelper.accessor("offerQty", {
      header: "Quantity",
      meta: {
        darkCell: true,
        tooltipContent: "the number of units of the POA product on sale",
      },
      cell: (info) => (
        <NumberFormatter value={info.getValue()} emptyContent={"-"} />
      ),
    }),
    columnHelper.accessor("offerPrice", {
      header: "Offer",
      meta: {
        darkCell: true,
        headerClassName: "border-r border-white",
        className: "border-r border-r-white ",
        tooltipContent: "the price per unit being asked",
      },
      cell: (info) => (
        <NumberFormatter
          value={info.getValue()}
          isCurrency={true}
          tickerCode={info.row.original?.underlying}
          decimalScale={2}
          emptyContent={"-"}
        />
      ),
    }),
    ...dynamicCol,
    columnHelper.accessor("considerationTypeList", {
      header: "Settle With",
      meta: {
        tooltipContent:
          "what type of consideration you will need to settle your purchase",
      },
    }),
    columnHelper.accessor("lastUpdateDate", {
      header: "Last Update",
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format("DD MMM YY") : "-",
    }),
    columnHelper.display({
      id: "action",
      header: "Enter Bid",
      meta: {
        hideSort: true,
        tooltipContent:
          "click on the button to enter an expression of interest for this POA",
      },
      cell: (props) => (
        <button
          disabled={getInvestorKYCStatus(userDetail) !== "REGISTERED_APPROVED"}
          className={`${
            getInvestorKYCStatus(userDetail) !== "REGISTERED_APPROVED" &&
            "cursor-not-allowed"
          } text-[#D9D9D9] py-1 px-5 self-end bg-black bg-black-gradient rounded-[20px]`}
          onClick={() => handleBid(props.row.original)}
        >
          Bid
        </button>
      ),
    }),
  ];
};
