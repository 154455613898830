import React, { Component } from "react";
import "./styles.scss";
import PageContent from "../common/PageContent";
import { map } from "lodash";
import { Row, Col } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  OnlinePDFRegistrationDocument,
  SophisticatedDocumentContent,
} from "../common/constants";
class OnlinePDFRegistration extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmitApplication = () => {
    this.props.history.push("/online-pdf-upload");
  };

  render() {
    return (
      <PageContent
        className="pdf-registration-document-container"
        childrenClassName={"document-container"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <div className="pdf-registration-content">
              <div className="sub-header">
                Registering to trade on the POA Platform
              </div>
            </div>
          </Col>
          <Col span={12} />
          <Col span={12}>
            <div className="pdf-registration-content">
              <p>
                Download the Registration form below which matches your
                Investing Entity Type.
              </p>
              <p>
                Once saved locally complete the form by typing details directly
                within the form, sign and save.
              </p>
              <p>
                Prepare your identification documents, (they will need to be
                certified copies).
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div className="pdf-registration-content">
              <p>
                PRISM POA’s are only available to Investors classified as
                Sophisticated or Wholesale.
              </p>
              <p>
                Download the appropriate Sophisticated / Wholesale Investor
                forms below which matches your circumstances.
              </p>
              <p>
                Once saved locally complete the form by typing details directly
                within the form, sign and save.
              </p>
              <p>
                Provide evidence requested on the Wholesale Client Certificate.
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div className="pdf-registration-content">
              <div className="sub-header">Registration Forms</div>
              <div className="investing-entity-header">
                Investing Entity Type:
              </div>
              <ul className="document-list-reg-forms">
                {map(OnlinePDFRegistrationDocument, (item) => (
                  <li>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      download={item.label}
                      title={item.label}
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col span={12}>
            <div className="pdf-registration-content">
              <div className="sub-header">
                {" "}
                Sophisticated / Wholesale Investor Documents{" "}
              </div>
              <ul className="document-list">
                {map(SophisticatedDocumentContent, (item) => (
                  <li>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      download={item.label}
                      title={item.label}
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col span={20}>
            <div className="pdf-registration-content-mail">
              <div>
                Filled forms and supporting documents should be emailed to:
              </div>
              <a href="mailto:onboarding@prism.markets">
                onboarding@prism.markets
              </a>
            </div>
          </Col>
          <Col span={4}>
            <button
              className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 whitespace-nowrap submit-application"
              onClick={this.handleSubmitApplication}
            >
              Submit Application <ArrowRightOutlined className="right-arrow" />
            </button>
          </Col>
        </Row>
      </PageContent>
    );
  }
}
export default OnlinePDFRegistration;
