import React, { Component } from "react";
import "./style.scss";
import Input from "antd/es/input";
import { intl } from "../../utils/intl_i18n";
import axiosConfig from "../../axiosConfig";
import ROUTES from "../../routes/api.json";
import cn from "classnames";

class Acn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  validateAbn = (abn) => {
    var digits = abn.toString().split("");
    var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    var sum = 0;
    for (let i = 0; i < digits.length; i++) {
      //do the calculation
      if (i == 0) {
        digits[i] = digits[i] - 1;
      }
      sum += digits[i] * weights[i];
    }
    if (sum % 89 == 0) {
      return true;
    } else {
      return false;
    }
  };

  validateAcn = (acn) => {
    var digits = acn.toString().split("");
    var weights = [8, 7, 6, 5, 4, 3, 2, 1];
    var sum = 0;
    var check = 0;
    for (let i = 0; i < weights.length; i++) {
      //do the calculation
      sum += digits[i] * weights[i];
    }
    check = (10 - (sum % 10)) % 10;
    if (digits[8] == check) {
      return true;
    } else {
      return false;
    }
  };

  abnAcnLookup = (abnAcnValue, endPoint, params) => {
    const abn_acn = abnAcnValue;
    var errors = { ...this.state.errors };
    var errorStr = "";
    var abnObj = {};
    console.log("endpoint", endPoint);
    if (abn_acn) {
      axiosConfig.get(endPoint, { params }).then((res) => {
        abnObj = res.data.slice(
          res.data.toString().indexOf("(") + 1,
          res.data.toString().lastIndexOf(")")
        );
        abnObj = JSON.parse(abnObj);
        errorStr = "";
        errors = { ...errors, abnAcn: errorStr };
        this.props.companyName(abnObj.EntityName);
        //PBT-1575 - Option to set the CompanyField editable when the API is called and ACN Number is validate, the company name is empty
        if (!abnObj.EntityName) {
          this.props.validateCompanyNameField(true);
        } else {
          this.props.validateCompanyNameField(false);
        }
        this.props.abnErrors(errors);
      });
    } else {
      errors.abnAcn = "Not a valid ABN or ACN";
      this.props.validateCompanyNameField(false); // Company Field is not editable when the ACN/ABN number is not valid
      this.props.companyName("");
      this.props.abnErrors(errors);
    }
  };

  /**
   * handle on input change
   */
  handleChange = (e) => {
    this.props.abnNumber(e.target.value);
    var errors = { ...this.state.errors };
    var abn_acn = e.target.value;

    if (abn_acn !== "") {
      //remove spaces and update
      abn_acn = abn_acn.toString().replace(/\s+/g, "");

      //validate only digits
      var isNumber = /^[0-9]+$/.test(abn_acn);
      if (!isNumber) {
        errors.abnAcn = "Not a valid ABN or ACN";
        this.props.validateCompanyNameField(false); // Company Field is not editable when the ACN/ABN number is not valid
        this.props.companyName("");
        this.props.abnErrors(errors);
        return false; // Invalid abn_acn, only numbers are allowed.
      }

      if (abn_acn.toString().length === 11) {
        if (this.validateAbn(abn_acn)) {
          let params = {
            abn: abn_acn,
          };
          this.abnAcnLookup(abn_acn, ROUTES.ABN_LOOKUP, params);
        }
      } else if (abn_acn.toString().length === 9) {
        if (this.validateAcn(abn_acn)) {
          let params = {
            acn: abn_acn,
          };
          this.abnAcnLookup(abn_acn, ROUTES.ACN_LOOKUP, params);
        }
      } else {
        errors.abnAcn = "Not a valid ABN or ACN";
        this.props.validateCompanyNameField(false); // Company Field is not editable when the ACN/ABN number is not valid
        this.props.companyName("");
        this.props.abnErrors(errors);
      }
    } else {
      errors.abnAcn = "Not a valid ABN or ACN";
      this.props.validateCompanyNameField(false); // Company Field is not editable when the ACN/ABN number is not valid
      this.props.companyName("");
      this.props.abnErrors(errors);
    }
  };

  render() {
    return (
      <Input
        className={cn(this.props.className, "abn-input-field")}
        placeholder={
          this.props.placeHolderText || this.formatMessage_i18n("acn.Number")
        }
        onChange={this.handleChange}
        value={this.props.value}
      />
    );
  }
}

export default Acn;
