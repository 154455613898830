import { useState, useEffect } from "react";
import "./styles.scss";
import { Tab } from "@headlessui/react";
import SearchIcon from "../logo/searchIcon";
import FilterPanel from "../common/Filters/FilterPanel";
import Container from "@mui/material/Container";
import PageContent from "../common/PageContent";
import Products from "./Products";
import NotApprovedBanner from "../common/NotApprovedBanner";
import OnlinePDFRegistrationBanner from "../common/OnlinePDFRegistrationBanner";
import { INVESTOR_SHARIA_COMPLIANT_GROUPNAME } from "../common/constants";
import { useUserDetail } from "../../hooks/investors";
import { usePersistingGrowthIncomeTab } from "../../hooks/tabs";
import { useBuyOpportunities } from "../../hooks/opportunities";
import { getFilteredOpportunities } from "./utils";
import { activeCurrencyAtom, activeProductsAtom } from "../../store/atoms";
import { useAtomValue } from "jotai";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabIndexMap = {
  0: "GROWTH",
  1: "INCOME",
};

export default function AvailableToBuy() {
  const [searchValue, setSearchValue] = useState("");
  const [activeIndex, setActiveIndex] = usePersistingGrowthIncomeTab();

  const { data: opportunitiesData, isLoading, error } = useBuyOpportunities();
  const { data: userDetail } = useUserDetail();

  const investorIsShariaCompliant = userDetail?.groups?.includes(
    INVESTOR_SHARIA_COMPLIANT_GROUPNAME
  );

  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const activeProducts = useAtomValue(activeProductsAtom);

  const growthOpportunities = getFilteredOpportunities(
    opportunitiesData,
    "GROWTH",
    activeCurrency,
    activeProducts,
    searchValue
  );
  const incomeOpportunities = getFilteredOpportunities(
    opportunitiesData,
    "INCOME",
    activeCurrency,
    activeProducts,
    searchValue
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PageContent>
      <Container maxWidth="xl" sx={{ marginTop: "15px" }}>
        <div className="text-white text-[40px] font-extralight text-center">
          Available to Buy
        </div>
        <NotApprovedBanner />
        <OnlinePDFRegistrationBanner />
        <Tab.Group
          selectedIndex={activeIndex}
          onChange={(index) => setActiveIndex(index)}
        >
          <Tab.Panels className="bg-[#2f333b] rounded-xl p-8 max-h-[calc(100vh_-_265px)] flex flex-col space-y-4 divide-y divide-black">
            <div className="flex flex-col md:flex-row justify-between gap-2">
              <div className="flex space-x-4 items-center">
                <Tab.List className="flex space-x-4 p-1">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "rounded-full py-2 w-[100px] text-sm font-light leading-5 text-white border border-[#797D82]",
                        "ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-1",
                        selected
                          ? "bg-black font-semibold shadow"
                          : "text-blue-100 hover:bg-[#343741]/[0.12] hover:text-white"
                      )
                    }
                  >
                    Growth ({growthOpportunities?.length})
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "rounded-full py-2 w-[100px] text-sm font-light leading-5 text-white border border-[#797D82]",
                        "ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-1",
                        selected
                          ? "bg-black font-semibold shadow"
                          : "text-blue-100 hover:bg-[#343741]/[0.12] hover:text-white",
                        investorIsShariaCompliant && "hidden"
                      )
                    }
                  >
                    Dividend ({incomeOpportunities?.length})
                  </Tab>
                </Tab.List>
                <div className="relative rounded-full shadow-sm">
                  <input
                    type="text"
                    name="search-input"
                    id="search-input"
                    className="bg-transparent block w-full rounded-full border border-[#797D82] py-1.5 pl-4 pr-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    style={{
                      fill: "linear-gradient(135deg, rgba(232, 232, 232, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%)",
                      boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.30) inset",
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <SearchIcon />
                  </div>
                </div>
              </div>
              <FilterPanel currency side={tabIndexMap[activeIndex]} />
            </div>
            <Tab.Panel className="flex flex-col pt-4 overflow-y-scroll">
              <Products
                opportunities={growthOpportunities}
                type="GROWTH"
                searchValue={searchValue}
                isLoading={isLoading}
                error={error}
              />
            </Tab.Panel>
            <Tab.Panel
              className={`flex flex-col pt-4 overflow-y-scroll ${
                investorIsShariaCompliant && "hidden"
              }`}
            >
              <Products
                opportunities={incomeOpportunities}
                type="INCOME"
                searchValue={searchValue}
                isLoading={isLoading}
                error={error}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </PageContent>
  );
}
