import Modal from "antd/es/modal";

export default function ConfirmCancel({ handleCancel, handleConfirm }) {
  return (
    <Modal
      className="sell-Positions-modal"
      visible
      onCancel={handleCancel}
      footer={null}
    >
      <div className="text-white pt-8">
        <p>Do you wish to cancel this order?</p>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-black rounded-full text-white px-4 py-1 w-[150px]"
          onClick={handleCancel}
        >
          No
        </button>
        <button
          className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 w-[150px]"
          onClick={() => handleConfirm()}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
}
