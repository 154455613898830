import React, { Component } from "react";
import "./style.scss";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import { message, Modal } from "antd";
import { intl } from "../../../../utils/intl_i18n";
import { individualTaxResidentErrorMessage } from "../../../common/message";
import TaxResidentContent from "../../../TaxResidentContent";
import NumberFormat from "react-number-format";
import { validateTfn } from "../../../common/validation";

class CountryOfTaxResidency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      viewDetailsPreviewModal: false,
      type: props.type || "",
    };

    if (!this.state.payload.countryTaxResidentList) {
      this.state.payload.countryTaxResidentList = [];
      this.addCountryList();
    }
  }

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
    if (!isNaN(this.state.payload.taxFileNumber)) {
      this.validateTaxFileNumber(this.state.payload.taxFileNumber);
    }
  }

  validateTaxFileNumber = (tfn) => {
    const validateStatus = validateTfn(tfn);
    if (validateStatus && !validateStatus.isTfnValid) {
      message.error({ content: validateStatus.errorMessage });
      this.onValidationFail();
    }
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload, afterUpdate) => {
    this.setState(payload, () => {
      if (afterUpdate) {
        afterUpdate();
      }
      this.validate();
    });
  };

  resetCountryTaxResidentFields = (payload, value) => {
    payload.countryTaxResidentList = [];
    if (value === "yes") {
      this.addCountryList();
    }
  };

  addCountryList = () => {
    const { payload } = this.state;
    payload.countryTaxResidentList.push({ country: "", tin: "", reason: "" });
    this.updateState(payload, this.scrolltoActiveResidentList);
  };

  removeCountryList = (taxResident) => {
    const { payload } = this.state;
    let index = payload.countryTaxResidentList.indexOf(taxResident);
    payload.countryTaxResidentList.splice(index, 1);
    this.updateState(payload);
  };

  /**
   * @method - Method to scroll to the newly added Country List
   */
  scrolltoActiveResidentList = () => {
    const taxResidentList =
      document.getElementsByClassName("country-input").length;
    if (taxResidentList > 0) {
      document
        .getElementsByClassName("country-input")
        [taxResidentList - 1].focus();
    }
  };

  validateCountryTaxResidentList = () => {
    const { payload } = this.state;
    var flag = true;
    if (!payload.countryTaxResidentList) {
      return false;
    }
    if (payload.countryTaxResidentList.length === 0) {
      return false;
    }
    for (var i = 0; i < payload.countryTaxResidentList.length; i++) {
      const taxResident = payload.countryTaxResidentList[i];
      if (!taxResident.country || (!taxResident.tin && !taxResident.reason)) {
        flag = false;
        break;
      }
    }

    return flag;
  };

  handleYesNo = (e, field) => {
    const { payload } = this.state;
    payload[field] = e.target.value;
    // Resetting the payload field whenever the value has been changed
    if (field === "isTaxNonAustralian") {
      this.resetCountryTaxResidentFields(payload, e.target.value);
    }

    if (!this.validateIndividualResidantStatus(payload)) {
      message.error({
        content: individualTaxResidentErrorMessage,
        duration: 3,
      });
    }
    this.updateState(payload);
  };

  handleCountryChange = (value, taxResident) => {
    const { payload } = this.state;
    taxResident.country = value;
    this.updateState(payload);
  };

  handleTinChange = (e, taxResident) => {
    const { payload } = this.state;
    taxResident.tin = e.target.value;
    // Clearing the reason field whenever the TIN value is not empty
    if (e.target.value !== "") {
      taxResident.reason = "";
    }
    this.updateState(payload);
  };

  handleReason = (e, taxResident) => {
    const { payload } = this.state;
    taxResident.reason = e.target.value;
    // Clearing the tin field whenever the REASON value is not empty
    if (e.target.value !== "") {
      taxResident.tin = "";
    }
    this.updateState(payload);
  };

  validate = () => {
    const { payload, type } = this.state;

    if (
      type === "individual" &&
      (payload.isInvestorAustralianResident === undefined ||
        !payload.isInvestorAustralianResident ||
        !this.validateIndividualResidantStatus())
    ) {
      this.onValidationFail();
      return;
    }

    if (
      payload.isTaxNonAustralian === undefined ||
      !payload.isTaxNonAustralian
    ) {
      this.onValidationFail();
      return;
    }

    if (
      payload.isTaxNonAustralian === "yes" &&
      !this.validateCountryTaxResidentList()
    ) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  handleOk = () => {
    this.setState({ viewDetailsPreviewModal: false });
  };

  getTaxRulesContent = (e) => {
    this.setState({ viewDetailsPreviewModal: true });
  };

  getTaxResidentContent = (type) => {
    let residentLabel =
      "Is the company a tax resident of a country other than Australia?";
    let tinLabel =
      "Please provide the company’s country of tax residence and tax identification number (“TIN”) or equivalent, for all relevant countries.";
    if (type && type === "individual") {
      residentLabel =
        "Is the individual a tax resident of a country other than Australia?";
      tinLabel =
        "Please provide the individual's country of tax residence and tax identification number (“TIN”) or equivalent, for all relevant countries.";
    }
    return { residentLabel, tinLabel };
  };

  /**
   * @method to Validate the tax Individual and Investor Resident Status for "Individual" investor type
   */
  validateIndividualResidantStatus = () => {
    const { payload, type } = this.state;
    let isValidate = true;
    if (
      type === "individual" &&
      payload.isInvestorAustralianResident === "no" &&
      payload.isTaxNonAustralian === "no"
    ) {
      isValidate = false;
    }
    return isValidate;
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    this.updateState(payload);
  };

  render() {
    const { payload, viewDetailsPreviewModal, type } = this.state;
    const { residentLabel, tinLabel } = this.getTaxResidentContent(type);
    return (
      <div className="country-of-tax-residency">
        <div className="title">Tax Information</div>
        <div className="container-inner">
          {type !== "individual" && (
            <div className="sub-title">Country of Tax Residency</div>
          )}
          <div
            className="tax-rules"
            onClick={(e) => this.getTaxRulesContent(e)}
          >
            Tax Residency Rules*
          </div>
          {type === "individual" && (
            <>
              <div className="question">Are you tax resident in Australia?</div>
              <div className="select">
                <select
                  onChange={(e) =>
                    this.handleYesNo(e, "isInvestorAustralianResident")
                  }
                  value={payload.isInvestorAustralianResident}
                  className={cn({
                    "error-field":
                      _isUndefined(payload.isInvestorAustralianResident) ||
                      payload.isInvestorAustralianResident === "" ||
                      !this.validateIndividualResidantStatus(),
                  })}
                >
                  <option value="">Yes/No</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {payload.isInvestorAustralianResident === "yes" && (
                  <NumberFormat
                    className={cn("countryOfTaxResidency-tfn")}
                    placeholder={this.formatMessage_i18n(
                      "countryOfTaxResidency.taxFileNumber"
                    )}
                    onChange={(e) =>
                      this.onInputChange("taxFileNumber", e.target.value)
                    }
                    value={payload.taxFileNumber}
                    type="password"
                    onBlur={(e) => {
                      this.validateTaxFileNumber(e.target.value);
                    }}
                  />
                )}
              </div>
            </>
          )}

          <div className="question">{residentLabel}</div>
          <div className="select">
            <select
              onChange={(e) => this.handleYesNo(e, "isTaxNonAustralian")}
              value={payload.isTaxNonAustralian}
              className={cn({
                "error-field":
                  _isUndefined(payload.isTaxNonAustralian) ||
                  payload.isTaxNonAustralian === "" ||
                  !this.validateIndividualResidantStatus(),
              })}
            >
              <option value="">Yes/No</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <TaxResidentContent
            tinLabel={tinLabel}
            renderCountryContent={
              payload.isTaxNonAustralian !== "" &&
              payload.isTaxNonAustralian !== "no"
            }
            residentList={payload.countryTaxResidentList}
            countryChangeCallback={(val, taxResidentList) => {
              this.handleCountryChange(val, taxResidentList);
            }}
            tinChangeCallback={(e, taxResidentList) =>
              this.handleTinChange(e, taxResidentList)
            }
            reasonChangeCallback={(e, taxResidentList) =>
              this.handleReason(e, taxResidentList)
            }
            addCountryListCallback={this.addCountryList}
            removeCountryListCallback={this.removeCountryList}
          />
        </div>
        <Modal
          className="view-details-modal"
          visible={viewDetailsPreviewModal}
          onOk={this.handleOk}
          onCancel={this.handleOk}
          footer={null}
        >
          {this.formatMessage_i18n("tax.rules.content")}
        </Modal>
      </div>
    );
  }
}

export default CountryOfTaxResidency;
