import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "../components/login";
import Register from "../components/register";
import Welcome from "../components/welcome";
import AvailableToBuy from "../components/available-to-buy/AvailableToBuy";
import OpenInterests from "../components/openOrders";
import OrderBook from "../components/orderBook";
import PlaceOrder from "../components/place-order";
import ShareholderApplication from "../components/shareholder-application";
import Portfolio from "../components/portfolio";
import TransactionDocument from "../components/transactionDocuments";
import OnlinePDFRegistration from "../components/onlinePDFRegistration";
import ResetPassword from "../components/reset-password";
import ResetPasswordRequest from "../components/confirm-reset";
import _isEmpty from "lodash/isEmpty";
import CompleteSignup from "../components/complete-signup";
import SampleMessage from "../components/sample-message";
import SessionValidation from "../utils/sessionValidation";
import Investor from "../components/investor";
import SophisticatedDocuments from "../components/sphstctdInvstrDocs";
import ExpiredPassword from "../components/password-expired";
import UploadAndRegister from "../components/onlinePDFRegistration/upload-and-register";
import ViewUploads from "../components/onlinePDFRegistration/view-uploads";
import ContactUs from "../components/contact-us";

function Routers() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to={!_isEmpty(verifyAccess()) ? "/welcome" : "/login"} />
        </Route>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/register" component={Register} />
        <PrivateRoute exact path="/welcome" component={Welcome} />
        <PrivateRoute exact path="/available" component={AvailableToBuy} />
        <PrivateRoute exact path="/open-interests" component={OpenInterests} />
        <PrivateRoute exact path="/order" component={PlaceOrder} />
        <PrivateRoute
          exact
          path="/shareholder-application"
          component={ShareholderApplication}
        />
        <PrivateRoute exact path="/portfolio" component={Portfolio} />
        <PrivateRoute exact path="/investor" component={Investor} />
        <PrivateRoute
          exact
          path="/transaction-document"
          component={TransactionDocument}
        />
        <PrivateRoute
          exact
          path="/sophisticated-documents"
          component={SophisticatedDocuments}
        />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/complete-signup" component={CompleteSignup} />
        <Route exact path="/confirm-reset" component={ResetPasswordRequest} />
        <Route exact path="/sample-message" component={SampleMessage} />
        <Route exact path="/expired-password" component={ExpiredPassword} />
        <PrivateRoute
          exact
          path="/online-pdf-registration"
          component={OnlinePDFRegistration}
        />
        <Route exact path="/online-pdf-upload" component={UploadAndRegister} />
        <Route exact path="/view-uploaded-documents" component={ViewUploads} />
        <Route exact path="/contact-us" component={ContactUs} />
      </Switch>
    </BrowserRouter>
  );
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  new SessionValidation({
    onFailed: () => {
      sessionStorage.clear();
      window.location = "/login";
    },
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        !_isEmpty(verifyAccess()) === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}

export const verifyAccess = () => {
  let session = sessionStorage.getItem("pstack") || "";
  return session;
};

export default Routers;
