import { createColumnHelper } from "@tanstack/react-table";
import NumberFormatter from "../../common/NumberFormatter";
import {
  convertToDateFormat,
  convertToDateTimeFormat,
} from "../../../utils/utile";
import moment from "moment";
import ActionMenu from "../../common/ActionMenu";
import ETAPill from "../../common/ETAPills/ETAPill";
import { orderType, seriesType, otherSide } from "../../common/constants";

const columnHelper = createColumnHelper();

export const getColumns = (viewOrderAudit) => {
  return [
    columnHelper.accessor("groupId", {
      header: "ID",
      cell: (info) => info.getValue() || "-",
    }),
    columnHelper.accessor("updatedTime", {
      header: "Created",
      cell: (info) => convertToDateTimeFormat(info.getValue()) || "-",
    }),
    columnHelper.accessor("orderSide", {
      header: "Side",
      cell: (info) => {
        const record = info.row.original;
        if (record?.orderType === orderType.REDEEM) {
          return orderType.REDEEM;
        }
        if (record?.orderType === orderType.SHARE) {
          return "SHARE APP";
        }
        return info.getValue() || "-";
      },
    }),
    columnHelper.accessor("underlying", {
      header: "Underlying Code",
      cell: (info) => info.getValue() || "-",
    }),
    columnHelper.accessor("productName", {
      header: "Product",
      cell: (info) => {
        const record = info.row.original;
        return info.getValue() ? (
          <>
            {(record?.orderType === orderType.REDEEM ||
              record?.orderType === orderType.SHARE) && (
              <div style={{ paddingTop: "5px" }}>
                <ETAPill
                  id={crypto.randomUUID()}
                  productId={otherSide[info.getValue()]}
                  className="w-[110px]"
                />
              </div>
            )}
            <ETAPill
              id={crypto.randomUUID()}
              productId={info.getValue()}
              className="w-[110px]"
            />
          </>
        ) : (
          "-"
        );
      },
    }),
    columnHelper.accessor("considerationType", {
      header: "Consideration Type",
      cell: (info) =>
        info.row.original?.orderType === orderType.REDEEM ||
        info.row.original?.orderType === orderType.SHARE
          ? "N/A"
          : info.getValue() || "-",
    }),
    columnHelper.accessor("quantity", {
      header: "Order Qty",
      cell: (info) => (
        <NumberFormatter value={info.getValue()} emptyContent={"-"} />
      ),
    }),
    columnHelper.accessor("filledQty", {
      header: "Filled Qty",
      cell: (info) => (
        <NumberFormatter value={info.getValue()} emptyContent={"-"} />
      ),
    }),
    columnHelper.accessor("priceDollars", {
      header: "Price",
      cell: (info) => {
        const record = info.row.original;
        return record?.orderType === orderType.REDEEM ||
          record?.orderType === orderType.SHARE ? (
          "N/A"
        ) : (
          <NumberFormatter
            value={info.getValue()}
            isCurrency={true}
            tickerCode={record.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        );
      },
    }),
    columnHelper.accessor("clientRef", {
      header: "Your Ref",
    }),
    columnHelper.accessor("term", {
      header: "Term / Maturity",
      cell: (info) => {
        const record = info.row.original;
        return record?.maturityDate ? record?.maturityDate : info.getValue();
      },
    }),
    columnHelper.accessor("status", {
      header: "Status",
    }),
    columnHelper.accessor("", {
      header: "Action",
      cell: (info) => {
        const record = info.row.original;
        return (
          <div>
            <a onClick={() => viewOrderAudit(record)}>EOI Audit Trail</a>
          </div>
        );
      },
    }),
  ];
};
