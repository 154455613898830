import { useState, useMemo, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosConfig from "../axiosConfig";
import ROUTES from "../routes/api.json";
import { useInvestorId } from "../components/common/utils";
import { useParams } from "react-router-dom";
import { orderType } from "../components/common/constants";
import { orderProductByShareholderApplicationOption } from "../components/place-order/config";

const rollbackOpenOrdersUpdate = (
  err,
  variables,
  context,
  investorId,
  queryClient
) => {
  if (context?.previousOrders) {
    queryClient.setQueryData(
      ["openOrders", investorId],
      context.previousOrders
    );
  }
};

export const useSubmitOrder = () => {
  const investorId = useInvestorId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ requestData, lastPrice }) => {
      const { data } = await axiosConfig.post(
        `${ROUTES.MY_ORDERS}/${investorId}`,
        requestData
      );
      return data;
    },
    enabled: !!investorId,
    onMutate: ({ requestData: newOrder, lastPrice }) => {
      const previousOrders = queryClient.getQueryData([
        "openOrders",
        investorId,
      ]);
      const updatedOrders = [
        ...previousOrders,
        {
          id: 0,
          securityCode: null,
          groupId: 0,
          priorId: null,
          seriesType: newOrder.productName.includes("DIV")
            ? "INCOME"
            : "GROWTH",
          estabPrice: 0.0,
          remainingQty: newOrder.quantity,
          filledQty: 0,
          allowPartialMatch: false,
          status: "NEW",
          orderType: "LIMIT",
          productNameDisplay: newOrder.productName
            .toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(""),
          ...newOrder,
          considerationType: newOrder.considerationType == 1 ? "CASH" : "STOCK",
          lastPrice,
        },
      ];
      queryClient.setQueryData(["openOrders", investorId], updatedOrders);
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["openOrders", investorId] });
      }, 2000);
      return { previousOrders };
    },
    onError: (err, variables, context) =>
      rollbackOpenOrdersUpdate(err, variables, context),
  });
};

export const useAmendOrder = () => {
  const investorId = useInvestorId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ requestData, lastPrice }) => {
      const { data } = await axiosConfig.put(
        `${ROUTES.MY_ORDERS}/${investorId}`,
        requestData
      );
      return data;
    },
    enabled: !!investorId,
    onMutate: ({ requestData: updatedOrder, lastPrice }) => {
      const previousOrders = queryClient.getQueryData([
        "openOrders",
        investorId,
      ]);
      const updatedOrders = previousOrders.map((order) => {
        if (order.id == updatedOrder.id) {
          return {
            id: 0,
            securityCode: null,
            groupId: 0,
            priorId: null,
            seriesType: updatedOrder.productName.includes("DIV")
              ? "INCOME"
              : "GROWTH",
            estabPrice: 0.0,
            remainingQty: updatedOrder.quantity,
            filledQty: 0,
            allowPartialMatch: false,
            status: "updated",
            orderType: "LIMIT",
            productNameDisplay: updatedOrder.productName
              .toLowerCase()
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(""),
            ...updatedOrder,
            considerationType:
              updatedOrder.considerationType == 1 ? "CASH" : "STOCK",
            maturityDate: updatedOrder?.maturityDate,
            lastPrice,
          };
        } else {
          return order;
        }
      });
      queryClient.setQueryData(["openOrders", investorId], updatedOrders);
      return { previousOrders };
    },
    onError: (err, variables, context) =>
      rollbackOpenOrdersUpdate(
        err,
        variables,
        context,
        investorId,
        queryClient
      ),
  });
};

export const useDeleteOrder = () => {
  const investorId = useInvestorId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (requestData) => {
      const { data } = await axiosConfig.delete(
        `${ROUTES.MY_ORDERS}/${investorId}`,
        { data: requestData }
      );
      return data;
    },
    enabled: !!investorId,
    onMutate: (deletedOrder) => {
      const previousOrders = queryClient.getQueryData([
        "openOrders",
        investorId,
      ]);
      const updatedOrders = previousOrders.filter(
        (order) => order.id !== deletedOrder.id
      );
      queryClient.setQueryData(["openOrders", investorId], updatedOrders);
      return { previousOrders };
    },
    onError: (err, variables, context) => {
      if (context?.previousOrders) {
        queryClient.setQueryData(
          ["openOrders", investorId],
          context.previousOrders
        );
      }
    },
  });
};

export const useOrders = () => {
  const investorId = useInvestorId();
  return useQuery({
    queryKey: ["openOrders", investorId],
    queryFn: async () => {
      const { data } = await axiosConfig.get(
        `${ROUTES.OPEN_ORDERS}/${investorId}`
      );
      return data;
    },
    enabled: !!investorId,
    staleTime: 60 * 60 * 1000,
  });
};

export const useOrderById = (orderId) => {
  const { data: orders, ...query } = useOrders();
  return {
    ...query,
    data: orders?.find((order) => order.id == orderId),
  };
};

export const useAllOrdersByUnderlyingProduct = (
  underlying,
  productName,
  investorId
) => {
  return useQuery({
    queryKey: ["allOrders", underlying, productName, investorId],
    queryFn: async () => {
      const { data } = await axiosConfig.get(
        `${ROUTES.OPEN_ORDERS}/expanded/${underlying}/${productName}/${investorId}`
      );
      return data;
    },
    staleTime: 60 * 60 * 1000,
  });
};

export const useShareOrder = () => {
  const investorId = useInvestorId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ requestData }) => {
      const { data } = await axiosConfig.post(
        `${ROUTES.SHAREHOLDER_APP}/${investorId}`,
        requestData
      );
      return data;
    },
    enabled: !!investorId,
    onMutate: ({ requestData: newOrder, lastPrice }) => {
      const previousOrders = queryClient.getQueryData([
        "openOrders",
        investorId,
      ]);
      const updatedOrders = [
        ...previousOrders,
        fetchOrderData(
          newOrder,
          newOrder.growthProduct,
          lastPrice,
          orderProductByShareholderApplicationOption[
            newOrder.shareholderApplicationOption
          ]?.receiveGrowthProductName
        ),
        fetchOrderData(
          newOrder,
          newOrder.incomeProduct,
          lastPrice,
          orderProductByShareholderApplicationOption[
            newOrder.shareholderApplicationOption
          ]?.receiveIncomeProductName
        ),
      ];
      queryClient.setQueryData(["openOrders", investorId], updatedOrders);
      return { previousOrders };
    },
    onError: (err, variables, context) =>
      rollbackOpenOrdersUpdate(err, variables, context),
  });
};
const fetchOrderData = (order, product, lastPrice, productName) => {
  return {
    id: 0,
    securityCode: null,
    groupId: 0,
    priorId: null,
    seriesType: product.includes("DIV") ? "INCOME" : "GROWTH",
    estabPrice: 0.0,
    remainingQty: order.quantity,
    filledQty: 0,
    allowPartialMatch: false,
    status: "NEW",
    orderType: orderType.SHARE,
    productNameDisplay: productName,
    ...order,
    considerationType: "STOCK",
    lastPrice,
  };
};
