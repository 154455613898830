import PlaceOrder from "../place-order";
import { SHAREHOLDER_APP } from "../place-order/config";

function ShareholderApplication() {
  return (
    <>
      <PlaceOrder type={SHAREHOLDER_APP} />
    </>
  );
}

export default ShareholderApplication;
