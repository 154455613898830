import React, { Component } from "react";
import "./style.scss";
import _isUndefined from "lodash/isUndefined";
import Input from "antd/es/input";
import cn from "classnames";
import { intl } from "../../../../utils/intl_i18n";

class NumberOfInvestors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  validate = () => {
    const { payload } = this.state;
    if (payload.noofinvestors === undefined || !payload.noofinvestors) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }
  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };
  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, v) => {
    const { payload } = this.state;

    if (field === "noofinvestors") {
      let value = parseInt(v);
      if (isNaN(value) || value < 1 || value > 4) {
        payload.noofinvestors = "";
        this.updateState(payload);
        return;
      }
      payload.noofinvestors = value;
      // Need to create a callback object and migrate this changes to the Sequence page
      payload.details = [];
      payload.currentIndex = 0;
      for (let i = 0; i < value; i++) {
        payload.details.push({});
      }
    }
    this.updateState(payload);
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="form">
        <div className="header">
          <div className="title">Individual or Sole Trader Registration</div>
        </div>
        <div className="main">
          <div className="label">
            {this.formatMessage_i18n("numberOfInvestors.noOfInvestorsUpto4")}
          </div>
          <Input
            className={cn(
              "register-input-small",
              (_isUndefined(payload.noofinvestors) ||
                payload.noofinvestors === "") &&
                "error-field"
            )}
            placeholder={this.formatMessage_i18n(
              "numberOfInvestors.noOfInvestors"
            )}
            onChange={(e) =>
              this.onInputChange("noofinvestors", e.target.value)
            }
            value={payload.noofinvestors}
          />
        </div>
      </div>
    );
  }
}

export default NumberOfInvestors;
