import { Component } from "react";
import { intl } from "../../../../utils/intl_i18n";

class DocumentSelection extends Component {
  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  fieldOnChange = (data) => {
    this.props.parentCallback(data || null);
  };

  render() {
    const { payload } = this.props;
    return (
      <div className="document-selction-main">
        <select
          onChange={(e) => {
            this.fieldOnChange(e.target.value);
          }}
        >
          <option value="">
            {" "}
            {this.formatMessage_i18n("verification.procedure.document.text")}
          </option>
          {payload.AusDrivingLicense === true ? (
            <option value="AusDrivingLicense">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.Australian.Driving.License"
              )}
            </option>
          ) : null}
          {payload.AusPassport === true ? (
            <option value="AusPassport">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.australian.passport"
              )}
            </option>
          ) : null}
          {payload.StateCardIssuanceForAgeProof === true ? (
            <option value="StateCardIssuanceForAgeProof">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.state.or.territory.card"
              )}
            </option>
          ) : null}
          {payload.CurrentPassport === true ? (
            <option value="CurrentPassport">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.current.passport"
              )}
            </option>
          ) : null}
          {payload.AusBirthCertificate === true ? (
            <option value="AusBirthCertificate">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.australian.birth.certificate"
              )}
            </option>
          ) : null}
          {payload.AusCitizenCertificate === true ? (
            <option value="AusCitizenCertificate">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.australian.citizen.certificate"
              )}
            </option>
          ) : null}
          {payload.PensionCard === true ? (
            <option value="PensionCard">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.pension.card.by.HSD"
              )}
            </option>
          ) : null}
          {payload.ForeignDrivingLicense === true ? (
            <option value="ForeignDrivingLicense">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.foreign.driving.license"
              )}
            </option>
          ) : null}
          {payload.NationalID === true ? (
            <option value="NationalID">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.national.id"
              )}
            </option>
          ) : null}
          {payload.CommonWealthOrTerritoryDoc === true ? (
            <option value="CommonWealthOrTerritoryDoc">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.commonwealth.state.territory"
              )}
            </option>
          ) : null}
          {payload.AusTaxationDoc === true ? (
            <option value="AusTaxationDoc">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.Australian.Taxation"
              )}
            </option>
          ) : null}
          {payload.LocGovtOrUtilityProviderDoc === true ? (
            <option value="LocGovtOrUtilityProviderDoc">
              {" "}
              {this.formatMessage_i18n(
                "individual-verification.document.LocalGovernment.utility.provider"
              )}
            </option>
          ) : null}
        </select>
      </div>
    );
  }
}

export default DocumentSelection;
