import React, { Component } from "react";
import "./styles.scss";
import Button from "antd/es/button";
import { CountryDropdown } from "react-country-region-selector";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

class TaxResidentContent extends Component {
  render() {
    const {
      tinLabel,
      residentList,
      countryChangeCallback,
      tinChangeCallback,
      renderCountryContent,
      reasonChangeCallback,
      addCountryListCallback,
      removeCountryListCallback,
    } = this.props;
    return (
      <>
        {renderCountryContent && (
          <div className="country-content">
            <div className="tax-information">
              <div className="question">{tinLabel}</div>
              <div className="question">
                If you cannot provide a TIN, you must select one of the three
                reasons from the drop-down.
              </div>
              <div className="tax-resident-list">
                {residentList.map((taxResidentList) => (
                  <div className="tax-resident">
                    <div className="tax-resident-content">
                      <div className="country-list">
                        <CountryDropdown
                          className={cn(
                            "tax-information-input ant-input-lg country-input",
                            (_isUndefined(taxResidentList.country) ||
                              taxResidentList.country === "") &&
                              "error-field"
                          )}
                          value={taxResidentList.country}
                          defaultOptionLabel="Country"
                          onChange={(val) => {
                            countryChangeCallback(val, taxResidentList);
                          }}
                        />
                      </div>
                      <div className="tin">
                        <input
                          className={cn("tax-information-input tin-input", {
                            "error-field":
                              (_isUndefined(taxResidentList.tin) ||
                                taxResidentList.tin === "") &&
                              (_isUndefined(taxResidentList.reason) ||
                                taxResidentList.reason === ""),
                          })}
                          type="text"
                          placeholder="Tax Identification Number"
                          onChange={(e) =>
                            tinChangeCallback(e, taxResidentList)
                          }
                          value={taxResidentList.tin}
                        />
                      </div>
                      <div className="reason-list">
                        <div
                          className={cn("reason-select", {
                            "select-box-margin": residentList.length === 3,
                          })}
                        >
                          <select
                            className={cn("tax-information-input -input", {
                              "error-field":
                                (_isUndefined(taxResidentList.reason) ||
                                  taxResidentList.reason === "") &&
                                (_isUndefined(taxResidentList.tin) ||
                                  taxResidentList.tin === ""),
                            })}
                            disabled={
                              taxResidentList.tin !== "" &&
                              taxResidentList.tin !== undefined
                            }
                            onChange={(e) =>
                              reasonChangeCallback(e, taxResidentList)
                            }
                            value={taxResidentList.reason}
                          >
                            <option value="">Reason</option>
                            <option value="The country of tax residency does not issue TINs to tax residents.">
                              The country of tax residency does not issue TINs
                              to tax residents.
                            </option>
                            <option value="The individual has not been issued with a TIN.">
                              The individual has not been issued with a TIN.
                            </option>
                            <option value="The country of tax residency does not require the TIN to be disclosed.">
                              The country of tax residency does not require the
                              TIN to be disclosed.
                            </option>
                          </select>
                        </div>
                        {/* A New Country Tax Resident List can contain only 3 lists  */}
                        {residentList.length < 3 && (
                          <div className="country-tax-add-button">
                            <Button
                              onClick={() => addCountryListCallback()}
                              icon={<PlusOutlined />}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={cn("list-clear-btn", {
                        "show-btn": residentList.length > 1,
                      })}
                    >
                      <MinusCircleOutlined
                        className="minus-icon"
                        onClick={() =>
                          removeCountryListCallback(taxResidentList)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TaxResidentContent;
