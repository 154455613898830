import { Component } from "react";
import Checkbox from "antd/es/checkbox";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import "./styles.scss";

class NationalCreditCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    const { payload } = this.state;
    if (payload.hasDeclaredCredit === undefined) {
      payload.hasDeclaredCredit = false;
      this.updateState(payload);
    }
    this.onValidationPass();
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value.target.checked;
    this.updateState(payload);
  };

  render() {
    const { hasDeclaredCredit } = this.state.payload;
    return (
      <div className="national-credit-main-content">
        <div className="reg-header">
          {" "}
          {this.formatMessage_i18n("national.credit.title.content")}
        </div>
        <div className="national-credit-text">
          {this.formatMessage_i18n("national.credit.introduction.text")}
        </div>
        <div className="national-credit-text">
          {this.formatMessage_i18n("national.credit.statement.text")}
        </div>
        <div>
          <ul className="national-credit-text">
            <li>
              {this.formatMessage_i18n("national.credit.declaration.option.1")}
            </li>
            <li>
              {this.formatMessage_i18n("national.credit.declaration.option.2")}
            </li>
          </ul>
        </div>

        <div className="national-credit-text">
          {this.formatMessage_i18n("national.credit.caution.text")}
        </div>
        <div className="national-credit-text">
          {this.formatMessage_i18n("national.credit.effect.text")}
        </div>
        <div className="national-credit-option">
          <Checkbox
            className="reg-checkbox"
            checked={hasDeclaredCredit}
            onChange={(e) => this.onChange("hasDeclaredCredit", e)}
          >
            {this.formatMessage_i18n(
              "national.credit.declaration.option.content"
            )}
            <ul className="national-credit-text">
              <li>
                {this.formatMessage_i18n(
                  "national.credit.declaration.option.1"
                )}
                {this.formatMessage_i18n("semicolon.or.text")}
              </li>
              <li>
                {this.formatMessage_i18n(
                  "national.credit.declaration.option.2"
                )}
              </li>
            </ul>
          </Checkbox>
        </div>
      </div>
    );
  }
}

export default NationalCreditCode;
