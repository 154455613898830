import React, { Component } from "react";
import "./styles.scss";
import Input from "antd/es/input";
import { InputNumber } from "antd";
import Button from "antd/es/button";
import message from "antd/es/message";
import Checkbox from "antd/es/checkbox";
import axiosConfig from "../../../axiosConfig";
import {
  availableQtyForSellOrderAmendment,
  availableQtyForSellOrderCreation,
  consideration,
  getDiffBetweenDatesInYears,
} from "../../../utils/utile";
import NumberFormatter from "../../common/NumberFormatter";
import { numberWithCommas } from "../../../utils/numberFormatting";
import ROUTES from "../../../routes/api.json";
import { Modal } from "antd";
import { warningMessaageForUnmarketableParcels } from "../../common/message";
import {
  isLeftoverLessThanMinSize,
  isTransactionLessThanMinSize,
} from "../../common/NonSophisticatedInvestorValidation";
import { currencyInputValidation, isReadOnlyStatus } from "../../common/utils";
import { fetchCurrencySymbol } from "../../common/currencies";

const isReadOnly = isReadOnlyStatus();

class SellOrder extends Component {
  constructor(props) {
    super(props);
    let order = this.props.amendOrder
      ? this.props.amendOrder
      : this.props.sellOrder;
    this.state = {
      order: order,
      priceDollars: this.props.amendOrder
        ? this.props.amendOrder.priceDollars
        : "",
      quantity: this.props.amendOrder
        ? this.props.amendOrder.remainingQty
        : this.props.sellOrder.quantity - this.props.sellOrder.lockedQty,
      reference: this.props.amendOrder ? this.props.amendOrder.clientRef : "",
      allowPartialMatch: true,
      agreeTerms: false,
      agreeProductIM: false,
      enableButton: false,
      considerationType: "",
      priceQtyPlaceholder: "Price/Qty",
      estimatedPrice: "",
      terms: null,
      availableQty: undefined,
      showWarnIcon: false,
      isInvestorKYCExpired: false,
      investorId: this.props.investorId,
    };
  }

  componentDidMount() {
    let term = null;
    let estimatedPrice = null;
    let availableQty = null;
    const { order } = this.state;
    if (order) {
      term = getDiffBetweenDatesInYears(new Date(), order.maturityDate || "");
      availableQty = availableQtyForSellOrderCreation(order);
    }
    if (this.props.amendOrder) {
      estimatedPrice = order.priceDollars * order.quantity;
      if (this.props.otcPosition) {
        availableQty = availableQtyForSellOrderAmendment(
          order,
          this.props.otcPosition
        );
      }
    }
    this.setState({
      terms: term,
      estimatedPrice: estimatedPrice,
      availableQty: availableQty,
    });
    this.validateInvestorKYCDetail();
  }

  /**
   * trade size warnings and validations only apply if the user/investor of level "Retail"
   * and if investor is some higher level than Retail having the certificate expired.
   */
  validateInvestorKYCDetail = () => {
    axiosConfig
      .get(ROUTES.INVESTOR_DETAILS_VALIDATE)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ isInvestorKYCExpired: response.data });
        } else {
          console.log(
            "Received API response with HTTP status: " + response.status
          );
        }
      })
      .catch((e) => {
        console.log("error=>", e.response);
      });
  };

  onChangeCheckbox = (field, value) => {
    let data = {};
    data[field] = value.target.checked;
    this.setState(data, () => {
      this.setState({ enableButton: this.canEnableButton() });
    });
  };

  canEnableButton = () => {
    const { agreeTerms, agreeProductIM, quantity, priceDollars } = this.state;

    if (agreeTerms && parseInt(quantity) > 0 && parseFloat(priceDollars) > 0)
      return true;

    return false;
  };

  isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  fieldOnChange = (field, value) => {
    let data = {};
    data[field] = value;
    const { order } = this.state;
    if (field === "priceDollars") {
      if (value !== "" && !this.isNumeric(data[field])) {
        return;
      }
      this.checkUnmarketableParcels(field, value);
    }
    if (field === "quantity") {
      if (value.includes(".")) {
        return;
      }
      if (value !== "" && !this.isNumeric(data[field])) {
        return;
      }
      if (!this.props.amendOrder && value > order.quantity) {
        return;
      }
      this.checkUnmarketableParcels(field, value);
    }

    this.setState(data, () => {
      this.setState({ enableButton: this.canEnableButton() });
    });
  };

  handlePriceFormatAndUpdate = (e) => {
    const { order } = this.state;
    const currency = fetchCurrencySymbol(order?.underlying);
    let value = currencyInputValidation(currency, e.target.value);
    if (Number(value) < 0) {
      value = 0;
    }
    value = Number(value).toFixed(2);
    this.fieldOnChange("priceDollars", value);
    this.calculatedEstmatedPrice("priceDollars", value);
  };

  /**
   * Helps to check if there any position left over in creating new sell order
   * If anything left, check that left over is enough in size to create an order
   * If not, shows a warning message
   *
   */
  checkUnmarketableParcels = (field, value) => {
    const { priceDollars, quantity, availableQty, isInvestorKYCExpired } =
      this.state;
    if (isInvestorKYCExpired) {
      let condition1 = false;
      let condition2 = false;
      if (field === "priceDollars" && parseFloat(value) > 0 && quantity > 0) {
        condition1 = isLeftoverLessThanMinSize(availableQty, quantity, value);
      }
      if (field === "quantity" && parseFloat(priceDollars) > 0 && value > 0) {
        condition2 = isLeftoverLessThanMinSize(
          availableQty,
          value,
          priceDollars
        );
      }
      this.setState({ showWarnIcon: condition1 || condition2 });
    }
  };

  clearOrder = () => {
    let defaultState = {
      priceDollars: "",
      quantity: "",
      reference: "",
      allowPartialMatch: true,
      estimatedPrice: "",
    };
    this.setState(defaultState);
    this.setState({ enableButton: false });
  };

  /**
   * @method Calculate the Estimated Price based on the changes in the field
   * @param {string} fieldType
   * @param {string | number} value
   * @returns
   */
  calculatedEstmatedPrice = (fieldType, value) => {
    let { priceDollars, quantity } = this.state;
    const { order } = this.state;
    if (fieldType === "priceDollars") {
      priceDollars = value;
    }
    if (fieldType === "quantity") {
      quantity = value;
    }

    let estimatedPrice = "";
    if (
      !priceDollars ||
      !quantity ||
      (!this.props.amendOrder && quantity > order.quantity)
    ) {
      estimatedPrice = "";
    } else {
      estimatedPrice = quantity * priceDollars;
    }

    this.setState({ estimatedPrice: estimatedPrice });
  };

  /*
   * amend the sell order from open order widgets
   */
  validateAmendOrder = () => {
    const {
      allowPartialMatch,
      reference,
      quantity,
      priceDollars,
      availableQty,
      isInvestorKYCExpired,
    } = this.state;
    const { order } = this.state;
    let remainingQty = availableQty - quantity;
    if (!reference) {
      message.warning("Please fill the fields!");
      return;
    }
    // trade size warnings and validations only apply if the user/investor of level "Retail"
    // and if investor is some higher level than Retail having the certificate expired.
    if (isInvestorKYCExpired) {
      /**
       * not allow to create an order if value is <500K and have few positions left
       */
      if (
        remainingQty > 0 &&
        isTransactionLessThanMinSize(quantity, priceDollars)
      ) {
        message.warning("Stock transaction size should be greater than $500K");
        return;
      }
    }

    if (isReadOnly) {
      return;
    }

    let data = {
      underlying: order.underlying,
      productName: order.productName,
      orderSide: "SELL",
      priceDollars: parseFloat(priceDollars),
      estabPrice: order.establishmentPrice,
      quantity: quantity,
      maturityDate: order.maturityDate,
      considerationType: consideration.cash.value,
      allowPartialMatch: allowPartialMatch,
      clientRef: reference,
      investorId: order.investorId,
      id: order.id,
    };
    console.log(data);
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .put(`${ROUTES.MY_ORDERS}/${this.state.investorId}`, data, config)
      .then((res) => {
        this.props.parentCallback(false);
      })
      .catch((error) => {
        message.error("Failed");
      });
  };

  renderConfirmationPopup = () => {
    const { showWarnIcon, isInvestorKYCExpired } = this.state;
    // trade size warnings and validations only apply if the user/investor of level "Retail"
    // and if investor is some higher level than Retail having the certificate expired.
    if (isInvestorKYCExpired && showWarnIcon) {
      Modal.confirm({
        title: warningMessaageForUnmarketableParcels,
        okText: "Yes",
        cancelText: "No",
        className: "sell-Positions-modal",
        okButtonProps: {
          className: "ok-button",
          disabled: isReadOnly,
        },
        cancelButtonProps: {
          className: "cancel-button",
        },
        onOk: () => {
          if (this.props.amendOrder) {
            this.validateAmendOrder();
          } else {
            this.validateOrder();
          }
        },
      });
    } else {
      if (this.props.amendOrder) {
        this.validateAmendOrder();
      } else {
        this.validateOrder();
      }
    }
  };

  /*
   * create a sell order which reuse same API for creating buy order in Expression of interest component
   */
  validateOrder = () => {
    const {
      allowPartialMatch,
      reference,
      quantity,
      priceDollars,
      availableQty,
      isInvestorKYCExpired,
    } = this.state;
    const { order } = this.state;
    let remainingQty = availableQty - quantity;
    if (!reference) {
      message.warning("Please fill the fields!");
      return;
    }
    // trade size warnings and validations only apply if the user/investor of level "Retail"
    // and if investor is some higher level than Retail having the certificate expired.
    if (isInvestorKYCExpired) {
      /**
       * not allow to create an order if value is <500K and have few positions left
       */
      if (
        remainingQty > 0 &&
        isTransactionLessThanMinSize(quantity, priceDollars)
      ) {
        message.warning("Stock transaction size should be greater than $500K");
        return;
      }
    }

    if (isReadOnly) {
      return;
    }

    let data = {
      securityCode: order.securityCode,
      underlying: order.underlying,
      productName: order.productName,
      orderSide: "SELL",
      priceDollars: priceDollars,
      estabPrice: order.establishmentPrice,
      quantity: quantity,
      maturityDate: order.maturityDate,
      considerationType: consideration.stock.value, // The person selling is always paying with "stock" (should really be with POA)
      allowPartialMatch: allowPartialMatch,
      clientRef: reference,
    };
    console.log(data);
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .post(`${ROUTES.MY_ORDERS}/${this.state.investorId}`, data, config)
      .then((res) => {
        message.success(`Success`);
        this.props.parentCallback(false);
      })
      .catch((error) => {
        message.error("Failed");
      });
  };

  render() {
    const {
      priceDollars,
      reference,
      quantity,
      terms,
      allowPartialMatch,
      agreeTerms,
      agreeProductIM,
      enableButton,
      estimatedPrice,
      showWarnIcon,
    } = this.state;
    const { order } = this.state;

    /*
     * Sell order should be created only with held position. Order <= position quantity - locked quantity
     * To amend the sell order there is no restrictions in quantity.
     */
    const hasReachMaxQtyLimit = (inputObj) => {
      const { value } = inputObj;
      return (
        this.props.amendOrder ||
        value <= this.props.sellOrder.quantity - this.props.sellOrder.lockedQty
      );
    };

    return (
      <div>
        <div className="flex gap-x-2">
          <div className="text-white">{order ? order.underlying : null}</div>
          <div className="text-white">
            {order ? order.productNameDisplay : null}
          </div>
        </div>

        <div className="flex gap-x-4 mt-2">
          <div className="w-full">
            <Input
              id="quantity"
              name="quantity"
              value={numberWithCommas(quantity)}
              className="!rounded-full border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white"
              placeholder=""
              onChange={(e) => {
                let value = e.target.value.replace(/,/g, "");
                this.fieldOnChange("quantity", value);
                this.calculatedEstmatedPrice("quantity", parseInt(value));
              }}
              status={false && "error"}
            />
            {false ? (
              <label
                htmlFor="quantity"
                className="text-[#ff4d4f] pl-3 text-[14px] block"
              ></label>
            ) : (
              <label htmlFor="quantity" className="text-white pl-3 text-[14px]">
                Quantity
              </label>
            )}
          </div>

          <div className="w-full">
            <InputNumber
              id="priceDollars"
              name="priceDollars"
              value={priceDollars}
              className="!rounded-full !border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white !w-full"
              placeholder=""
              onChange={(value) => {
                value = value?.toString() ?? "";
                this.fieldOnChange("priceDollars", value);
                this.calculatedEstmatedPrice("priceDollars", value);
              }}
              onBlur={this.handlePriceFormatAndUpdate}
              status={false && "error"}
              controls={false}
              formatter={(value) => {
                const currency = fetchCurrencySymbol(order?.underlying);
                if (String(priceDollars)?.split(".")[1]?.length === 2) {
                  return `${currency} ${Number(value).toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  );
                }
                return `${currency} ${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                );
              }}
              parser={(value) => {
                const currency = fetchCurrencySymbol(order?.underlying);
                return currencyInputValidation(currency, value);
              }}
            />
            {false ? (
              <label
                htmlFor="priceDollars"
                className="text-[#ff4d4f] pl-3 text-[14px] block"
              >
                error
              </label>
            ) : (
              <label
                htmlFor="priceDollars"
                className="text-white pl-3 text-[14px]"
              >
                Limit Price
              </label>
            )}
          </div>
        </div>

        <div className="w-full mt-4">
          <Input
            id="reference"
            name="reference"
            value={reference}
            className="!rounded-full border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white"
            placeholder=""
            onChange={(e) => {
              this.fieldOnChange("reference", e.target.value);
            }}
            status={false && "error"}
          />
          {false ? (
            <label
              htmlFor="reference"
              className="text-[#ff4d4f] pl-3 text-[14px] block"
            ></label>
          ) : (
            <label htmlFor="reference" className="text-white pl-3 text-[14px]">
              Your Reference
            </label>
          )}
        </div>

        <div className="flex text-white">
          <label className="mr-1">Estimation:</label>
          <NumberFormatter
            value={estimatedPrice}
            placeholder="Estimation"
            className="text-white"
            isCurrency={true}
            isNumericString={typeof estimatedPrice === "string"}
            allowNegative={false}
            decimalScale={2}
            tickerCode={order ? order.underlying : ""}
            readOnly
          />
        </div>

        <div className="flex text-white">
          <label className="mr-1">Term:</label>
          <div className="mr-1">
            <NumberFormatter
              value={terms}
              className="sell-space"
              allowNegative={false}
              decimalScale={1}
            />
          </div>{" "}
          <span>years</span>
        </div>

        <div className="mt-2 mb-4 flex flex-col items-center">
          <Checkbox
            name="termsAgreed"
            onChange={(e) => {
              this.onChangeCheckbox("agreeTerms", e);
            }}
            className={`!text-white text-[16px] font-light ${
              false && "ant-error"
            }`}
          >
            I agree to the{" "}
            <a className="text-white" href={"/transaction-document"}>
              Transaction Documents
            </a>
          </Checkbox>
          {false && <div style={{ color: "#ff4d4f" }}>error</div>}
        </div>

        {this.props.amendOrder && (
          <div className="flex justify-between items-center">
            <Button className="clear-sell" onClick={this.props.parentCallback}>
              Close
            </Button>
            <Button
              className={enableButton ? "place-sell" : "place-sell-disable"}
              disabled={!enableButton}
              onClick={this.renderConfirmationPopup}
            >
              Amend Sell order
            </Button>
          </div>
        )}

        {!this.props.amendOrder && (
          <div className="flex justify-between items-center">
            <button
              className="bg-black rounded-full text-white px-4 py-1"
              onClick={this.clearOrder}
            >
              Clear
            </button>
            <button
              className={`${
                !enableButton
                  ? "bg-[#E6E6E6] text-[#B3B3B3] cursor-not-allowed"
                  : "bg-[#5B7FFC] text-white"
              } rounded-full px-4 py-1`}
              disabled={!enableButton}
              onClick={this.renderConfirmationPopup}
            >
              Place Sell order
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default SellOrder;
