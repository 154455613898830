/**
 * @author Satheesh Kumar
 * @fileoverview This files contains all the messages used in the application
 *
 */

export const dateErrorMessage =
  "Date of birth should be greater than or equal to 18 years";
export const minTermMinimumErrorMessage = "Min Term must be greater than zero";
export const minTermMaximumErrorMessage = "Min Term must be less than Max Term";
export const maxTermMaximumErrorMessage = "Max Term must be  within 10 years";
export const maxTermMinimumErrorMessage =
  "Max Term must be  greater than Min Term";
export const streetNameMaximumErrorMessage =
  "Street name cannot exceed more than 225 characters";
export const individualTaxResidentErrorMessage =
  'Please make sure either Tax Resident of Australia or Tax Resident of other countries must be "Yes"';
export const warningMessaageForUnmarketableParcels =
  "This order will leave a remaining holding in this POA which is below the minimum trade size";
export const MinimumStockTransactionSizeMessage =
  "Our minimum stock transaction size is $1 Million, do you want to proceed?";
export const taxFileNumberErrorMessage = "Invalid Tax File Number";
export const brokerAccountConnectSuccessMessage =
  "Broker Account has been connected successfully";
export const brokerAccountConnectErrorMessage =
  "Error occurred while connecting the selected broker";
export const brokerAccountDisconnectSuccessMessage =
  "Broker Account has been disconnected successfully";
export const brokerAccountDisconnectErrorMessage =
  "Error occurred while disconnecting the selected broker";
