import * as XLSX from "xlsx/xlsx.mjs";
import _isEmpty from "lodash/isEmpty";
import NumberFormatter from "../components/common/NumberFormatter";
import React from "react";
import moment from "moment";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function formatDate(input) {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function absoluteUrl(path) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export const getAccess = () => {
  return sessionStorage.getItem("pstack")
    ? `Bearer ${sessionStorage.getItem("pstack")}`
    : "";
};

export const consideration = {
  cash: {
    value: "CASH",
    displayLabel: "Cash",
  },
  stock: {
    value: "STOCK",
    displayLabel: "Stock",
  },
};

export const considerationList = [
  { value: consideration.cash.value, text: consideration.cash.displayLabel },
  { value: consideration.stock.value, text: consideration.stock.displayLabel },
];

export const exportTabletoXLSX = (id, fileName, additionRows) => {
  var tableElement = document.getElementById(id);
  var workbook = XLSX.utils.table_to_book(tableElement);
  if (additionRows !== undefined && additionRows !== "") {
    var workSheet = workbook.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(workSheet, additionRows, { origin: -1 });
  }
  XLSX.writeFile(workbook, fileName);
};

export const getDiffBetweenDatesInYears = (date1, date2) => {
  if (_isEmpty(date1.toString()) || _isEmpty(date2.toString())) return null;
  var startDate = new Date(date1);
  var endDate = new Date(date2);
  var diff = (endDate.getTime() - startDate.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(diff / 365).toFixed(1);
};

/*
 * BUY ORDER -Takes the two term components, "minTermDays" and "maxTermDays" and renders
 * them into a single string, shown in years.
 * SELL ORDER - Render the difference between current date and maturity Date in years with 1 decimal scale
 */
export const getOrderTermString = (order) => {
  let terms = null;
  if (order.orderSide === "BUY") {
    terms =
      Math.round(order.minTermDays / 365) +
      "-" +
      Math.round(order.maxTermDays / 365);
    terms = <div> {terms} </div>;
  } else if (order.orderSide === "SELL") {
    terms = getDiffBetweenDatesInYears(new Date(), order.maturityDate || "");
    terms = (
      <div className="row">
        {" "}
        <NumberFormatter
          value={terms}
          className="sell-space"
          allowNegative={false}
          decimalScale={1}
        />{" "}
      </div>
    );
  }
  return terms;
};

// Get prefered date format
export const dateFormat = () => {
  return "DD-MM-YYYY";
};

// Get prefered date and time format
export const dateTimeFormat = () => {
  return "DD-MM-YYYY HH:mm:ss";
};

export const convertToDateFormat = (date) => {
  let formattedDate = "";
  if (date) {
    let momentObj = moment(date);
    formattedDate = moment(momentObj).format(dateFormat());
  }
  return formattedDate;
};

export const convertToDateTimeFormat = (dateTime) => {
  let formattedDateTime = "";
  if (dateTime) {
    let momentObj = moment(dateTime);
    formattedDateTime = moment(momentObj).format(dateTimeFormat());
  }
  return formattedDateTime;
};

export const otcMinTradeSize = () => {
  return "500000";
};

export const otcMaxTradeSize = () => {
  return "1000000000";
};

export const availableQtyForSellOrderCreation = (order) => {
  return order ? order.quantity - order.lockedQty : undefined;
};

export const availableQtyForSellOrderAmendment = (order, position) => {
  if (order && position) {
    return order.remainingQty + (position.quantity - position.lockedQty);
  }
  return undefined;
};

export const getInvestorRegistrationType = (userDetail) => {
  return userDetail?.investorCore?.registrationType;
};
export const getInvestorKYCStatus = (userDetail) => {
  return userDetail?.investorCore?.kycStatus;
};

/**
 * @constant ETA Pair Mapping constant
 */

export const etaPairMapping = {
  PURE_GROWTH: {
    displayLabel: "PureGrowth",
    colorCode: "#CDE9E3",
  },
  PURE_DIV: {
    displayLabel: "PureDiv",
    colorCode: "#3D8D74",
  },
  GROWTH_GUARD: {
    displayLabel: "GrowthGuard",
    colorCode: "#A94548",
  },
  MAX_DIV: {
    displayLabel: "MaxDiv",
    colorCode: "#FCF4EC",
  },
  MAX_GROWTH: {
    displayLabel: "MaxGrowth",
    colorCode: "#CAEAF6",
  },
  DIV_GUARD: {
    displayLabel: "DivGuard",
    colorCode: "#416399",
  },
  ULTRA_GROWTH: {
    displayLabel: "UltraGrowth",
    colorCode: "#DEDFF0",
  },
  ULTRA_GUARD: {
    displayLabel: "UltraGuard",
    colorCode: "#7368AE",
  },
};

export const otcMinStockTransactionSize = () => {
  return "1000000";
};

export const investorType = {
  SOPHISTICATED: {
    value: "SOPHISTICATED",
    displayLabel: "Sophisticated Investor",
  },
  PROFESSIONAL: {
    value: "PROFESSIONAL",
    displayLabel: "Professional Investor",
  },
  WHOLESALE: {
    value: "WHOLESALE",
    displayLabel: " Wholesale Investor",
  },
  RETAIL: {
    value: "RETAIL",
    displayLabel: "Retail",
  },
};

export const getDateXYearsAway = (x) => {
  const currentDate = new Date();
  const futureDate = new Date(currentDate);

  futureDate.setFullYear(currentDate.getFullYear() + x);

  const day = futureDate.getDate().toString().padStart(2, "0");
  const month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
  const year = futureDate.getFullYear();

  return `${year}-${month}-${day}`;
};
