import Input from "antd/es/input";
import Button from "antd/es/button";
import message from "antd/es/message";
import "./style.scss";
import validator from "validator";
import axiosConfig from "../../axiosConfig";
import ROUTES from "../../routes/api.json";
import { intl } from "../../utils/intl_i18n";
import PageContent from "../common/PageContent";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

function ResetPassword() {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please enter a valid username"),
    }),
    onSubmit: (values) => {
      requestResetPassword(values.email);
    },
  });

  const getLogin = () => {
    sessionStorage.clear();
    history.push("/login");
  };

  const requestResetPassword = (email) => {
    if (!validator.isEmail(email)) {
      message.warning("Please enter valid Email address!");
    } else {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axiosConfig
        .post(ROUTES.REQUEST_RESET_PASSWORD, { email }, config)
        .then((res) => {
          sessionStorage.setItem("userMail", email);
          history.push("/sample-message");
        })
        .catch((error) => {
          message.error("Given mail ID is not registered");
        });
    }
  };

  return (
    <PageContent
      className="reset-container"
      childrenClassName={"reset-password-main h-full"}
      renderMenu={false}
    >
      <div className="w-full flex flex-col">
        <div className="max-w-sm font-din2014 rounded-2xl mx-auto py-8 px-10 w-10/12 md:w-1/2 lg:w-2/3 xl:w-3/4 bg-blur-linear-gradient backdrop-blur login-box-shadow">
          <h2 className="text-center text-[32px] font-extralight text-[#FFFFFF] mb-1">
            {intl.formatMessage({ id: "Request.password.reset" })}
          </h2>
          <h3 className="text-center text-base font-extralight text-[#D9D9D9] mb-7">
            {intl.formatMessage({ id: "reset.password.content" })}
          </h3>
          <div className="flex flex-col items-center w-full">
            <form
              onSubmit={formik.handleSubmit}
              className="w-full flex flex-col prism-login-form"
            >
              <Input
                value={formik.values.email}
                name="email"
                size="large"
                placeholder={intl.formatMessage({ id: "login.email" })}
                onChange={formik.handleChange}
                className="!mb-4 text-[#D9D9D9] bg-black bg-black-gradient"
                status={formik.errors.email ? "error" : ""}
                style={{
                  borderRadius: "20px",
                }}
              />

              <button
                className="text-[#D9D9D9] text-sm py-1 px-3 self-end bg-black bg-black-gradient rounded-[20px]"
                type="submit"
              >
                {intl.formatMessage({ id: "login.reset.password" })}
              </button>
            </form>
          </div>
        </div>
        <button
          type="button"
          className="text-white mt-8 mx-auto"
          onClick={getLogin}
        >
          {intl.formatMessage({ id: "login.login" })}
        </button>
      </div>
    </PageContent>
  );
}

export default ResetPassword;
