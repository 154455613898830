import moment from "moment";

export const getTermFromMaturity = (maturity) => {
  let currentDate = moment();
  let maturityDate = moment(maturity, "DD-MM-YYYY");
  let yearsUntilMaturity = maturityDate.diff(currentDate, "years");
  let possibleValues = [3, 5, 10];
  let closestValue = possibleValues.reduce((prev, curr) => {
    return Math.abs(curr - yearsUntilMaturity) <
      Math.abs(prev - yearsUntilMaturity)
      ? curr
      : prev;
  });
  return closestValue;
};

const searchFilteredOpportunities = (opportunities, searchValue) => {
  if (!searchValue) return opportunities;
  const lowerSearchValue = searchValue.toLowerCase();
  return opportunities.filter(
    ({ underlying, productNameDisplay, considerationTypeList }) => {
      const lowerUnderlying = underlying.toLowerCase();
      const lowerProductNameDisplay = productNameDisplay.toLowerCase();
      return (
        lowerUnderlying.includes(lowerSearchValue) ||
        lowerProductNameDisplay.includes(lowerSearchValue) ||
        considerationTypeList.some((consid) =>
          consid.toLowerCase().includes(lowerSearchValue)
        )
      );
    }
  );
};

export const getFilteredOpportunities = (
  opportunitiesData,
  type,
  activeCurrency,
  activeProducts,
  searchValue
) => {
  if (!Array.isArray(opportunitiesData)) return [];

  console.log("activeCurrency", activeCurrency);
  console.log("opportunitiesData", opportunitiesData);

  const filteredOpportunities = opportunitiesData
    .filter((product) =>
      activeProducts
        ? product.productNameColorCode === activeProducts?.colour
        : true
    )
    .filter((product) =>
      activeCurrency ? product.currency === activeCurrency : true
    )
    .filter((product) => product.seriesType === type);

  return searchFilteredOpportunities(filteredOpportunities, searchValue);
};
