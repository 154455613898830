import * as React from "react";
import PropTypes from "prop-types";
import Select, { selectClasses } from "@mui/base/Select";
import Option, { optionClasses } from "@mui/base/Option";
import Popper from "@mui/base/Popper";
import { styled } from "@mui/system";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";

export const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <Select {...props} ref={ref} slots={slots} />;
});

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const Button = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <div>▾</div>
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    defaultListboxOpen: PropTypes.bool,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool.isRequired,
    focusVisible: PropTypes.bool.isRequired,
    getSerializedValue: PropTypes.func,
    listboxId: PropTypes.string,
    listboxOpen: PropTypes.bool,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onListboxOpenChange: PropTypes.func,
    open: PropTypes.bool.isRequired,
    optionStringifier: PropTypes.func,
    renderValue: PropTypes.func,
    slotProps: PropTypes.shape({
      listbox: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      popper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }),
    slots: PropTypes.shape({
      listbox: PropTypes.elementType,
      popper: PropTypes.func,
      root: PropTypes.elementType,
    }),
    value: PropTypes.any,
  }).isRequired,
};

const StyledButton = styled(Button, { shouldForwardProp: () => true })(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  background: linear-gradient(152.97deg, rgba(139, 142, 148, 0.6) 0%, rgba(71, 76, 85, 0.6) 100%);
  box-sizing: border-box;
  padding: 5px 15px;
  text-align: left;
  line-height: initial;
  box-shadow: inset 0px 1px 1px 0px rgba(137, 136, 136, 0.7);
  border: 0;
  position: relative;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    // background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    // border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[500] : blue[200]};
  }

  & > div {
    font-size: 25px;
    line-height: 28.5px;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    right: 0;
    align-items: center;
    padding-left: 10px;
    padding-right: 11px;
    background: #8B8E94;
    color: #d9d9d959;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  width: 100%;
  min-width: 180px;
  margin: 12px 0;
  overflow: auto;
  outline: 0px;
  background: linear-gradient(152.97deg, rgba(139, 142, 148, 0.6) 0%, rgba(71, 76, 85, 0.6) 100%);
  color: grey[300]
  box-shadow: 0px 4px 30px grey[900]
  `
);

export const StyledOption = styled(Option)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(Popper)`
  z-index: 1;
`;
