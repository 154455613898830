import React, { Component } from "react";
import Table from "antd/es/table";
import "./styles.scss";
import axiosConfig from "../../../axiosConfig";
import ROUTES from "../../../routes/api.json";
import NumberFormatter from "../../common/NumberFormatter";
import {
  convertToDateFormat,
  convertToDateTimeFormat,
} from "../../../utils/utile";

/*
 * This component provides the user a view of the transactions that make up a
 * specific position.
 */
class ViewPositions extends Component {
  /*
   * Constructor.
   */
  constructor(props) {
    super(props);
    this.state = {
      securityCode: props["securityCode"],
      underlying: props["underlying"],
      portfolioTransactions: [],
      investorId: this.props.investorId,
    };
  }

  /*
   * Declares the columns to be rendered in the table, as well as each
   * column's index into the data.
   */
  getColumns = () => {
    return [
      {
        title: "Date/Time",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (timestamp) => {
          return timestamp ? convertToDateTimeFormat(timestamp) : "-";
        },
        className: "table-row-content",
      },
      {
        title: "Transaction",
        dataIndex: "transactionType",
        key: "transactionType",
        className: "table-row-content",
      },
      {
        title: "Your Ref",
        dataIndex: "userRef",
        key: "userRef",
        className: "table-row-content",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => (
          <NumberFormatter
            value={price}
            isCurrency={true}
            tickerCode={this.state.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        ),
        className: "table-row-content align-right",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (quantity) => (
          <NumberFormatter value={quantity} emptyContent={"-"} />
        ),
        className: "table-row-content align-right",
      },
      {
        title: "Fees",
        dataIndex: "fees",
        key: "fees",
        render: (fees) => (
          <NumberFormatter
            value={fees}
            isCurrency={true}
            tickerCode={this.state.underlying}
            decimalScale={2}
            emptyContent={"-"}
          />
        ),
        className: "table-row-content align-right",
      },
      {
        title: "Value Date",
        dataIndex: "valueDate",
        key: "valueDate",
        render: (valueDate) => {
          return valueDate ? convertToDateFormat(valueDate) : "-";
        },
        className: "table-row-content align-center",
      },
    ];
  };

  /*
   * We pull down the portfolio data as soon as this component is fully loaded.
   */
  componentDidMount() {
    this.fetchTransactions();
  }

  /*
   * Calls the server API to retrieve a list of portfolio events
   * that make up a given position for the currently logged in user.
   * {ticker}/{productName}
   */
  fetchTransactions = () => {
    axiosConfig
      .get(
        `${ROUTES.PORTFOLIO_POSITIONS}/${this.state.investorId}/${this.state.securityCode}`
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ portfolioTransactions: response.data });
        }
      })
      .catch((e) => {
        this.setState({ portfolioTransactions: [] });
        console.log("error=>", e.response);
      });
  };

  render() {
    return (
      <div className="view-container">
        <div className="view-table-container">
          <div className="view-table">
            <div className="table-container">
              <Table
                className="table-data"
                pagination={false}
                dataSource={this.state.portfolioTransactions}
                columns={this.getColumns()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewPositions;
