import React, { Component } from "react";
import "./styles.scss";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import { intl } from "../../../../utils/intl_i18n";
import { forEach } from "lodash";
import message from "antd/es/message";
import TaxResidentContent from "../../../TaxResidentContent";
import { individualTaxResidentErrorMessage } from "../../../common/message";

class BeneficialOwnerFactaDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      activeBeneficiary: "",
      activeName: "",
      activeIndex: "",
    };
  }

  checkBeneficalOwnerTaxResident = (payload, activeIndex) => {
    const beneficialType = payload.hasBeneficialOwners;
    let beneficialList = payload.beneficialOwnersList;
    let activeBeneficiary = "";
    let activeName = "";
    if (beneficialType === "no") {
      beneficialList = payload.individualControllerList;
    }
    forEach(beneficialList, (list, index) => {
      if (index === activeIndex) {
        activeBeneficiary = list;
        activeBeneficiary.isTaxNonAustralian = list?.isTaxNonAustralian || "";
        // Creating the TaxResidentList object while determining the active Beneficiary
        if (activeBeneficiary.taxResidentList === undefined) {
          activeBeneficiary.taxResidentList = [
            { country: "", tin: "", reason: "" },
          ];
        }
      }
    });
    this.setState(
      {
        activeBeneficiary: activeBeneficiary,
        activeName: activeName,
        activeIndex: activeIndex,
      },
      () => {
        this.validate();
      }
    );
  };

  /**
   * @method - To add new Tax Resident List in the Beneficiary List
   */
  addBeneficiaryResidentList = () => {
    const { activeBeneficiary } = this.state;
    activeBeneficiary.taxResidentList.push({
      country: "",
      tin: "",
      reason: "",
    });
    this.updateState(activeBeneficiary, this.scrolltoActiveResidentList);
  };

  /**
   * @method - To remove a Tax Resident List in the Beneficiary List
   */
  removeBeneficialResidentList = (taxResident) => {
    const { activeBeneficiary } = this.state;
    let index = activeBeneficiary.taxResidentList.indexOf(taxResident);
    activeBeneficiary.taxResidentList.splice(index, 1);
    this.updateState(activeBeneficiary);
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload, afterUpdate) => {
    this.setState(payload, () => {
      if (afterUpdate) {
        afterUpdate();
      }
      this.validate();
    });
  };

  handleTaxResidentSelection = (e) => {
    const { activeBeneficiary } = this.state;
    activeBeneficiary.isTaxNonAustralian = e.target.value;
    //Resetting the List to empty whenever any changes made on the Resident Status
    activeBeneficiary.taxResidentList = [{ country: "", tin: "", reason: "" }];

    // Either beneficiary should be Tax Resident of Australia or Tax Resident of countries other than Australia
    if (!this.validateBeneficiaryResidantStatus(activeBeneficiary)) {
      message.error({
        content: individualTaxResidentErrorMessage,
        duration: 3,
      });
    }
    this.updateState(activeBeneficiary);
  };

  handleCountryChange = (value, taxResident) => {
    const { activeBeneficiary } = this.state;
    taxResident.country = value;
    this.updateState(activeBeneficiary);
  };

  handleTinChange = (e, taxResident) => {
    const { activeBeneficiary } = this.state;
    taxResident.tin = e.target.value;
    // Clearing the reason field whenever the TIN value is not empty
    if (e.target.value !== "") {
      taxResident.reason = "";
    }
    this.updateState(activeBeneficiary);
  };

  handleReason = (e, taxResident) => {
    const { activeBeneficiary } = this.state;
    // Clearing the tin field whenever the REASON value is not empty
    if (e.target.value !== "") {
      taxResident.tin = "";
    }
    taxResident.reason = e.target.value;
    this.updateState(activeBeneficiary);
  };

  /**
   * @method - To validate the Tax Resident List
   */
  validateTaxResidentList = () => {
    const { activeBeneficiary } = this.state;
    var flag = true;
    if (!activeBeneficiary.taxResidentList) {
      return false;
    }
    if (activeBeneficiary.taxResidentList.length === 0) {
      return false;
    }
    for (var i = 0; i < activeBeneficiary.taxResidentList.length; i++) {
      const taxResident = activeBeneficiary.taxResidentList[i];
      if (!taxResident.country || (!taxResident.tin && !taxResident.reason)) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  validate = () => {
    const { activeBeneficiary } = this.state;
    if (
      activeBeneficiary.isTaxNonAustralian === undefined ||
      !activeBeneficiary.isTaxNonAustralian
    ) {
      this.onValidationFail();
      return;
    }

    // Either beneficiary should be Tax Resident of Australia or Tax Resident of countries other than Australia
    if (!this.validateBeneficiaryResidantStatus(activeBeneficiary)) {
      this.onValidationFail();
      return;
    }

    if (
      activeBeneficiary.isTaxNonAustralian === "yes" &&
      !this.validateTaxResidentList()
    ) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  setActiveBeneficary = () => {
    this.checkBeneficalOwnerTaxResident(
      this.props.payload,
      this.props.payload.currentIndex
    );
  };

  /**
   * @method - Method to scroll to the newly added Tax Residence List
   */
  scrolltoActiveResidentList = () => {
    const taxResidentList =
      document.getElementsByClassName("country-input").length;
    if (taxResidentList > 0) {
      document
        .getElementsByClassName("country-input")
        [taxResidentList - 1].focus();
    }
  };

  /**
   * @method To Validate the tax Beneficiary Resident Status - Either beneficiary should be Tax Resident of Australia or Tax Resident of countries other than Australia
   */
  validateBeneficiaryResidantStatus = (activeBeneficiary) => {
    let isValidate = true;
    if (
      activeBeneficiary.isTaxResidentOfAustralia === "no" &&
      activeBeneficiary.isTaxNonAustralian === "no"
    ) {
      isValidate = false;
    }
    return isValidate;
  };

  render() {
    // Loading the Beneficary details
    if (
      this.state.activeIndex === "" ||
      this.state.activeIndex !== this.props.payload.currentIndex
    ) {
      this.setActiveBeneficary();
    }
    const { activeBeneficiary } = this.state;
    return (
      <div className="beneficial-owner-facta-details">
        <div className="reg-header">
          {this.formatMessage_i18n("beneficial.facta.title")}
        </div>
        <div className="facta-main-container">
          <div className="username">
            <input
              className={cn("user-name-input")}
              type="text"
              placeholder="Family Name, Given Name"
              value={`${activeBeneficiary?.familyName}, ${activeBeneficiary?.givenName}`}
              readOnly
            />
          </div>
          <div className="reg-sub-header">
            {" "}
            {this.formatMessage_i18n("beneficial.facta.country.title")}
          </div>
          <div className="container-label">
            {this.formatMessage_i18n("beneficial.facta.country.label")}
          </div>
          <div className="select">
            <select
              onChange={this.handleTaxResidentSelection}
              value={activeBeneficiary.isTaxNonAustralian || ""}
              className={cn({
                "error-field":
                  _isUndefined(activeBeneficiary.isTaxNonAustralian) ||
                  activeBeneficiary.isTaxNonAustralian === "" ||
                  !this.validateBeneficiaryResidantStatus(activeBeneficiary),
              })}
            >
              <option value="">Yes/No</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div
            className="tax-information"
            style={{
              display:
                activeBeneficiary.isTaxNonAustralian === "" ||
                activeBeneficiary.isTaxNonAustralian === "no"
                  ? "none"
                  : "",
            }}
          ></div>
          {activeBeneficiary?.taxResidentList !== undefined && (
            <TaxResidentContent
              tinLabel={this.formatMessage_i18n("beneficial.facta.tax.title")}
              renderCountryContent={
                activeBeneficiary.isTaxNonAustralian !== "" &&
                activeBeneficiary.isTaxNonAustralian !== "no"
              }
              residentList={activeBeneficiary.taxResidentList}
              countryChangeCallback={(val, taxResidentList) => {
                this.handleCountryChange(val, taxResidentList);
              }}
              tinChangeCallback={(e, taxResidentList) =>
                this.handleTinChange(e, taxResidentList)
              }
              reasonChangeCallback={(e, taxResidentList) =>
                this.handleReason(e, taxResidentList)
              }
              addCountryListCallback={this.addBeneficiaryResidentList}
              removeCountryListCallback={this.removeBeneficialResidentList}
            />
          )}
        </div>
      </div>
    );
  }
}

export default BeneficialOwnerFactaDetails;
