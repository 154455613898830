import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";

export const fetchLastPrice = (lastPriceList, record, filterKey) => {
  const underLying = record[filterKey];
  const filteredData = _filter(lastPriceList, { ticker: underLying });
  let lastPrice = "";
  if (!_isEmpty(filteredData)) {
    lastPrice = filteredData[0].lastPrice;
  }
  return lastPrice;
};
/*
 *   to calculate the price gap for Issuance order
 */
export const fetchPriceGap = (lastPriceList, record, filterKey) => {
  const lastPrice = fetchLastPrice(lastPriceList, record, filterKey);
  let priceGap = "";
  if (
    !_isEmpty(lastPrice.toString()) &&
    lastPrice > 0 &&
    !_isEmpty(record.growthBid.toString()) &&
    record.growthBid > 0 &&
    !_isEmpty(record.incomeBid.toString()) &&
    record.incomeBid > 0
  ) {
    priceGap = lastPrice - record.growthBid - record.incomeBid;
  }
  return priceGap;
};

/*
 *   to calculate the price gap for secondary order
 */
export const fetchSecondaryPriceGap = (record) => {
  let priceGap = "";
  if (
    !_isEmpty(record.offerPrice.toString()) &&
    record.offerPrice > 0 &&
    !_isEmpty(record.bidPrice.toString()) &&
    record.bidPrice > 0
  ) {
    priceGap = record.offerPrice - record.bidPrice;
  }
  return priceGap;
};
