import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: `otc-trading-(${process.env.REACT_APP_ENV})`,
  env: process.env.REACT_APP_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
