import { forEach } from "lodash";
import { numberWithCommas } from "../../utils/numberFormatting";
import { fetchCurrencySymbol } from "../common/currencies";
import { INVESTOR_SHARIA_COMPLIANT_GROUPNAME } from "../common/constants";
import ETAPill from "../common/ETAPills/ETAPill";

export const SHAREHOLDER_APP = "SHAREHOLDER_APPLICATION";

export const considerationOptions = [
  {
    name: "Stock",
    value: "STOCK",
  },
  {
    name: "Cash",
    value: "CASH",
  },
];

const stockOptions = [
  {
    name: "Sell Dividend",
    value: "SELL_INCOME_STOCK",
  },
  {
    name: "Sell Growth",
    value: "SELL_GROWTH_STOCK",
  },
  {
    name: "Shareholder Application",
    value: SHAREHOLDER_APP,
  },
];

const cashOptions = [
  {
    name: "Buy Dividend",
    value: "BUY_INCOME_CASH",
  },
  {
    name: "Buy Growth",
    value: "BUY_GROWTH_CASH",
  },
];

export const optionsByConsideration = {
  STOCK: stockOptions,
  CASH: cashOptions,
};

const shariaCompliantOptionsByConsideration = {
  STOCK: [
    {
      name: "Sell Dividend",
      value: "SELL_INCOME_STOCK",
    },
  ],
  CASH: [
    {
      name: "Buy Growth",
      value: "BUY_GROWTH_CASH",
    },
  ],
};

// To hide the Shareholder option while amending the order
export const getOptionsByConsideration = (
  consideration,
  userDetail,
  orderId
) => {
  if (userDetail?.groups?.includes(INVESTOR_SHARIA_COMPLIANT_GROUPNAME))
    return shariaCompliantOptionsByConsideration[consideration];
  let options = optionsByConsideration[consideration];
  if (orderId && consideration === "STOCK") {
    options = options.filter((option) => {
      return option.value !== SHAREHOLDER_APP;
    });
  }
  return options;
};

export const riskOptionsRGB = [
  {
    name: "Low",
    value: "LOW_RISK",
  },
  {
    name: "Market",
    value: "MARKET_RISK",
  },
  {
    name: "Accelarated Upside",
    value: "HIGH_RISK",
  },
];

export const riskOptionsPurple = [
  {
    name: "Market",
    value: "ULTRA_RISK",
  },
];

export const riskQuestionByActionOption = {
  SELL_INCOME_STOCK: "My appetite for Growth risk is...",
  SELL_GROWTH_STOCK: "My appetite for Capital risk is...",
  BUY_INCOME_CASH: "My appetite for Capital risk is...",
  BUY_GROWTH_CASH: "My appetite for Growth risk is...",
};
export const shareholderApplicationOptionPurple = [
  {
    name: "UltraGuard / UltraGrowth",
    value: "ULTRA_GUARD_ULTRA_GROWTH",
  },
];

export const shareholderApplicationOptions = [
  {
    name: "MaxDiv / GrowthGuard",
    value: "MAX_DIV_GROWTH_GUARD",
  },
  {
    name: "PureDiv / PureGrowth",
    value: "PURE_DIV_PURE_GROWTH",
  },
  {
    name: "DivGuard / MaxGrowth",
    value: "DIV_GUARD_MAX_GROWTH",
  },
];

export const orderProductByActionRisk = {
  SELL_INCOME_STOCK: {
    LOW_RISK: {
      receiveProductId: "GROWTH_GUARD",
      receiveProductName: "GrowthGuard",
      orderProductId: "MAX_DIV",
      orderProductName: "MaxDiv",
      orderSide: "SELL",
      considerationType: 2,
    },
    MARKET_RISK: {
      receiveProductId: "PURE_GROWTH",
      receiveProductName: "PureGrowth",
      orderProductId: "PURE_DIV",
      orderProductName: "PureDiv",
      orderSide: "SELL",
      considerationType: 2,
    },
    HIGH_RISK: {
      receiveProductId: "MAX_GROWTH",
      receiveProductName: "MaxGrowth",
      orderProductId: "DIV_GUARD",
      orderProductName: "DivGuard",
      orderSide: "SELL",
      considerationType: 2,
    },
    ULTRA_RISK: {
      receiveProductId: "ULTRA_GROWTH",
      receiveProductName: "UltraGrowth",
      orderProductId: "ULTRA_GUARD",
      orderProductName: "UltraGuard",
      orderSide: "SELL",
      considerationType: 2,
    },
  },
  SELL_GROWTH_STOCK: {
    LOW_RISK: {
      receiveProductId: "DIV_GUARD",
      receiveProductName: "DivGuard",
      orderProductId: "MAX_GROWTH",
      orderProductName: "MaxGrowth",
      orderSide: "SELL",
      considerationType: 2,
    },
    MARKET_RISK: {
      receiveProductId: "PURE_DIV",
      receiveProductName: "PureDiv",
      orderProductId: "PURE_GROWTH",
      orderProductName: "PureGrowth",
      orderSide: "SELL",
      considerationType: 2,
    },
    HIGH_RISK: {
      receiveProductId: "MAX_DIV",
      receiveProductName: "MaxDiv",
      orderProductId: "GROWTH_GUARD",
      orderProductName: "GrowthGuard",
      orderSide: "SELL",
      considerationType: 2,
    },
    ULTRA_RISK: {
      receiveProductId: "ULTRA_GUARD",
      receiveProductName: "UltraGuard",
      orderProductId: "ULTRA_GROWTH",
      orderProductName: "UltraGrowth",
      orderSide: "SELL",
      considerationType: 2,
    },
  },
  BUY_INCOME_STOCK: {
    LOW_RISK: {
      receiveProductId: "DIV_GUARD",
      receiveProductName: "DivGuard",
      orderProductId: "MAX_GROWTH",
      orderProductName: "MaxGrowth",
      orderSide: "SELL",
      considerationType: 2,
    },
    MARKET_RISK: {
      receiveProductId: "PURE_DIV",
      receiveProductName: "PureDiv",
      orderProductId: "PURE_GROWTH",
      orderProductName: "PureGrowth",
      orderSide: "SELL",
      considerationType: 2,
    },
    HIGH_RISK: {
      receiveProductId: "MAX_DIV",
      receiveProductName: "MaxDiv",
      orderProductId: "GROWTH_GUARD",
      orderProductName: "GrowthGuard",
      orderSide: "SELL",
      considerationType: 2,
    },
    ULTRA_RISK: {
      receiveProductId: "ULTRA_GUARD",
      receiveProductName: "UltraGuard",
      orderProductId: "ULTRA_GROWTH",
      orderProductName: "UltraGrowth",
      orderSide: "SELL",
      considerationType: 2,
    },
  },
  BUY_GROWTH_STOCK: {
    LOW_RISK: {
      receiveProductId: "GROWTH_GUARD",
      receiveProductName: "GrowthGuard",
      orderProductId: "MAX_DIV",
      orderProductName: "MaxDiv",
      orderSide: "SELL",
      considerationType: 2,
    },
    MARKET_RISK: {
      receiveProductId: "PURE_GROWTH",
      receiveProductName: "PureGrowth",
      orderProductId: "PURE_DIV",
      orderProductName: "PureDiv",
      orderSide: "SELL",
      considerationType: 2,
    },
    HIGH_RISK: {
      receiveProductId: "MAX_GROWTH",
      receiveProductName: "MaxGrowth",
      orderProductId: "DIV_GUARD",
      orderProductName: "DivGuard",
      orderSide: "SELL",
      considerationType: 2,
    },
    ULTRA_RISK: {
      receiveProductId: "ULTRA_GROWTH",
      receiveProductName: "UltraGrowth",
      orderProductId: "ULTRA_GUARD",
      orderProductName: "UltraGuard",
      orderSide: "SELL",
      considerationType: 2,
    },
  },
  BUY_INCOME_CASH: {
    LOW_RISK: {
      receiveProductId: "DIV_GUARD",
      receiveProductName: "DivGuard",
      orderProductId: "DIV_GUARD",
      orderProductName: "DivGuard",
      orderSide: "BUY",
      considerationType: 1,
    },
    MARKET_RISK: {
      receiveProductId: "PURE_DIV",
      receiveProductName: "PureDiv",
      orderProductId: "PURE_DIV",
      orderProductName: "PureDiv",
      orderSide: "BUY",
      considerationType: 1,
    },
    HIGH_RISK: {
      receiveProductId: "MAX_DIV",
      receiveProductName: "MaxDiv",
      orderProductId: "MAX_DIV",
      orderProductName: "MaxDiv",
      orderSide: "BUY",
      considerationType: 1,
    },
    ULTRA_RISK: {
      receiveProductId: "ULTRA_GUARD",
      receiveProductName: "UltraGuard",
      orderProductId: "ULTRA_GUARD",
      orderProductName: "UltraGuard",
      orderSide: "BUY",
      considerationType: 1,
    },
  },
  BUY_GROWTH_CASH: {
    LOW_RISK: {
      receiveProductId: "GROWTH_GUARD",
      receiveProductName: "GrowthGuard",
      orderProductId: "GROWTH_GUARD",
      orderProductName: "GrowthGuard",
      orderSide: "BUY",
      considerationType: 1,
    },
    MARKET_RISK: {
      receiveProductId: "PURE_GROWTH",
      receiveProductName: "PureGrowth",
      orderProductId: "PURE_GROWTH",
      orderProductName: "PureGrowth",
      orderSide: "BUY",
      considerationType: 1,
    },
    HIGH_RISK: {
      receiveProductId: "MAX_GROWTH",
      receiveProductName: "MaxGrowth",
      orderProductId: "MAX_GROWTH",
      orderProductName: "MaxGrowth",
      orderSide: "BUY",
      considerationType: 1,
    },
    ULTRA_RISK: {
      receiveProductId: "ULTRA_GROWTH",
      receiveProductName: "UltraGrowth",
      orderProductId: "ULTRA_GROWTH",
      orderProductName: "UltraGrowth",
      orderSide: "BUY",
      considerationType: 1,
    },
  },
};

export const orderProductByShareholderApplicationOption = {
  MAX_DIV_GROWTH_GUARD: {
    receiveIncomeProductId: "MAX_DIV",
    receiveIncomeProductName: "MaxDiv",
    receiveGrowthProductId: "GROWTH_GUARD",
    receiveGrowthProductName: "GrowthGuard",
  },
  PURE_DIV_PURE_GROWTH: {
    receiveIncomeProductId: "PURE_DIV",
    receiveIncomeProductName: "PureDiv",
    receiveGrowthProductId: "PURE_GROWTH",
    receiveGrowthProductName: "PureGrowth",
  },
  DIV_GUARD_MAX_GROWTH: {
    receiveIncomeProductId: "DIV_GUARD",
    receiveIncomeProductName: "DivGuard",
    receiveGrowthProductId: "MAX_GROWTH",
    receiveGrowthProductName: "MaxGrowth",
  },
  ULTRA_GUARD_ULTRA_GROWTH: {
    receiveIncomeProductId: "ULTRA_GUARD",
    receiveIncomeProductName: "UltraGuard",
    receiveGrowthProductId: "ULTRA_GROWTH",
    receiveGrowthProductName: "UltraGrowth",
  },
};

export const tenorOptions = [
  {
    name: "3 years",
    value: 3,
  },
  {
    name: "5 years",
    value: 5,
  },
  {
    name: "10 years",
    value: 10,
  },
];

export const prismFee = 0.0033;

export const formActionOption = (orderData) => {
  return `${orderData?.orderSide}_${orderData.seriesType}_${orderData.considerationType}`;
};

export const formRiskOption = (orderData) => {
  const optionValue = formActionOption(orderData);
  const actionRiskOptions = orderProductByActionRisk[optionValue];
  let risk = "";
  forEach(actionRiskOptions, (list, key) => {
    let type =
      orderData?.orderSide === "BUY" ? "receiveProductId" : "orderProductId";
    if (list[type] === orderData?.productName) {
      risk = key;
      return false;
    }
  });
  return risk;
};
