import React, { Component } from "react";
import "./style.scss";
import _isUndefined from "lodash/isUndefined";
import _isEmpty from "lodash/isEmpty";
import Input from "antd/es/input";
import cn from "classnames";
import message from "antd/es/message";
import { intl } from "../../../../utils/intl_i18n";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";
import { PrismAddress, validatePOInput } from "../../../PrismAddress";
import Acn from "../../../acn";
import { validateDate } from "../../../common/validation";
import { dateErrorMessage } from "../../../common/message";
import axiosConfig from "../../../../axiosConfig";
import ROUTES from "../../../../routes/api.json";

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
      isUserDetailsEmpty: this.validateUserDetails(props.payload),
      isACNDetailsEmpty: this.validateACNDetails(props.payload),
      showSoleTrader: false,
      isCompanyFieldEditable: false,
    };
  }

  validateUserDetails = (payload) => {
    let isUserContentEmpty = true;
    if (
      !_isEmpty(payload?.title) ||
      !_isEmpty(payload?.familyName) ||
      !_isEmpty(payload?.givenName)
    ) {
      isUserContentEmpty = false;
    }
    return isUserContentEmpty;
  };

  validateACNDetails = (payload) => {
    let isACNContentEmpty = true;
    if (!_isEmpty(payload?.acnNumber)) {
      isACNContentEmpty = false;
    }
    return isACNContentEmpty;
  };

  validate = () => {
    const { isACNDetailsEmpty, isUserDetailsEmpty, payload } = this.state;
    // If userDetails as well as ACN details are empty, validation is failed
    if (isUserDetailsEmpty && isACNDetailsEmpty) {
      this.onValidationFail();
      return;
    }

    //If the user details and the ACN Details are not empty, all the fields inside both should be validated
    if (
      !isUserDetailsEmpty &&
      !isACNDetailsEmpty &&
      (this.validateACNFields() || this.validateUserFields())
    ) {
      this.onValidationFail();
      return;
    }

    // If userDetails is not empty and ACN details are empty, only the validation required for the UserDetails fields
    if (!isUserDetailsEmpty && isACNDetailsEmpty && this.validateUserFields()) {
      this.onValidationFail();
      return;
    }

    // If ACN details is not empty and userDetails are empty, only the validation required for the ACN details fields
    if (isUserDetailsEmpty && !isACNDetailsEmpty && this.validateACNFields()) {
      this.onValidationFail();
      return;
    }

    // ACN Input field validation is not handled earlier, the Next button is enabled eventhough the ACN field throws error
    if (!isACNDetailsEmpty && payload?.errors?.abnAcn) {
      this.onValidationFail();
      return;
    }

    this.onValidationPass();
  };

  /** @method - To valid the userDetails fields and send the validation status */
  validateUserFields = () => {
    const { payload } = this.state;
    if (
      payload.title === undefined ||
      !payload.title ||
      payload.familyName === undefined ||
      !payload.familyName ||
      payload.givenName === undefined ||
      !payload.givenName ||
      payload.dateOfBirth === undefined ||
      !payload.dateOfBirth ||
      (payload.dateOfBirth && !validateDate(payload.dateOfBirth)) ||
      payload.streetAddress === undefined ||
      !payload.streetAddress ||
      (payload.streetAddress &&
        (payload.streetAddress.length < 8 ||
          payload.streetAddress.length > 225 ||
          !validatePOInput(payload.streetAddress))) ||
      payload.subUrban === undefined ||
      !payload.subUrban ||
      (payload.subUrban && payload.subUrban.length < 4) ||
      payload.state === undefined ||
      !payload.state ||
      payload.postCode === undefined ||
      !payload.postCode ||
      (payload.postCode && payload.postCode.length < 4) ||
      payload.country === undefined ||
      !payload.country
    ) {
      return true;
    } else {
      return false;
    }
  };

  /** @method - To valid the ACN fields and send the validation status */
  validateACNFields = () => {
    const { payload } = this.state;
    if (
      payload.acnNumber === undefined ||
      !payload.acnNumber ||
      payload.companyName === undefined ||
      !payload.companyName ||
      payload.businessStreetAddress === undefined ||
      !payload.businessStreetAddress ||
      (payload.businessStreetAddress &&
        (payload.businessStreetAddress.length < 8 ||
          payload.businessStreetAddress.length > 225 ||
          !validatePOInput(payload.businessStreetAddress))) ||
      payload.businessSubUrban === undefined ||
      !payload.businessSubUrban ||
      (payload.businessSubUrban && payload.businessSubUrban.length < 4) ||
      payload.businessState === undefined ||
      !payload.businessState ||
      payload.businessPostCode === undefined ||
      !payload.businessPostCode ||
      (payload.businessPostCode && payload.businessPostCode.length < 4) ||
      payload.businessCountry === undefined ||
      !payload.businessCountry
    ) {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount() {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .get(`${ROUTES.STORAGE_URL}`, config)
      .then((res) => {
        if (res.data) {
          if (res.data !== "") {
            if (res.data.payload.investorEntity.value !== "registeredTrust") {
              this.setState({ showSoleTrader: true });
            }
          }
        }
      })
      .catch((error) => {
        console.log("Server response: " + error);
      });
    this.validate();
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  onInputChange = (field, value) => {
    const { payload, isACNDetailsEmpty, isUserDetailsEmpty } = this.state;
    payload[field] = value;

    // Enabling the User and ACN details on every change event to make the address fields mandatory
    if (
      isACNDetailsEmpty !== this.validateACNDetails(payload) ||
      isUserDetailsEmpty !== this.validateUserDetails(payload)
    ) {
      this.setState({
        isACNDetailsEmpty: this.validateACNDetails(payload),
        isUserDetailsEmpty: this.validateUserDetails(payload),
      });
    }
    this.updateState(payload);
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  /**
   * @Method To set the value of the companyName based on the ACN lookup
   * @param {string} value Value from the ACN lookup field to set the CompanyName
   */
  companyName = (value) => {
    const { payload } = this.state;
    // PBT-1575 - Updating the value only if the value returns from the API, otherwise if the user edits and ACN lookup API returns empty, the previous value is not cleared
    if (value) {
      payload.companyName = value;
    }
    payload.errors = "";
    this.updateState(payload);
  };
  abnNumber = (value) => {
    const { payload } = this.state;
    payload.acnNumber = value;
    this.setState({
      isACNDetailsEmpty: this.validateACNDetails(payload),
    });
    this.updateState(payload);
  };

  abnErrors = (value) => {
    const { payload } = this.state;
    payload.errors = value;
    this.updateState(payload);
  };

  handleAddressChange = (fieldValue, fieldName) => {
    this.onInputChange(fieldName, fieldValue);
  };

  /** PBT-1575
   * @method Method to validate the CompanyName to be editable based on the value from the ACN lookup API
   * @param {boolean} value - Value to update if the CompanyField is editable
   */
  validateCompanyNameField = (value) => {
    this.updateState({
      isCompanyFieldEditable: value,
    });
  };

  /**
   * PBT-1575 - This will work only if the company field is editable
   * @param {*} fieldKey - Key to handle the fieldKey in the Payload
   * @param {*} value - value to be updated after the field change
   */
  handleCompanyNameChange = (fieldKey, value) => {
    const { isCompanyFieldEditable } = this.state;
    if (!isCompanyFieldEditable) {
      return;
    }
    this.onInputChange(fieldKey, value);
  };

  render() {
    const { payload } = this.state;

    return (
      <div className="form">
        <div className="header">
          <div className="title">
            {this.formatMessage_i18n("personalDetails.title")}
          </div>
        </div>
        <div className="main">
          <div className="block">
            <div className="label">
              {this.formatMessage_i18n("personalDetails.label1")}
            </div>
            <div className="row">
              <div className="left">
                <select
                  value={payload.title}
                  className={cn("register-select-large", {
                    // Either User Details must be present or ACN details must be present or both
                    "error-field":
                      (!this.state.isUserDetailsEmpty ||
                        this.state.isACNDetailsEmpty) &&
                      (_isUndefined(payload.title) || payload.title === ""),
                  })}
                  onChange={(e) => this.onInputChange("title", e.target.value)}
                >
                  <option value="">Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="right">
                <Input
                  className={cn(
                    "register-input-small user-details-input firstname-field",
                    {
                      // Either User Details must be present or ACN details must be present or both
                      "error-field":
                        (!this.state.isUserDetailsEmpty ||
                          this.state.isACNDetailsEmpty) &&
                        (_isUndefined(payload.familyName) ||
                          payload.familyName === ""),
                    }
                  )}
                  placeholder={this.formatMessage_i18n(
                    "personalDetails.familyName"
                  )}
                  onChange={(e) =>
                    this.onInputChange("familyName", e.target.value)
                  }
                  value={payload.familyName}
                />
                <Input
                  className={cn("register-input-small user-details-input", {
                    // Either User Details must be present or ACN details must be present or both
                    "error-field":
                      (!this.state.isUserDetailsEmpty ||
                        this.state.isACNDetailsEmpty) &&
                      (_isUndefined(payload.givenName) ||
                        payload.givenName === ""),
                  })}
                  placeholder={this.formatMessage_i18n(
                    "personalDetails.givenName"
                  )}
                  onChange={(e) =>
                    this.onInputChange("givenName", e.target.value)
                  }
                  value={payload.givenName}
                />
                <DatePicker
                  size="middle"
                  format="DD-MM-YYYY"
                  className={cn("register-input-small user-details-input", {
                    "error-field":
                      !this.state.isUserDetailsEmpty &&
                      (_isUndefined(payload.dateOfBirth) ||
                        payload.dateOfBirth === "" ||
                        (payload.dateOfBirth &&
                          !validateDate(payload.dateOfBirth))),
                  })}
                  placeholder={this.formatMessage_i18n("user.dateofbirth")}
                  value={
                    !_isUndefined(payload.dateOfBirth) &&
                    payload.dateOfBirth !== ""
                      ? moment(payload.dateOfBirth, "DD-MM-YYYY")
                      : ""
                  }
                  onChange={(date, dateString) => {
                    if (!validateDate(dateString)) {
                      message.error({ content: dateErrorMessage, duration: 3 });
                    }
                    this.onInputChange("dateOfBirth", dateString);
                  }}
                  allowClear={false}
                  suffixIcon={<CalendarOutlined className="calender-icon" />}
                />
              </div>
            </div>
            <div className="row">
              <PrismAddress
                streetPlaceholder={this.formatMessage_i18n("Street.Address")}
                streetValue={payload.streetAddress}
                streetOnChange={(value) =>
                  this.handleAddressChange(value, "streetAddress")
                }
                isStreetFieldRequired={!this.state.isUserDetailsEmpty}
                subUrbanPlaceholder={this.formatMessage_i18n("Suborb.or.town")}
                subUrbanValue={payload.subUrban}
                subUrbanOnChange={(value) =>
                  this.handleAddressChange(value, "subUrban")
                }
                isSubUrbanFieldRequired={!this.state.isUserDetailsEmpty}
                statePlaceholder={this.formatMessage_i18n("reg.state")}
                stateValue={payload.state}
                stateOnChange={(val) => this.handleAddressChange(val, "state")}
                isStateFieldRequired={!this.state.isUserDetailsEmpty}
                codePlaceholder={this.formatMessage_i18n("reg.postCode")}
                codeValue={payload.postCode}
                codeOnChange={(val) =>
                  this.handleAddressChange(val, "postCode")
                }
                isCodeFieldRequired={!this.state.isUserDetailsEmpty}
                countryPlaceholder={this.formatMessage_i18n("reg.country")}
                countryValue={payload.country}
                countryOnChange={(val) =>
                  this.handleAddressChange(val, "country")
                }
                isCountryFieldRequired={!this.state.isUserDetailsEmpty}
              />
            </div>
          </div>
          {this.state.showSoleTrader && (
            <div className="block">
              <div className="label">
                {this.formatMessage_i18n("personalDetails.label2")}
              </div>
              <Acn
                className={cn("register-input-small", {
                  // Either User Details must be present or ACN details must be present or both
                  "error-field":
                    (this.state.isUserDetailsEmpty ||
                      !this.state.isACNDetailsEmpty) &&
                    (_isUndefined(payload.acnNumber) ||
                      payload.acnNumber === "" ||
                      payload?.errors?.abnAcn),
                })}
                companyName={this.companyName}
                abnErrors={this.abnErrors}
                abnNumber={this.abnNumber}
                value={payload.acnNumber}
                validateCompanyNameField={this.validateCompanyNameField}
              />
              <Input
                className={cn("register-input-small", {
                  // Either User Details must be present or ACN details must be present or both
                  "error-field":
                    (this.state.isUserDetailsEmpty ||
                      !this.state.isACNDetailsEmpty) &&
                    (_isUndefined(payload.companyName) ||
                      payload.companyName === ""),
                })}
                placeholder={this.formatMessage_i18n("company.name")}
                value={payload.companyName}
                readOnly={!this.state.isCompanyFieldEditable}
                onChange={(e) =>
                  this.handleCompanyNameChange("companyName", e.target.value)
                }
              />
              <PrismAddress
                streetPlaceholder={this.formatMessage_i18n("Street.Address")}
                streetValue={payload.businessStreetAddress}
                streetOnChange={(value) =>
                  this.handleAddressChange(value, "businessStreetAddress")
                }
                isStreetFieldRequired={!this.state.isACNDetailsEmpty}
                subUrbanPlaceholder={this.formatMessage_i18n("Suborb.or.town")}
                subUrbanValue={payload.businessSubUrban}
                subUrbanOnChange={(value) =>
                  this.handleAddressChange(value, "businessSubUrban")
                }
                isSubUrbanFieldRequired={!this.state.isACNDetailsEmpty}
                statePlaceholder={this.formatMessage_i18n("reg.state")}
                stateValue={payload.businessState}
                stateOnChange={(val) =>
                  this.handleAddressChange(val, "businessState")
                }
                isStateFieldRequired={!this.state.isACNDetailsEmpty}
                codePlaceholder={this.formatMessage_i18n("reg.postCode")}
                codeValue={payload.businessPostCode}
                codeOnChange={(val) =>
                  this.handleAddressChange(val, "businessPostCode")
                }
                isCodeFieldRequired={!this.state.isACNDetailsEmpty}
                countryPlaceholder={this.formatMessage_i18n("reg.country")}
                countryValue={payload.businessCountry}
                countryOnChange={(val) =>
                  this.handleAddressChange(val, "businessCountry")
                }
                isCountryFieldRequired={!this.state.isACNDetailsEmpty}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PersonalDetails;
