import { intl } from "../../utils/intl_i18n";
import {
  getInvestorRegistrationType,
  getInvestorKYCStatus,
} from "../../utils/utile";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useUserDetail } from "../../hooks/investors";

export default function NotApprovedBanner() {
  const { data: userDetail } = useUserDetail();
  if (
    getInvestorRegistrationType(userDetail) === "ONLINE_WEBFORMS" &&
    getInvestorKYCStatus(userDetail) !== "REGISTERED_APPROVED"
  ) {
    return (
      <div className="max-w-md mx-auto p-4 bg-light-gradient backdrop-blur login-box-shadow rounded-xl text-white flex justify-between">
        <div className="flex items-center gap-x-2">
          <ExclamationCircleFilled />
          {intl.formatMessage({ id: "wait.to.approve" })}
        </div>
        <a className="text-white" href="/register">
          Complete Application
        </a>
      </div>
    );
  }
  return null;
}
