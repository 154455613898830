import React, { Component } from "react";
import "./style.scss";
import _isUndefined from "lodash/isUndefined";
import { intl } from "../../../../utils/intl_i18n";
import { CountryDropdown } from "react-country-region-selector";
import cn from "classnames";
import PlaceIndex from "../../../awslocation/placeIndex";

class ForeignCorporateIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  validate = () => {
    const { payload } = this.state;
    if (
      payload.registeredForeignBody === undefined ||
      !payload.registeredForeignBody
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.country === undefined || !payload.country) {
      this.onValidationFail();
      return;
    }
    if (
      payload.registeredForeignBody === "yes" &&
      (payload.registrarName === undefined || !payload.registrarName)
    ) {
      this.onValidationFail();
      return;
    }
    if (
      payload.foreignCompanyName === undefined ||
      !payload.foreignCompanyName
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.registeredByASIC === undefined || !payload.registeredByASIC) {
      this.onValidationFail();
      return;
    }
    if (payload.registeredAddress === undefined || !payload.registeredAddress) {
      this.onValidationFail();
      return;
    }
    if (
      payload.foreignCompanyType === undefined ||
      !payload.foreignCompanyType
    ) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onChangeRegisteredForeignBody = (e) => {
    const { payload } = this.state;
    payload.registeredForeignBody = e.target.value;
    if (e.target.value === "no") {
      payload.registrarName = "";
    }
    this.updateState(payload);
  };

  onChangeRegisteredByASIC = (e) => {
    const { payload } = this.state;
    payload.registeredByASIC = e.target.value;
    this.updateState(payload);
  };

  onChangeforeignCompanyType = (e) => {
    const { payload } = this.state;
    payload.foreignCompanyType = e.target.value;
    this.updateState(payload);
  };

  selectCountry(val) {
    const { payload } = this.state;
    payload.country = val;
    this.updateState(payload);
  }

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    this.updateState(payload);
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="foreignCorporateIdentification-main">
        <div className="foreignCorporateIdentification-title">
          {this.formatMessage_i18n("foreignCorporateIdentification.title")}
        </div>
        <div className="foreignCorporateIdentification-label">
          {this.formatMessage_i18n("foreignCorporateIdentification.country")}
        </div>
        <div className="foreignCorporateIdentification-dropdown">
          <CountryDropdown
            className={
              (_isUndefined(payload.country) || payload.country === "") &&
              "error-field"
            }
            onChange={(val) => this.selectCountry(val)}
            value={payload.country}
          />
        </div>
        <div className="foreignCorporateIdentification-label">
          {this.formatMessage_i18n(
            "foreignCorporateIdentification.foreignCompanyName"
          )}
        </div>
        <div className="foreign-company-name">
          <input
            className={cn("foreignCorporateIdentification-foreignCompanyName", {
              "error-field":
                _isUndefined(payload.foreignCompanyName) ||
                payload.foreignCompanyName === "",
            })}
            placeholder={this.formatMessage_i18n(
              "foreignCorporateIdentification.foreignCompanyName"
            )}
            onChange={(e) =>
              this.onInputChange("foreignCompanyName", e.target.value)
            }
            value={payload.foreignCompanyName}
          />
        </div>
        <div className="foreignCorporateIdentification-label">
          {this.formatMessage_i18n(
            "foreignCorporateIdentification.registeredForeignBody"
          )}
        </div>
        <div className="select">
          <select
            className={
              (_isUndefined(payload.registeredForeignBody) ||
                payload.registeredForeignBody === "") &&
              "error-field"
            }
            onChange={this.onChangeRegisteredForeignBody}
            value={payload.registeredForeignBody}
          >
            <option value="">Yes/No</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>

          {payload.registeredForeignBody === "yes" && (
            <input
              className={cn("foreignCorporateIdentification-registrarName", {
                "error-field":
                  _isUndefined(payload.registrarName) ||
                  payload.registrarName === "",
              })}
              placeholder={this.formatMessage_i18n(
                "foreignCorporateIdentification.registrarName"
              )}
              onChange={(e) =>
                this.onInputChange("registrarName", e.target.value)
              }
              value={payload.registrarName}
            />
          )}
        </div>
        <div className="foreignCorporateIdentification-label">
          {this.formatMessage_i18n(
            "foreignCorporateIdentification.registeredByASIC"
          )}
        </div>
        <div className="select">
          <select
            className={
              (_isUndefined(payload.registeredByASIC) ||
                payload.registeredByASIC === "") &&
              "error-field"
            }
            onChange={this.onChangeRegisteredByASIC}
            value={payload.registeredByASIC}
          >
            <option value="">Yes/No</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="foreignCorporateIdentification-label">
          {this.formatMessage_i18n(
            "foreignCorporateIdentification.registeredAddress.title"
          )}
        </div>
        <div className={cn("foreignCorporateIdentification-registeredAddress")}>
          <PlaceIndex
            placeholder={this.formatMessage_i18n(
              "foreignCorporateIdentification.registeredAddress.text"
            )}
            onChange={(value) => this.onInputChange("registeredAddress", value)}
            value={payload.registeredAddress}
            className={cn({
              "error-field-autosuggest":
                _isUndefined(payload.registeredAddress) ||
                payload.registeredAddress === "",
            })}
          />
        </div>
        <div className="foreignCorporateIdentification-label">
          {this.formatMessage_i18n(
            "foreignCorporateIdentification.foreignCompanyType.title"
          )}
        </div>
        <div className="foreignCorporateIdentification-dropdown">
          <select
            className={
              (_isUndefined(payload.foreignCompanyType) ||
                payload.foreignCompanyType === "") &&
              "error-field"
            }
            onChange={this.onChangeforeignCompanyType}
            value={payload.foreignCompanyType}
          >
            <option value="">Public/Private</option>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>
        </div>
      </div>
    );
  }
}

export default ForeignCorporateIdentification;
