import React, { Component } from "react";

class SettingsLogo extends Component {
  render() {
    return (
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="13.625"
          y1="21"
          x2="13.625"
          y2="15.125"
          stroke="#8a8e94"
          strokeWidth="2"
        />
        <line
          x1="13.625"
          y1="7.875"
          x2="13.625"
          y2="2"
          stroke="#8a8e94"
          strokeWidth="2"
        />
        <line
          x1="21.2929"
          y1="12.2071"
          x2="17.6679"
          y2="8.58211"
          stroke="#8a8e94"
          strokeWidth="2"
        />
        <line
          y1="-1"
          x2="5.12652"
          y2="-1"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 22 11.5)"
          stroke="#8a8e94"
          strokeWidth="2"
        />
        <line
          x1="22"
          y1="12.5"
          x2="7.375"
          y2="12.5"
          stroke="#8a8e94"
          strokeWidth="2"
        />
        <line
          x1="-2.13499e-09"
          y1="1"
          x2="14.625"
          y2="1"
          stroke="#8a8e94"
          strokeWidth="2"
        />
        <line
          x1="-2.13499e-09"
          y1="20"
          x2="14.625"
          y2="20"
          stroke="#8a8e94"
          strokeWidth="2"
        />
        <line x1="1" y1="2" x2="1" y2="21" stroke="#8a8e94" strokeWidth="2" />
      </svg>
    );
  }
}

export default SettingsLogo;
