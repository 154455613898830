import CurrencyFilter from "./CurrencyFilter";
import RGBToggle from "./RGBToggle";

export default function FilterPanel({ currency, side }) {
  return (
    <div className="flex gap-x-4 items-center">
      <div className="italic font-light text-[#C0C1C6] text-[19px]">
        Filters
      </div>
      {currency && <CurrencyFilter />}
      <RGBToggle side={side} />
    </div>
  );
}
