// First we need to import axios.js
import axios from "axios";
import { getAccess } from "./utils/utile";
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
});

instance.interceptors.request.use(
  (request) => {
    let token = getAccess();
    if (token != "") {
      request.headers["Authorization"] = token;
    }
    return request;
  },
  (error) => {
    console.log("request interceptor", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("response interceptor", error.response);

    if (error.response.status === 403) {
      // Create the event.
      const event = new CustomEvent("error", { detail: true });
      // target can be any Element or other EventTarget.
      document.body.dispatchEvent(event);
    }

    return Promise.reject(error);
  }
);

export default instance;
