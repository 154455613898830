import React, { useEffect, useState } from "react";
import { Upload, Button, Select, Modal, List, Row, Col, Spin } from "antd";
import PageContent from "../../common/PageContent";
import {
  DeleteOutlined,
  FilePdfOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import ROUTES from "../../../routes/api.json";
import axiosConfig from "../../../axiosConfig";
import { useHistory } from "react-router-dom";
import message from "antd/es/message";
import { useUserDetail } from "../../../hooks/investors";
import "./styles.scss";

const { Option } = Select;

const UploadAndRegister = () => {
  const history = useHistory();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentUploadedFile, setCurrentUploadedFile] = useState(undefined);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [visible, setVisible] = useState(false);
  const { data: userDetail } = useUserDetail();
  const [updateConfirmation, setUpdateConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (file) => {
    setCurrentUploadedFile(file);
    showModal();
  };

  const fetchDocumentTypes = async () => {
    try {
      const res = await axiosConfig.get(ROUTES.REGISTRATION_DOCUMENT_TYPES);
      if (res?.data) {
        setDocumentTypes(res.data);
      }
    } catch (error) {
      // Handle error if API call fails
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDocumentTypes();

    // setUpdateConfirmation(true);
  }, [userDetail]);

  const handleDelete = (item) => {
    const index = uploadedFiles.indexOf(item);
    uploadedFiles.splice(index, 1);
    setUploadedFiles([...uploadedFiles]);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (currentUploadedFile) {
      let uploadedFile = {
        documentTypeId: selectedDocumentType.id,
        file: currentUploadedFile,
        description: selectedDocumentType.description,
      };
      console.log(uploadedFile);
      uploadedFiles.push(uploadedFile);
      setUploadedFiles([...uploadedFiles]);
      setVisible(false);
    }
    setCurrentUploadedFile(undefined);
    setSelectedDocumentType(undefined);
    setVisible(false);
  };

  const handleCancel = () => {
    setCurrentUploadedFile(undefined);
    setSelectedDocumentType(undefined);
    setVisible(false);
  };

  const handleConfirmationOk = () => {
    setUpdateConfirmation(false);
  };

  const handleConfirmationCancel = () => {
    history.push("/available");
  };

  const handleDocumentTypeChange = (id) => {
    const documentType = documentTypes.find(
      (documentType) => documentType.id === id
    );
    setSelectedDocumentType(documentType);
  };

  const handleBack = () => {
    history.push("/online-pdf-registration");
  };

  const handleSubmit = async () => {
    const uploadData = new FormData();

    setLoading(true);
    if (uploadedFiles.length <= 0) {
      message.warn("Cannot upload empty files");
      setLoading(false);
      return;
    }
    uploadedFiles.forEach((uploadedFile, index) => {
      uploadData.append(`files`, uploadedFile.file);
      uploadData.append(`documentTypeIds`, uploadedFile.documentTypeId);
    });

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      await axiosConfig.post(ROUTES.PDF_REGISTRATION_DOCUMENTS, uploadData, {
        headers: headers,
      });
      message.success("Documents are uploaded successfully");
      setLoading(false);
      setTimeout(() => {
        history.push("/view-uploaded-documents");
      }, 2000);
    } catch (error) {
      setLoading(false);
      message.error("Documents upload failed");
      console.error(error); // Handle any error that occurred during the request
    }
  };

  return (
    <PageContent
      className="upload-and-register-container"
      childrenClassName={"document-container"}
    >
      <div className="upload-and-view">
        <p className="heading">Upload and Submit your application</p>
        <Row gutter={24}>
          <Col span={14}>
            <div className="preview-container">
              <h2 className="subheading">Uploaded Files</h2>

              {uploadedFiles?.length > 0 && (
                <div className="list-items">
                  <List
                    dataSource={uploadedFiles}
                    renderItem={(item, index) => (
                      <List.Item
                        className="list-item"
                        actions={[
                          <Button
                            key={item.id}
                            type="text"
                            icon={<DeleteOutlined className="delete-icon" />}
                            onClick={() => handleDelete(item)}
                          />,
                        ]}
                      >
                        <p className="file-name">
                          {item.file.name} - ({item.description})
                        </p>
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col span={10}>
            <Upload.Dragger
              className="upload-container"
              multiple={false}
              accept=".pdf"
              beforeUpload={(file) => {
                return false;
              }}
              showUploadList={false}
              onChange={(info) => {
                handleFileUpload(info.file);
              }}
            >
              <p className="ant-upload-drag-icon">
                <FilePdfOutlined className="upload-icon" />
              </p>
              <p className="line-1">Select a PDF file to upload</p>
              <p className="line-2">or drag and drop it here</p>
            </Upload.Dragger>
          </Col>
        </Row>
        <Row className="action-button" gutter={24} justify="center">
          <button
            className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 whitespace-nowrap download-forms"
            onClick={handleBack}
          >
            <ArrowLeftOutlined className="left-arrow" /> Download Forms
          </button>

          <button
            className="bg-[#5B7FFC] rounded-full text-white px-4 py-1 whitespace-nowrap submit-application"
            onClick={handleSubmit}
          >
            Submit Application
            {loading ? (
              <Spin className="spinner" size="medium" />
            ) : (
              <ArrowRightOutlined className="right-arrow" />
            )}
          </button>
        </Row>
        <Modal
          className="document-type-select-dialog"
          title="Choose Document Type"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="body">
            <Row>
              <Col className="text">
                Select document type for the file{" "}
                <b>{currentUploadedFile?.name}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  className="select-input"
                  placeholder="Select an option"
                  onChange={handleDocumentTypeChange}
                  value={selectedDocumentType?.id}
                >
                  {documentTypes.map((documentType) => (
                    <Option key={documentType.id} value={documentType.id}>
                      {documentType.description}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          className="document-type-select-dialog"
          title="Document Update Confirmation"
          open={updateConfirmation}
          onOk={handleConfirmationOk}
          onCancel={handleConfirmationCancel}
        >
          <div className="body">
            You've already submitted the document. Updating the documents may
            override existing documents. Are you sure you want to proceed?
          </div>
        </Modal>
      </div>
    </PageContent>
  );
};

export default UploadAndRegister;
