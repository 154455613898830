import React, { Component } from "react";
import Input from "antd/es/input";
import Button from "antd/es/button";
import message from "antd/es/message";
import "./style.scss";
import validator from "validator";
import axiosConfig from "../../axiosConfig";
import ROUTES from "../../routes/api.json";
import { intl } from "../../utils/intl_i18n";
import PageContent from "../common/PageContent";

export class ExpiredPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  getLogin = () => {
    sessionStorage.clear();
    this.props.history.push("/login");
  };

  handleEmailChange = (value) => {
    this.setState({ email: value });
  };

  requestResetPassword = () => {
    if (!validator.isEmail(this.state.email)) {
      message.warning("Please enter valid Email address!");
    } else {
      let userEntity = {};
      userEntity.email = this.state.email;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axiosConfig
        .post(ROUTES.REQUEST_RESET_PASSWORD, userEntity, config)
        .then((res) => {
          sessionStorage.setItem("userMail", this.state.email);
          this.props.history.push("/sample-message");
        })
        .catch((error) => {
          message.error("Given mail ID is not registered");
        });
    }
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  render() {
    return (
      <PageContent
        className="reset-container"
        childrenClassName={"reset-password-main"}
        renderMenu={false}
      >
        <div className="password-expired">
          <p>Your password has expired, you are required to set a new one </p>
        </div>
        <div className="reset-password-activity">
          <div className="reset-password">
            <p>{this.formatMessage_i18n("Request.password.reset")}</p>
          </div>
          <div className="reset-password-layout">
            <div className="reset-password-entry">
              <Input
                size="large"
                className="reset-password-input"
                placeholder={this.formatMessage_i18n("login.email")}
                type="email"
                onChange={(e) => this.handleEmailChange(e.target.value)}
              />
              <div className="reset-password-button-layout">
                <Button
                  className="reset-password-button"
                  onClick={this.requestResetPassword}
                >
                  {this.formatMessage_i18n("login.reset.password")}
                </Button>
              </div>
            </div>
            <div className="reset-password-content">
              <p> {this.formatMessage_i18n("reset.password.content")}</p>
            </div>
          </div>
        </div>
        <div className="reset-password-resetPassword">
          <p onClick={this.getLogin}>
            {this.formatMessage_i18n("login.login")}
          </p>
        </div>
      </PageContent>
    );
  }
}

export default ExpiredPassword;
