import { useState } from "react";
import cn from "classnames";
import Header from "../Header";
import Footer from "../Footer";
import Modal from "antd/es/modal";
import ParticipantDetail from "../ParticipantDetail";
import "./styles.scss";
import BrokerDetail from "../BrokerDetail";

function PageContent({
  className,
  skipHeader,
  childrenClassName,
  children,
  skipFooter,
  activeMenuHeader,
  footerRefContent,
  history,
  headerMenuCallback,
  renderMenu = true,
  userState,
  renderPageFilter,
  currencySelectCallback,
  etaChangeCallback,
  oboCallback,
  brokerCallback,
  updateSelectionInvestor,
}) {
  const [renderParticipantDetail, setRenderParticipantDetail] = useState(false);
  const [renderBrokerDetail, setRenderBrokerDetail] = useState(false);

  const toggleOBOMenu = () => {
    setRenderParticipantDetail((value) => !value);
  };
  const toggleBrokerMenu = () => {
    setRenderBrokerDetail((value) => !value);
  };
  return (
    <div
      className={cn(
        "content-page bg-cubic-bg-lhs bg-no-repeat bg-center bg-cover h-full flex flex-col overflow-y-scroll pt-[111px]",
        className
      )}
    >
      {!skipHeader && (
        <Header
          activeMenuHeader={activeMenuHeader}
          history={history}
          headerMenuCallback={headerMenuCallback}
          userState={userState}
          renderMenu={renderMenu}
          renderPageFilter={renderPageFilter}
          currencySelectCallback={currencySelectCallback}
          etaChangeCallback={etaChangeCallback}
          oboCallback={toggleOBOMenu}
          brokerCallback={toggleBrokerMenu}
        />
      )}
      <div className="flex-1">
        <div className={cn(childrenClassName, "w-full")}>{children}</div>
      </div>
      {renderParticipantDetail && (
        <Modal
          title="Participant Detail"
          className="participant-detail-modal"
          header={null}
          visible={renderParticipantDetail}
          onCancel={toggleOBOMenu}
          footer={null}
        >
          <div>
            <ParticipantDetail cancelCallback={toggleOBOMenu} />
          </div>
        </Modal>
      )}
      {renderBrokerDetail && (
        <Modal
          title="Select Client Account"
          className="broker-detail-modal"
          header={null}
          visible={renderBrokerDetail}
          onCancel={toggleBrokerMenu}
          footer={null}
        >
          <div>
            <BrokerDetail cancelCallback={toggleBrokerMenu} />
          </div>
        </Modal>
      )}
      {!skipFooter && <Footer FooterRefContent={footerRefContent} />}
    </div>
  );
}

export default PageContent;
