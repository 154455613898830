import { Component } from "react";
import Checkbox from "antd/es/checkbox";
import "antd/dist/antd.css";
import { intl } from "../../../../utils/intl_i18n";
import {
  INVESTMENT_MEMORANDUM,
  PRODUCT_WARRANTY,
} from "../../../common/constants";
import "./styles.scss";

const InvestmentMemorandum = INVESTMENT_MEMORANDUM;

class SubscriptionOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  onValidationFail = () => {
    this.props.onValidationFail();
  };
  onValidationPass = () => {
    this.props.onValidationPass();
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    const { payload } = this.state;
    if (payload.hasSubscribed === undefined) {
      payload.hasSubscribed = false;
      this.updateState(payload);
    }
    if (payload.hasAgreedLegalDocuments === undefined) {
      payload.hasAgreedLegalDocuments = false;
      this.updateState(payload);
    }

    if (payload.hasAgreedProductWarranty === undefined) {
      payload.hasAgreedProductWarranty = false;
      this.updateState(payload);
    }

    if (!payload.hasAgreedLegalDocuments || !payload.hasAgreedProductWarranty) {
      this.onValidationFail();
      return;
    }
    this.onValidationPass();
  };

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value.target.checked;
    this.updateState(payload);
  };

  render() {
    const { hasSubscribed, hasAgreedLegalDocuments, hasAgreedProductWarranty } =
      this.state.payload;
    return (
      <div className="subscription-reg-main-content">
        <div className="reg-header">
          {" "}
          {this.formatMessage_i18n("subscription.option.title.content")}
        </div>
        <div className="reg-subscription-option">
          <Checkbox
            className="reg-checkbox"
            checked={hasSubscribed}
            onChange={(e) => this.onChange("hasSubscribed", e)}
          >
            {this.formatMessage_i18n("subscription.option.content")}
          </Checkbox>
        </div>
        <div className="reg-subscription-option">
          <Checkbox
            className="reg-checkbox reg-terms-conditions"
            checked={hasAgreedLegalDocuments}
            onChange={(e) => this.onChange("hasAgreedLegalDocuments", e)}
          >
            I agree to the{" "}
            <span className="terms">
              <a href="/transaction-document" target="_blank" rel="noreferrer">
                Transaction Documents
              </a>
            </span>{" "}
            and{" "}
            <span className="terms">
              <a
                href={InvestmentMemorandum}
                download="OTC_investment_memorandum.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Product IM
              </a>
            </span>
          </Checkbox>
        </div>
        <div className="reg-subscription-option">
          <Checkbox
            className="reg-checkbox reg-terms-conditions"
            checked={hasAgreedProductWarranty}
            onChange={(e) => this.onChange("hasAgreedProductWarranty", e)}
          >
            I agree to the{" "}
            <span className="terms">
              <a href={PRODUCT_WARRANTY} target="_blank" rel="noreferrer">
                Product Warranties linked here
              </a>
            </span>
          </Checkbox>
        </div>
      </div>
    );
  }
}

export default SubscriptionOptions;
