import React, { Component } from "react";

class SearchIcon extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={21}
        fill="none"
      >
        <path
          stroke="#797D82"
          strokeWidth={1.974}
          d="M11.874 13.793a5.527 5.527 0 1 0 0-11.054 5.527 5.527 0 0 0 0 11.054Z"
        />
        <path
          fill="#797D82"
          d="M.478 18.35a1.533 1.533 0 0 0-.05 2.11l.025.027.026.026a1.533 1.533 0 0 0 2.11-.051l5.025-5.62a5.09 5.09 0 0 1-1.813-1.71L.478 18.351Z"
        />
      </svg>
    );
  }
}

export default SearchIcon;
