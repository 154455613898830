import { Component } from "react";
import { intl } from "../../../../utils/intl_i18n";

class OwnershipControl extends Component {
  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  render() {
    return (
      <div className="ownership.control">
        {this.formatMessage_i18n("beneficial.ownership.popup.content")}
      </div>
    );
  }
}

export default OwnershipControl;
