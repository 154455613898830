import { useState, useEffect, useRef } from "react";
import "../../styles/react-grid-layout-styles.css";
import "../../styles/react-resizable-styles.css";
import "./styles.scss";
import "./viewPositions/styles.scss";
import "../../styles/table-override.scss";
import PageContent from "../common/PageContent";
import { Box, Container } from "@mui/material";
import Button from "antd/es/button";
import OriginalTable from "antd/es/table";
import Modal from "antd/es/modal";
import cn from "classnames";
import SearchIcon from "../logo/searchIcon";
import SellOrder from "./sellOrder";
import ViewPositions from "./viewPositions";
import axiosConfig from "../../axiosConfig";
import ROUTES from "../../routes/api.json";
import _isUndefined from "lodash/isUndefined";
import NumberFormatter from "../common/NumberFormatter";
import DownloadLogo from "../logo/downloadLogo";
import {
  convertToDateFormat,
  exportTabletoXLSX,
  getAccess,
} from "../../utils/utile";
import { numberWithCommas } from "../../utils/numberFormatting";
import ETAPills from "../common/ETAPills";
import SockJsClient from "react-stomp";
import jwt_decode from "jwt-decode";
import Input from "antd/es/input";
import { message } from "antd";
import {
  isReadOnlyStatus,
  isSecondaryMarketAllowed,
  getOBOInvestorID,
  getStorageData,
  useInvestorId,
  getUserId,
} from "../common/utils";
import { intl } from "../../utils/intl_i18n";
import ParticipantDetail from "../common/ParticipantDetail";
import ActionMenu from "../common/ActionMenu";
import ConfirmRedeem from "./ConfirmRedeem";
import { getInvestorEmail } from "../common/utils";
import { usePortfolioPositions } from "../../hooks/investors";
import { useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getColumns } from "./portfolioModels";
import Table from "../common/Table";
import { compareItems, rankItem } from "@tanstack/match-sorter-utils";
import { fetchLastPrice } from "../trade/utils";

const isReadOnly = isReadOnlyStatus();

function Portfolio({ history }) {
  const tableRef = useRef(null);
  const footerRef = useRef(null);
  const [portfolioData, setPortfolioData] = useState([]);
  const [filteredPortfolioData, setFilteredPortfolioData] = useState(undefined);
  const [sellWidgetVisible, setSellWidgetVisible] = useState(false);
  const [sellOrder, setSellOrder] = useState([]);
  const [viewPositionVisible, setViewPositionVisible] = useState(false);
  const [lastPriceList, setLastPriceList] = useState([]);
  const [yPosition, setYPosition] = useState("");
  const [secondaryMarketDataAccess, setSecondaryMarketDataAccess] =
    useState(false);
  const [redeemModalVisible, setRedeemModalVisible] = useState(false);
  const [confirmRedeemActive, setConfirmRedeemActive] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [selectedQuantity, setSelectedQuantity] = useState(undefined);
  const [maxQuantity, setMaxQuantity] = useState(undefined);
  const [maximumQuantity, setMaximumQuantity] = useState(undefined);
  const [securityCode, setSecurityCode] = useState(undefined);
  const [underlying, setUnderlying] = useState(undefined);

  const investorId = useInvestorId();

  const queryClient = useQueryClient();

  const { data: portfolioPositions } = usePortfolioPositions();

  useEffect(() => {
    if (portfolioPositions) enableRedeemButton(portfolioPositions);
  }, [portfolioPositions]);

  const formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  /**
   * To get the secondary market data access for the investor
   */
  const getSecondaryMarketAccessStatus = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axiosConfig
      .get(`${ROUTES.USER_DETAILS}`, config)
      .then((res) => {
        setSecondaryMarketDataAccess(isSecondaryMarketAllowed(res.data.groups));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  /*
   * We pull down the portfolio data as soon as this component is fully loaded.
   */
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    calculateTablePosition();
    fetchSecurities();
    getSecondaryMarketAccessStatus();
  }, []);

  /*
   * Calls the server API to retrieve a list of Securities to render the Last Price
   */
  const fetchSecurities = () => {
    axiosConfig
      .get(ROUTES.SECURITIES)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          let underlyingList = [];
          for (let i = 0; i < responseData.length; i++) {
            let underlying = {};
            underlying.ticker = responseData[i].ticker;
            underlying.lastPrice = responseData[i].lastPrice;
            underlyingList.push(underlying);
          }
          setLastPriceList(underlyingList);
        }
      })
      .catch((e) => console.log("error=>", e.response));
  };

  const enableRedeemButton = (data) => {
    if (data.length) {
      data.forEach((res, i) => {
        data.forEach((opp, j) => {
          if (data[i].securityCode === data[j].opposingSecurityCode) {
            data[i].isRedeemAllowed = true;
            // The most we can allow is the smallest of the two holdings
            setMaxQuantity(
              data[i].quantity > data[j].quantity
                ? data[j].quantity
                : data[i].quantity
            );
          } else if (!data[i].isRedeemAllowed && !data[j].isRedeemAllowed) {
            data[i].isRedeemAllowed = false;
          }
        });
      });
    }
    setPortfolioData(data);
  };

  /*
   * Show the Sell component if the user presses the button.
   */
  const handleSellOrder = (record) => {
    if (isReadOnlyStatus()) {
      return;
    }
    setSellWidgetVisible(true);
    setSellOrder(record);
  };

  const handleRedeem = (record) => {
    if (isReadOnlyStatus()) {
      return;
    }
    const holdingAvailableQuantity = record.quantity - record.lockedQty;
    const opposingSecurity = portfolioPositions?.find(
      (p) => p.securityCode === record.opposingSecurityCode
    );
    if (!opposingSecurity) return;
    const opposingAvailableQuantity =
      opposingSecurity.quantity - opposingSecurity.lockedQty;
    const maxQuantity = Math.min(
      holdingAvailableQuantity,
      opposingAvailableQuantity
    );
    setSelectedPosition(record);
    setSelectedQuantity(maxQuantity);
    setRedeemModalVisible(true);
    setMaximumQuantity(maxQuantity);
  };

  /*
   * Hide the currently visible component if the user presses Cancel.
   */
  const handleCancel = () => {
    setSellWidgetVisible(false);
    setViewPositionVisible(false);
    setRedeemModalVisible(false);
  };

  /*
   * Show the ViewTransactions pop-up to show which transactions make up the
   * selected position.
   */
  const handleViewPositions = (record) => {
    if (isReadOnlyStatus()) {
      return;
    }
    setViewPositionVisible(true);
    setSecurityCode(record.securityCode);
    setUnderlying(record.underlying);
  };

  /*
   * if the user enters text, then use that text as a filter against
   * the currently loaded portfolio data.
   */
  const onSearch = (e) => {
    const filteredData = portfolioData.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    !_isUndefined(filteredData) && setFilteredPortfolioData(filteredData);
  };

  const getPortfolioLength = () => {
    const tableData = !_isUndefined(filteredPortfolioData)
      ? filteredPortfolioData
      : portfolioData;
    return tableData.length;
  };

  const calculateTablePosition = () => {
    const tableOffset = tableRef?.current?.offsetTop;
    const footer = document.getElementsByClassName("page-content-footer")[0];
    const footerOffset = footer?.offsetTop;
    const footerHeight = footer?.clientHeight;
    setYPosition(footerOffset - tableOffset - footerHeight - 10);
  };

  const redirectToInvestorPage = () => {
    history.push("/investor");
  };

  const handleResize = () => {
    calculateTablePosition();
  };

  const handleQuantityChange = (value) => {
    setSelectedQuantity(value);
  };

  const getOpposingPosition = (securityCode) => {
    let positions = portfolioData.filter(
      (data) => data.securityCode === securityCode
    );
    if (positions.length === 0) {
      return undefined;
    }
    return positions[0];
  };

  const handleConfirmRedeem = () => {
    setRedeemModalVisible(false);
    setConfirmRedeemActive(true);
  };

  /**
   * Preparing api request and api call for early redemption
   * OtcRedemtion entity
   */

  const applyRedeem = () => {
    setConfirmRedeemActive(false);
    if (isReadOnly) {
      return;
    }
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = {};
    console.log(
      "Submitting redemption for :" + selectedQuantity + " Max:" + maxQuantity
    );
    if (0 < selectedQuantity && selectedQuantity <= maxQuantity) {
      let selectedOpposingPosition = getOpposingPosition(
        selectedPosition.opposingSecurityCode
      );
      if (!selectedOpposingPosition) {
        return;
      }
      // Preparing api request as a OtcRedemption
      data.userId = selectedPosition.userId;
      data.underlyingPrice = fetchLastPrice(
        lastPriceList,
        selectedPosition,
        "underlying"
      );
      data.quantity = selectedQuantity;
      data.growthSecurityCode = selectedPosition.securityCode;
      data.incomeSecurityCode = selectedOpposingPosition.securityCode;
      data.fees = selectedPosition.fees;
      axiosConfig
        .post(`${ROUTES.PORTFOLIO_REDEEM}/${investorId}/redeem`, data, config)
        .then((response) => {
          console.log("res", response);
          message.success("Redeem Initiated.");
          setRedeemModalVisible(false);
          queryClient.invalidateQueries(["portfolioPositions", investorId]);
        })
        .catch((e) => message.error("Redeem order failed."));
    } else {
      message.error("Please submit a valid quantity.");
      return;
    }
  };

  /*
   * Called whenever the notification(which contains an update on one of the orders) is received.
   */
  const handleNotification = (notification) => {
    let userId = JSON.parse(sessionStorage.getItem("UserDetails")).investorId;
    if (notification === userId) {
      queryClient.invalidateQueries(["portfolioPositions", investorId]);
    }
  };

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const columns = getColumns(
    lastPriceList,
    isReadOnlyStatus(),
    secondaryMarketDataAccess,
    handleViewPositions,
    handleSellOrder,
    handleRedeem
  );

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const data = portfolioData ?? [];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
      globalFilter,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <PageContent
      className="portfolio-content"
      childrenClassName={"portfolio-container"}
      activeMenuHeader={"portfolio"}
      footerRefContent={footerRef}
      history={history}
    >
      <SockJsClient
        headers={{ authorization: getAccess() }}
        url={ROUTES.WEB_SOCKET_URL}
        topics={[ROUTES.TOPIC_PORTFOLIO_POSITIONS]}
        onMessage={handleNotification}
      />
      <Container maxWidth="xl" sx={{ marginTop: "15px" }}>
        <div className="text-white text-[40px] font-extralight text-center">
          Portfolio
        </div>
        <div className="bg-[#2f333b] rounded-xl">
          <Box sx={{ p: 3 }}>
            <div>
              <div className="flex items-center justify-between mb-6">
                <div className="relative rounded-full shadow-sm w-[184px]">
                  <input
                    type="text"
                    name="search-input"
                    id="search-input"
                    className="bg-transparent block w-full rounded-full border border-[#797D82] py-1.5 pl-4 pr-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(String(e.target.value))}
                    style={{
                      fill: "linear-gradient(135deg, rgba(232, 232, 232, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%)",
                      boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.30) inset",
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <SearchIcon />
                  </div>
                </div>

                <div className="portfolio-table-action cursor-pointer">
                  <span
                    className={cn("download-table", {
                      "download-disabled": getPortfolioLength() <= 0,
                    })}
                    onClick={() => {
                      if (getPortfolioLength() > 0) {
                        exportTabletoXLSX(
                          "portfolio-table",
                          "Portfolio-report.xlsx"
                        );
                      }
                    }}
                  >
                    {" "}
                    <DownloadLogo />{" "}
                  </span>
                </div>
              </div>

              <Table table={table} tableId="portfolio-table" />

              {sellWidgetVisible && (
                <Modal
                  title="Sell"
                  className="sell-Positions-modal"
                  visible={sellWidgetVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <SellOrder
                    sellOrder={sellOrder}
                    parentCallback={handleCancel}
                    investorId={investorId}
                  />
                </Modal>
              )}

              {viewPositionVisible && (
                <Modal
                  title="View Events"
                  className="view-Positions-modal"
                  visible={viewPositionVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <ViewPositions
                    securityCode={securityCode}
                    underlying={underlying}
                    investorId={investorId}
                  />
                </Modal>
              )}
              {redeemModalVisible && (
                <Modal
                  title="Redemption"
                  className="view-redemptions-modal p-8"
                  visible={redeemModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <>
                    <div className="text-white text-center max-w-md mx-auto">
                      {formatMessage_i18n("Redemption-sameHolding-content1")}{" "}
                      <NumberFormatter value={maximumQuantity} />{" "}
                      {formatMessage_i18n("Redemption-sameHolding-content2")}
                    </div>

                    <div className="w-full mt-4 mb-4 max-w-sm mx-auto">
                      <Input
                        id="selectedQuantity"
                        name="selectedQuantity"
                        value={numberWithCommas(selectedQuantity)}
                        className="!rounded-full border-[#7b7b7d] !bg-[#36373D] !bg-input-gradient !text-white"
                        placeholder=""
                        onChange={(e) => {
                          const value = parseInt(
                            e.target.value !== ""
                              ? e.target.value.replace(/,/g, "")
                              : 0
                          );
                          handleQuantityChange(value);
                        }}
                        status={false && "error"}
                      />
                      {false ? (
                        <label
                          htmlFor="selectedQuantity"
                          className="text-[#ff4d4f] pl-3 text-[14px] block"
                        ></label>
                      ) : (
                        <label
                          htmlFor="selectedQuantity"
                          className="text-white pl-3 text-[14px]"
                        >
                          Quantity to Redeem
                        </label>
                      )}
                    </div>

                    <div className="flex justify-between">
                      <button
                        className="bg-black rounded-full text-white px-4 py-1"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${
                          isReadOnly
                            ? "bg-[#E6E6E6] cursor-not-allowed"
                            : "bg-[#5B7FFC]"
                        } rounded-full text-white px-4 py-1`}
                        onClick={handleConfirmRedeem}
                        disabled={isReadOnly}
                      >
                        Apply for Early Redemption
                      </button>
                    </div>
                  </>
                </Modal>
              )}
              {confirmRedeemActive && (
                <ConfirmRedeem
                  handleCancel={() => setConfirmRedeemActive(false)}
                  handleConfirm={applyRedeem}
                  quantity={selectedQuantity}
                  product1={selectedPosition?.securityCode}
                  product2={selectedPosition?.opposingSecurityCode}
                  underlying={selectedPosition?.underlying}
                />
              )}
            </div>
          </Box>
        </div>
      </Container>
    </PageContent>
  );
}
export default Portfolio;
