import React, { Component } from "react";
import "./style.scss";
import { intl } from "../../../../utils/intl_i18n";
import cn from "classnames";
import _isUndefined from "lodash/isUndefined";
import { validateGiin } from "../../../../components/common/validation";

class RTTaxInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  /**
   *
   * @returns validation failed and disbled the next button, If the required field is empty
   */
  validate = () => {
    const { payload } = this.state;
    if (
      payload.regulatedTrustTaxFatcaExempt === undefined ||
      payload.regulatedTrustTaxFatcaExempt === ""
    ) {
      this.onValidationFail();
      return;
    }
    if (payload.regulatedTrustTaxFatcaExempt === "no") {
      if (!payload.regulatedTrusteeGiin) {
        if (payload.fatcaStatus === undefined || !payload.fatcaStatus) {
          this.onValidationFail();
          return;
        } else if (payload.fatcaStatus === "other") {
          if (
            payload.otherFatcaStatus === undefined ||
            !payload.otherFatcaStatus
          ) {
            this.onValidationFail();
            return;
          }
        }
      }
    }
    this.onValidationPass();
  };

  onValidationFail = () => {
    this.props.onValidationFail();
  };

  onValidationPass = () => {
    this.props.onValidationPass();
  };

  /**
   *
   * @param {*} payload
   * Will get the updated payload when the field changes added
   */
  updateState = (payload) => {
    this.setState(payload, () => {
      this.validate();
    });
  };

  onInputChange = (field, value) => {
    const { payload } = this.state;
    payload[field] = value;
    this.updateState(payload);
  };

  onChangeRtTaxInformationStatus = (e) => {
    const { payload } = this.state;
    payload.regulatedTrustTaxFatcaExempt = e.target.value;
    this.updateState(payload);
  };

  /**
   *
   * @param {string} giinValue - GIIN value
   * @returns {boolean} - Whether we need to disable the Facta Dropdown field or not
   */
  checkGiinState = (giinValue) => {
    if (giinValue === undefined) {
      return false;
    }
    if (giinValue !== "") {
      return true;
    }
  };

  onChangeFatcaStatus = (e) => {
    const { payload } = this.state;
    payload.fatcaStatus = e.target.value;
    // Resetting the values in the Other facta status field whenever the facta dropdown value changes
    payload.otherFatcaStatus = "";
    this.updateState(payload);
  };

  render() {
    const { payload } = this.state;
    return (
      <div className="RTTax-main">
        <div className="RTTax-title">
          {this.formatMessage_i18n("regulatedTrust.taxInformation.name")}
        </div>
        <div className="RTTax-description">
          {this.formatMessage_i18n("regulatedTrust.taxInformation.title1")}
        </div>
        <div className="RTTax-description">
          {this.formatMessage_i18n("regulatedTrust.taxInformation.title2")}
        </div>
        <div className="tax-exempt-status-dropdown">
          <select
            onChange={this.onChangeRtTaxInformationStatus}
            value={payload.regulatedTrustTaxFatcaExempt || ""}
            className={cn({
              "error-field":
                _isUndefined(payload.regulatedTrustTaxFatcaExempt) ||
                payload.regulatedTrustTaxFatcaExempt === "",
            })}
          >
            <option value="">Yes/No</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        {/* PBT-1684 */}
        {payload.regulatedTrustTaxFatcaExempt === "no" && (
          <div className="additional-details">
            <div className="RTTax-description">
              {this.formatMessage_i18n("regulatedTrust.taxInformation.giin")}
            </div>
            <div className="giin-textbox">
              <input
                // Adding the error field check to the giin input field when the fatcaStatus is set empty( or undefined) and giin is empty( or undefined)
                className={cn("giin-input-large", {
                  "error-field":
                    (_isUndefined(payload.fatcaStatus) ||
                      payload.fatcaStatus === "") &&
                    (_isUndefined(payload.regulatedTrusteeGiin) ||
                      payload.regulatedTrusteeGiin === "" ||
                      !validateGiin(payload.regulatedTrusteeGiin)),
                })}
                // Already we have a same place holder in the financial institution tax info. used the same
                placeholder={this.formatMessage_i18n(
                  "FITaxInformation.giinPlaceHolder"
                )}
                value={payload.regulatedTrusteeGiin}
                onChange={(e) =>
                  this.onInputChange("regulatedTrusteeGiin", e.target.value)
                }
              />
            </div>
            {/**
             * Dropdown values are reused from the financial institution tax information
             */}
            <div className="RTTax-description">
              {this.formatMessage_i18n(
                "regulatedTrust.taxInformation.fatcaStatus"
              )}
            </div>
            <div className="fatca-status-dropdown">
              <select
                onChange={this.onChangeFatcaStatus}
                value={payload.fatcaStatus}
                disabled={this.checkGiinState(payload.regulatedTrusteeGiin)}
              >
                <option value="">
                  {this.formatMessage_i18n(
                    "FITaxInformation.fatcaDropdownPlaceholder"
                  )}
                </option>
                <option value="deemedCompliant">
                  {this.formatMessage_i18n(
                    "FITaxInformation.fatcaDropdownOption1"
                  )}
                </option>
                <option value="exceptedFinancialInstitution">
                  {this.formatMessage_i18n(
                    "FITaxInformation.fatcaDropdownOption2"
                  )}
                </option>
                <option value="exemptBeneficialOwner">
                  {this.formatMessage_i18n(
                    "FITaxInformation.fatcaDropdownOption3"
                  )}
                </option>
                <option value="nonReportingIGA">
                  {this.formatMessage_i18n(
                    "FITaxInformation.fatcaDropdownOption4"
                  )}
                </option>
                <option value="nonParticipating">
                  {this.formatMessage_i18n(
                    "FITaxInformation.fatcaDropdownOption5"
                  )}
                </option>
                <option value="other">
                  {this.formatMessage_i18n(
                    "FITaxInformation.fatcaDropdownOption6"
                  )}
                </option>
              </select>
            </div>
            <div
              className="otherFatcaStatus-textbox"
              style={{
                display: payload.fatcaStatus === "other" ? "block" : "none",
              }}
            >
              <input
                // Adding the error field check to the otherFacta Status field when the fatcaStatus is set to other and value is empty
                className={cn("fatcaStatus-input-large", {
                  "error-field":
                    payload.fatcaStatus === "other" &&
                    (_isUndefined(payload.otherFatcaStatus) ||
                      payload.otherFatcaStatus === ""),
                })}
                placeholder={this.formatMessage_i18n(
                  "FITaxInformation.otherFatcaStatusText"
                )}
                value={payload.otherFatcaStatus}
                onChange={(e) =>
                  this.onInputChange("otherFatcaStatus", e.target.value)
                }
                disabled={this.checkGiinState(payload.regulatedTrusteeGiin)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RTTaxInformation;
