import _isUndefined from "lodash/isUndefined";
import {
  otcMaxTradeSize,
  otcMinStockTransactionSize,
  otcMinTradeSize,
} from "../../utils/utile";

export const isTransactionLessThanMinSize = (quantity, price) => {
  let result = false;
  if (
    !_isUndefined(quantity) &&
    !_isUndefined(price) &&
    parseInt(quantity) > 0 &&
    parseFloat(price) > 0
  ) {
    result = parseInt(quantity) * parseFloat(price) <= otcMinTradeSize();
  }

  return result;
};

export const isTransactionMoreThanMaxSize = (quantity, price) => {
  let result = false;
  if (
    !_isUndefined(quantity) &&
    !_isUndefined(price) &&
    parseInt(quantity) > 0 &&
    parseFloat(price) > 0
  ) {
    result = parseInt(quantity) * parseFloat(price) >= otcMaxTradeSize();
  }

  return result;
};

export const isStockTransactionLessThanMinSize = (quantity, price) => {
  let result = false;
  if (
    !_isUndefined(quantity) &&
    !_isUndefined(price) &&
    parseInt(quantity) > 0 &&
    parseFloat(price) > 0
  ) {
    result =
      parseInt(quantity) * parseFloat(price) <= otcMinStockTransactionSize();
  }
  return result;
};

export const isLeftoverLessThanMinSize = (availableQty, quantity, price) => {
  let result,
    condition1,
    condition2 = false;
  if (parseFloat(price) > 0 && parseInt(quantity) > 0) {
    condition1 = quantity < availableQty;
    condition2 = (availableQty - quantity) * price < otcMinTradeSize();
    result = condition1 && condition2;
  }
  return result;
};
