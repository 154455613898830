import ROUTES from "../.././../routes/api.json";
import axiosConfig from "../../../axiosConfig";
import message from "antd/es/message";
import {
  doIterateNext,
  doIteratePrev,
  getActiveBeneficiaryList,
} from "../utils";
import _each from "lodash/each";
import { TIME_FOR_REGISTER_INTERVAL } from "../../common/constants";

class AustralianRegisteredTrustSequence {
  constructor(screenRef) {
    this.screenRef = screenRef;
  }

  register = (payload) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .put(ROUTES.REGISTER, payload, config)
      .then((res) => {
        message.success("Registration Completed");
      })
      .catch((error) => {});
  };

  checkNextIteration = (list) => {
    let index = list.currentIndex;
    const listLength = list.details.length;
    if (index < listLength - 1) {
      list.currentIndex !== "" && list.currentIndex !== undefined
        ? list.currentIndex++
        : (list.currentIndex = 1);
    } else {
      return false;
    }
    return true;
  };

  checkPrevIteration = (list) => {
    let index = list.currentIndex;
    const listLength = list.details.length;
    if (index < listLength && index - 1 >= 0) {
      list.currentIndex !== "" && list.currentIndex !== undefined
        ? list.currentIndex--
        : (list.currentIndex = 1);
    } else {
      return false;
    }
    return true;
  };

  registerUser = (payload) => {
    this.register(payload);
    setTimeout(() => {
      //replace props.history with window.location to prevent infinite call for validation-token api
      window.location.href = "/order";
    }, TIME_FOR_REGISTER_INTERVAL);
  };

  onNext = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };

    if (currentComponent.key === "investorEntity") {
      componentContent.key = "australianRegulatedTrustIdentification";
      return componentContent;
    }

    if (currentComponent.key === "australianRegulatedTrustIdentification") {
      componentContent.key = "trusteeIdentification";
      componentContent.sequenceCallback = this.trusteeIdentificationCallback;
      return componentContent;
    }

    if (currentComponent.key === "trusteeIdentification") {
      if (payload.trusteeIdentification.typeOfTrustee === "individualTrustee") {
        componentContent.key = "personalDetails";
      } else if (
        payload.trusteeIdentification.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        componentContent.key = "australianCompany";
      } else if (
        payload.trusteeIdentification.typeOfTrustee === "foreignCompanyTrustee"
      ) {
        componentContent.key = "foreignCorporateIdentification";
      }
      return componentContent;
    }

    if (
      currentComponent.key === "personalDetails" &&
      payload.trusteeIdentification.typeOfTrustee === "individualTrustee"
    ) {
      componentContent.key = "regulatedTrustTaxInformation";
      return componentContent;
    }

    if (
      currentComponent.key === "australianCompany" &&
      payload.trusteeIdentification.typeOfTrustee === "australianCompanyTrustee"
    ) {
      if (payload.australianCompany.companyType === "proprietary") {
        componentContent.key = "proprietaryDirectors";
      }
      if (payload.australianCompany.companyType === "public") {
        componentContent.key = "regulatedTrustTaxInformation";
      }
      return componentContent;
    }

    if (currentComponent.key === "proprietaryDirectors") {
      if (
        payload.trusteeIdentification.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        componentContent.key = "regulatedTrustTaxInformation";
      }
      if (
        payload.trusteeIdentification.typeOfTrustee ===
          "foreignCompanyTrustee" &&
        payload.foreignCorporateIdentification.foreignCompanyType === "private"
      ) {
        componentContent.key = "corporateListingAndRegulatory";
      }
      return componentContent;
    }

    if (currentComponent.key === "foreignCorporateIdentification") {
      componentContent.key = "foreignCorporateDetails";
      componentContent.dependentProps = {
        registeredByASIC:
          payload.foreignCorporateIdentification.registeredByASIC,
      };
      return componentContent;
    }

    if (currentComponent.key === "foreignCorporateDetails") {
      if (
        payload.foreignCorporateIdentification.foreignCompanyType === "private"
      ) {
        componentContent.key = "proprietaryDirectors";
      } else if (
        payload.foreignCorporateIdentification.foreignCompanyType === "public"
      ) {
        componentContent.key = "corporateListingAndRegulatory";
      }
    }

    if (currentComponent.key === "corporateListingAndRegulatory") {
      if (
        payload.corporateListingAndRegulatory.isAustralianListedCompany ===
          false &&
        payload.corporateListingAndRegulatory.isPublicListedCompany === false &&
        payload.corporateListingAndRegulatory.isRegulatedInAustralia === false
      ) {
        componentContent.key = "beneficialOwnership";
      } else {
        componentContent.key = "regulatedTrustTaxInformation";
      }
      return componentContent;
    }

    if (
      currentComponent.key === "beneficialOwnership" ||
      currentComponent.key === "beneficialOwnerFactaDetails"
    ) {
      let list = getActiveBeneficiaryList(payload);
      let doIteration = doIterateNext(
        payload[payloadField],
        list,
        (item) => true
      );
      if (doIteration) {
        componentContent.key = "beneficialOwnerFactaDetails";
        return componentContent;
      }
      componentContent.key = "regulatedTrustTaxInformation";
      return componentContent;
    }

    if (currentComponent.key === "regulatedTrustTaxInformation") {
      componentContent.key = "settlementInstructions";
      return componentContent;
    }

    if (currentComponent.key === "settlementInstructions") {
      componentContent.key = "chessDetails";
      return componentContent;
    }

    if (currentComponent.key === "chessDetails") {
      this.uploadFileAndSaveRegister(
        payload[currentComponent.key],
        payload,
        currentComponent
      );
      componentContent.key = "trustVerificationProcedure";
      return componentContent;
    }

    if (currentComponent.key === "trustVerificationProcedure") {
      this.uploadFileAndSaveRegister(
        payload[currentComponent.key],
        payload,
        currentComponent
      );
      componentContent.next = "Complete Registration";
      componentContent.key = "subscriptionOptions";
      return componentContent;
    }

    if (currentComponent.key === "subscriptionOptions") {
      componentContent.renderConfirmationPopup = true;
      componentContent.confirmCallback = () => {
        this.registerUser(payload);
      };
      return componentContent;
    }
    return componentContent;
  };

  onPrev = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };

    if (currentComponent.key === "australianRegulatedTrustIdentification") {
      componentContent.prev = null;
      componentContent.next = "Begin";
      componentContent.key = "investorEntity";
      componentContent.includePayloadCallback = true;
      return componentContent;
    }

    if (currentComponent.key === "trusteeIdentification") {
      componentContent.key = "australianRegulatedTrustIdentification";
      return componentContent;
    }

    if (
      currentComponent.key === "personalDetails" ||
      currentComponent.key === "foreignCorporateIdentification" ||
      currentComponent.key === "australianCompany"
    ) {
      componentContent.key = "trusteeIdentification";
      componentContent.sequenceCallback = this.trusteeIdentificationCallback;
      return componentContent;
    }

    if (currentComponent.key === "foreignCorporateDetails") {
      componentContent.key = "foreignCorporateIdentification";
      return componentContent;
    }

    if (currentComponent.key === "corporateListingAndRegulatory") {
      if (
        payload.foreignCorporateIdentification.foreignCompanyType === "private"
      ) {
        componentContent.key = "proprietaryDirectors";
      } else if (
        payload.foreignCorporateIdentification.foreignCompanyType === "public"
      ) {
        componentContent.key = "foreignCorporateDetails";
        componentContent.dependentProps = {
          registeredByASIC:
            payload.foreignCorporateIdentification.registeredByASIC,
        };
      }
      return componentContent;
    }

    if (
      currentComponent.key === "beneficialOwnership" &&
      payload.trusteeIdentification.typeOfTrustee === "foreignCompanyTrustee"
    ) {
      componentContent.key = "corporateListingAndRegulatory";
      return componentContent;
    }

    if (currentComponent.key === "beneficialOwnerFactaDetails") {
      let list = getActiveBeneficiaryList(payload);
      let doIteration = doIteratePrev(
        payload["beneficialOwnership"],
        list,
        (item) => true
      );
      if (doIteration) {
        componentContent.key = "beneficialOwnerFactaDetails";
        return componentContent;
      }
      componentContent.key = "beneficialOwnership";
      return componentContent;
    }

    if (currentComponent.key === "proprietaryDirectors") {
      if (
        payload?.trusteeIdentification?.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        componentContent.key = "australianCompany";
      } else {
        componentContent.key = "foreignCorporateDetails";
        componentContent.dependentProps = {
          registeredByASIC:
            payload.foreignCorporateIdentification.registeredByASIC,
        };
      }
      return componentContent;
    }

    if (currentComponent.key === "regulatedTrustTaxInformation") {
      if (payload.trusteeIdentification.typeOfTrustee === "individualTrustee") {
        componentContent.key = "personalDetails";
      } else if (
        payload.trusteeIdentification.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        if (payload?.australianCompany?.companyType === "proprietary") {
          componentContent.key = "proprietaryDirectors";
        } else if (payload?.australianCompany?.companyType === "public") {
          componentContent.key = "australianCompany";
        }
      } else if (
        payload.trusteeIdentification.typeOfTrustee === "foreignCompanyTrustee"
      ) {
        if (
          payload.corporateListingAndRegulatory.isAustralianListedCompany ===
            false &&
          payload.corporateListingAndRegulatory.isPublicListedCompany ===
            false &&
          payload.corporateListingAndRegulatory.isRegulatedInAustralia === false
        ) {
          let list = getActiveBeneficiaryList(payload);
          let doIteration = doIteratePrev(
            payload["beneficialOwnership"],
            list,
            (item) => true
          );
          if (doIteration) {
            componentContent.key = "beneficialOwnerFactaDetails";
            return componentContent;
          }
          componentContent.key = "beneficialOwnership";
          return componentContent;
        } else {
          componentContent.key = "corporateListingAndRegulatory";
        }
      }
      return componentContent;
    }

    if (currentComponent.key === "settlementInstructions") {
      componentContent.key = "regulatedTrustTaxInformation";
      return componentContent;
    }

    if (currentComponent.key === "chessDetails") {
      componentContent.key = "settlementInstructions";
      return componentContent;
    }

    if (currentComponent.key === "trustVerificationProcedure") {
      componentContent.key = "chessDetails";
      return componentContent;
    }

    if (currentComponent.key === "subscriptionOptions") {
      componentContent.key = "trustVerificationProcedure";
      return componentContent;
    }
    return componentContent;
  };

  uploadFileAndSaveRegister = async (
    payloadData,
    payload,
    currentComponent
  ) => {
    console.log("calling upoad");
    let fileList = payloadData["documentList"];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        let res = await this.uploadFile(fileList[i].originFileObj);
        if (res && res.data) {
          payloadData[fileList[i].fileId] = {};
          payloadData[fileList[i].fileId] = res.data;
        } else {
          message.error("File upload failed");
          return;
        }
      }
    }

    this.saveRegistrationDetails(payload, currentComponent);
  };

  uploadFile = async (fileObj) => {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("file", fileObj);
    let userId = JSON.parse(sessionStorage.getItem("UserDetails")).investorId;
    let res = await axiosConfig.post(
      `${ROUTES.FILE_UPLOAD}/${userId}`,
      formData,
      config
    );
    return res;
  };

  saveRegistrationDetails(data, currentComponent) {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //Added to maintain JSON structure as of Participant registration
    let userRegisterData = {};
    userRegisterData["currentComponent"] = currentComponent;
    userRegisterData["payload"] = data;

    axiosConfig
      .post(`${ROUTES.STORAGE_URL}`, JSON.stringify(userRegisterData), config)
      .then((res) => {
        console.log("Server result:", res);
      })
      .catch((error) => {
        console.log("Server error: " + error);
      });
  }

  trusteeIdentificationCallback = (payloadData) => {
    const excludedData = [
      "investorEntity",
      "australianRegTrustIdentity",
      "trusteeIdentification",
    ];
    _each(payloadData, (value, key) => {
      if (excludedData.indexOf(key) < 0) {
        delete payloadData[key];
      }
    });
    return payloadData;
  };
}

export default AustralianRegisteredTrustSequence;
