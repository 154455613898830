// Method to fetch the Beneficiary list based on hasBeneficialOwners flag
export const getActiveBeneficiaryList = (payload) => {
  const beneficialContent = payload.beneficialOwnership;
  const beneficialType = beneficialContent.hasBeneficialOwners;
  let beneficialList = beneficialContent.beneficialOwnersList;
  if (beneficialType === "no") {
    beneficialList = beneficialContent.individualControllerList;
  }
  return beneficialList;
};

export const doIteratePrev = (data, list, callback) => {
  if (data.currentIndex === undefined) {
    data.currentIndex = list.length - 1;
  } else {
    data.currentIndex--;
  }
  while (data.currentIndex >= 0) {
    if (callback(list[data.currentIndex]) === true) {
      return true;
    }
    data.currentIndex--;
  }
  delete data["currentIndex"];
  return false;
};

export const doIterateNext = (data, list, callback) => {
  if (data.currentIndex === undefined) {
    data.currentIndex = 0;
  } else {
    data.currentIndex++;
  }
  while (data.currentIndex < list.length) {
    if (callback(list[data.currentIndex]) === true) {
      return true;
    }
    data.currentIndex++;
  }
  delete data["currentIndex"];
  return false;
};
