import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { fetchCurrencySymbol } from "./currencies";

class NumberFormatter extends Component {
  render() {
    const {
      isCurrency,
      value = "",
      emptyContent = null,
      displayType = "text",
      changeCallback,
      className,
      placeholder,
      isNumericString,
      allowNegative,
      decimalScale = 0,
      readOnly,
      isAllowed,
      fixedDecimalScale = false,
      tickerCode,
      format,
      mask,
      onBlur,
      disabled,
    } = this.props;

    const itemProps = {};
    let ignoreEmptyContent = false; // flag used to determine whether we need to show emptyContent from the props if the value if empty. This flag works only for the displaytype === text
    let fixedDecimalScaleProp = fixedDecimalScale;
    if (isCurrency) {
      /** Fetch the currency symbol based on qualified ticker code */
      itemProps.prefix = fetchCurrencySymbol(tickerCode);
      // Enabling this only for text type and the number is currency because, it will add extra zeros at the end, adding it for the input type will add extra for the input field
      if (displayType === "text") {
        fixedDecimalScaleProp = true;
      }
    }

    if (displayType === "input") {
      itemProps.placeholder = placeholder;
      ignoreEmptyContent = true;
      if (typeof changeCallback === "function" && changeCallback !== "") {
        itemProps.onValueChange = changeCallback;
      }
      if (readOnly) {
        itemProps.readOnly = readOnly;
      }
    }

    if (isAllowed) {
      itemProps.isAllowed = isAllowed;
    }

    return (
      <>
        {!ignoreEmptyContent &&
        (value === "" || value === null || value === undefined) ? (
          emptyContent
        ) : (
          <NumberFormat
            value={value}
            className={className}
            displayType={displayType}
            thousandSeparator={true}
            isNumericString={isNumericString}
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScaleProp}
            format={format}
            mask={mask}
            onBlur={onBlur}
            disabled={disabled}
            {...itemProps}
          />
        )}
      </>
    );
  }
}

export default NumberFormatter;
