import React, { Component } from "react";
import "./style.scss";
import PageContent from "../common/PageContent";
import { intl } from "../../utils/intl_i18n";

class SampleMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordResetToken: "",
      password: "",
      confirmPassword: "",
    };
  }

  formatMessage_i18n = (messageId) => {
    return intl.formatMessage({ id: messageId });
  };

  render() {
    return (
      <PageContent
        className="sample-message-container"
        childrenClassName={"sample-message-body"}
        renderMenu={false}
      >
        <div className="sample-message-content mx-auto">
          <p>
            We’ve sent you an email to confirm your password reset. Please
            follow the link in the email to complete the process.
          </p>
        </div>
      </PageContent>
    );
  }
}

export default SampleMessage;
