import { createColumnHelper } from "@tanstack/react-table";
import NumberFormatter from "../../common/NumberFormatter";
import { convertToDateFormat } from "../../../utils/utile";
import moment from "moment";
import ActionMenu from "../../common/ActionMenu";
import { dealStatus } from "../../common/constants";

const columnHelper = createColumnHelper();

export const getColumns = (getStoredS3Url) => {
  return [
    columnHelper.accessor("dealId", {
      header: "Deal ID",
    }),
    columnHelper.accessor("tradeDate", {
      header: "Trade Date",
      cell: (info) => convertToDateFormat(info.getValue()),
    }),
    columnHelper.accessor("settleDate", {
      header: "Settlement Date",
      cell: (info) => convertToDateFormat(info.getValue()),
    }),
    columnHelper.accessor("maturityDate", {
      header: "Maturity Date",
      cell: (info) => convertToDateFormat(info.getValue()),
    }),
    columnHelper.accessor("quantity", {
      header: "Qty",
      cell: (info) => (
        <NumberFormatter value={info.getValue()} emptyContent={"-"} />
      ),
    }),
    columnHelper.accessor("underlying", {
      header: "Underlying Code",
    }),
    columnHelper.accessor("securityCode", {
      header: "Instrument Code",
    }),
    columnHelper.accessor("productNameDisplay", {
      header: "Product",
    }),
    columnHelper.accessor("orderSide", {
      header: "Side",
      cell: (info) => {
        let record = info.row.original;
        if (record?.dealType === "SHARE_APP") return "SHARE APP";
        if (record?.dealType === "REDEMPTION") return "REDEMPTION";
        return info.getValue();
      },
    }),
    columnHelper.accessor("priceDollars", {
      header: "Price",
      cell: (info) => (
        <NumberFormatter
          value={info.getValue()}
          isCurrency={true}
          tickerCode={info.row.original?.underlying}
          decimalScale={2}
          emptyContent={"-"}
        />
      ),
    }),
    columnHelper.accessor("status", {
      header: "Status",
    }),
    columnHelper.accessor("", {
      header: "Confirmation",
      cell: (info) => {
        let menuItems = [];
        const record = info.row.original;
        if (
          record?.status === dealStatus.FILLED ||
          record?.status === dealStatus.SETTLED
        ) {
          menuItems.push({
            label: dealStatus.FILLED,
            onClick: () => {
              getStoredS3Url(
                record,
                record.status === dealStatus.FILLED
                  ? record.status
                  : dealStatus.FILLED
              );
            },
          });
        }
        if (record?.status === dealStatus.SETTLED) {
          menuItems.push({
            label: dealStatus.SETTLED,
            onClick: () => {
              getStoredS3Url(
                record,
                record.status === dealStatus.SETTLED
                  ? record.status
                  : dealStatus.SETTLED
              );
            },
          });
        }
        return menuItems.length > 0 ? (
          <ActionMenu menuItems={menuItems} />
        ) : (
          "-"
        );
      },
    }),
  ];
};
