import ROUTES from "../.././../routes/api.json";
import axiosConfig from "../../../axiosConfig";
import message from "antd/es/message";
import _each from "lodash/each";
import {
  doIterateNext,
  doIteratePrev,
  getActiveBeneficiaryList,
} from "../utils";
import { TIME_FOR_REGISTER_INTERVAL } from "../../common/constants";

class UnregulatedForeignTrust {
  constructor(screenRef) {
    this.screenRef = screenRef;
  }

  register = (payload) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosConfig
      .put(ROUTES.REGISTER, payload, config)
      .then((res) => {
        message.success("Registration Completed");
      })
      .catch((error) => {});
  };

  checkNextIteration = (list) => {
    let index = list.currentIndex;
    const listLength = list.details.length;
    if (index < listLength - 1) {
      list.currentIndex !== "" && list.currentIndex !== undefined
        ? list.currentIndex++
        : (list.currentIndex = 1);
    } else {
      return false;
    }
    return true;
  };

  checkPrevIteration = (list) => {
    let index = list.currentIndex;
    const listLength = list.details.length;
    if (index < listLength && index - 1 >= 0) {
      list.currentIndex !== "" && list.currentIndex !== undefined
        ? list.currentIndex--
        : (list.currentIndex = 1);
    } else {
      return false;
    }
    return true;
  };

  registerUser = (payload) => {
    this.register(payload);
    setTimeout(() => {
      //replace props.history with window.location to prevent infinite call for validation-token api
      window.location.href = "/order";
    }, TIME_FOR_REGISTER_INTERVAL);
  };

  onNext = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };
    if (currentComponent.key === "investorEntity") {
      componentContent.key = "unRegulatedTrust";
      return componentContent;
    }
    if (currentComponent.key === "unRegulatedTrust") {
      componentContent.key = "unregulatedTrustBeneficiary";
      return componentContent;
    }
    if (currentComponent.key === "unregulatedTrustBeneficiary") {
      componentContent.key = "unregulatedTrustTrustee";
      return componentContent;
    }

    if (currentComponent.key === "unregulatedTrustTrustee") {
      componentContent.key = "trusteeIdentification";
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      componentContent.sequenceCallback = this.trusteeIdentificationCallback;
      return componentContent;
    }

    if (currentComponent.key === "trusteeIdentification") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.trusteeIdentification.typeOfTrustee === "individualTrustee"
      ) {
        componentContent.key = "personalDetails";
      } else if (
        detailsData.trusteeIdentification.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        componentContent.key = "australianCompany";
      } else if (
        detailsData.trusteeIdentification.typeOfTrustee ===
        "foreignCompanyTrustee"
      ) {
        componentContent.key = "foreignCorporateIdentification";
      }
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "personalDetails") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.trusteeIdentification.typeOfTrustee === "individualTrustee"
      ) {
        const doIteration = this.checkNextIteration(
          payload["unregulatedTrustTrustee"]
        );
        if (doIteration) {
          componentContent.key = "trusteeIdentification";
          componentContent.payloadType = "array";
          componentContent.payloadData = "unregulatedTrustTrustee";
          componentContent.payloadParam = "details";
        } else {
          componentContent.key = "unRegulatedTaxInformation";
        }
        return componentContent;
      }
    }

    if (currentComponent.key === "australianCompany") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.trusteeIdentification.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        if (detailsData.australianCompany.companyType === "proprietary") {
          componentContent.payloadType = "array";
          componentContent.payloadData = "unregulatedTrustTrustee";
          componentContent.payloadParam = "details";
          componentContent.key = "proprietaryDirectors";
        }
        if (detailsData.australianCompany.companyType === "public") {
          const doIteration = this.checkNextIteration(
            payload["unregulatedTrustTrustee"]
          );
          if (doIteration) {
            componentContent.key = "trusteeIdentification";
            componentContent.payloadType = "array";
            componentContent.payloadData = "unregulatedTrustTrustee";
            componentContent.payloadParam = "details";
          } else {
            componentContent.key = "unRegulatedTaxInformation";
          }
        }
      }
      return componentContent;
    }

    if (currentComponent.key === "proprietaryDirectors") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.trusteeIdentification.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        const doIteration = this.checkNextIteration(
          payload["unregulatedTrustTrustee"]
        );
        if (doIteration) {
          componentContent.key = "trusteeIdentification";
          componentContent.payloadType = "array";
          componentContent.payloadData = "unregulatedTrustTrustee";
          componentContent.payloadParam = "details";
        } else {
          componentContent.key = "unRegulatedTaxInformation";
        }
      }
      if (
        detailsData.trusteeIdentification.typeOfTrustee ===
          "foreignCompanyTrustee" &&
        detailsData.foreignCorporateIdentification.foreignCompanyType ===
          "private"
      ) {
        componentContent.key = "corporateListingAndRegulatory";
        componentContent.payloadType = "array";
        componentContent.payloadData = "unregulatedTrustTrustee";
        componentContent.payloadParam = "details";
      }
      return componentContent;
    }

    if (currentComponent.key === "foreignCorporateIdentification") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      componentContent.key = "foreignCorporateDetails";
      componentContent.dependentProps = {
        registeredByASIC:
          detailsData.foreignCorporateIdentification.registeredByASIC,
      };
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "foreignCorporateDetails") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.foreignCorporateIdentification.foreignCompanyType ===
        "private"
      ) {
        componentContent.key = "proprietaryDirectors";
      } else if (
        detailsData.foreignCorporateIdentification.foreignCompanyType ===
        "public"
      ) {
        componentContent.key = "corporateListingAndRegulatory";
      }
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
    }

    if (currentComponent.key === "corporateListingAndRegulatory") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.corporateListingAndRegulatory.isAustralianListedCompany ===
          false &&
        detailsData.corporateListingAndRegulatory.isPublicListedCompany ===
          false &&
        detailsData.corporateListingAndRegulatory.isRegulatedInAustralia ===
          false
      ) {
        componentContent.key = "beneficialOwnership";
        componentContent.payloadType = "array";
        componentContent.payloadData = "unregulatedTrustTrustee";
        componentContent.payloadParam = "details";
      } else {
        const doIteration = this.checkNextIteration(
          payload["unregulatedTrustTrustee"]
        );
        if (doIteration) {
          componentContent.key = "trusteeIdentification";
          componentContent.payloadType = "array";
          componentContent.payloadData = "unregulatedTrustTrustee";
          componentContent.payloadParam = "details";
        } else {
          componentContent.key = "unRegulatedTaxInformation";
        }
      }
      return componentContent;
    }

    if (currentComponent.key === "beneficialOwnership") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      let list = getActiveBeneficiaryList(detailsData);
      let doIteration = doIterateNext(
        detailsData[payloadField],
        list,
        (item) => true
      );
      if (doIteration) {
        componentContent.key = "beneficialOwnerFactaDetails";
        componentContent.payloadType = "array";
        componentContent.payloadData = "unregulatedTrustTrustee";
        componentContent.payloadParam = "details";
      }
      return componentContent;
    }

    if (currentComponent.key === "beneficialOwnerFactaDetails") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      let list = getActiveBeneficiaryList(detailsData);
      let doIteration = doIterateNext(
        detailsData[payloadField],
        list,
        (item) => true
      );
      if (doIteration) {
        componentContent.key = "beneficialOwnerFactaDetails";
        componentContent.payloadType = "array";
        componentContent.payloadData = "unregulatedTrustTrustee";
        componentContent.payloadParam = "details";
      } else if (this.checkNextIteration(payload["unregulatedTrustTrustee"])) {
        componentContent.key = "trusteeIdentification";
        componentContent.payloadType = "array";
        componentContent.payloadData = "unregulatedTrustTrustee";
        componentContent.sequenceCallback = this.trusteeIdentificationCallback;
        componentContent.payloadParam = "details";
      } else {
        componentContent.key = "unRegulatedTaxInformation";
      }
      return componentContent;
    }

    if (currentComponent.key === "unRegulatedTaxInformation") {
      if (
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
          "foreignCharity" ||
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
          "activeNonFinancial" ||
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
          "other"
      ) {
        componentContent.key = "countryOfTaxResidency";
      } else if (
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
        "australianRegisteredCharity"
      ) {
        componentContent.key = "settlementInstructions";
      } else {
        if (
          payload.unregulatedTrustTaxInformation
            .isNonCRSJurisdictionAndManagedByAFinancialInsto === "no"
        ) {
          componentContent.key = "settlementInstructions";
        } else {
          componentContent.key = "unRegulatedTrustBeneficialTaxInformation";
        }
      }
    }

    if (
      currentComponent.key === "countryOfTaxResidency" ||
      currentComponent.key === "unRegulatedTrustBeneficialTaxInformation"
    ) {
      componentContent.key = "settlementInstructions";
    }

    if (currentComponent.key === "settlementInstructions") {
      componentContent.key = "chessDetails";
    }

    if (currentComponent.key === "chessDetails") {
      this.uploadFileAndSaveRegister(
        payload[currentComponent.key],
        payload,
        currentComponent
      );
      componentContent.key = "unRegulatedVerificationProcedure";
    }

    if (currentComponent.key === "unRegulatedVerificationProcedure") {
      this.uploadFileAndSaveRegister(
        payload[currentComponent.key],
        payload,
        currentComponent
      );
      componentContent.next = "Complete Registration";
      componentContent.key = "subscriptionOptions";
    }
    return componentContent;
  };

  onPrev = (currentComponent, payload, payloadField) => {
    const componentContent = {
      prev: "Back",
      next: "Next",
    };
    if (currentComponent.key === "unRegulatedTrust") {
      componentContent.prev = null;
      componentContent.next = "Begin";
      componentContent.key = "investorEntity";
      componentContent.includePayloadCallback = true;
      return componentContent;
    }
    if (currentComponent.key === "unregulatedTrustBeneficiary") {
      componentContent.key = "unRegulatedTrust";
      return componentContent;
    }
    if (currentComponent.key === "unregulatedTrustTrustee") {
      componentContent.key = "unregulatedTrustBeneficiary";
      return componentContent;
    }

    if (currentComponent.key === "trusteeIdentification") {
      let doIteration = this.checkPrevIteration(
        payload["unregulatedTrustTrustee"]
      );
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (doIteration) {
        if (
          detailsData.trusteeIdentification.typeOfTrustee ===
          "individualTrustee"
        ) {
          componentContent.key = "personalDetails";
        } else if (
          detailsData.trusteeIdentification.typeOfTrustee ===
          "australianCompanyTrustee"
        ) {
          if (detailsData?.australianCompany?.companyType === "proprietary") {
            componentContent.key = "proprietaryDirectors";
          } else if (detailsData?.australianCompany?.companyType === "public") {
            componentContent.key = "australianCompany";
          }
        } else if (
          detailsData.trusteeIdentification.typeOfTrustee ===
          "foreignCompanyTrustee"
        ) {
          if (
            detailsData.corporateListingAndRegulatory
              .isAustralianListedCompany === false &&
            detailsData.corporateListingAndRegulatory.isPublicListedCompany ===
              false &&
            detailsData.corporateListingAndRegulatory.isRegulatedInAustralia ===
              false
          ) {
            let list = getActiveBeneficiaryList(detailsData);
            let doIteration = doIteratePrev(
              detailsData["beneficialOwnership"],
              list,
              (item) => true
            );
            if (doIteration) {
              componentContent.key = "beneficialOwnerFactaDetails";
            } else {
              componentContent.key = "beneficialOwnership";
            }
          } else {
            componentContent.key = "corporateListingAndRegulatory";
          }
        }
        componentContent.payloadType = "array";
        componentContent.payloadData = "unregulatedTrustTrustee";
        componentContent.payloadParam = "details";
      } else {
        componentContent.key = "unregulatedTrustTrustee";
      }
      return componentContent;
    }

    if (
      currentComponent.key === "personalDetails" ||
      currentComponent.key === "foreignCorporateIdentification" ||
      currentComponent.key === "australianCompany"
    ) {
      componentContent.key = "trusteeIdentification";
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      componentContent.sequenceCallback = this.trusteeIdentificationCallback;
      return componentContent;
    }

    if (currentComponent.key === "proprietaryDirectors") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData?.trusteeIdentification?.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        componentContent.key = "australianCompany";
      } else {
        componentContent.key = "foreignCorporateDetails";
        componentContent.dependentProps = {
          registeredByASIC:
            detailsData.foreignCorporateIdentification.registeredByASIC,
        };
      }
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "foreignCorporateDetails") {
      componentContent.key = "foreignCorporateIdentification";
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "corporateListingAndRegulatory") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.foreignCorporateIdentification.foreignCompanyType ===
        "private"
      ) {
        componentContent.key = "proprietaryDirectors";
      } else if (
        detailsData.foreignCorporateIdentification.foreignCompanyType ===
        "public"
      ) {
        componentContent.key = "foreignCorporateDetails";
        componentContent.dependentProps = {
          registeredByASIC:
            detailsData.foreignCorporateIdentification.registeredByASIC,
        };
      }
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "beneficialOwnership") {
      componentContent.key = "corporateListingAndRegulatory";
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "beneficialOwnerFactaDetails") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      let list = getActiveBeneficiaryList(detailsData);
      let doIteration = doIteratePrev(
        detailsData["beneficialOwnership"],
        list,
        (item) => true
      );
      if (doIteration) {
        componentContent.key = "beneficialOwnerFactaDetails";
      } else {
        componentContent.key = "beneficialOwnership";
      }
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "unRegulatedTaxInformation") {
      const index = payload["unregulatedTrustTrustee"]?.currentIndex;
      const detailsData = payload["unregulatedTrustTrustee"]?.details[index];
      if (
        detailsData.trusteeIdentification.typeOfTrustee === "individualTrustee"
      ) {
        componentContent.key = "personalDetails";
      } else if (
        detailsData.trusteeIdentification.typeOfTrustee ===
        "australianCompanyTrustee"
      ) {
        if (detailsData?.australianCompany?.companyType === "proprietary") {
          componentContent.key = "proprietaryDirectors";
        } else if (detailsData?.australianCompany?.companyType === "public") {
          componentContent.key = "australianCompany";
        }
      } else if (
        detailsData.trusteeIdentification.typeOfTrustee ===
        "foreignCompanyTrustee"
      ) {
        if (
          detailsData.corporateListingAndRegulatory
            .isAustralianListedCompany === false &&
          detailsData.corporateListingAndRegulatory.isPublicListedCompany ===
            false &&
          detailsData.corporateListingAndRegulatory.isRegulatedInAustralia ===
            false
        ) {
          let list = getActiveBeneficiaryList(detailsData);
          let doIteration = doIteratePrev(
            detailsData["beneficialOwnership"],
            list,
            (item) => true
          );
          if (doIteration) {
            componentContent.key = "beneficialOwnerFactaDetails";
          } else {
            componentContent.key = "beneficialOwnership";
          }
        } else {
          componentContent.key = "corporateListingAndRegulatory";
        }
      }
      componentContent.payloadType = "array";
      componentContent.payloadData = "unregulatedTrustTrustee";
      componentContent.payloadParam = "details";
      return componentContent;
    }

    if (currentComponent.key === "settlementInstructions") {
      if (
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
          "foreignCharity" ||
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
          "activeNonFinancial" ||
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
          "other"
      ) {
        componentContent.key = "countryOfTaxResidency";
      } else if (
        payload.unregulatedTrustTaxInformation.unregulatedTrustTaxStatus ===
        "australianRegisteredCharity"
      ) {
        componentContent.key = "unRegulatedTaxInformation";
      } else {
        if (
          payload.unregulatedTrustTaxInformation
            .isNonCRSJurisdictionAndManagedByAFinancialInsto === "no"
        ) {
          componentContent.key = "unRegulatedTaxInformation";
        } else {
          componentContent.key = "unRegulatedTrustBeneficialTaxInformation";
        }
      }
    }

    if (currentComponent.key === "unRegulatedTrustBeneficialTaxInformation") {
      if (
        payload.unregulatedTrustTaxInformation
          .isNonCRSJurisdictionAndManagedByAFinancialInsto === "yes"
      ) {
        componentContent.key = "unRegulatedTaxInformation";
      }
      return componentContent;
    }

    if (currentComponent.key === "countryOfTaxResidency") {
      componentContent.key = "unRegulatedTaxInformation";
      return componentContent;
    }

    if (currentComponent.key === "chessDetails") {
      componentContent.key = "settlementInstructions";
    }

    if (currentComponent.key === "unRegulatedVerificationProcedure") {
      componentContent.key = "chessDetails";
    }

    if (currentComponent.key === "subscriptionOptions") {
      componentContent.key = "unRegulatedVerificationProcedure";
    }
    return componentContent;
  };

  uploadFileAndSaveRegister = async (
    payloadData,
    payload,
    currentComponent
  ) => {
    let fileList = payloadData["documentList"];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        let res = await this.uploadFile(fileList[i].originFileObj);
        if (res && res.data) {
          payloadData[fileList[i].fileId] = {};
          payloadData[fileList[i].fileId] = res.data;
        } else {
          message.error("File upload failed");
          return;
        }
      }
    }

    this.saveRegistrationDetails(payload, currentComponent);
  };

  uploadFile = async (fileObj) => {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("file", fileObj);
    let userId = JSON.parse(sessionStorage.getItem("UserDetails")).investorId;
    let res = await axiosConfig.post(
      `${ROUTES.FILE_UPLOAD}/${userId}`,
      formData,
      config
    );
    return res;
  };

  saveRegistrationDetails(data, currentComponent) {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //Added to maintain JSON structure as of Participant registration
    let userRegisterData = {};
    userRegisterData["currentComponent"] = currentComponent;
    userRegisterData["payload"] = data;

    axiosConfig
      .post(`${ROUTES.STORAGE_URL}`, JSON.stringify(userRegisterData), config)
      .then((res) => {
        console.log("Server result:", res);
      })
      .catch((error) => {
        console.log("Server error: " + error);
      });
  }

  trusteeIdentificationCallback = (payloadData) => {
    const index = payloadData["unregulatedTrustTrustee"]?.currentIndex;
    const detailsData = payloadData["unregulatedTrustTrustee"]?.details[index];
    const resetContent = [
      "user",
      "australianCompany",
      "foreignCorporateIdentification",
      "proprietaryDirectors",
      "corporateListingAndRegulatory",
      "foreignCorporateIdentification",
      "foreignCorporateDetails",
      "beneficialOwnership",
      "beneficialOwnerFactaDetails",
    ];
    _each(detailsData, (value, key) => {
      if (resetContent.indexOf(key) >= 0) {
        delete detailsData[key];
      }
    });
    return payloadData;
  };
}

export default UnregulatedForeignTrust;
